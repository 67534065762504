import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  AppBar,
  Button,
  Grid,
  Theme,
  Typography,
  Select,
  IconButton
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { push } from "connected-react-router";
import Input from "@mui/material/Input";
import MenuItem from "@mui/material/MenuItem";
import { getConfig } from "../../config/config";
import { publicClient } from "@toorak/tc-common-fe-sdk";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { setTabView } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  editCustomer,
  getOriginatorAccounts,
  getOriginatorNames
} from "../create-loan.action";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { originatorUserFormFields } from "./ToorakUsers/toorakUsersFormConfig";
import axios from "axios";
// import * as Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { CloseIcon } from "../Icons";
import { getCookie } from "../../utils/cookies";

// export function getCookie(name: string) {
//   return Cookies.get(name) === undefined ? null : Cookies.get(name);
// }

export const UserCreation = (props: any) => {
  let rolesSelected: string[] = [];

  let {
    hideAppbar: hideAppBar,
    isEditMode: isUpdateMode,
    originatorSelected: originators,
    searchUserData: userData
  } = props;

  rolesSelected = useMemo(() => {
    if (isUpdateMode) {
      return userData?.roles?.map((r: any) => r.roleName);
    }
  }, [userData, isUpdateMode]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      dialogButton: {
        width: 70,
        height: 28,
        borderRadius: 4,
        boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
        backgroundColor: "#5e72e4",
        marginTop: "20px"
      },
      loanCreationTypography: {
        alignSelf: "center",
        color: "#FFF"
      },
      selected: {
        color: "#5e72e4",
        fontWeight: 600
      },
      fixedHeader: {
        position: "fixed",
        height: "60px",
        top: "0"
      },
      headerText: {
        fontSize: "1em",
        [theme.breakpoints.down("sm")]: {
          fontSize: "0.8em"
        }
      },
      cards: {
        width: 1172,
        margin: "100px 33px 0 44px",
        padding: "54px 68px 35px 67px",
        backgroundColor: "#fbfbfd"
      },
      heading: {
        textAlign: "center"
      },
      formControl: {},
      formSelect: {
        borderBottomColor: "#8897aa",
        borderRadius: 5,
        marginTop: "15px",
        width: 300
      },
      formSpan: {
        fontWeight: 600,
        fontSize: "12px",
        color: "#8897aa",
        margin: "0px"
      },
      asterick: {
        color: "red"
      },
      hideAstrick: {
        display: "none"
      },
      InputValue: {
        border: "solid 1px #cad1d7",
        borderRadius: "4px",
        background: "#ffffff",
        color: "#000000",
        padding: "2px 5px",
        height: "36px",
        width: "100%",
        marginBottom: "20px"
      },
      headerInputText: {
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: 1.5,
        color: "#32325d",
        marginBottom: "5px"
      }
    })
  );

  const classes = useStyles();
  const getDefaultValues = () => {
    let fieldValues = {};
    if (isUpdateMode) {
      originatorUserFormFields.forEach((fields: any) => {
        fieldValues = { ...fieldValues, [fields.key]: userData?.[fields.key] };
      });
    } else {
      originatorUserFormFields.forEach((fields: any) => {
        fieldValues = { ...fieldValues, [fields.key]: "" };
      });
    }
    return fieldValues;
  };
  const [userFormObject, setUserFormObject] = useState<any>(getDefaultValues());
  const [isForeignNational, setIsForeignNational] = useState(
    userData?.isForeignNational || false
  );

  const [selectedOrig, setSelectedOrig] = useState<string[]>(originators || []);
  const [origList, setOrgList] = useState<any>([]);
  const [updateObject, setUpdateObject] = useState<any>({
    disableButton: true,
    isCreated: false,
    isRoleUpdated: false,
    isPrimaryDetailsUpdated: false
  });

  const [customMessage, setCustomMessage] = useState<string>("");
  const [roles, setRoles] = useState([]);
  const [selectedRole, setselectedRole] = useState<string[]>(
    rolesSelected || []
  );

  const getOriginatorAccountNames = async () => {
    const responseData: any = await getOriginatorAccounts();
    const data: any = responseData.data.response.hits.hits;
    const allOrig: any = data?.map((item: any) => item._source.accountName);
    setOrgList([...allOrig]);
  };

  useEffect(() => {
    getOriginatorAccountNames();
    dispatch(hideLoader());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleUpdateChanges = (key: string, value: any) => {
    setUpdateObject((prevValue: any) => ({
      ...prevValue,
      [key]: value
    }));
  };

  const buttonDisable = () => {
    let isButtonDisabled = false;
    originatorUserFormFields.forEach((fields: any) => {
      if (fields.isMandatory && userFormObject?.[fields.key] === undefined) {
        handleUpdateChanges("disableButton", true);
        isButtonDisabled = true;
      }
    });
    if (!isButtonDisabled) {
      handleUpdateChanges("disableButton", false);
    }
  };

  const getOriginatorRoles = (Role: []) => {
    const tempRoles = Role.filter((role: any) => {
      return role.roleName.startsWith("ORIGINATOR_");
    });
    return tempRoles;
  };

  async function getFilteredRoles() {
    let allRoles: any = [];
    const res: any = await getOriginatorNames();
    allRoles.push(res.data.data);
    let filteredRoles: any = getOriginatorRoles(allRoles[0]);
    setRoles(filteredRoles);
  }

  useEffect(() => {
    getFilteredRoles();
    dispatch(hideLoader());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedOrig(event.target.value as string[]);
  };

  const handleChangeRoles = (event: React.ChangeEvent<{ value: unknown }>) => {
    handleUpdateChanges("isRoleUpdated", true);
    setselectedRole(event.target.value as string[]);
  };

  const handleChangeUserForm = (key: string, value: string) => {
    setUserFormObject((prevValue: any) => ({
      ...prevValue,
      [key]: value
    }));
  };

  const closeDialog = () => {
    handleUpdateChanges("isCreated", false);

    if (!hideAppBar) {
      dispatch(setTabView(5));
      navigate("/loans/list?tab=5");
    } else {
      window.location.reload();
    }
  };

  const createUSER = () => {
    var data = {
      addressList: [],
      contactList: [],
      dateOfBirth: null,
      firstName: userFormObject.firstName,
      middleName: userFormObject.middleName,
      lastName: userFormObject.lastName,
      isForeignNational: isForeignNational,
      primaryEmail: userFormObject.primaryEmail,
      password: "",
      partyType: "person",
      roleMapping: {
        account: selectedOrig,
        roles: selectedRole,
        accountType: "ORIGINATOR"
      }
    };
    const url = `${getConfig().apiUrl}/aggregate/create/user`;
    publicClient
      .post(url, data, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((r: any) => {
        setCustomMessage("Created");
        handleUpdateChanges("isCreated", true);
      });
  };

  return (
    <>
      {!hideAppBar && (
        <AppBar
          color="default"
          position="static"
          className={classes.fixedHeader}
        >
          <Grid container spacing={1}>
            <Grid item>
              <Button
                data-testid="nav-back"
                variant="contained"
                color="primary"
                startIcon={
                  <KeyboardArrowLeftIcon className={classes.headerText} />
                }
                onClick={() => {
                  dispatch(setTabView(4));
                  navigate("/loans/list?tab=5");
                }}
              >
                <Typography className={classes.headerText}>Back</Typography>
              </Button>
            </Grid>
            <Grid item className={classes.loanCreationTypography}>
              <Typography className={classes.headerText}>
                User Creation Request
              </Typography>
            </Grid>
          </Grid>
        </AppBar>
      )}

      <Grid className={classes.cards} container spacing={2}>
        <div>
          <IconButton
            onClick={() => {
              dispatch(setTabView(0));
              navigate("/support/home/page");
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <Grid item sm={12} md={12} xl={12} xs={12}>
          <Typography className={classes.heading}>
            Please fill the details to create a User
          </Typography>
        </Grid>
        {originatorUserFormFields.map((item: any) => {
          return (
            <Grid item sm={6} md={6} xl={6} xs={6}>
              <p className={classes.headerInputText}>
                {item.label}
                <span
                  className={`${classes.asterick} ${
                    item.label === "Middle Name" ? classes.hideAstrick : null
                  }`}
                >
                  *
                </span>
              </p>
              <Input
                autoFocus={true}
                type={item.inputType}
                disabled={item.isViewMode}
                className={classes.InputValue}
                value={userFormObject[item.key]}
                placeholder={""}
                onChange={(e: any) => {
                  handleUpdateChanges("isPrimaryDetailsUpdated", true);
                  buttonDisable();
                  handleChangeUserForm(item.key, e.target.value);
                }}
              />
            </Grid>
          );
        })}
        <Grid item sm={6} md={6} xl={6} xs={6}>
          <FormControl className={classes.formControl}>
            <span
              id="demo-mutiple-name-label"
              style={{
                fontWeight: 600,
                fontSize: "12px",
                color: "#8897aa",
                margin: "0px"
              }}
            >
              Select Originator
            </span>

            <Select
              className={classes.formSelect}
              variant="outlined"
              labelId="demo-mutiple-name-label"
              id="demo-mutiple-name"
              multiple
              value={selectedOrig}
              onChange={handleChange as any}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {origList.map((name: any) => (
                <MenuItem
                  key={name}
                  value={name}
                  selected
                  classes={{ selected: classes.selected }}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item sm={6} md={6} xl={6} xs={6}>
          <FormControl className={classes.formControl}>
            <span id="demo-mutiple-name-label" className={classes.formSpan}>
              Roles
            </span>
            <Select
              className={classes.formSelect}
              variant="outlined"
              labelId="demo-mutiple-name-label"
              multiple
              id="demo-mutiple-name"
              value={selectedRole}
              onChange={handleChangeRoles as any}
              input={<Input />}
              MenuProps={MenuProps}
            >
              {roles.map((name: any) => (
                <MenuItem
                  key={name.roleId}
                  value={name.roleName}
                  selected
                  classes={{ selected: classes.selected }}
                >
                  {name.roleName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item sm={6} md={6} xl={6} xs={6}>
          <FormControlLabel
            style={{
              margin: "30px 0px 0px 30px",
              fontWeight: 600,
              fontSize: "12px",
              color: "rgb(136, 151, 170)"
            }}
            control={
              <Switch
                checked={isForeignNational}
                onChange={() => {
                  setIsForeignNational(!isForeignNational);
                }}
                name="isForeignNational"
              />
            }
            label="isForeignNational"
            labelPlacement="start"
          />
        </Grid>

        <Grid item sm={12} md={12} xl={12} xs={12}>
          {!isUpdateMode ? (
            <Button
              className={classes.dialogButton}
              variant="contained"
              onClick={() => {
                createUSER();
              }}
              disabled={updateObject.disableButton}
              color="info"
            >
              Create
            </Button>
          ) : (
            <Button
              className={classes.dialogButton}
              variant="contained"
              color="info"
              onClick={async () => {
                const body = {
                  addressList: [],
                  contactList: [],
                  partyAttributes: {},
                  dateOfBirth: null,
                  firstName: userFormObject.firstName,
                  middleName: userFormObject.middleName,
                  lastName: userFormObject.lastName,
                  isForeignNational: isForeignNational,
                  primaryEmail: userFormObject.primaryEmail,
                  isTestParty: false,
                  partyType: "person",
                  roles: selectedRole
                };
                try {
                  if (updateObject.isPrimaryDetailsUpdated) {
                    dispatch(showLoader());
                    const resp: any = await editCustomer(
                      body,
                      userData?.partyId
                    );
                    if (resp.status === 200) {
                      dispatch(hideLoader());
                    }
                  }
                  const roleIds: any = [];
                  selectedRole.forEach((item) => {
                    roles.forEach((role: any) => {
                      if (item === role.roleName)
                        roleIds.push({ roleId: role.roleId });
                    });
                  });

                  const updatingRoles = await axios({
                    method: "GET",
                    url: `${getConfig().apiUrl}/access/person/${
                      userData?.personId
                    }/access?accountId=${userData?.accountId}`,
                    headers: {
                      Authorization: `Bearer ${getCookie("id_token")}`
                    },

                    data: {}
                  });

                  const updatingRolesIds =
                    updatingRoles?.data?.data?.roles?.map(
                      (d: any) => d?.roleId
                    );

                  const uniqueIds = roleIds.filter(
                    (d: any) => updatingRolesIds.indexOf(d.roleId) < 0
                  );

                  const rolesBody = {
                    personId: userData?.personId,
                    accountId: userData?.accountId,
                    roles: uniqueIds
                  };

                  const url = `${getConfig().apiUrl}/access/person/account`;

                  if (updateObject.isRoleUpdated) {
                    dispatch(showLoader());
                    return new Promise((resolve, reject) => {
                      publicClient
                        .post(url, rolesBody)
                        .then((res: any) => {
                          resolve(res);
                          dispatch(hideLoader());
                          window.location.reload();
                        })
                        .catch(reject);
                    });
                  }
                } catch (e) {
                  console.error(e);
                  dispatch(hideLoader());
                }
                setCustomMessage("Updated");
                handleUpdateChanges("isCreated", true);
              }}
            >
              Update
            </Button>
          )}
        </Grid>
      </Grid>
      <Dialog
        open={updateObject.isCreated}
        onClose={closeDialog}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">SUCCESS..</DialogTitle>
        <DialogContent>
          <DialogContentText>
            USER is {customMessage} successfully..!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <button
            onClick={closeDialog}
            className={classes.dialogButton}
            color="primary"
          >
            OK
          </button>
        </DialogActions>
      </Dialog>
    </>
  );
};
