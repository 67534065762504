/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, ChangeEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  Grid,
  Theme,
  Tooltip,
  Dialog,
  Button
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import CloseIcon from "@mui/icons-material/Close";
import {
  DropDown,
  PropertyInformation,
  CreateLoanParamsBridgeLoan,
  LoanStage,
  TextFieldForm
} from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { useParams } from "react-router-dom";
import moment from "moment";
import { BridgeLoanData } from "../form-components/FormComponentsProps";
import {
  updatePropertyDetails,
  previousLoanState,
  validateForm as validateFormCheck,
  // valueEditing,
  postPropertyData,
  // POST_PROPERTY_SUCCESS,
  // POST_PROPERTY_FAILED,
  updatePreviousLoanStateAction
} from "../create-loan.action";
import { RootState } from "../../stores/rootReducer";
import iconMinimize from "../../images/icon_minimize.svg";
import iconMaximize from "../../images/icon_maximize.svg";
import editImage from "../../images/Edit.svg";
import {
  PropertyTypeDropDown,
  NoOfUnitsTextField,
  PropertyValuationDateTextField,
  PropertyAcquisitionDateTextField,
  ChangeInUseCaseDropDown,
  PreRehabSquareFootageTextField,
  PostRehabSquareFootageTextField,
  PercentageOccupiedField
} from "../form-components/PropertyInformation";
import {
  globalSaveOnPropertyFieldChange,
  prepareCommentObjectProperty
} from "./PropertyDetailsPropertyEconomics";
import {
  propertyTypeDropDown,
  rentalPropertyTypeDropDown,
  ToorakProductEnum,
  yesNoDropdown,
  yOrNDropDown
} from "../constants/loanCreationDropDownValues";
import {
  checkFieldValidity,
  errorDateText,
  sanitizeNumericInput,
  isValidPercentage,
  cleanPercentage,
  sanitizePercentage,
  isValidNumber,
  enableReasonField,
  formatNumberToCommaSeparated,
  isEmptyValue,
  editHistoryValue
} from "../../utils/formatChecks";
import { bridgePropertyFields } from "../constants/loanFieldType";
import { getEvaluationRequestBody } from "../evaluation-results/helpers/ruleRequestBody";
import { evaluateLoan } from "../../stores/EvaluationResults/EvaluationResults.action";
import { isLATUser } from "../../utils/AccessManagement";
import { defaultDateFormat, MMMDDYYYYDateFormat } from "../../utils/utils";
import { requestWaiverMessage } from "../../utils/constants";
import { usePriorStages } from "../appraisal/shared/loanLandmarks";
import { useAppraisalForm } from "../appraisal/shared/useAppraisalForm";
import {
  loanTypeFailed,
  setAutoSaveCall,
  updatePropertySuccess,
  valueEditing
} from "../create-loan.reducer";

export const isHidden = (fieldId: string, loanData: any) => {
  const isHiddenFn = bridgePropertyFields?.[fieldId]?.isHidden;
  return isHiddenFn && isHiddenFn(loanData);
};
let changedValueFields: any = [];
const editableFlags: any = {};
let originalPropertyInfo: any;

const propertyTypeValues = propertyTypeDropDown.map((item) => ({
  value: item,
  label: item === "SFR" ? "Single Family Detached" : item
}));
const rentalPropertyTypeValues = rentalPropertyTypeDropDown.map((item) => ({
  value: item,
  label: item === "SFR" ? "Single Family Detached" : item
}));
const changeInUseCaseValues = yOrNDropDown.map((item) => ({
  value: item,
  label: item
}));

const propertyPurchasedLastTwoYearsValues = yesNoDropdown.map((item) => ({
  value: item,
  label: item
}));

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },

    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      color: "#32325d",
      backgroundColor: "#fff",
      "&:hover": {
        background: "none"
      },
      marginLeft: 10
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    editImageDisplay: {
      display: "none"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32355d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    modalDeleteButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    }
  })
);

export interface PropertyDetailsPropertyInformationProps {
  propertyInformation?: PropertyInformation | null;
  propertyId: string;
  isEvaluationPanelVisible: boolean;
  data?: BridgeLoanData;
  isAllowedToEdit: boolean;
  index?: number;
  isMandatoryServicerField?: any;
}

export function PropertyDetailsPropertyInformation(
  props: PropertyDetailsPropertyInformationProps
) {
  const {
    propertyInformation,
    propertyId,
    isEvaluationPanelVisible,
    isAllowedToEdit,
    data,
    isMandatoryServicerField,
    index
  } = props;
  const colSpan = 4;
  const classes = useStyles();
  const dispatch = useDispatch();

  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const {
    propertyDetails,
    loanState
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = loanDetails;
  const validateForm = useSelector<RootState, { validate: boolean }>(
    (state) => state.createLoanStore.validateForm
  );
  const [errors, setErrors] = useState(0);
  const [isMinimized, setMinimized] = useState(false);
  const [isEditable, setEditable] = useState(false);
  const [propertyTypeEdited, setPropertyTypeEdited] = useState(false);
  const [
    propertyPurchasedLastTwoYearsEdited,
    setPropertyPurchasedLastTwoYearsEdited
  ] = useState(false);
  const [numberOfUnitsEdited, setNumberOfUnitsEdited] = useState(false);
  const [acquistionDateEdited, setAcquistionDateEdited] = useState(false);
  const [valuetionDateEdited, setValuetionDateEdited] = useState(false);
  const [preRehabEdited, setPreRehabEdited] = useState(false);
  const [postRehabEdited, setPostRehabEdited] = useState(false);
  const [changeUseCaseEdited, setChangeUseCaseEdited] = useState(false);
  const [percentChangedEdited, setPercentChangedEdited] = useState(false);
  const activePropertyId = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails.activePropertyId
  );
  const [resetData, setResetData] = useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };

  const [callAutoSave, setCallAutoSave] = useState<{
    value: boolean;
    impactingFieldChange: boolean;
  }>({ value: false, impactingFieldChange: false });
  const { loanId, loanStage } = useParams<any>() as any;
  useEffect(() => {
    if (callAutoSave.value) {
      postPropertyAutoSave(loanId, propertyDetails);
      setCallAutoSave({ value: false, impactingFieldChange: false });
    }
  }, [propertyInformation]);

  const handleClose = () => {
    setOpen(false);
  };
  const loanEvaluationResult = useSelector<
    RootState,
    { loanResult: any; propertiesResults: any }
  >((state) => state.evaluationResultsStore?.loanEvaluationResult);
  let infoContent = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentProperty
  );
  if (infoContent) {
    infoContent = JSON.parse(JSON.stringify(infoContent));
  }
  // Error
  const [selectedPropertyTypeError, setSelectedPropertyTypeError] =
    useState("");
  const [noOfUnitsError, setNoOfUnitsError] = useState("");
  const [propertyEvaluationDateError, setPropertyEvaluationDateError] =
    useState("");
  const [propertyAcquisitionDateError, setPropertyAcquisitionDateError] =
    useState("");
  const [selectedChangeInUseCaseError, setSelectedChangeInUseCaseError] =
    useState("");
  const [preSquareFootageError, setPreSquareFootageError] = useState("");
  const [postSquareFootageError, setPostSquareFootageError] = useState("");
  const [percentageOccupiedError, setPercentageOccupiedError] = useState("");
  const [
    propertyPurchasedLastTwoYearsError,
    setPropertyPurchasedLastTwoYearsError
  ] = useState("");
  const editHistory = useSelector<RootState, any>(
    (state) => state.createLoanStore.editHistory
  );
  const editHistory2 = editHistory;
  const {
    loanID: appraisalOriginatorLoanId,
    propertySquareFootageProposed,
    propertySquareFootageAsIs
  } = useAppraisalForm();

  useEffect(() => {
    if (appraisalOriginatorLoanId && loanState === LoanStatusEnum.DataEntry) {
      let propertyAcqDate = defaultDateFormat(
        propertyInformation!.propertyAcquisitionDate
      );
      propertyAcqDate = moment(propertyAcqDate).format("YYYY-MM-DD");
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            payload: { propertyAcquisitionDate: new Date(propertyAcqDate) }
          }
        })
      );
      let propertyValDate = defaultDateFormat(
        propertyInformation!.propertyValuationDate
      );
      propertyValDate = moment(propertyValDate).format("YYYY-MM-DD");

      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            payload: { propertyValuationDate: new Date(propertyValDate) }
          }
        })
      );
    }
  }, [loanId, loanStage, appraisalOriginatorLoanId]);

  useEffect(() => {
    if (loanState === LoanStatusEnum.DataEntry) {
      if (propertySquareFootageProposed) {
        const sanitizedpostRehabSquare = sanitizeNumericInput(
          propertySquareFootageProposed.toString()
        );
        dispatch(
          updatePropertyDetails(propertyId, {
            propertyInformation: {
              payload: {
                postRehabSquareFootage: sanitizedpostRehabSquare
              }
            }
          })
        );
      }
      if (propertySquareFootageAsIs) {
        const sanitizedpreRehabSquare = sanitizeNumericInput(
          propertySquareFootageAsIs.toString()
        );
        dispatch(
          updatePropertyDetails(propertyId, {
            propertyInformation: {
              payload: { preRehabSquareFootage: sanitizedpreRehabSquare }
            }
          })
        );
      }
    }
  }, [propertySquareFootageProposed, propertySquareFootageAsIs, loanState]);

  useEffect(() => {
    if (resetData && originalPropertyInfo) {
      console.log("calling inside the mein function");
      dispatch(
        previousLoanState(originalPropertyInfo, "propertyInformation", true)
      );
      setResetData(!resetData);
    }
  });

  useEffect(() => {
    const errorCount = [
      selectedPropertyTypeError,
      noOfUnitsError,
      propertyEvaluationDateError,
      propertyAcquisitionDateError,
      selectedChangeInUseCaseError,
      preSquareFootageError,
      postSquareFootageError,
      percentageOccupiedError,
      propertyPurchasedLastTwoYearsError
    ].filter((item) => item).length;

    setErrors(errorCount);
    if (validateForm.validate) {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            errors: errorCount
          }
        })
      );
    }
  }, [
    selectedPropertyTypeError,
    noOfUnitsError,
    propertyEvaluationDateError,
    propertyAcquisitionDateError,
    selectedChangeInUseCaseError,
    preSquareFootageError,
    postSquareFootageError,
    percentageOccupiedError,
    propertyPurchasedLastTwoYearsError,

    dispatch
  ]);

  useEffect(() => {
    if (validateForm.validate) {
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            errors
          }
        })
      );
    }
  }, [validateForm]);

  useEffect(() => {
    if (loanState === LoanStatusEnum.DueDiligenceReview) {
      for (const i in editableFlags) editableFlags[i] = false;
      dispatch(valueEditing(false));
      setEditable(!isEditable);
      // setResetData(!resetData);
      changedValueFields = [];
      setAcquistionDateEdited(false);
      setPostRehabEdited(false);
      setPreRehabEdited(false);
      setPercentChangedEdited(false);
      setValuetionDateEdited(false);
      setChangeUseCaseEdited(false);
      setPropertyTypeEdited(false);
      setNumberOfUnitsEdited(false);
    }
  }, [loanState]);

  useEffect(() => {
    if (activePropertyId && resetData) {
      onCancel();
      updatePreviousLoanState();
      dispatch(
        previousLoanState(originalPropertyInfo, "propertyInformation", true)
      );
      setResetData(!resetData);
    }
  }, [activePropertyId]);

  const onCancel = () => {
    for (const i in editableFlags) editableFlags[i] = false;
    dispatch(valueEditing(false));
    setEditable(!isEditable);
    setResetData(!resetData);
    changedValueFields = [];
    setAcquistionDateEdited(false);
    setPostRehabEdited(false);
    setPreRehabEdited(false);
    setPercentChangedEdited(false);
    setValuetionDateEdited(false);
    setChangeUseCaseEdited(false);
    setPropertyTypeEdited(false);
    setNumberOfUnitsEdited(false);
    prepareCommentObjectProperty("", "", "", true);
  };

  function isFieldValueValid(
    value: any,
    fieldData: { fieldTpe: string; extraData?: any },
    fieldName: string
  ) {
    return (
      isHidden(fieldName, loanDetails) ||
      (!isHidden(fieldName, loanDetails) &&
        checkFieldValidity(value, fieldData))
    );
  }

  function validatePropertyInfo() {
    if (
      !isFieldValueValid(
        propertyInformation?.propertyType,
        bridgePropertyFields.propertyType,
        "propertyType"
      )
    ) {
      setSelectedPropertyTypeError("This field is required");
    } else {
      setSelectedPropertyTypeError("");
    }

    if (
      !isFieldValueValid(
        propertyInformation?.isPropertyPurchasedLast2Years,
        bridgePropertyFields.isPropertyPurchasedLast2Years,
        "isPropertyPurchasedLast2Years"
      )
    ) {
      setPropertyPurchasedLastTwoYearsError("This field is required");
    } else {
      setPropertyPurchasedLastTwoYearsError("");
    }

    if (
      !isFieldValueValid(
        propertyInformation?.changeInUseCase,
        bridgePropertyFields.changeInUseCase,
        "changeInUseCase"
      )
    ) {
      if (
        isEmptyValue(propertyInformation?.changeInUseCase) &&
        bridgePropertyFields.changeInUseCase?.optionalIn?.includes(loanStage)
      ) {
        setSelectedChangeInUseCaseError("");
      } else setSelectedChangeInUseCaseError("This field is required");
    } else {
      setSelectedChangeInUseCaseError("");
    }
    if (
      !isFieldValueValid(
        propertyInformation?.propertyValuationDate,
        bridgePropertyFields.propertyValuationDate,
        "propertyValuationDate"
      )
    ) {
      if (!propertyInformation?.propertyValuationDate) {
        setPropertyEvaluationDateError("This field is required");
      } else {
        setPropertyEvaluationDateError(
          errorDateText(propertyInformation?.propertyValuationDate)
        );
      }
    } else {
      setPropertyEvaluationDateError("");
    }
    if (
      !isFieldValueValid(
        propertyInformation?.propertyAcquisitionDate,
        bridgePropertyFields.propertyAcquisitionDate,
        "propertyAcquisitionDate"
      )
    ) {
      if (!propertyInformation?.propertyAcquisitionDate) {
        setPropertyAcquisitionDateError("This field is required");
      } else {
        setPropertyAcquisitionDateError(
          errorDateText(propertyInformation?.propertyAcquisitionDate)
        );
      }
    } else {
      setPropertyAcquisitionDateError("");
    }
    if (
      !isFieldValueValid(
        propertyInformation!.numberOfUnits,
        bridgePropertyFields.numberOfUnits,
        "numberOfUnits"
      )
    ) {
      if (!propertyInformation?.numberOfUnits) {
        setNoOfUnitsError("This field is required");
      } else {
        setNoOfUnitsError("Please enter a valid number");
      }
    } else {
      setNoOfUnitsError("");
    }
    if (
      !isFieldValueValid(
        propertyInformation!.preRehabSquareFootage,
        bridgePropertyFields.preRehabSquareFootage,
        "preRehabSquareFootage"
      )
    ) {
      setPreSquareFootageError("This field is required");
    } else {
      setPreSquareFootageError("");
    }
    if (
      !isFieldValueValid(
        propertyInformation!.postRehabSquareFootage,
        bridgePropertyFields.postRehabSquareFootage,
        "postRehabSquareFootage"
      )
    ) {
      if (!propertyInformation?.postRehabSquareFootage) {
        setPostSquareFootageError("This field is required");
      } else {
        setPostSquareFootageError("Please enter a valid number");
      }
    } else {
      setPostSquareFootageError("");
    }
    if (
      !isFieldValueValid(
        propertyInformation!.percentageOccupied,
        bridgePropertyFields.percentageOccupied,
        "percentageOccupied"
      )
    ) {
      if (!propertyInformation?.percentageOccupied) {
        setPercentageOccupiedError("This field is required");
      } else {
        setPercentageOccupiedError("Please enter value in decimal");
      }
    } else {
      setPercentageOccupiedError("");
    }
  }
  useEffect(() => {
    if (validateForm.validate) {
      validatePropertyInfo();
    }
  }, [propertyId, propertyInformation, validateForm]);

  const postPropertyAutoSave = (
    impactingFieldChanged: boolean = false,
    extraData?: any
  ) => {
    if (!isEvaluationPanelVisible) {
      dispatch(setAutoSaveCall(true));
      // const extraneousData = extraData || {};
      const promise2 = postPropertyData(loanId, loanStage, propertyDetails);
      promise2
        .then((response: CreateLoanParamsBridgeLoan) => {
          // dispatch({
          //   type: POST_PROPERTY_SUCCESS,
          //   payload: {
          //     reservation: response
          //   }
          // });
          dispatch(updatePropertySuccess());
          if (impactingFieldChanged && data) {
            getEvaluationRequestBody(
              loanId,
              loanStage,
              data,
              loanEvaluationResult,
              propertyDetails
            )
              .then(({ evaluate, loanEvaluationRequest }) => {
                if (evaluate && loanEvaluationRequest) {
                  dispatch(
                    evaluateLoan(loanEvaluationRequest, loanId, loanStage)
                  );
                }else{
                  dispatch(setAutoSaveCall(false));
                }
              })
              .catch((e) => {
                dispatch(setAutoSaveCall(false));
                console.log("error while sending evaluation request", e);
              });
          }else{
            dispatch(setAutoSaveCall(false));
          }
        })
        .catch((error) => {
          console.log("err", error);
          dispatch(setAutoSaveCall(false));
          // dispatch({ type: POST_PROPERTY_FAILED, error });
          dispatch(
            loanTypeFailed({
              error
            })
          );
        });
    }
  };

  // Handle Changes
  const handlepropertyPurchasedLastTwoYearsChange = (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    if (isEvaluationPanelVisible) {
      const { value } = event.target;
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.isPropertyPurchasedLast2Years`,
        value,
        "",
        false,
        true
      );
      if (
        !JSON.stringify(changedValueFields).includes(
          "isPropertyPurchasedLast2Years"
        )
      ) {
        editableFlags[
          `propertyPurchasedLastTwoYearsEdited${activePropertyId}`
        ] = true;
        setPropertyPurchasedLastTwoYearsEdited(
          !propertyPurchasedLastTwoYearsEdited
        );
        changedValueFields.push("isPropertyPurchasedLast2Years");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: {
            isPropertyPurchasedLast2Years: event.target.value as string
          }
        }
      })
    );
    setPropertyPurchasedLastTwoYearsError("");
    setCallAutoSave({ value: true, impactingFieldChange: true });
  };

  const handlePropertyTypeChange = (event: ChangeEvent<{ value: unknown }>) => {
    if (isEvaluationPanelVisible) {
      const value =
        event.target.value === "SFR"
          ? "Single Family Detached"
          : event.target.value;
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.propertyType`,
        value,
        "",
        false,
        true
      );
      if (!JSON.stringify(changedValueFields).includes("propertyType")) {
        editableFlags[`propertyTypeEdited${activePropertyId}`] = true;
        setPropertyTypeEdited(!propertyTypeEdited);
        changedValueFields.push("propertyType");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: { propertyType: event.target.value as string }
        }
      })
    );
    setSelectedPropertyTypeError("");
    setCallAutoSave({ value: true, impactingFieldChange: true });
  };

  const handleChangeInUseCaseChange = (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.changeInUseCase`,
        event.target.value,
        "",
        false,
        true
      );
      if (!JSON.stringify(changedValueFields).includes("changeInUseCase")) {
        editableFlags[`changeUseCaseEdited${activePropertyId}`] = true;
        setChangeUseCaseEdited(!changeUseCaseEdited);
        changedValueFields.push("changeInUseCase");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: { changeInUseCase: event.target.value as string }
        }
      })
    );
    setSelectedChangeInUseCaseError("");
    setCallAutoSave({ value: true, impactingFieldChange: true });
  };
  const handlePropertyEvaluationDateChange = (date: any) => {
    if (date) {
      if (isEvaluationPanelVisible) {
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyinfo.recentPropertyValuationDate`,
          MMMDDYYYYDateFormat(date),
          "",
          false,
          true
        );

        if (
          !JSON.stringify(changedValueFields).includes(
            "mostRecentPropertyValuationDate"
          )
        ) {
          setValuetionDateEdited(!valuetionDateEdited);
          editableFlags[`evaluationDateEdited${activePropertyId}`] = true;
          changedValueFields.push("mostRecentPropertyValuationDate");
        }
      }
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            payload: { propertyValuationDate: date }
          }
        })
      );
      setPropertyEvaluationDateError("");
      setCallAutoSave({ value: true, impactingFieldChange: true });
    }
  };
  const handlePropertyAcquisitionDateChange = (date: any) => {
    if (date) {
      if (isEvaluationPanelVisible) {
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyinfo.propertyAcquisitionDate`,
          MMMDDYYYYDateFormat(date),
          "",
          false,
          true
        );

        if (
          !JSON.stringify(changedValueFields).includes(
            "propertyAcquisitionDate"
          )
        ) {
          setAcquistionDateEdited(!acquistionDateEdited);
          editableFlags[`acquistionDateEdited${activePropertyId}`] = true;
          changedValueFields.push("propertyAcquisitionDate");
        }
      }
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            payload: { propertyAcquisitionDate: date }
          }
        })
      );
      setPropertyAcquisitionDateError("");
      setCallAutoSave({ value: true, impactingFieldChange: true });
    }
  };
  const onPreSquareFootageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sanitizedNumericInput = sanitizeNumericInput(event.target.value);

    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.preRehabSquareFootage`,
        sanitizedNumericInput,
        "",
        false,
        true
      );

      if (
        !JSON.stringify(changedValueFields).includes("preRehabSquareFootage")
      ) {
        setPreRehabEdited(!preRehabEdited);
        editableFlags[`preRehabEdited${activePropertyId}`] = true;
        changedValueFields.push("preRehabSquareFootage");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: { preRehabSquareFootage: sanitizedNumericInput }
        }
      })
    );
    if (!sanitizedNumericInput) {
      setPreSquareFootageError("This field is required");
    } else {
      setPreSquareFootageError("");
    }
  };
  const onPostSquareFootageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sanitizedNumericInput = sanitizeNumericInput(event.target.value);
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.postRehabSquareFootage`,
        sanitizedNumericInput,
        "",
        false,
        true
      );

      if (
        !JSON.stringify(changedValueFields).includes("postRehabSquareFootage")
      ) {
        setPostRehabEdited(!postRehabEdited);
        editableFlags[`postRehabEdited${activePropertyId}`] = true;
        changedValueFields.push("postRehabSquareFootage");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: {
            postRehabSquareFootage: sanitizedNumericInput
          }
        }
      })
    );
    if (!sanitizedNumericInput) {
      setPostSquareFootageError("This field is required");
    } else {
      setPostSquareFootageError("");
    }
  };
  const onNoOfUnitsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sanitizedNumericInput = sanitizeNumericInput(event.target.value);

    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.propertyUnits`,
        sanitizedNumericInput,
        "",
        false,
        true
      );

      if (!JSON.stringify(changedValueFields).includes("numberOfUnits")) {
        setNumberOfUnitsEdited(!numberOfUnitsEdited);
        editableFlags[`numberOfUnitsEdited${activePropertyId}`] = true;
        changedValueFields.push("numberOfUnits");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: { numberOfUnits: sanitizedNumericInput }
        }
      })
    );
    if (!sanitizedNumericInput) {
      setNoOfUnitsError("This field is required");
    } else {
      setNoOfUnitsError("");
    }
  };
  const onPercentageOccupiedChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (isEvaluationPanelVisible) {
      prepareCommentObjectProperty(
        `data.properties[${activePropertyId}].propertyinfo.occupiedPercentage`,
        cleanPercentage(event.target.value),
        "",
        false,
        true
      );

      if (!JSON.stringify(changedValueFields).includes("percentOccupied")) {
        setPercentChangedEdited(!percentChangedEdited);
        editableFlags[`percentChangedEdited${activePropertyId}`] = true;
        changedValueFields.push("percentOccupied");
      }
    }
    dispatch(
      updatePropertyDetails(propertyId, {
        propertyInformation: {
          payload: {
            percentageOccupied: cleanPercentage(event.target.value)
          }
        }
      })
    );
    if (!isValidPercentage(event.target.value)) {
      setPercentageOccupiedError("Please enter value in decimal");
    } else {
      setPercentageOccupiedError("");
    }
  };

  const propertyTypeFocusOut = (event: ChangeEvent<{ value: unknown }>) => {
    const value =
      propertyInformation?.propertyType === "SFR"
        ? "Single Family Detached"
        : propertyInformation?.propertyType;
    prepareCommentObjectProperty(
      `data.properties[${activePropertyId}].propertyinfo.propertyType`,
      value as string,
      event.target.value as string
    );
  };

  const propertyPurchasedLastTwoYearsFocusOut = (
    event: ChangeEvent<{ value: unknown }>
  ) => {
    const value = propertyInformation?.isPropertyPurchasedLast2Years;
    prepareCommentObjectProperty(
      `data.properties[${activePropertyId}].propertyinfo.isPropertyPurchasedLast2Years`,
      value as string,
      event.target.value as string
    );
  };
  const valuationDateFocusOut = (event: ChangeEvent<{ value: unknown }>) => {
    prepareCommentObjectProperty(
      `data.properties[${activePropertyId}].propertyinfo.recentPropertyValuationDate`,
      MMMDDYYYYDateFormat(propertyInformation?.propertyValuationDate),
      event.target.value as string
    );
  };
  const acquistionDateFocusOut = (event: ChangeEvent<{ value: unknown }>) => {
    prepareCommentObjectProperty(
      `data.properties[${activePropertyId}].propertyinfo.propertyAcquisitionDate`,
      MMMDDYYYYDateFormat(propertyInformation?.propertyAcquisitionDate),
      event.target.value as string
    );
  };

  const useCaseFocusOut = (event: ChangeEvent<{ value: unknown }>) => {
    console.log("editing");
    prepareCommentObjectProperty(
      `data.properties[${activePropertyId}].propertyinfo.changeInUseCase`,
      propertyInformation?.changeInUseCase as string,
      event.target.value as string
    );
  };

  const handleBlur =
    (fieldName: string, fieldType: string) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      let formattedValue = event.target.value;
      switch (fieldType) {
        case "number":
          const numericInput = sanitizeNumericInput(event.target.value);
          if (isValidNumber(numericInput)) formattedValue = numericInput;
          formattedValue = formatNumberToCommaSeparated(formattedValue);
          break;
        case "percentage":
          const percentInput = sanitizePercentage(event.target.value);
          if (isValidPercentage(percentInput)) formattedValue = percentInput;
          break;
        default:
      }
      const payload = {
        [fieldName]: formattedValue
      };
      const propertyInfoCopy: any = propertyInformation;
      if (
        event?.target.parentElement?.parentElement?.innerText.includes(
          "Reason For Editing"
        )
      ) {
        let commentFieldName: string = fieldName;
        let fieldValue = propertyInfoCopy[fieldName];
        switch (fieldName) {
          case "postRehabSquareFootage":
            commentFieldName = "postRehabSquareFootage";
            break;
          case "numberOfUnits":
            commentFieldName = "propertyUnits";
            break;
          case "percentageOccupied": {
            commentFieldName = "occupiedPercentage";
            fieldValue = propertyInfoCopy[fieldName].replace("%", "");
            break;
          }
        }
        prepareCommentObjectProperty(
          `data.properties[${activePropertyId}].propertyinfo.${commentFieldName}`,
          fieldValue,
          event.target.value
        );
        return;
      }
      dispatch(
        updatePropertyDetails(propertyId, {
          propertyInformation: {
            payload
          }
        })
      );
      setCallAutoSave({ value: true, impactingFieldChange: true });
    };

  const updatePreviousLoanState = () => {
    originalPropertyInfo = updatePreviousLoanStateAction(
      propertyDetails,
      activePropertyId,
      "propertyInformation"
    );
  };

  const priorStages = usePriorStages();
  const handleServiceFlowRender = (field: string): boolean => {
    return (
      !isMandatoryServicerField ||
      (isMandatoryServicerField && isMandatoryServicerField(field))
    );
  };

  return (
    <div className={classes.container}>
      {/* Minimize Section start */}
      <Grid container spacing={3} className={classes.sectionWrapper}>
        <Grid item xs={12} container>
          <Grid item xs={6} sm={11}>
            <Typography className={classes.headingTypography}>
              Property {index} Information
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1} className={classes.headingImageContainer}>
            {isAllowedToEdit && (
              <img
                className={
                  editableFlags[`editProp${activePropertyId}`] ||
                  !isEvaluationPanelVisible
                    ? classes.editImageDisplay
                    : classes.editImage
                }
                src={editImage}
                alt="Edit Section"
                onClick={() => {
                  for (const i in editableFlags) editableFlags[i] = false;
                  updatePreviousLoanState();
                  dispatch(valueEditing(true));
                  setEditable(!isEditable);
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
                onKeyDown={() => {
                  for (const i in editableFlags) editableFlags[i] = false;
                  updatePreviousLoanState();
                  dispatch(valueEditing(true));
                  setEditable(!isEditable);
                  editableFlags[`editProp${activePropertyId}`] = true;
                }}
              />
            )}
            <Tooltip title={isMinimized ? "Expand" : "Collapse"}>
              <img
                className={classes.headingImage}
                src={isMinimized ? iconMaximize : iconMinimize}
                alt={isMinimized ? "Maximize section" : "Minimize section"}
                onClick={() => {
                  setMinimized(!isMinimized);
                }}
                onKeyDown={() => {
                  setMinimized(!isMinimized);
                }}
              />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      {/* Minimize Section end */}
      {/* Divider Section start */}
      {isMandatoryServicerField ? (
        <div className={classes.divider} />
      ) : (
        <Grid
          item
          xs={12}
          sm={12}
          style={{
            display: isMinimized ? "none" : ""
          }}
        >
          <div className={classes.divider} />
        </Grid>
      )}
      {/* Divider Section end */}{" "}
      <Grid
        container
        spacing={4}
        style={{
          padding: 24,
          paddingTop: isMandatoryServicerField
            ? !handleServiceFlowRender("recentPropertyValuationDate")
              ? 24
              : 24
            : 24,
          marginBottom: isMandatoryServicerField
            ? !handleServiceFlowRender("recentPropertyValuationDate")
              ? "-76px"
              : "-16px"
            : "-16px",
          display: isMinimized ? "none" : ""
        }}
      >
        {handleServiceFlowRender("propertyType") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PropertyTypeDropDown
              selectionValue={propertyInformation!.propertyType}
              selectionOnChange={handlePropertyTypeChange}
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("propertyType")
              }
              selectionData={
                loanDetails?.bridgeLoanInformation?.payload
                  ?.selectedToorakProduct === ToorakProductEnum.Rental //
                  ? rentalPropertyTypeValues
                  : propertyTypeValues
              }
              helperText={
                validateForm.validate ? selectedPropertyTypeError : ""
              }
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={
                editableFlags[`propertyTypeEdited${activePropertyId}`]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "propertyType",
                loanState
              )}
              onBlur={propertyTypeFocusOut}
              editedData={
                editHistory2[
                  `data.properties[${activePropertyId}].propertyinfo.propertyType`
                ]
              }
              isLATView={isLATUser()}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyInfo.propertyType`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("propertyUnits") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <NoOfUnitsTextField
              value={propertyInformation!.numberOfUnits}
              onChange={onNoOfUnitsChange}
              onBlur={handleBlur("numberOfUnits", "number")}
              error={validateForm.validate && noOfUnitsError !== ""}
              helperText={validateForm.validate ? noOfUnitsError : ""}
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={
                editableFlags[`numberOfUnitsEdited${activePropertyId}`]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "numberOfUnits",
                loanState
              )}
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("propertyUnits")
              }
              // onBlur= {numOfUnitsFocusOut}
              editedData={
                editHistory2[
                  `data.properties[${activePropertyId}].propertyinfo.propertyUnits`
                ]
              }
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyInfo.propertyUnits`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("recentPropertyValuationDate") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PropertyValuationDateTextField
              value={propertyInformation!.propertyValuationDate}
              onChange={handlePropertyEvaluationDateChange}
              error={
                validateForm.validate && propertyEvaluationDateError !== ""
              }
              helperText={
                validateForm.validate ? propertyEvaluationDateError : ""
              }
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={
                editableFlags[`evaluationDateEdited${activePropertyId}`]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "mostRecentPropertyValuationDate",
                loanState
              )}
              onBlur={valuationDateFocusOut}
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("recentPropertyValuationDate")
              }
              editedData={
                editHistory2[
                  `data.properties[${activePropertyId}].propertyinfo.recentPropertyValuationDate`
                ]
              }
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyInfo.recentPropertyValuationDate`
                ]
              }
            />
          </Grid>
        )}
        {handleServiceFlowRender("propertyAcquisitionDate") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PropertyAcquisitionDateTextField
              value={propertyInformation!.propertyAcquisitionDate as Date}
              onChange={handlePropertyAcquisitionDateChange}
              error={
                validateForm.validate && propertyAcquisitionDateError !== ""
              }
              helperText={
                validateForm.validate ? propertyAcquisitionDateError : ""
              }
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={
                editableFlags[`acquistionDateEdited${activePropertyId}`]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "propertyAcquisitionDate",
                loanState
              )}
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("propertyAcquisitionDate")
              }
              onBlur={acquistionDateFocusOut}
              editedData={
                editHistory2[
                  `data.properties[${activePropertyId}].propertyinfo.propertyAcquisitionDate`
                ]
              }
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyInfo.propertyAcquisitionDate`
                ]
              }
            />
          </Grid>
        )}
        {!isHidden("changeInUseCase", loanDetails) &&
          handleServiceFlowRender("changeInUseCase") && (
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <ChangeInUseCaseDropDown
                selectionValue={propertyInformation!.changeInUseCase}
                selectionOnChange={handleChangeInUseCaseChange}
                selectionData={changeInUseCaseValues}
                helperText={
                  validateForm.validate ? selectedChangeInUseCaseError : ""
                }
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                editedLabel={
                  editableFlags[`changeUseCaseEdited${activePropertyId}`]
                }
                enableReason={enableReasonField(
                  changedValueFields,
                  "changeInUseCase",
                  loanState
                )}
                isServicerMandatory={
                  isMandatoryServicerField &&
                  isMandatoryServicerField("changeInUseCase")
                }
                onBlur={useCaseFocusOut}
                isLATView={isLATUser()}
                editedData={
                  editHistory2[
                    `data.properties[${activePropertyId}].propertyinfo.changeInUseCase`
                  ]
                }
                notRequired={bridgePropertyFields.changeInUseCase?.optionalIn?.includes(
                  loanStage
                )}
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyInfo.changeInUseCase`
                  ]
                }
              />
            </Grid>
          )}
        {handleServiceFlowRender("preRehabSquareFootage") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PreRehabSquareFootageTextField
              value={propertyInformation!.preRehabSquareFootage}
              onChange={onPreSquareFootageChange}
              error={validateForm.validate && preSquareFootageError !== ""}
              helperText={validateForm.validate ? preSquareFootageError : ""}
              onBlur={handleBlur("preRehabSquareFootage", "number")}
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={editableFlags[`preRehabEdited${activePropertyId}`]}
              enableReason={enableReasonField(
                changedValueFields,
                "preRehabSquareFootage",
                loanState
              )}
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("preRehabSquareFootage")
              }
              // onBlur={preRehabFocusOut}
              editedData={
                editHistory2[
                  `data.properties[${activePropertyId}].propertyinfo.preRehabSquareFootage`
                ]
              }
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyInfo.preRehabSquareFootage`
                ]
              }
            />
          </Grid>
        )}
        {!isHidden("postRehabSquareFootage", loanDetails) &&
          handleServiceFlowRender("postRehabSquareFootage") && (
            <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
              <PostRehabSquareFootageTextField
                value={propertyInformation!.postRehabSquareFootage}
                onChange={onPostSquareFootageChange}
                error={validateForm.validate && postSquareFootageError !== ""}
                helperText={validateForm.validate ? postSquareFootageError : ""}
                onBlur={handleBlur("postRehabSquareFootage", "number")}
                isViewMode={
                  isEvaluationPanelVisible &&
                  !editableFlags[`editProp${activePropertyId}`]
                }
                editedLabel={
                  editableFlags[`postRehabEdited${activePropertyId}`]
                }
                enableReason={enableReasonField(
                  changedValueFields,
                  "postRehabSquareFootage",
                  loanState
                )}
                isServicerMandatory={
                  isMandatoryServicerField &&
                  isMandatoryServicerField("postRehabSquareFootage")
                }
                editedData={
                  editHistory2[
                    `data.properties[${activePropertyId}].propertyinfo.postRehabSquareFootage`
                  ]
                }
                infoIconData={
                  infoContent &&
                  infoContent[
                    `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyInfo.postRehabSquareFootage`
                  ]
                }
              />
            </Grid>
          )}

        {handleServiceFlowRender("occupiedPercentage") && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <PercentageOccupiedField
              value={propertyInformation!.percentageOccupied}
              onChange={onPercentageOccupiedChange}
              error={validateForm.validate && percentageOccupiedError !== ""}
              helperText={validateForm.validate ? percentageOccupiedError : ""}
              onBlur={handleBlur("percentageOccupied", "percentage")}
              isServicerMandatory={
                isMandatoryServicerField &&
                isMandatoryServicerField("occupiedPercentage")
              }
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={
                editableFlags[`percentChangedEdited${activePropertyId}`]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "percentOccupied",
                loanState
              )}
              editedData={editHistoryValue(
                editHistory2[
                  `data.properties[${activePropertyId}].propertyinfo.occupiedPercentage`
                ],
                "percentage"
              )}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyInfo.occupiedPercentage`
                ]
              }
            />
          </Grid>
        )}

        {(priorStages.has(LoanStage.tv) || propertyInformation?.toorvalId) && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <TextFieldForm
              disabled
              value="Toorval"
              label="Valuation Source"
              data-testid="valuation-source"
              valueTestId="valuation-source-val"
              isViewMode
            />
            {/* <DropDown
              formControlTestId="valuation-source-Error"
              inputLabelId="valuation-source-label"
              inputLabelTestId="valuation-source-label-testid"
              label="Valuation Source"
              selectId="appraisal-valuation-source-selectid"
              selectTestId="appraisal-valuation-source-select-testid"
              selectionValue="Toorval"
              selectionData={[{ value: "Toorval", label: "Toorval" }]}
              helperText=""
              notRequired
              disabled
            /> */}
          </Grid>
        )}
        {!isHidden("isPropertyPurchasedLast2Years", loanDetails) && (
          <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
            <DropDown
              formControlTestId="property-purchased-last-TwoYears-Error"
              inputLabelId="property-purchased-last-TwoYears-label"
              inputLabelTestId="property-purchased-last-TwoYears-label-testid"
              label="Property Purchased Within Last 2 years"
              selectId="property-purchased-last-TwoYears-selectid"
              selectTestId="property-purchased-last-TwoYears-select-testid"
              selectionValue={
                propertyInformation!.isPropertyPurchasedLast2Years || ""
              }
              selectionOnChange={handlepropertyPurchasedLastTwoYearsChange}
              selectionData={propertyPurchasedLastTwoYearsValues}
              helperText={
                validateForm.validate ? propertyPurchasedLastTwoYearsError : ""
              }
              isViewMode={
                isEvaluationPanelVisible &&
                !editableFlags[`editProp${activePropertyId}`]
              }
              editedLabel={
                editableFlags[
                  `propertyPurchasedLastTwoYearsEdited${activePropertyId}`
                ]
              }
              enableReason={enableReasonField(
                changedValueFields,
                "isPropertyPurchasedLast2Years",
                loanState
              )}
              onBlur={propertyPurchasedLastTwoYearsFocusOut}
              editedData={
                editHistory2[
                  `data.properties[${activePropertyId}].propertyinfo.isPropertyPurchasedLast2Years`
                ]
              }
              isLATView={isLATUser()}
              infoIconData={
                infoContent &&
                infoContent[
                  `fieldInfo.bridge.${loanStage.toLowerCase()}.properties.propertyInfo.isPropertyPurchasedLast2Years`
                ]
              }
            />
          </Grid>
        )}
        {editableFlags[`editProp${activePropertyId}`] && (
          <Grid container item xs={12} sm={12} md={12} xl={12}>
            <Button
              variant="contained"
              color="info"
              // data-testid="save-button"
              className={
                editableFlags[`editProp${activePropertyId}`]
                  ? classes.savebutton
                  : classes.editImageDisplay
              }
              onClick={() => {
                handleOpen();
              }}
              disabled={changedValueFields.length === 0}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="info"
              // data-testid="save-button"
              className={
                editableFlags[`editProp${activePropertyId}`]
                  ? classes.cancelButton
                  : classes.editImageDisplay
              }
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="duplicate-property-modal"
        aria-describedby="duplicate-property-modal-description"
      >
        <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}>
              Warning
            </div>
            <CloseIcon
              htmlColor="#414169"
              style={{ width: 16, height: 16, cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <div
            style={{
              fontSize: 12,
              lineHeight: 1.5,
              color: "#32325d",
              marginTop: 40
            }}
          >
            {requestWaiverMessage}
          </div>
          <div className={classes.modalDeleteButtonConatainer}>
            <Button
              variant="contained"
              color="primary"
              data-testid="modal-delete-button"
              className={classes.modalButton}
              onClick={() => {
                handleClose();
                if (errors === 0) {
                  dispatch(validateFormCheck(true));
                  globalSaveOnPropertyFieldChange(
                    changedValueFields,
                    propertyInformation
                  ).then((res: any) => {
                    if (res !== "false") {
                      dispatch(valueEditing(false));
                      editableFlags[`editProp${activePropertyId}`] = false;
                      setEditable(!isEditable);
                      changedValueFields = [];
                      // updatePreviousLoanState();
                    }
                  });
                }
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              data-testid="modal-cancel-button"
              className={classes.modalCancelButton}
              onClick={() => {
                handleClose();
                onCancel();
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
