/* eslint-disable no-plusplus */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */

import { produce } from "immer";
// import {
//   GET_EXCEPTION_ENQUIRIES_LOADING,
//   GET_EXCEPTIONS_BY_ID_SUCCESS,
//   GET_EXCEPTIONS_BY_ID_FAILED,
//   SET_ASK_A_QUESTION,
//   SET_SATISFY_EXCEPTIONS,
//   DELETE_ACTION,
//   COMPLETE_INQUIRY_STATUS,
//   UPDATE_ACTION_CLICKS,
//   POST_CHAT_BY_ID,
//   POST_RESPONSE_BY_CHAT_SEQUENCE_ID,
//   POST_RESPONSE_BY_PERSON_PARTY_ID,
//   GET_RESPONSES_BY_EXCEPTION_ID,
//   GET_RESPONSES_BY_CHAT_SEQUENCE_ID,
//   CANCEL_ACTION_BY_ACTION_ID,
//   UPDATE_DOCS_IN_ACTIONS_CLICKS_BY_ACTION_ID,
//   UPLOAD_EXCEL_LOAN_EXCEPTION_CHANGE,
//   DELETE_DOCS_FROM_FILELIST,
//   GET_WAIVER_BY_LOAN_ID,
//   DELETE_EXCEPTION_BY_RESPONSE,
//   DELETE_ENQUIRY_BY_ID,
//   GET_EXCEPTION_BY_EXCEPTION_ID,
//   // UPDATE_EXCEPTION_STATUS,
//   DELETE_WAIVER_BY_ID,
//   SET_WAIVER_REQUEST,
//   UPDATE_WAIVER_APPROVAL_STATUS,
//   ADD_ENQUIRY,
//   ADD_EDIT_RECOMMENDATION,
//   CONVERT_INQUIRY,
//   UPDATE_WAIVER_IN_REDUX,
//   RESET_EXCEPTION_RESPONSE,
//   UPDATE_FETCHED_EVENTS_COUNTS,
//   GET_INTERNAL_COMMENTS_BY_CHAT_SEQUENCE_ID,
//   GET_ENQUIRY_DOCS,
//   GET_QUERY,
//   DELETE_QUERY,
//   GET_COMMUNICATION_DATA,
//   DISABLE_CONDT_RESET_FILTER,
//   SET_FILTERS,
//   SET_FILTERED_EXCEPTION,
//   ADD_RESPONSE_TO_EXCEPTIONS_ARRAY,
//   SET_SELECTED_CONDITION,
//   ADD_TO_BULK_SATISFY_PAYLOAD,
//   DELETE_FROM_BULK_SATISFY_PAYLOAD,
//   RESET_BULK_SATISFY_PAYLOAD,
//   UPDATE_EXCEPTION,
//   SET_CLOSE_CONDITION,
//   SET_RESCIND_CONDITION,
//   SET_WAIVE_CONDITION
// } from "./ExceptionActions";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { RESET_EXCEPTION_STORE } from "../../create-loan/create-loan.action";
import { InquiryStatusEnum } from "../../utils/constants";
import { ObjectType } from "../../masterView/common";

export interface ExceptionsStore {
  exceptionAndEnquiriesLoading: boolean;
  isEvaluationPanelVisible: boolean;
  isAskAQuestionClicked: any;
  closeConditionClicked: ObjectType | null;
  waiveConditionClicked: ObjectType | null;
  rescindConditionClicked: ObjectType | null;
  isSatisfyExceptionClicked: any;
  isWaiverRequestClicked: any;
  exceptionsResult: {
    exceptions: any[];
  } & ObjectType;
  actionsResult: any;
  actionClicks: any;
  responses: any;
  waiverResult: any;
  type: string;
  inquiryDocs: any;
  queryResponse: any;
  communicationResponse: any;
  disableCondtReset: any;
  visiblitySelected: any;
  waiverSelected: any;
  satisfiedSelected: any;

  filteredExceptionsArray: any;
  selectedCondition: any;
  bulkSatisfyPayload: any;
}
export const initialStateExceptionsStore: ExceptionsStore = {
  exceptionsResult: {
    exceptions: []
  },
  exceptionAndEnquiriesLoading: false,
  isEvaluationPanelVisible: false,
  isAskAQuestionClicked: {},
  closeConditionClicked: {},
  waiveConditionClicked: {},
  rescindConditionClicked: {},
  isSatisfyExceptionClicked: {},
  isWaiverRequestClicked: {},
  actionsResult: [],
  actionClicks: [],
  responses: [],
  waiverResult: [],
  type: "",
  inquiryDocs: [],
  queryResponse: [],
  communicationResponse: [],
  disableCondtReset: false,
  visiblitySelected: [],
  satisfiedSelected: [],
  waiverSelected: [],
  filteredExceptionsArray: [],
  selectedCondition: [],
  bulkSatisfyPayload: []
};

// ... (previous code)

const exceptionsResultsSlice = createSlice({
  name: "exceptionsResults",
  initialState: initialStateExceptionsStore,
  reducers: {
    getExceptionEnquiriesLoading: (state) => {
      state.exceptionAndEnquiriesLoading = true;
    },
    getExceptionsByIdFailed: (state) => {
      state.exceptionAndEnquiriesLoading = false;
      state.isEvaluationPanelVisible = false;
    },
    setFilteredException: (state, action: PayloadAction<any>) => {
      state.filteredExceptionsArray = { exceptions: action.payload };
    },
    updateConditionsFilters: (state, action: PayloadAction<any>) => {
      const { visiblitySelected, waiverSelected, satisfiedSelected } =
        action.payload;
      const index = visiblitySelected?.indexOf("externalAndInternal");
      if (index > -1) {
        visiblitySelected.splice(index, 1);
        visiblitySelected.push("internal", "external");
      }
      state.visiblitySelected = visiblitySelected;
      state.waiverSelected = waiverSelected;
      state.satisfiedSelected = satisfiedSelected;
    },
    disableCondtResetFilter: (state, action: PayloadAction<boolean>) => {
      state.disableCondtReset = action.payload;
    },
    setExceptionsByIdSuccess: (state, action: PayloadAction<any>) => {
      const { exceptionResults } = action.payload;
      const waivers = state.exceptionsResult?.waivers ?? null;

      state.exceptionAndEnquiriesLoading = false;
      state.exceptionsResult = {
        ...exceptionResults.exceptions,
        customers: exceptionResults.customers,
        accountMapping: exceptionResults.accountMapping,
        accounts: exceptionResults.accounts,
        chatLines: exceptionResults.chatLines,
        latestComment: exceptionResults.latestComment ?? [],
        waivers: waivers
      };
    },
    addResponseToExceptionsArray: (state, action: PayloadAction<any>) => {
      const { data, id, setViewMore = false } = action.payload;
      const arr = state?.exceptionsResult?.exceptions;
      if (arr?.length) {
        const index = state.exceptionsResult.exceptions.findIndex(
          (item: any) => item?.exceptionId === id
        );
        const newArr = JSON.parse(
          JSON.stringify(state.exceptionsResult.exceptions)
        );
        if (index !== -1 && data?.exceptions?.response && newArr?.[index]) {
          newArr[index].responses = data.exceptions.response;
          newArr[index].exceptions = data.exceptions;
          const newResp = newArr[index].responses.map((item: any) => {
            let chatLiness = data.chatLines.map((items: any) => {
              if (items[item.chatSequenceId]) return items[item.chatSequenceId];
            });
            chatLiness = chatLiness.filter((chat: any) => chat !== undefined);
            item.chatLines = chatLiness[0];
            item.accounts = data.accounts;
            item.customers = data.customers;
            item.accountMapping = data.accountMapping;
            item.latestComment = data.latestComment ?? [];

            return item;
          });

          newArr[index].responses = newResp;
          newArr[index].viewMore = setViewMore;
        }
        if (data?.waiverData?.waivers) {
          const waivers = data.waiverData.waivers.map(
            (waiver: any, index: any) => {
              waiver.documents =
                data.waiverData.documents[index] &&
                data.waiverData.documents[index].primaryIdentifierValue ===
                  waiver.waiverId
                  ? data.waiverData.documents[index]?.docs
                  : [];
              return waiver;
            }
          );
          const newExp = {
            waivers: [...waivers],
            customers: data.waiverData.customers,
            accountMapping: data.waiverData.accountMapping,
            accounts: data.waiverData.accounts,
            documents: data.waiverData.documents,
            properties: data.waiverData.properties
          };

          newArr[index].waiverResult = newExp;
        }
        state.exceptionsResult.exceptions[index] = newArr[index];
        state.waiverResult = newArr[index].waiverResult;
      }
    },
    addEditRecommendation: (state, action: PayloadAction<any>) => {
      const { exceptionResults, exceptionId } = action.payload;
      if (!exceptionId) {
        const newExp = {
          ...exceptionResults.exceptions,
          customers: [...exceptionResults.customers],
          accountMapping: [...exceptionResults.accountMapping],
          accounts: [...exceptionResults.accounts],
          chatLines: [...exceptionResults.chatLines]
        };
        state.exceptionsResult = newExp;
      } else {
        const exceptionsResult = { ...state.exceptionsResult };
        if (exceptionResults?.exceptions?.exceptions instanceof Array) {
          const index = exceptionResults.exceptions?.exceptions.findIndex(
            (item?: any) => item.exceptionId === exceptionId
          );
          if (index !== -1) {
            exceptionsResult.exceptions[0].response = [
              ...exceptionResults.exceptions.exceptions[index].response
            ];
            exceptionsResult.exceptions[0].status =
              exceptionResults.exceptions.exceptions[index].status;
            exceptionsResult.exceptions[0].workflowState =
              exceptionResults.exceptions.exceptions[index].workflowState;
          }
        } else {
          exceptionsResult.exceptions[0].response = [
            ...exceptionResults.exceptions.exceptions.response
          ];
          exceptionsResult.exceptions[0].status =
            exceptionResults.exceptions.exceptions.status;
          exceptionsResult.exceptions[0].workflowState =
            exceptionResults.exceptions.exceptions.workflowState;
        }
        const newExp = {
          ...exceptionsResult,
          customers: [...exceptionResults.customers],
          accountMapping: [...exceptionResults.accountMapping],
          accounts: [...exceptionResults.accounts],
          chatLines: [...exceptionResults.chatLines]
        };
        state.exceptionsResult = newExp;
      }
    },
    updateFetchedEventsCounts: (state, action: PayloadAction<any>) => {
      const { exceptionId, count, resourceId } = action.payload;
      const exceptions: any = state?.exceptionsResult?.exceptions ?? [];
      const eventcount: any = {
        eventcount: count,
        resourceId
      };
      if (exceptions instanceof Array) {
        const exceptionIndex = exceptions.findIndex(
          (item?: any) => item.exceptionId === exceptionId
        );
        state.exceptionsResult.exceptions[exceptionIndex] = {
          ...eventcount,
          ...state.exceptionsResult?.exceptions[exceptionIndex]
        };
      } else {
        state.exceptionsResult.exceptions = {
          ...eventcount,
          ...state.exceptionsResult?.exceptions
        };
      }
    },
    convertInquiry: (state, action: PayloadAction<any>) => {
      const { exceptionResults } = action.payload;
      const newExp = {
        ...exceptionResults.exceptions,
        customers: [...exceptionResults.customers],
        accountMapping: [...exceptionResults.accountMapping],
        accounts: [...exceptionResults.accounts],
        chatLines: [...exceptionResults.chatLines],
        latestComment: exceptionResults.latestComment
          ? [...exceptionResults.latestComment]
          : []
      };
      state.exceptionsResult = newExp;
    },
    addEnquiry: (state, action: PayloadAction<any>) => {
      const exceptions = action.payload.exceptions;

      let temp = state?.exceptionsResult?.exceptions;
      if (temp) {
        temp.push(exceptions[0]);
      } else {
        temp = [];
        temp.push(exceptions[0]);
      }
      state.exceptionsResult.exceptions = [...temp];
    },
    updateException: (state, action: PayloadAction<any>) => {
      const { updateObj, exceptionId } = action.payload;
      let exceptions: any = state?.exceptionsResult?.exceptions ?? [];
      if (exceptions instanceof Array) {
        exceptions = [
          ...exceptions.map((exceptionData: any) => {
            if (exceptionData.exceptionId === exceptionId) {
              return {
                ...exceptionData,
                ...updateObj
              };
            }
            return exceptionData;
          })
        ];
      } else {
        exceptions = {
          ...(exceptions || {}),
          ...updateObj
        };
      }
      state.exceptionsResult.exceptions = exceptions;
    },
    setExceptionByExceptionId: (state, action: PayloadAction<any>) => {
      const { exceptionResults, docs } = action.payload;
      const newExp = {
        exceptions: [exceptionResults.exceptions],
        customers: exceptionResults.customers,
        accountMapping: exceptionResults.accountMapping,
        accounts: exceptionResults.accounts,
        chatLines: exceptionResults.chatLines,
        documents: docs
      };
      state.exceptionsResult = newExp;
      state.type = exceptionResults.exceptions.type;
    },
    deleteAction: (state, action: PayloadAction<any>) => {
      const { exceptionId, responseType } = action.payload;
      const { exceptions } = state.exceptionsResult;
      if (exceptions instanceof Array) {
        const exceptionIndex = state.exceptionsResult?.exceptions?.findIndex(
          (item?: any) => item.exceptionId === exceptionId
        );
        if (exceptionIndex !== -1) {
          exceptions[exceptionIndex].response = exceptions[
            exceptionIndex
          ].response.filter((resp: any) => resp.responseType !== responseType);
        }
      }
    },
    completeInquiryStatus: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      payload.forEach((obj: any) => {
        const exceptionIndex = state.exceptionsResult?.exceptions?.findIndex(
          (item?: any) => item.exceptionId === obj.exceptionId
        );
        if (exceptionIndex !== -1) {
          state.exceptionsResult.exceptions[exceptionIndex].workflowState =
            InquiryStatusEnum.Completed;
        }
      });
    },
    setSatisfyExceptions(
      state,
      action: PayloadAction<{ clicked: any; id: string }>
    ) {
      const { clicked, id } = action.payload;
      const isSatisfyClick: any = { ...state.isSatisfyExceptionClicked };
      isSatisfyClick[id] = clicked;
      state.isSatisfyExceptionClicked = isSatisfyClick;
    },

    setAskAQuestion(
      state,
      action: PayloadAction<{ clicked: any; id: string }>
    ) {
      const { clicked, id } = action.payload;
      const isAskAQuest: any = { ...state.isAskAQuestionClicked };
      isAskAQuest[id] = clicked;
      state.isAskAQuestionClicked = isAskAQuest;
    },
    setCloseConditionClicked(
      state,
      action: PayloadAction<{ clicked: any; id: string }>
    ) {
      // state.closeConditionClicked[action.payload.id] = action.payload.clicked;
      const { id, clicked } = action.payload;
      state.closeConditionClicked = {
        ...state.closeConditionClicked,
        [id]: clicked
      };
    },
    setWaiveConditionClicked(
      state,
      action: PayloadAction<{ clicked: any; id: string }>
    ) {
      // state.waiveConditionClicked[action.payload.id] = action.payload.clicked;
      const { id, clicked } = action.payload;
      state.waiveConditionClicked = {
        ...state.waiveConditionClicked,
        [id]: clicked
      };
    },
    setRescindConditionClicked(
      state,
      action: PayloadAction<{ clicked: any; id: string }>
    ) {
      const { id, clicked } = action.payload;
      state.rescindConditionClicked = {
        ...state.rescindConditionClicked,
        [id]: clicked
      };
    },
    setSelectedConditionClicked(
      state,
      action: PayloadAction<{ selectedConditionsArray: any }>
    ) {
      const { selectedConditionsArray } = action.payload;
      state.selectedCondition = selectedConditionsArray;
    },
    setWaiverRequest(
      state,
      action: PayloadAction<{ clicked: any; id: string }>
    ) {
      const { clicked, id } = action.payload;
      const waiverReqClick: any = { ...state.isWaiverRequestClicked };
      waiverReqClick[id] = clicked;
      state.isWaiverRequestClicked = waiverReqClick;
    },
    setActionClicks(
      state,
      action: PayloadAction<{
        type: string;
        actionId: string;
        exceptionId: string;
      }>
    ) {
      const { type, actionId, exceptionId } = action.payload;
      state.actionClicks = state.actionClicks.filter(
        (val: any) =>
          val.actionId !== actionId && val.exceptionId !== exceptionId
      );
      state.actionClicks.push({ type, actionId, exceptionId });
    },
    postChatById(
      state,
      action: PayloadAction<{ actionId: string; chatIdObject: any }>
    ) {
      const { actionId, chatIdObject } = action.payload;
      const chatIindex = state.actionClicks.findIndex(
        (item?: any) => item.actionId === actionId
      );
      if (chatIindex !== -1) {
        state.actionClicks[chatIindex] = {
          ...chatIdObject,
          ...state.actionClicks[chatIindex]
        };
      }
    },
    postResponseByChatSequenceId(
      state,
      action: PayloadAction<{ actionId: string; responseTypeObject: any }>
    ) {
      const { actionId, responseTypeObject } = action.payload;
      const chatIindex = state.actionClicks.findIndex(
        (item?: any) => item.actionId === actionId
      );
      if (chatIindex !== -1) {
        state.actionClicks[chatIindex] = {
          ...responseTypeObject,
          ...state.actionClicks[chatIindex]
        };
      }
    },
    postResponseByPersonPartyId(
      state,
      action: PayloadAction<{
        actionId: string;
        lineText: string;
        personPartyId: string;
        hasDocuments: boolean;
      }>
    ) {
      const { actionId, lineText, personPartyId, hasDocuments } =
        action.payload;
      const chatTextObject = {
        actionId,
        lineText,
        personPartyId,
        hasDocuments
      };
      const chatIindex = state.actionClicks.findIndex(
        (item?: any) => item.actionId === actionId
      );
      if (chatIindex !== -1) {
        state.actionClicks[chatIindex] = {
          ...state.actionClicks[chatIindex],
          ...chatTextObject
        };
      }
    },
    setResponsesByExceptionId(
      state,
      action: PayloadAction<{ responses: any }>
    ) {
      state.responses = action.payload.responses;
    },
    setResponsesByChatSequenceId(
      state,
      action: PayloadAction<{
        chatLines: any;
        chatSequenceId: string;
        count: number;
        filter: boolean;
        documents?: any;
      }>
    ) {
      const { chatLines, chatSequenceId, documents, count, filter } =
        action.payload;
      const chatSequeneceIdIindex = state.responses.findIndex(
        (item?: any) => item.chatSequenceId === chatSequenceId
      );
      if (chatSequeneceIdIindex !== -1) {
        state.responses[chatSequeneceIdIindex] = {
          ...state.responses[chatSequeneceIdIindex],
          count,
          ...chatLines,
          ...documents
        };
        if (filter) {
          const temp: any = state.responses[chatSequeneceIdIindex];
          state.responses.splice(chatSequeneceIdIindex, 1);
          state.responses.unshift(temp);
        }
      }
      state.exceptionAndEnquiriesLoading = false;
    },
    setInternalCommentsByChatSequenceId(
      state,
      action: PayloadAction<{
        chatLines: any;
        chatSequenceId: string;
        filter: boolean;
      }>
    ) {
      const { chatLines, chatSequenceId, filter } = action.payload;
      const chatSequeneceIdIindex = state.responses.findIndex(
        (item?: any) => item.chatSequenceId === chatSequenceId
      );
      if (chatSequeneceIdIindex !== -1) {
        for (let i = 0; i < chatLines.chatLines.length; i++) {
          const chatItem = state?.responses[
            chatSequeneceIdIindex
          ]?.chatLines?.filter(
            (chat: any) =>
              chat.chatLineSequenceId ===
              chatLines.chatLines[i].chatLineSequenceId
          ).length;
          if (!chatItem) {
            state.responses[chatSequeneceIdIindex]?.chatLines?.push(
              chatLines.chatLines[i]
            );
          }
        }

        for (let i = 0; i < chatLines.documents.length; i++) {
          const docItem = state.responses[
            chatSequeneceIdIindex
          ]?.documents?.filter(
            (docs: any) =>
              docs.secondaryIdentifierValue ===
              chatLines.documents[i].secondaryIdentifierValue
          ).length;
          if (!docItem) {
            state.responses[chatSequeneceIdIindex]?.documents?.push(
              chatLines.documents[i]
            );
          }
        }

        for (
          let i = 0;
          chatLines.accountMapping && i < chatLines.accountMapping.length;
          i++
        ) {
          const accountMappingItem = state.responses[
            chatSequeneceIdIindex
          ]?.accountMapping?.filter(
            (acc: any) => acc.personId === chatLines.accountMapping[i].personId
          ).length;
          if (!accountMappingItem) {
            state.responses[chatSequeneceIdIindex]?.accountMapping?.push(
              chatLines.accountMapping[i]
            );
          }
        }

        for (
          let i = 0;
          chatLines.accounts && i < chatLines.accounts.length;
          i++
        ) {
          const accountsItem = state.responses[
            chatSequeneceIdIindex
          ]?.accounts?.filter(
            (acc: any) => acc.partyId === chatLines.accounts[i].partyId
          ).length;
          if (!accountsItem) {
            state.responses[chatSequeneceIdIindex]?.accounts?.push(
              chatLines.accounts[i]
            );
          }
        }

        for (
          let i = 0;
          chatLines.customers && i < chatLines.customers.length;
          i++
        ) {
          const customersItem = state.responses[
            chatSequeneceIdIindex
          ]?.customers?.filter(
            (customer: any) =>
              customer.partyId === chatLines.customers[i].partyId
          ).length;
          if (!customersItem) {
            state.responses[chatSequeneceIdIindex]?.customers?.push(
              chatLines.customers[i]
            );
          }
        }

        if (filter) {
          const temp: any = state.responses[chatSequeneceIdIindex];
          state.responses.splice(chatSequeneceIdIindex, 1);
          state.responses.unshift(temp);
        }
      }
      state.exceptionAndEnquiriesLoading = false;
    },
    deleteExceptionByResponse(
      state,
      action: PayloadAction<{ chatSequenceId: string }>
    ) {
      const { chatSequenceId } = action.payload;
      const chatSequeneceIdIndex = state.responses.findIndex(
        (item?: any) => item.chatSequenceId === chatSequenceId
      );
      if (chatSequeneceIdIndex !== -1) {
        state.responses.splice(chatSequeneceIdIndex, 1);
      }
    },
    updateExceptionAfterDelete(state, action: PayloadAction<string>) {
      const exceptionId = action.payload;
      const exceptionIndex = state.exceptionsResult?.exceptions?.findIndex(
        (item?: any) => item.exceptionId === exceptionId
      );
      if (exceptionIndex !== -1) {
        state.exceptionsResult.exceptions.splice(exceptionIndex, 1);
      }
    },
    updateDocumentInException: (
      state,
      action: PayloadAction<{ actionId: string; filesList: any }>
    ) => {
      const { actionId, filesList } = action.payload;
      const actionIndex = state.actionClicks.findIndex(
        (item?: any) => item.actionId === actionId
      );
      if (actionIndex !== -1) {
        state.actionClicks[actionIndex].files = { ...filesList };
      }
    },
    deleteUploadedDocumentException: (
      state,
      action: PayloadAction<{ actionId: string }>
    ) => {
      const { actionId } = action.payload;
      state.actionClicks.findIndex((item?: any) => item.actionId === actionId);
    },
    uploadExcelLoanExceptionChange: (state, action: PayloadAction<any>) => {
      state = action.payload;
    },
    setWaiverByLoanId: (
      state,
      action: PayloadAction<{
        waiver: any;
        documents: any;
        tempWaiver: any;
      }>
    ) => {
      const { waiver, documents, tempWaiver } = action.payload;
      if (documents.length && tempWaiver?.length !== 0) {
        waiver.waivers = tempWaiver?.map((item: any, index: number) => {
          item.documents = documents[index] ? documents[index]?.data : [];
          return item;
        });
      }
      const newExp = {
        waivers: [...waiver.waivers],
        customers: waiver.customers,
        accountMapping: waiver.accountMapping,
        accounts: waiver.accounts
      };
      state.waiverResult = newExp;
    },
    setWaiverApprovalStatus: (
      state,
      action: PayloadAction<{ waiverId: string; waiver: any }>
    ) => {
      const { waiverId, waiver } = action.payload;
      const waiverIdIndex = state.waiverResult.waivers?.findIndex(
        (item?: any) => item.waiverId === waiverId
      );
      if (waiverIdIndex !== -1) {
        let temp =
          state?.waiverResult?.waivers?.[waiverIdIndex]?.waiverResponses;
        if (temp) {
          temp.push(waiver);
        } else {
          temp = [];
          temp.push(waiver);
        }
        state.waiverResult.waivers[waiverIdIndex].waiverResponses = temp;
      }
    },
    updateWaiverInRedux: (
      state,
      action: PayloadAction<{ waiverDetails: any }>
    ) => {
      const { waiverDetails } = action.payload;
      state.waiverResult = [waiverDetails, ...state.waiverResult];
    },
    deleteWaiverById: (state, action: PayloadAction<{ waiverId: string }>) => {
      const { waiverId } = action.payload;
      const waiverIdIndex = state.waiverResult.waivers.findIndex(
        (item?: any) => item.waiverId === waiverId
      );
      if (waiverIdIndex !== -1) {
        state.waiverResult.waivers.splice(waiverIdIndex, 1);
      }
    },
    resetExceptionResponse: (state) => {
      state.responses = [];
    },
    resetExceptionStore: (state) => {
      state.actionClicks = [];
      state.isAskAQuestionClicked = false;
      state.isSatisfyExceptionClicked = false;
      state.isWaiverRequestClicked = false;
      state.closeConditionClicked = null;
      state.waiveConditionClicked = null;
      state.rescindConditionClicked = null;
    },
    setEnquiryDocs: (state, action: PayloadAction<any>) => {
      state.inquiryDocs = action.payload;
    },
    setQuery: (state, action: PayloadAction<any>) => {
      state.queryResponse = action.payload;
    },
    deleteQuery: (state, action: PayloadAction<{ chatId: string }>) => {
      const currentState = state;
      const queryIndex =
        currentState.exceptionsResult?.exceptions?.[0]?.response?.findIndex(
          (item?: any) => item.chatSequenceId === action.payload.chatId
        );
      if (queryIndex !== -1) {
        currentState.exceptionsResult.exceptions?.[0]?.response.splice(
          queryIndex,
          1
        );
      }
      state.queryResponse = currentState.exceptionsResult;
    },
    setCommunicationData: (
      state,
      action: PayloadAction<{ chats: any; exceptionDetails: any }>
    ) => {
      const { chats, exceptionDetails } = action.payload;
      let communicationArray: any = [];
      if (chats && exceptionDetails && exceptionDetails.length) {
        const keys = Object.keys(chats);
        if (keys.length) {
          keys.forEach((key: any) => {
            const correspondingException = exceptionDetails.find(
              (val: any) =>
                val.exceptionId === chats[key]?.chatDetails?.identifierValue
            );
            if (correspondingException) {
              const element: any = chats[key];
              const obj: any = {};
              obj.chatId = key;
              obj.chatDetails = element.chatDetails;
              obj.comments = [element.comments[element.comments.length - 1]];
              obj.loanId = obj.chatDetails?.secondaryIdentifierValue;
              obj.description =
                correspondingException.initialComment || "<Not Available>";
              obj.tag =
                correspondingException.tags?.length &&
                correspondingException.tags[0] !== ""
                  ? correspondingException.tags[0]
                  : null;
              obj.datapoint = correspondingException.datapoints?.length
                ? correspondingException.datapoints[0]
                : null;
              communicationArray.push(obj);
            }
          });
          communicationArray = communicationArray
            .slice()
            .sort(
              (a: any, b: any) =>
                Date.parse(b.comments[0].createdOn) -
                Date.parse(a.comments[0].createdOn)
            );
        }
        state.communicationResponse = communicationArray;
      }
    },
    addToBulkSatisfyPayload: (
      state,
      action: PayloadAction<{
        exceptionId: string;
        filesList?: any[];
        loanId: string;
        loanStage: string;
        visibility: any;
        lineText?: string;
      }>
    ) => {
      const {
        exceptionId,
        filesList = [],
        loanId,
        loanStage,
        visibility
      } = action.payload;
      let { lineText } = action.payload;
      if (lineText === "") {
        lineText = "-";
      }
      let documents = [];
      if (filesList.length) {
        documents = filesList
          .map((file: any) => {
            return file?.body?.[0];
          })
          .filter(Boolean);
      }
      state.bulkSatisfyPayload = state.bulkSatisfyPayload.filter(
        (val: any) => val.exceptionId !== exceptionId
      );
      state.bulkSatisfyPayload = [
        ...state.bulkSatisfyPayload,
        {
          exceptionId,
          responseType: "SATISFY_EXCEPTION",
          visibility,
          lineText,
          loanId,
          loanStage,
          documents,
          filesList
        }
      ];
    },
    deleteFromBulkSatisfyPayload: (state, action: PayloadAction<string>) => {
      const exceptionId = action.payload;
      state.bulkSatisfyPayload = state.bulkSatisfyPayload.filter(
        (val: any) => val.exceptionId !== exceptionId
      );
    },
    resetBulkSatisfyPayload: (state) => {
      state.bulkSatisfyPayload = [];
    },
    cancelActionByActionId: (state, action: PayloadAction<any>) => {
      const { actionId } = action.payload;
      const actionIndex = state.actionClicks.findIndex(
        (item?: any) => item.actionId === actionId
      );
      if (actionIndex !== -1) {
        state.actionClicks.splice(actionIndex, 1);
      }
      state.actionClicks = [...state.actionClicks];
    },
    softUpdateException: (state, action: PayloadAction<any>) => {
      const { data, workflowState, conclusionComment } = action.payload;
      const arr = JSON.parse(
        JSON.stringify(state?.exceptionsResult.exceptions)
      );
      if (arr.length) {
        data?.forEach((eachData: any, index: any) => {
          const tempIndex: any = arr.findIndex(
            (item: any) => item?.exceptionId === eachData?.exceptionId
          );
          arr[tempIndex].response.push(eachData);
          arr[tempIndex].workflowState = workflowState;
          arr[tempIndex].status = workflowState;
          if (conclusionComment !== "") {
            arr[tempIndex].conclusionComment = conclusionComment;
          }
          state.exceptionsResult.exceptions = arr;
        });
      }
    },
    initailCommentSoftUpdateLoan: (state, action: PayloadAction<any>) => {
      const { exceptionId, initialComment } = action.payload;
      const arr = state?.exceptionsResult.exceptions;
      // ;
      if (arr?.length) {
        const index = state.exceptionsResult.exceptions.findIndex(
          (item: any) => item?.exceptionId === exceptionId
        );
        const latestCommentIndex =
          state.exceptionsResult?.latestComment?.findIndex(
            (eachComment: any) => {
              return eachComment.exceptionId === exceptionId;
            }
          );
        const newArr = JSON.parse(
          JSON.stringify(state.exceptionsResult.exceptions)
        );
        const newLatestCommentArr = JSON.parse(
          JSON.stringify(state.exceptionsResult?.latestComment)
        );
        if (index > -1) {
          newArr[index].initialComment =
            initialComment; /** update the initial comment of the exception */
        }
        if (latestCommentIndex > -1) {
          newLatestCommentArr[latestCommentIndex].accountType = "LAT";
        }
        state.exceptionsResult.exceptions = newArr;
        state.exceptionsResult.latestComment =
          newLatestCommentArr; /** update the latest comment of the exception */
      }
    },
    updateSetViewmoreToExceptionsArray: (state, action: PayloadAction<any>) => {
      const { exceptionId, setViewMore } = action.payload;
      const arr = state?.exceptionsResult.exceptions;
      // ;
      if (arr?.length) {
        const index = state.exceptionsResult.exceptions.findIndex(
          (item: any) => item?.exceptionId === exceptionId
        );
        const newArr = JSON.parse(
          JSON.stringify(state.exceptionsResult.exceptions)
        );
        if (index !== -1 && newArr?.[index]) {
          newArr[index].viewMore = setViewMore;
        }
        state.exceptionsResult.exceptions[index] = newArr[index];
      }
    }
  }
});

// Export actions
export const {
  initailCommentSoftUpdateLoan,
  softUpdateException,
  updateSetViewmoreToExceptionsArray,
  getExceptionEnquiriesLoading,
  getExceptionsByIdFailed,
  setFilteredException,
  updateConditionsFilters,
  disableCondtResetFilter,
  setExceptionsByIdSuccess,
  addResponseToExceptionsArray,
  addEditRecommendation,
  updateFetchedEventsCounts,
  convertInquiry,
  addEnquiry,
  updateException,
  setExceptionByExceptionId,
  deleteAction,
  completeInquiryStatus,
  setSatisfyExceptions,
  setAskAQuestion,
  setCloseConditionClicked,
  setWaiveConditionClicked,
  setRescindConditionClicked,
  setSelectedConditionClicked,
  setWaiverRequest,
  setActionClicks,
  postChatById,
  postResponseByChatSequenceId,
  postResponseByPersonPartyId,
  setResponsesByExceptionId,
  setResponsesByChatSequenceId,
  setInternalCommentsByChatSequenceId,
  deleteExceptionByResponse,
  updateExceptionAfterDelete,
  updateDocumentInException,
  deleteUploadedDocumentException,
  uploadExcelLoanExceptionChange,
  setWaiverByLoanId,
  setWaiverApprovalStatus,
  updateWaiverInRedux,
  deleteWaiverById,
  resetExceptionResponse,
  resetExceptionStore,
  setEnquiryDocs,
  setQuery,
  deleteQuery,
  setCommunicationData,
  addToBulkSatisfyPayload,
  deleteFromBulkSatisfyPayload,
  resetBulkSatisfyPayload,
  cancelActionByActionId
} = exceptionsResultsSlice.actions;

// Export reducer
export const ExceptionsResultsReducer = exceptionsResultsSlice.reducer;

// export const ExceptionsResultsReducer = (
//   state = initialStateExceptionsStore,
//   action: any
// ) => {
//   switch (action.type) {
//     case GET_EXCEPTION_ENQUIRIES_LOADING: {
//       const nextState = produce(state, (draftState) => {
//         draftState.exceptionAndEnquiriesLoading = true;
//       });
//       return nextState;
//     }
//     case GET_EXCEPTIONS_BY_ID_FAILED: {
//       return {
//         ...state,
//         exceptionAndEnquiriesLoading: false,
//         isEvaluationPanelVisible: false
//       };
//     }

//     case SET_FILTERED_EXCEPTION: {
//       const nextState = produce(state, (draftState) => {
//         draftState.filteredExceptionsArray = action.payload;
//       });
//       return nextState;
//     }

//     case SET_FILTERS: {
//       const { visiblitySelected, waiverSelected, satisfiedSelected } =
//         action.payload;
//       const index = visiblitySelected?.indexOf("externalAndInternal");
//       if (index > -1) {
//         visiblitySelected.splice(index, 1);
//         visiblitySelected.push("internal", "external");
//       }
//       const nextState = produce(state, (draftState) => {
//         draftState.visiblitySelected = visiblitySelected;
//         draftState.waiverSelected = waiverSelected;
//         draftState.satisfiedSelected = satisfiedSelected;
//       });
//       return nextState;
//     }
//     case DISABLE_CONDT_RESET_FILTER: {
//       const nextState = produce(state, (draftState) => {
//         draftState.disableCondtReset = action.payload.disableCondtReset;
//       });
//       return nextState;
//     }
//     case GET_EXCEPTIONS_BY_ID_SUCCESS: {
//       const { exceptionResults } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.exceptionAndEnquiriesLoading = false;
//         const newExp = {
//           ...exceptionResults.exceptions,
//           customers: exceptionResults.customers,
//           accountMapping: exceptionResults.accountMapping,
//           accounts: exceptionResults.accounts,
//           chatLines: exceptionResults.chatLines
//         };
//         draftState.exceptionsResult = newExp;
//       });
//       return nextState;
//     }
//     case ADD_RESPONSE_TO_EXCEPTIONS_ARRAY: {
//       const nextState = produce(state, (draftState) => {
//         const { data, id } = action.payload;
//         const arr = state?.exceptionsResult.exceptions;
//         // ;
//         if (arr?.length) {
//           const index = state.exceptionsResult.exceptions.findIndex(
//             (item: any) => item?.exceptionId === id
//           );
//           const newArr = JSON.parse(
//             JSON.stringify(state.exceptionsResult.exceptions)
//           );
//           if (index !== -1 && data?.exceptions?.response && newArr?.[index]) {
//             newArr[index].responses = data.exceptions.response;
//             newArr[index].exceptions = data.exceptions;
//             const newResp = newArr[index].responses.map((item: any) => {
//               let chatLiness = data.chatLines.map((items: any) => {
//                 if (items[item.chatSequenceId])
//                   return items[item.chatSequenceId];
//               });
//               chatLiness = chatLiness.filter((chat: any) => chat !== undefined);
//               item.chatLines = chatLiness[0];
//               item.accounts = data.accounts;
//               item.customers = data.customers;
//               item.accountMapping = data.accountMapping;

//               return item;
//             });

//             newArr[index].responses = newResp;
//           }
//           if (data?.waiverData?.waivers) {
//             const waivers = data.waiverData.waivers.map(
//               (waiver: any, index: any) => {
//                 waiver.documents =
//                   data.waiverData.documents[index] &&
//                   data.waiverData.documents[index].primaryIdentifierValue ===
//                     waiver.waiverId
//                     ? data.waiverData.documents[index]?.docs
//                     : [];
//                 return waiver;
//               }
//             );
//             const newExp = {
//               waivers: [...waivers],
//               customers: data.waiverData.customers,
//               accountMapping: data.waiverData.accountMapping,
//               accounts: data.waiverData.accounts,
//               documents: data.waiverData.documents,
//               properties: data.waiverData.properties
//             };

//             newArr[index].waiverResult = newExp;
//           }
//           draftState.exceptionsResult.exceptions[index] = newArr[index];
//           draftState.waiverResult = newArr[index].waiverResult;
//         }
//       });
//       return nextState;
//     }
//     case ADD_EDIT_RECOMMENDATION: {
//       const { exceptionResults, exceptionId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         if (!exceptionId) {
//           const newExp = {
//             ...exceptionResults.exceptions,
//             customers: [...exceptionResults.customers],
//             accountMapping: [...exceptionResults.accountMapping],
//             accounts: [...exceptionResults.accounts],
//             chatLines: [...exceptionResults.chatLines]
//           };
//           draftState.exceptionsResult = newExp;
//         } else {
//           const exceptionsResult = { ...draftState.exceptionsResult };
//           if (exceptionResults?.exceptions?.exceptions instanceof Array) {
//             const index = exceptionResults.exceptions?.exceptions.findIndex(
//               (item?: any) => item.exceptionId === exceptionId
//             );
//             if (index !== -1) {
//               exceptionsResult.exceptions[0].response = [
//                 ...exceptionResults.exceptions.exceptions[index].response
//               ];
//               exceptionsResult.exceptions[0].status =
//                 exceptionResults.exceptions.exceptions[index].status;
//               exceptionsResult.exceptions[0].workflowState =
//                 exceptionResults.exceptions.exceptions[index].workflowState;
//             }
//           } else {
//             exceptionsResult.exceptions[0].response = [
//               ...exceptionResults.exceptions.exceptions.response
//             ];
//             exceptionsResult.exceptions[0].status =
//               exceptionResults.exceptions.exceptions.status;
//             exceptionsResult.exceptions[0].workflowState =
//               exceptionResults.exceptions.exceptions.workflowState;
//           }
//           const newExp = {
//             ...exceptionsResult,
//             customers: [...exceptionResults.customers],
//             accountMapping: [...exceptionResults.accountMapping],
//             accounts: [...exceptionResults.accounts],
//             chatLines: [...exceptionResults.chatLines]
//           };
//           draftState.exceptionsResult = newExp;
//         }
//       });
//       return nextState;
//     }
//     case UPDATE_FETCHED_EVENTS_COUNTS: {
//       const { exceptionId, count, resourceId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const exceptions: any = draftState?.exceptionsResult?.exceptions ?? [];
//         const eventcount: any = {
//           eventcount: count,
//           resourceId
//         };
//         if (exceptions instanceof Array) {
//           const exceptionIndex = exceptions.findIndex(
//             (item?: any) => item.exceptionId === exceptionId
//           );
//           draftState.exceptionsResult.exceptions[exceptionIndex] = {
//             ...eventcount,
//             ...draftState.exceptionsResult?.exceptions[exceptionIndex]
//           };
//         } else {
//           draftState.exceptionsResult.exceptions = {
//             ...eventcount,
//             ...draftState.exceptionsResult?.exceptions
//           };
//         }
//       });
//       return nextState;
//     }
//     case CONVERT_INQUIRY: {
//       const { exceptionResults } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const newExp = {
//           ...exceptionResults.exceptions,
//           customers: [...exceptionResults.customers],
//           accountMapping: [...exceptionResults.accountMapping],
//           accounts: [...exceptionResults.accounts],
//           chatLines: [...exceptionResults.chatLines]
//         };
//         draftState.exceptionsResult = newExp;
//       });
//       return nextState;
//     }
//     case ADD_ENQUIRY: {
//       const { exceptions } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         let temp = draftState?.exceptionsResult?.exceptions;
//         if (temp) {
//           temp.push(exceptions[0]);
//         } else {
//           temp = [];
//           temp.push(exceptions[0]);
//         }
//         draftState.exceptionsResult.exceptions = [...temp];
//       });
//       return nextState;
//     }
//     case UPDATE_EXCEPTION: {
//       const { updateObj, exceptionId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         let exceptions: any = draftState?.exceptionsResult?.exceptions ?? [];
//         if (exceptions instanceof Array) {
//           exceptions = [
//             ...exceptions.map((exceptionData: any) => {
//               if (exceptionData.exceptionId === exceptionId) {
//                 return {
//                   ...exceptionData,
//                   ...updateObj
//                 };
//               }
//               return exceptionData;
//             })
//           ];
//         } else {
//           exceptions = {
//             ...(exceptions || {}),
//             ...updateObj
//           };
//         }
//         draftState.exceptionsResult.exceptions = exceptions;
//       });
//       return nextState;
//     }
//     case GET_EXCEPTION_BY_EXCEPTION_ID: {
//       const { exceptionResults, docs } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const newExp = {
//           exceptions: [exceptionResults.exceptions],
//           customers: exceptionResults.customers,
//           accountMapping: exceptionResults.accountMapping,
//           accounts: exceptionResults.accounts,
//           chatLines: exceptionResults.chatLines,
//           documents: docs
//         };
//         draftState.exceptionsResult = newExp;
//         draftState.type = exceptionResults.exceptions.type;
//       });
//       return nextState;
//     }
//     case DELETE_ACTION: {
//       const { exceptionId, responseType } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const { exceptions } = draftState.exceptionsResult;
//         if (exceptions instanceof Array) {
//           const exceptionIndex =
//             draftState.exceptionsResult?.exceptions?.findIndex(
//               (item?: any) => item.exceptionId === exceptionId
//             );
//           if (exceptionIndex !== -1) {
//             exceptions[exceptionIndex].response = exceptions[
//               exceptionIndex
//             ].response.filter(
//               (resp: any) => resp.responseType !== responseType
//             );
//           }
//         }
//         // else {
//         //   exceptions[0].response = exceptions[0].response.filter(
//         //     (resp: any) => resp.responseType !== responseType
//         //   );
//         // }
//       });
//       return nextState;
//     }
//     case COMPLETE_INQUIRY_STATUS: {
//       const { payload } = action;
//       const nextState = produce(state, (draftState) => {
//         payload.forEach((obj: any) => {
//           const exceptionIndex =
//             draftState.exceptionsResult?.exceptions?.findIndex(
//               (item?: any) => item.exceptionId === obj.exceptionId
//             );
//           if (exceptionIndex !== -1) {
//             draftState.exceptionsResult.exceptions[
//               exceptionIndex
//             ].workflowState = InquiryStatusEnum.Completed;
//           }
//         });
//       });
//       return nextState;
//     }
//     // >>>>>>>>>>>>>>>>>>>>>>>>>>>>
//     case SET_SATISFY_EXCEPTIONS: {
//       const nextState = produce(state, (draftState) => {
//         const { clicked, id } = action.payload;
//         const isSatisfyClick: any = { ...state.isSatisfyExceptionClicked };
//         isSatisfyClick[id] = clicked;
//         draftState.isSatisfyExceptionClicked = isSatisfyClick;
//       });
//       return nextState;
//     }
//     case SET_ASK_A_QUESTION: {
//       const nextState = produce(state, (draftState) => {
//         const { clicked, id } = action.payload;
//         const isAskAQuest: any = { ...state.isAskAQuestionClicked };
//         isAskAQuest[id] = clicked;
//         draftState.isAskAQuestionClicked = isAskAQuest;
//       });
//       return nextState;
//     }
//     case SET_CLOSE_CONDITION: {
//       const nextState = produce(state, (draftState) => {
//         const { clicked, id } = action.payload;
//         draftState.closeConditionClicked = {
//           ...state.closeConditionClicked,
//           [id]: clicked
//         };
//       });
//       return nextState;
//     }
//     case SET_WAIVE_CONDITION: {
//       const nextState = produce(state, (draftState) => {
//         const { clicked, id } = action.payload;
//         draftState.waiveConditionClicked = {
//           ...state.waiveConditionClicked,
//           [id]: clicked
//         };
//       });
//       return nextState;
//     }

//     case SET_RESCIND_CONDITION: {
//       const nextState = produce(state, (draftState) => {
//         const { clicked, id } = action.payload;
//         draftState.rescindConditionClicked = {
//           ...state.rescindConditionClicked,
//           [id]: clicked
//         };
//       });
//       return nextState;
//     }
//     case SET_SELECTED_CONDITION: {
//       const nextState = produce(state, (draftState) => {
//         const { selectedConditionsArray } = action.payload;
//         draftState.selectedCondition = selectedConditionsArray;
//       });
//       return nextState;
//     }
//     case SET_WAIVER_REQUEST: {
//       const nextState = produce(state, (draftState) => {
//         const { clicked, id } = action.payload;
//         const waiverReqClick: any = { ...state.isWaiverRequestClicked };
//         waiverReqClick[id] = clicked;
//         draftState.isWaiverRequestClicked = waiverReqClick;
//       });
//       return nextState;
//     }
//     case UPDATE_ACTION_CLICKS: {
//       const { type, actionId, exceptionId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.actionClicks = draftState.actionClicks.filter(
//           (val: any) =>
//             val.actionId !== actionId && val.exceptionId !== exceptionId
//         );
//         draftState.actionClicks = [...draftState.actionClicks, type];
//         // draftState.actionClicks = [type];
//       });
//       return nextState;
//     }
//     case POST_CHAT_BY_ID: {
//       const { actionId, chatIdObject } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const chatIindex = draftState.actionClicks.findIndex(
//           (item?: any) => item.actionId === actionId
//         );
//         if (chatIindex !== -1) {
//           draftState.actionClicks[chatIindex] = {
//             ...chatIdObject,
//             ...draftState.actionClicks[chatIindex]
//           };
//         }
//         draftState.actionClicks = [...draftState.actionClicks];
//       });
//       return nextState;
//     }
//     case POST_RESPONSE_BY_CHAT_SEQUENCE_ID: {
//       const { actionId, responseTypeObject } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const chatIindex = draftState.actionClicks.findIndex(
//           (item?: any) => item.actionId === actionId
//         );
//         if (chatIindex !== -1) {
//           draftState.actionClicks[chatIindex] = {
//             ...responseTypeObject,
//             ...draftState.actionClicks[chatIindex]
//           };
//         }
//         draftState.actionClicks = [...draftState.actionClicks];
//       });
//       return nextState;
//     }
//     case POST_RESPONSE_BY_PERSON_PARTY_ID: {
//       const { actionId, lineText, personPartyId, hasDocuments } =
//         action.payload;
//       const chatTextObject = {
//         actionId,
//         lineText,
//         personPartyId,
//         hasDocuments
//       };
//       const nextState = produce(state, (draftState) => {
//         const chatIindex = draftState.actionClicks.findIndex(
//           (item?: any) => item.actionId === actionId
//         );
//         if (chatIindex !== -1) {
//           draftState.actionClicks[chatIindex] = {
//             ...draftState.actionClicks[chatIindex],
//             ...chatTextObject
//           };
//         }
//         draftState.actionClicks = [...draftState.actionClicks];
//       });
//       return nextState;
//     }

//     case GET_RESPONSES_BY_EXCEPTION_ID: {
//       const { responses } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.responses = responses;
//       });
//       return nextState;
//     }
//     case GET_RESPONSES_BY_CHAT_SEQUENCE_ID: {
//       const { chatLines, chatSequenceId, documents, count, filter } =
//         action.payload;
//       const nextState = produce(state, (draftState) => {
//         const chatSequeneceIdIindex = draftState.responses.findIndex(
//           (item?: any) => item.chatSequenceId === chatSequenceId
//         );
//         if (chatSequeneceIdIindex !== -1) {
//           draftState.responses[chatSequeneceIdIindex] = {
//             ...draftState.responses[chatSequeneceIdIindex],
//             count,
//             ...chatLines,
//             ...documents
//           };
//           if (filter) {
//             const temp: any = draftState.responses[chatSequeneceIdIindex];
//             draftState.responses.splice(chatSequeneceIdIindex, 1);
//             draftState.responses.unshift(temp);
//           }
//         }
//         draftState.responses = [...draftState.responses];
//         draftState.exceptionAndEnquiriesLoading = false;
//       });
//       return nextState;
//     }

//     case GET_INTERNAL_COMMENTS_BY_CHAT_SEQUENCE_ID: {
//       const { chatLines, chatSequenceId, filter } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const chatSequeneceIdIindex = draftState.responses.findIndex(
//           (item?: any) => item.chatSequenceId === chatSequenceId
//         );
//         if (chatSequeneceIdIindex !== -1) {
//           for (let i = 0; i < chatLines.chatLines.length; i++) {
//             const chatItem = draftState?.responses[
//               chatSequeneceIdIindex
//             ]?.chatLines?.filter(
//               (chat: any) =>
//                 chat.chatLineSequenceId ===
//                 chatLines.chatLines[i].chatLineSequenceId
//             ).length;
//             if (!chatItem) {
//               draftState.responses[chatSequeneceIdIindex]?.chatLines?.push(
//                 chatLines.chatLines[i]
//               );
//             }
//           }

//           for (let i = 0; i < chatLines.documents.length; i++) {
//             const docItem = draftState.responses[
//               chatSequeneceIdIindex
//             ]?.documents?.filter(
//               (docs: any) =>
//                 docs.secondaryIdentifierValue ===
//                 chatLines.documents[i].secondaryIdentifierValue
//             ).length;
//             if (!docItem) {
//               draftState.responses[chatSequeneceIdIindex]?.documents?.push(
//                 chatLines.documents[i]
//               );
//             }
//           }

//           for (let i = 0; i < chatLines.accountMapping.length; i++) {
//             const accountMappingItem = draftState.responses[
//               chatSequeneceIdIindex
//             ]?.accountMapping?.filter(
//               (acc: any) =>
//                 acc.personId === chatLines.accountMapping[i].personId
//             ).length;
//             if (!accountMappingItem) {
//               draftState.responses[chatSequeneceIdIindex]?.accountMapping?.push(
//                 chatLines.accountMapping[i]
//               );
//             }
//           }

//           for (let i = 0; i < chatLines.accounts.length; i++) {
//             const accountsItem = draftState.responses[
//               chatSequeneceIdIindex
//             ]?.accounts?.filter(
//               (acc: any) => acc.partyId === chatLines.accounts[i].partyId
//             ).length;
//             if (!accountsItem) {
//               draftState.responses[chatSequeneceIdIindex]?.accounts?.push(
//                 chatLines.accounts[i]
//               );
//             }
//           }

//           for (let i = 0; i < chatLines.customers.length; i++) {
//             const customersItem = draftState.responses[
//               chatSequeneceIdIindex
//             ]?.customers?.filter(
//               (customer: any) =>
//                 customer.partyId === chatLines.customers[i].partyId
//             ).length;
//             if (!customersItem) {
//               draftState.responses[chatSequeneceIdIindex]?.customers?.push(
//                 chatLines.customers[i]
//               );
//             }
//           }

//           if (filter) {
//             const temp: any = draftState.responses[chatSequeneceIdIindex];
//             draftState.responses.splice(chatSequeneceIdIindex, 1);
//             draftState.responses.unshift(temp);
//           }
//         }
//         draftState.responses = [...draftState.responses];
//         draftState.exceptionAndEnquiriesLoading = false;
//       });
//       return nextState;
//     }
//     case DELETE_EXCEPTION_BY_RESPONSE: {
//       const { chatSequenceId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const chatSequeneceIdIndex = draftState.responses.findIndex(
//           (item?: any) => item.chatSequenceId === chatSequenceId
//         );
//         if (chatSequeneceIdIndex !== -1) {
//           draftState.responses.splice(chatSequeneceIdIndex, 1);
//         }
//         draftState.responses = [...draftState.responses];
//       });
//       return nextState;
//     }

//     case DELETE_ENQUIRY_BY_ID: {
//       const { exceptionId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const exceptionIndex =
//           draftState.exceptionsResult?.exceptions?.findIndex(
//             (item?: any) => item.exceptionId === exceptionId
//           );
//         if (exceptionIndex !== -1) {
//           draftState.exceptionsResult.exceptions.splice(exceptionIndex, 1);
//         }
//       });
//       return nextState;
//     }
// <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<
//     case CANCEL_ACTION_BY_ACTION_ID: {
//       const { actionId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const actionIndex = draftState.actionClicks.findIndex(
//           (item?: any) => item.actionId === actionId
//         );
//         if (actionIndex !== -1) {
//           draftState.actionClicks.splice(actionIndex, 1);
//         }
//         draftState.actionClicks = [...draftState.actionClicks];
//       });
//       return nextState;
//     }
//     case UPDATE_DOCS_IN_ACTIONS_CLICKS_BY_ACTION_ID: {
//       const { actionId, filesList } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const actionIndex = draftState.actionClicks.findIndex(
//           (item?: any) => item.actionId === actionId
//         );
//         if (actionIndex !== -1) {
//           draftState.actionClicks[actionIndex].files = {
//             ...filesList
//           };
//         }
//         draftState.actionClicks = [...draftState.actionClicks];
//       });
//       return nextState;
//     }
//     case DELETE_DOCS_FROM_FILELIST: {
//       const { actionId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.actionClicks.findIndex(
//           (item?: any) => item.actionId === actionId
//         );
//       });
//       return nextState;
//     }
//     case UPLOAD_EXCEL_LOAN_EXCEPTION_CHANGE: {
//       const { data } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState = data;
//       });
//       return nextState;
//     }
//     case GET_WAIVER_BY_LOAN_ID: {
//       const { waiver, documents, tempWaiver } = action.payload;
//       if (documents.length && tempWaiver?.length !== 0) {
//         waiver.waivers = tempWaiver?.map((item: any, index: number) => {
//           item.documents = documents[index] ? documents[index]?.data : [];
//           return item;
//         });
//       }
//       const nextState = produce(state, (draftState) => {
//         const newExp = {
//           waivers: [...waiver.waivers],
//           customers: waiver.customers,
//           accountMapping: waiver.accountMapping,
//           accounts: waiver.accounts
//         };
//         draftState.waiverResult = newExp;
//       });
//       return nextState;
//     }
//     case UPDATE_WAIVER_APPROVAL_STATUS: {
//       const { waiverId, waiver } = action.payload;

//       const nextState = produce(state, (draftState) => {
//         const waiverIdIndex = draftState.waiverResult.waivers?.findIndex(
//           (item?: any) => item.waiverId === waiverId
//         );
//         if (waiverIdIndex !== -1) {
//           let temp =
//             draftState?.waiverResult?.waivers?.[waiverIdIndex]?.waiverResponses;
//           if (temp) {
//             temp.push(waiver);
//           } else {
//             temp = [];
//             temp.push(waiver);
//           }
//           draftState.waiverResult.waivers[waiverIdIndex].waiverResponses = temp;
//         }
//         draftState.waiverResult.waivers = [...draftState.waiverResult.waivers];
//       });
//       return nextState;
//     }
//     // case UPDATE_EXCEPTION_STATUS: {
//     //   const { status } = action.payload;
//     //   const nextState = produce(state, draftState => {
//     //     draftState.exceptionsResult.exceptions = {
//     //       ...draftState.exceptionsResult.exceptions,
//     //       status
//     //     };
//     //   });
//     //   return nextState;
//     // }
//     case UPDATE_WAIVER_IN_REDUX: {
//       const { waiverDetails } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         draftState.waiverResult = [waiverDetails, ...draftState.waiverResult];
//       });
//       return nextState;
//     }
//     case DELETE_WAIVER_BY_ID: {
//       const { waiverId } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         const waiverIdIndex = draftState.waiverResult.waivers.findIndex(
//           (item?: any) => item.waiverId === waiverId
//         );
//         if (waiverIdIndex !== -1) {
//           draftState.waiverResult.waivers.splice(waiverIdIndex, 1);
//         }
//         draftState.waiverResult.waivers = [...draftState.waiverResult.waivers];
//       });
//       return nextState;
//     }
//     case RESET_EXCEPTION_RESPONSE: {
//       const nextState = produce(state, (draftState) => {
//         draftState.responses = [];
//       });
//       return nextState;
//     }
//     case RESET_EXCEPTION_STORE: {
//       const nextState = produce(state, (draftState) => {
//         draftState.actionClicks = [];
//         draftState.isAskAQuestionClicked = false;
//         draftState.isSatisfyExceptionClicked = false;
//         draftState.isWaiverRequestClicked = false;
//         draftState.closeConditionClicked = null;
//         draftState.waiveConditionClicked = null;
//         draftState.rescindConditionClicked = null;
//       });
//       return nextState;
//     }
//     case GET_ENQUIRY_DOCS: {
//       const nextState = produce(state, (draftState) => {
//         draftState.inquiryDocs = action.payload;
//       });
//       return nextState;
//     }
//     case GET_QUERY: {
//       const nextState = produce(state, (draftState) => {
//         draftState.queryResponse = action.payload;
//       });
//       return nextState;
//     }
//     case DELETE_QUERY: {
//       const nextState = produce(state, (draftState) => {
//         const currentState = draftState;
//         const queryIndex =
//           currentState.exceptionsResult?.exceptions?.[0]?.response?.findIndex(
//             (item?: any) => item.chatSequenceId === action.payload.chatId
//           );
//         if (queryIndex !== -1) {
//           currentState.exceptionsResult.exceptions?.[0]?.response.splice(
//             queryIndex,
//             1
//           );
//         }
//         draftState.queryResponse = currentState.exceptionsResult;
//       });
//       return nextState;
//     }
//     case GET_COMMUNICATION_DATA: {
//       const { chats, exceptionDetails } = action.payload;
//       const nextState = produce(state, (draftState) => {
//         let communicationArray: any = [];
//         if (chats && exceptionDetails && exceptionDetails.length) {
//           const keys = Object.keys(chats);
//           if (keys.length) {
//             keys.forEach((key: any) => {
//               const correspondingException = exceptionDetails.find(
//                 (val: any) =>
//                   val.exceptionId === chats[key]?.chatDetails?.identifierValue
//               );
//               if (correspondingException) {
//                 const element: any = chats[key];
//                 const obj: any = {};
//                 obj.chatId = key;
//                 obj.chatDetails = element.chatDetails;
//                 obj.comments = [element.comments[element.comments.length - 1]];
//                 obj.loanId = obj.chatDetails?.secondaryIdentifierValue;
//                 obj.description =
//                   correspondingException.initialComment || "<Not Available>";
//                 obj.tag =
//                   correspondingException.tags?.length &&
//                   correspondingException.tags[0] !== ""
//                     ? correspondingException.tags[0]
//                     : null;
//                 obj.datapoint = correspondingException.datapoints?.length
//                   ? correspondingException.datapoints[0]
//                   : null;
//                 communicationArray.push(obj);
//               }
//             });
//           }
//           communicationArray = communicationArray
//             .slice()
//             .sort(
//               (a: any, b: any) =>
//                 Date.parse(b.comments[0].createdOn) -
//                 Date.parse(a.comments[0].createdOn)
//             );
//         }
//         draftState.communicationResponse = communicationArray;
//       });
//       return nextState;
//     }
//     case ADD_TO_BULK_SATISFY_PAYLOAD: {
//       const nextState = produce(state, (draftState) => {
//         const {
//           exceptionId,
//           filesList = [],
//           loanId,
//           loanStage,
//           visibility
//         } = action.payload;
//         let { lineText } = action.payload;
//         if (lineText === "") {
//           lineText = "-";
//         }
//         let documents = [];
//         if (filesList.length) {
//           documents = filesList
//             .map((file: any) => {
//               return file?.body?.[0];
//             })
//             .filter(Boolean);
//         }
//         draftState.bulkSatisfyPayload = draftState.bulkSatisfyPayload.filter(
//           (val: any) => val.exceptionId !== exceptionId
//         );
//         draftState.bulkSatisfyPayload = [
//           ...draftState.bulkSatisfyPayload,
//           {
//             exceptionId,
//             responseType: "SATISFY_EXCEPTION",
//             visibility,
//             lineText,
//             loanId,
//             loanStage,
//             documents,
//             filesList
//           }
//         ];
//       });
//       return nextState;
//     }
//     case DELETE_FROM_BULK_SATISFY_PAYLOAD: {
//       const nextState = produce(state, (draftState) => {
//         const { exceptionId } = action.payload;
//         draftState.bulkSatisfyPayload = draftState.bulkSatisfyPayload.filter(
//           (val: any) => val.exceptionId !== exceptionId
//         );
//       });
//       return nextState;
//     }
//     case RESET_BULK_SATISFY_PAYLOAD: {
//       const nextState = produce(state, (draftState) => {
//         draftState.bulkSatisfyPayload = [];
//       });
//       return nextState;
//     }
//     default: {
//       return state;
//     }
//   }
// };
