/* eslint-disable no-shadow */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useMemo } from "react";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { push } from "connected-react-router";
import { LoanStage } from "@toorak/tc-common-fe-sdk";

import {
  setActionClicks,
  setAskAQuestion,
  setSatisfyExceptions,
  setWaiverRequest
} from "../../stores/ExceptionsResults/ExceptionsReducer";
import { uuidV4 } from "../../utils/utils";
import { isViewOnly } from "../../utils/formatChecks";
import {
  ConditionsIconDisabledStyles,
  ConditionsIconStyles
  // IconButtonDropdownStyles,
  // IconDisabledButtonDropdownStyles
} from "../waiver-form/DocumentsForm.style";
import { SettingsIconDark } from "../Icons";
import { InfoOnlyTooltip } from "../../utils/Tooltip";
import { isLATUser } from "../../utils/AccessManagement";
import { ObjectType } from "../../masterView/common";
import { RootState } from "../../stores/rootReducer";
import { waiverRequestConfig } from "../../utils/constants";
import { getLoanType } from "../../config/config";
import {
  setCloseConditionClicked,
  setRescindConditionClicked,
  setWaiveConditionClicked
} from "../../stores/ExceptionsResults/ExceptionsReducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex"
    },
    paper: {
      marginRight: theme.spacing(2)
    },
    actionsButton: {
      borderRadius: "4px",
      // boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      // backgroundColor: "#5e72e4",
      paddingTop: "5px",
      paddingBottom: "5px"
    },
    menuItem: {
      fontSize: "13px !important",
      color: "#32325d"
      // padding: "8px 10px",
    }
  })
);
const satisfyCondition = {
  id: "satisfyCondition",
  label: "Satisfy Condition"
};

const satisfyInquiry = {
  id: "satisfyInquiry",
  label: "Satisfy Inquiry"
};

const askAQuestion = {
  id: "askAQuestion",
  label: "Ask a Question"
};

const requestWaiver = {
  id: "requestWaiver",
  label: "Request a Waiver"
};

const closeCondition = {
  id: "closeCondition",
  label: "Close Condition"
};

const rescindCondition = {
  id: "rescindCondition",
  label: "Rescind Condition"
};

const waiveCondition = {
  id: "waiveCondition",
  label: "Waive Condition"
};

const approveConditionally = {
  id: "approveConditionally",
  label: "Approve-Conditionally"
};

const rejectWaiverRequest = {
  id: "rejectWaiverRequest",
  label: "Reject Waiver Request"
};

const rescindWaiver = {
  id: "rescindWaiver",
  label: "Rescind Waiver"
};

const approveWaiverRequest = {
  id: "approveWaiverRequest",
  label: "Approve Waiver Request"
};

const deleteWaiver = {
  id: "deleteWaiver",
  label: "Delete Waiver"
};

const menuOptions: ObjectType = {
  LATInquiry: [askAQuestion, satisfyInquiry, requestWaiver],
  LATInternalCondition: [
    satisfyCondition,
    requestWaiver,
    askAQuestion,
    closeCondition,
    waiveCondition,
    rescindCondition
  ],
  LATExternalCondition: [satisfyCondition, requestWaiver, askAQuestion],
  LATwaiverCondition: [
    approveWaiverRequest,
    approveConditionally,
    rejectWaiverRequest,
    rescindWaiver
  ],
  ORGwaiverCondition: [deleteWaiver, rescindWaiver],
  ORGInquiry: [askAQuestion, satisfyInquiry, requestWaiver],
  ORGCondition: [askAQuestion, satisfyCondition, requestWaiver]
};

export function ExceptionDropDown(props: any) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const iconButtonOverrideClasses = IconButtonDropdownStyles();
  const conditionsIconClasses = ConditionsIconStyles();
  const iconDisabledButtonDropdownStyles = ConditionsIconDisabledStyles();
  const {
    exceptionId,
    type,
    status,
    visibility,
    viewDetailsVisible,
    approvalStatus,
    isWithdrawnLoan,
    activeInteractionModal,
    loanDetails,
    actionID = uuidV4(),
    anchorRef,
    isAskAQuestionClicked,
    isSatisfyExceptionClicked,
    isWaiverRequestClicked,
    currentLoanStage,
    exceptionData,
    cardType,
    orgId
  } = props;
  const {
    closeConditionClicked,
    waiveConditionClicked,
    rescindConditionClicked
  } = useSelector<RootState, any>((state) => state.exceptionsResultsStore);

  const [open, setOpen] = React.useState(false);
  // const anchorRef = React.useRef<HTMLButtonElement>(null);
  let { loanId, loanType, loanStage }: any = "";
  if (loanDetails) {
    ({ loanId, loanType, loanStage } = loanDetails);
  } else {
    ({ loanId, loanType, loanStage } = useParams<{
      loanId: string;
      loanStage: LoanStage;
      loanType: string;
    }>());
  }

  const isAnyOptionClicked = useMemo(() => {
    return (
      isAskAQuestionClicked ||
      isSatisfyExceptionClicked ||
      isWaiverRequestClicked ||
      closeConditionClicked?.[exceptionId] ||
      waiveConditionClicked?.[exceptionId] ||
      rescindConditionClicked?.[exceptionId]
    );
  }, [
    isAskAQuestionClicked,
    isSatisfyExceptionClicked,
    isWaiverRequestClicked,
    closeConditionClicked,
    waiveConditionClicked,
    rescindConditionClicked,
    exceptionId
  ]);

  const actionType =
    type?.toLowerCase() === "inquiry" ? "Inquiry" : "Condition";

  const showWaiverRequestButton = useMemo(() => {
    const updatedLoanType =
      loanType === getLoanType[1].loanCreationName
        ? getLoanType[1].displayValue
        : getLoanType[0].displayValue;
    if (!waiverRequestConfig?.[updatedLoanType]?.showForOrgs) return true;
    return waiverRequestConfig?.[updatedLoanType]?.showForOrgs?.some(
      (id: string) => id === orgId
    );
  }, [loanType, orgId]);

  const options = useMemo(() => {
    let source = exceptionData?.source === "CONNECT" ? "Internal" : "External";
    source = cardType === "waiver" ? "waiver" : source;
    const key = `${isLATUser() ? "LAT" : "ORG"}${
      (actionType === "Condition" && isLATUser()) || cardType === "waiver"
        ? source
        : ""
    }${actionType}`;
    const updatedOptions = menuOptions?.[key];
    return showWaiverRequestButton
      ? updatedOptions
      : updatedOptions.filter(
          (option: ObjectType) => option.id !== "requestWaiver"
        );
  }, [actionType, exceptionData, cardType, showWaiverRequestButton]);

  const handleToggle = (click: boolean) => {
    if (click) {
      setOpen((prevOpen) => !prevOpen);
    }
  };

  const handleClose: any = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpen(false);
  };

  const resetActionState = (dispatch: any) => {
    dispatch(setAskAQuestion({ clicked: false, id: exceptionId }));
    dispatch(setSatisfyExceptions({ clicked: false, id: exceptionId }));
    dispatch(setWaiverRequest({ clicked: false, id: exceptionId }));
    dispatch(setWaiveConditionClicked({ clicked: false, id: exceptionId }));
    dispatch(setRescindConditionClicked({ clicked: false, id: exceptionId }));
    dispatch(setCloseConditionClicked({ clicked: false, id: exceptionId }));
  };

  const actionMap: ObjectType = {
    "Ask a Question": setAskAQuestion,
    "Satisfy Inquiry": setSatisfyExceptions,
    "Satisfy Condition": setSatisfyExceptions,
    "Request a Waiver": setWaiverRequest,
    "Close Condition": setCloseConditionClicked,
    "Waive Condition": setWaiveConditionClicked,
    "Rescind Condition": setRescindConditionClicked
  };

  const handleActionClick = (clickType: string) => {
    resetActionState(dispatch);
    const action = actionMap[clickType];
    dispatch(action({ clicked: true, id: exceptionId }));
    // action(true, exceptionId);
  };

  const handleActions = (
    event: React.MouseEvent<EventTarget>,
    clickType: any
  ) => {
    setOpen(false);
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    if (open) {
      dispatch(
        setActionClicks({
          type: clickType,
          exceptionId,
          actionId: actionID
        })
      );
    }
    if (viewDetailsVisible) {
      navigate(
        `/loan/create/${loanType}/${loanStage}/${loanId}/${type}/${exceptionId}/${visibility}/view`
      );
    }

    handleActionClick(clickType);
  };

  function handleListKeyDown(event: React.KeyboardEvent, click: boolean) {
    if (event.key === "Tab" && click) {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef?.current!.focus();
    }
    prevOpen.current = open;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const dropDownDisabled =
    (exceptionData.source === "WORKFLOW" && isLATUser()) ||
    (exceptionId && isSatisfyExceptionClicked) ||
    isAnyOptionClicked ||
    isWithdrawnLoan ||
    status ||
    isViewOnly(loanStage, loanId, currentLoanStage);

  return (
    <div className={classes.root}>
      <div>
        <InfoOnlyTooltip
          title={
            exceptionData.source === "WORKFLOW" && isLATUser()
              ? `Actions to SD Inquiries should be through ILP`
              : !dropDownDisabled
              ? `Actions on ${type}`
              : isAnyOptionClicked
              ? `Actions are disabled because you selected one of the actions`
              : status
              ? `Actions are disabled because ${type} is already ${approvalStatus}`
              : isWithdrawnLoan
              ? "Actions are disabled because this loan is now withdrawn"
              : isViewOnly(loanStage, loanId, currentLoanStage)
              ? `Actions are disabled as the ${type} was created in the previous stage of the loan`
              : `Actions on ${type}`
          }
          arrow
          placement="right"
        >
          <IconButton
            ref={anchorRef}
            aria-label="actions"
            component="button"
            // ref={anchorRef}
            aria-controls={open ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={(e: any) => handleToggle(!dropDownDisabled)}
            // onKeyDown={(e: any) => handleListKeyDown(e, !dropDownDisabled)}
            // disabled={isWithdrawnLoan}

            classes={
              !dropDownDisabled
                ? conditionsIconClasses
                : iconDisabledButtonDropdownStyles
            }
            style={{
              background: "white !important",
              boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
              opacity: dropDownDisabled ? 0.5 : 1
            }}
            size="large"
          >
            <SettingsIconDark />
          </IconButton>
        </InfoOnlyTooltip>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
          data-testid="pooper-dropdown"
          style={{ position: "absolute", zIndex: 1 }}
          className="actionListDropdown"
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
                minWidth: "132px",
                maxWidth: "202px",
                ...(!activeInteractionModal && {
                  // top: 30,
                  position: "relative"
                })
              }}
              data-testid="grow-divs"
            >
              <Paper data-testid="paper-div">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    // autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={(e: any) =>
                      handleListKeyDown(e, !dropDownDisabled)
                    }
                    data-testid="menuList-dropdown"
                  >
                    {options.map((option: ObjectType) => {
                      return (
                        <MenuItem
                          key={option.id}
                          className={classes.menuItem}
                          style={{ fontSize: "12px !important" }}
                          onClick={(e) => handleActions(e, option.label)}
                        >
                          {option.label}
                        </MenuItem>
                      );
                    })}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </div>
  );
}
