import React, { useState, useEffect } from 'react';
// import Header from '../header/header.component';
import NavigationTabs from '../navigation-tabs/navigation-tabs.component';
import './lists.component.css';
import { getFileContentInJson } from '../../services/file-utility.component';

import { hideLoader, showLoader } from '../../../stores/loaderAndException/loaderAndException.action';
import { useDispatch } from 'react-redux';
import { getCookie } from "../../../utils/cookies";
import { fileId } from "../../utils/config/jsonData";


function Lists() {
    const dispatch = useDispatch();

    const [listData, setData] = useState({} as any);
    const [listDataArray, setListDataArray] = useState([] as any);
    const [currentValue, setCurrentValue] = useState('' as any);
    function createSectionArray(listData: any) {
        const sectionArray: any = [];
        for (let i = 0; i < Object.keys(listData).length; i++) {
            if (typeof (listData[Object.keys(listData)[i]]) === 'object')
                sectionArray.push(Object.keys(listData)[i]);
        }
        setCurrentValue(sectionArray[0]);
        setListDataArray(sectionArray);
    }
    function onTab(tabName: any) {
        setCurrentValue(tabName);
    }

    function getFileId() {
      const originatorName = getCookie("organizationName") || "";
      switch (originatorName) {
        case "MCM Holdings Inc":
          return fileId.mcm;
        case "ABL FOUR, LLC":
          return fileId.abl;
        case "Broadview Capital LLC":
          return fileId.broadview;
        case "Peerstreet":
          return fileId.peerstreet;
        case "Nvestor":
          return fileId.nvestor;
        case "Park Place":
          return fileId.parkplace;
        default:
          return fileId.default;
      }
    }

    useEffect(() => {
        async function getData() {
            //pass the id of the file which json you want to read
            dispatch(showLoader());
            const data = await getFileContentInJson(getFileId());
            setData(data.data);
            console.log(data);
            createSectionArray(data.data);
            dispatch(hideLoader());

        }
        getData();
    }, [dispatch]);
    return (
        <div>
            {/* <Backdrop className={classes.backdrop} open={open}>
                <CircularProgress color="inherit" />
            </Backdrop> */}
            {/* <Header isLoggedIn={true} /> */}
            <div className="documents-container">
                <div className="container">
                    <div className="panel-container">
                        {listDataArray?.map((item: any, key: any) => <button key={key} className={`panel-btn ${currentValue === item ? 'active' : ''}`} aria-label={`${item}-panel-contents`} onClick={e => onTab(item)}>{listData[item].name}</button>)}
                    </div>
                    <div className="document-detail-container">
                        {(currentValue && listData[currentValue]) ? <div className={`${currentValue}-panel-contents panel-tab ${currentValue ? 'active' : ''}`}>
                            <p className="main-description">{listData[currentValue].description}</p>
                            <NavigationTabs listData={listData[currentValue]} /></div> : ''}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Lists;