/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Typography, Grid, Theme, Button, Dialog } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";
import { ExpandCollapse, PropertyLoanFeatures } from "@toorak/tc-common-fe-sdk";
import {
  cleanPercentageWithMinus,
  isEmptyValue,
  isValidPercentage,
  MergeRecursive,
  sanitizeNumericInput,
  sanitizePercentage
} from "../../utils/formatChecks";
import { GenericSummaryTextField } from "../form-components/SummaryInformation";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  // UPDATE_LOAN_EVALUATION,
  // SET_RULES_PREVIOUS_STATE,
  // GET_OVERRIDDER_VALUES
  evaluateLoan,
  postOverrideOutput
} from "../../stores/EvaluationResults/EvaluationResults.action";
// import {  globalSaveOnFieldChange } from "../bridge-loan-details/LoanDetailsBorrowerInformation";
import { RootState } from "../../stores/rootReducer";
// import { valueEditing } from "../create-loan.action";
import { getEvaluationRequestBody } from "../evaluation-results/helpers/ruleRequestBody";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { requestWaiverMessage } from "../../utils/constants";
import { valueEditing } from "../create-loan.reducer";
import {
  getOverriderValues,
  setRulesPreviousState,
  updateLoanEvaluation
} from "../../stores/EvaluationResults/EvaluationResults.reducer";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { LoanSizerEnum } from "../../frontend-sizer/FrontendSizerTemplate";
let changedValueFields: any = {};
let originalLoanFeatureObject: any;
var editableFlags: any = {};
export let globalRuleFieldChange: any;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      input: {
        backgroundColor: "#ffffff !important"
      }
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3)
      // fontFamily: "OpenSans"
    },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    editImageDisplay: {
      display: "none"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    iconInfo: {
      width: "16px !important",
      height: "16px !important",
      color: "#32355d",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      color: "#32325d",
      backgroundColor: "#fff",
      marginLeft: 10,
      "&:hover": {
        background: "none"
      }
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    labelInformation: {
      fontSize: 12,
      fontWeight: 600,
      color: "#8897aa",
      height: "14px",
      "&:hover": {
        color: "#5e72e4"
      }
    },
    modalDeleteButtonConatainer: {
      marginTop: 50
    },
    modalCancelButton: {
      color: "#32325d",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      marginTop: 18,
      borderWidth: 1,
      borderColor: "#ececec"
    },
    modalButton: {
      marginRight: 12,
      color: "#ffffff",
      padding: "7px 22px",
      textTransform: "none",
      borderRadius: 4,
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.83,
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      marginTop: 18,
      borderWidth: 0,
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    calculatedOutput: {
      marginLeft: 6,
      // textAlign: "left",
      fontSize: 10,
      fontWeight: "bold",
      color: "#172b4d",
      // lineHeight: 1.8,
      borderRadius: 9,
      backgroundColor: "#d9efff",
      padding: "0 6px",
      height: 16,
      marginTop: 2,
      [theme.breakpoints.down("md")]: {
        marginLeft: "0px",
        marginTop: 10
      },
      [theme.breakpoints.only("sm")]: {
        marginTop: 4,
        marginLeft: "5px"
      }
    }
  })
);
interface ExtendedPropertyLoanFeatures extends PropertyLoanFeatures {
  esriPopulationDensity: string;
}

export interface PropertyDetailsLoanFeaturesProps {
  loanFeatures: ExtendedPropertyLoanFeatures;
  isEvaluationPanelVisible: boolean;
  propertyId: string;
  isAllowedToEdit: boolean;
}

export function PropertyDetailsLoanFeatures(
  props: PropertyDetailsLoanFeaturesProps
) {
  const { loanId, loanStage } = useParams<{
    loanId: any;
    loanStage: any;
    loanType: any;
  }>();
  const [isMinimized, setMinimized] = useState(false);
  const [resetData, setResetData] = useState(false);
  // const [isEditable, setEditable] = useState(false);
  const { loanFeatures, propertyId } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const [initialState, setInitialStateLoanFeatures] = useState(true);
  const overriddenValues = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.overriddenValues
  );
  let infoContentResult = useSelector<RootState, any>(
    (state) => state.createLoanStore.infoContentResult
  );
  const { loanDetails } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) =>
      state.createLoanStore?.loanDetails.thirtyYearLoanInformation.payload
  );
  if (infoContentResult) {
    infoContentResult = JSON.parse(JSON.stringify(infoContentResult));
  }
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    if (resetData && originalLoanFeatureObject && propertyId) {
      console.log("calling inside the mein function");
      // dispatch({
      //   type: SET_RULES_PREVIOUS_STATE,
      //   payload: {
      //     prevObj: originalLoanFeatureObject,
      //     activePropertyId: propertyId,
      //     section: "loanFeatures",
      //     loanId: loanId
      //   }
      // });
      dispatch(
        setRulesPreviousState({
          prevObj: originalLoanFeatureObject,
          activePropertyId: propertyId,
          section: "loanFeatures",
          loanId: loanId
        })
      );

      setResetData(false);
    }
  });

  interface FieldType {
    field: any;
    fieldComponent: any;
    required?: any;
    errorChecks?: any;
    ChangeValueMethod?: any;
    value: any;
    selectionData?: any;
    // FocusOutMethod?: any;
    errorMsg?: any;
    testField?: any;
    label?: string;
    keyName?: string;
    isVisible?: any;
  }

  const tempLoanType =
    loanDetails?.bridgeLoanInformation?.payload?.selectedToorakProduct ===
      "Bridge" && loanDetails?.loanSizerType === LoanSizerEnum.oneFourFamily
      ? LoanSizerEnum.oneFourFamily
      : loanDetails?.bridgeLoanInformation?.payload?.selectedToorakProduct;
  const loanTypeStr =
    loanStage === LoanStage.fes ? loanDetails?.loanSizerType : tempLoanType;
  const GridFieldsLoanFeatures: FieldType[] = [
    {
      field: "textField",
      fieldComponent: GenericSummaryTextField,
      required: "required",
      keyName: "cbsa",
      testField: "borrower-email",
      label: "CBSA",
      value: loanFeatures.cbsa,
      isVisible: () => true
    },
    {
      field: "Numeric",
      fieldComponent: GenericSummaryTextField,
      required: "required",
      keyName: "medianValue",
      testField: "borrower-email",
      label: "Median Value ($)",
      value: loanFeatures.medianValue,
      isVisible: () => true
    },
    {
      field: "percentage",
      fieldComponent: GenericSummaryTextField,
      required: "required",
      keyName: "ltmHpa",
      testField: "borrower-email",
      label: "LTM HPA",
      value: getFieldValue(loanFeatures.ltmHpa, true),
      isVisible: () => true
    },
    {
      field: "Numeric",
      fieldComponent: GenericSummaryTextField,
      required: "required",
      keyName: "daysOnMarket",
      testField: "borrower-email",
      label: "Days on Market",
      value: loanFeatures.daysOnMarket,
      isVisible: () => true
    },
    {
      field: "Numeric",
      fieldComponent: GenericSummaryTextField,
      required: "required",
      keyName: "esriPopulationDensity",
      testField: "esri-population-density",
      label: "Zip Code Density",
      value: loanFeatures.esriPopulationDensity,
      isVisible: () => loanTypeStr === LoanSizerEnum.oneFourFamily
    }
  ];
  const activePropertyId = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails.activePropertyId
  );
  const handleFeatureResultChange = (
    keyName: any,
    value: any,
    fieldType: string
  ) => {
    console.log(loanFeatures);

    let fieldValue = value.value.replace("$", "");

    if (fieldType === "percentage") {
      fieldValue = JSON.stringify(Number(sanitizeNumericInput(value.value)));
    }
    if (fieldType === "Numeric") {
      fieldValue = sanitizeNumericInput(value.value);
    }
    if (!JSON.stringify(changedValueFields).includes(keyName)) {
      changedValueFields[keyName] = fieldValue;
    }
    // eslint-disable-next-line
    Object.keys(changedValueFields).map(function (key, index) {
      if (key === keyName) {
        changedValueFields[key] = fieldValue;
        // item[keyName] = fieldValue;
        // console.log('hello');
      }
    });
    // dispatch({
    //   type: UPDATE_LOAN_EVALUATION,
    //   payload: {
    //     key: keyName,
    //     value: fieldValue,
    //     section: "loanFeatures",
    //     loanId: loanId,
    //     activePropertyId: propertyId
    //   }
    // });
    dispatch(
      updateLoanEvaluation({
        key: keyName,
        value: fieldValue,
        section: "loanFeatures",
        loanId,
        activePropertyId: propertyId
      })
    );
  };
  const {
    bridgeLoanBorrowerInformation,
    bridgeLoanGuarantorInformation,
    bridgeLoanInformation,
    bridgeLoanEconomics,
    propertyDetails,
    loanState,
    loanRuleVersions,
    loanSummary
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector<RootState, any>(
    (state: { createLoanStore: { loanDetails: any } }) =>
      state.createLoanStore.loanDetails
  );
  const loanEvaluationResult = useSelector<
    RootState,
    { loanResult: any; propertiesResults: any }
  >((state) => state.evaluationResultsStore.loanEvaluationResult);
  if (editableFlags[`editProp${propertyId}`] === undefined) {
    editableFlags[`editProp${propertyId}`] = false;
  }
  const data = {
    borrowerInformation: bridgeLoanBorrowerInformation,
    guarantorInformation: bridgeLoanGuarantorInformation,
    loanInformation: bridgeLoanInformation.payload,
    loanEconomics: bridgeLoanEconomics.payload,
    loanRuleVersions,
    loanSummary
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (loanState === LoanStatusEnum.DueDiligenceReview) {
      dispatch(valueEditing(false));
      setInitialStateLoanFeatures(true);
      editableFlags[`editProp${propertyId}`] = false;
      setInitialStateLoanFeatures(true);

      // setResetData(!resetData);

      changedValueFields = {};
    }
  }, [loanState]);

  async function ruleFieldChange(changedValueFields: {}) {
    const newDs = {
      propertiesResults: {
        [activePropertyId]: {
          loanFeatures: { ...changedValueFields }
        }
      }
    };
    let lopin =
      overriddenValues.loanResult || overriddenValues.propertiesResults
        ? MergeRecursive(overriddenValues, newDs)
        : newDs;
    try {
      await postOverrideOutput(loanId, loanStage, newDs, takeoutPartnerId);
      // dispatch({
      //   type: GET_OVERRIDDER_VALUES,
      //   payload: {
      //     overidden: lopin
      //   }
      // });

      dispatch(
        getOverriderValues({
          overidden: lopin
        })
      );
      getEvaluationRequestBody(
        loanId,
        loanStage,
        data,
        loanEvaluationResult,
        propertyDetails
      ).then(({ evaluate, loanEvaluationRequest }) => {
        if (evaluate && loanEvaluationRequest) {
          loanEvaluationRequest.ruleAttributs = {
            ruleGroup: [],
            ruleField: [],
            ruleResult: []
          };
          loanEvaluationRequest.resultObject = lopin;
          // console.log(loanEvaluationRequest, evaluate);
          //  return resolve(loanEvaluationRequest);
          dispatch(
            evaluateLoan(
              loanEvaluationRequest,
              loanId,
              loanStage,
              true,
              false,
              propertyDetails
            )
          );
          // resolve("null");
        } else {
          // return reject(null);
        }
      });
    } catch (e) {
      console.log(e);
    }

    // changedValueFields = [];
  }
  globalRuleFieldChange = ruleFieldChange;

  // const activePropertyId = useSelector<RootState, string | null>(
  //   state => state.createLoanStore.loanDetails.activePropertyId
  // );

  function getFieldValue(value: any, editable?: boolean) {
    if (initialState || editable !== true) {
      return isValidPercentage(sanitizePercentage(value, true))
        ? sanitizePercentage(value, true)
        : value;
    } else {
      //  if(editable){
      //   return value*100;

      //  }
      return value;
    }
  }
  const onhtmChange = (keyName: any, value: any, fieldType: string) => {
    setInitialStateLoanFeatures(false);
    if (!JSON.stringify(changedValueFields).includes(keyName)) {
      changedValueFields[keyName] = value.value.replace("%", "") / 100;
    }
    // eslint-disable-next-line
    Object.keys(changedValueFields).map(function (key, index) {
      if (key === keyName) {
        changedValueFields[key] = value.value.replace("%", "") / 100;
        // item[keyName] = fieldValue;
        // console.log('hello');
      }
    });
    //  let fieldValue = (Number(sanitizeNumericInput(value.value))/100);

    // dispatch({
    //   type: UPDATE_LOAN_EVALUATION,
    //   payload: {
    //     key: keyName,
    //     value: cleanPercentageWithMinus(value.value),
    //     section: "loanFeatures",
    //     loanId: loanId,
    //     activePropertyId: propertyId
    //   }
    // });
    dispatch(
      updateLoanEvaluation({
        key: keyName,
        value: cleanPercentageWithMinus(value.value),
        section: "loanFeatures",
        loanId,
        activePropertyId: propertyId
      })
    );
  };
  const onltmHpaFocusOut = (keyName: any, value: any, fieldType: string) => {
    if (keyName !== "ltmHpa") {
      return;
    }
    let fieldValue: Number = 0;
    let mainValue = value.value;
    if (isNaN(mainValue)) {
      fieldValue = 0;
    } else {
      fieldValue =
        Number(cleanPercentageWithMinus(mainValue.replace("%", ""))) / 100;
    }

    let stringFieldValue = JSON.stringify(fieldValue);

    // dispatch({
    //   type: UPDATE_LOAN_EVALUATION,
    //   payload: {
    //     key: keyName,
    //     value: cleanPercentageWithMinus(stringFieldValue),
    //     section: "loanFeatures",
    //     loanId: loanId,
    //     activePropertyId: propertyId
    //   }
    // });
    dispatch(
      updateLoanEvaluation({
        key: keyName,
        value: cleanPercentageWithMinus(stringFieldValue),
        section: "loanFeatures",
        loanId,
        activePropertyId: propertyId
      })
    );

    setInitialStateLoanFeatures(true);
  };

  const colSpan = 4;
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {/* Minimize Section start */}
      <Grid container spacing={3} className={classes.sectionWrapper}>
        <Grid item xs={12} container>
          <Grid container item xs={6} sm={11}>
            <Typography variant="h6" className={classes.headingTypography}>
              Loan Features
            </Typography>
            <Typography variant="h6" className={classes.calculatedOutput}>
              CALCULATED OUTPUTS
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1} className={classes.headingImageContainer}>
            {/* {isAllowedToEdit && loanStage !== "FES" && <img
              className={
                editableFlags[`editProp${propertyId}`] || !isEvaluationPanelVisible || !isLATUser()
                  ? classes.editImageDisplay
                  : classes.editImage
              }
              src={editImage}
              alt="Edit Section"
              onClick={() => {
                changedValueFields = {};
                dispatch(valueEditing(true));
                originalLoanFeatureObject = JSON.parse(JSON.stringify(loanFeatures));
                setEditable(!isEditable);
                editableFlags[`editProp${propertyId}`] = true;
                // cancelForm("features","add");

              }}
              onKeyDown={() => {
                dispatch(valueEditing(true));
                originalLoanFeatureObject = JSON.parse(JSON.stringify(loanFeatures));
                setEditable(true);
                editableFlags[`editProp${propertyId}`] = true;

              }}
            />} */}
            <ExpandCollapse
              isMinimize={isMinimized}
              clicked={() => {
                setMinimized(!isMinimized);
              }}
              keyDown={() => {
                setMinimized(!isMinimized);
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        <div className={classes.divider} />
      </Grid>
      {/* Divider Section end */}
      <Grid
        container
        spacing={4}
        style={{
          padding: 24,
          display: isMinimized ? "none" : ""
        }}
      >
        {GridFieldsLoanFeatures.filter((field) => field.isVisible()).map(
          (item, index) => {
            const SummaryFieldComponent = item.fieldComponent;
            return (
              <Grid container item xs={12} sm={6} md={colSpan} xl={colSpan}>
                <SummaryFieldComponent
                  required
                  label={item.label}
                  isViewMode={!editableFlags[`editProp${propertyId}`]}
                  value={
                    item.keyName === "medianValue" && !isEmptyValue(item.value)
                      ? "$" + item.value
                      : item.value
                  }
                  onChange={
                    item.keyName !== "ltmHpa"
                      ? (e: React.FormEvent<HTMLInputElement>) => {
                          handleFeatureResultChange(
                            item.keyName,
                            e.target,
                            item.field
                          );
                        }
                      : (e: React.FormEvent<HTMLInputElement>) => {
                          onhtmChange(item.keyName, e.target, item.field);
                        }
                  }
                  onBlur={(e: React.FormEvent<HTMLInputElement>) => {
                    onltmHpaFocusOut(item.keyName, e.target, item.field);
                  }}
                  infoIconData={
                    infoContentResult &&
                    infoContentResult[
                      `fieldInfo.bridge.${loanStage.toLowerCase()}.result.propertyResults.loanFeatures.${
                        item.keyName
                      }`
                    ]
                  }
                />
              </Grid>
            );
          }
        )}
        {editableFlags[`editProp${activePropertyId}`] && (
          <Grid container item xs={12} sm={12} md={12} xl={12}>
            <Button
              variant="contained"
              color="info"
              // data-testid="save-button"
              className={
                editableFlags[`editProp${propertyId}`]
                  ? classes.savebutton
                  : classes.editImageDisplay
              }
              // startIcon={<SaveOutlinedIcon />}
              disabled={Object.keys(changedValueFields).length === 0}
              onClick={() => {
                handleOpen();
                // saveOnFieldChange();
                editableFlags[`editProp${propertyId}`] = false;
                // changedValueFields = [];
              }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="info"
              // data-testid="save-button"
              className={
                editableFlags[`editProp${propertyId}`]
                  ? classes.cancelButton
                  : classes.editImageDisplay
              }
              // startIcon={<SaveOutlinedIcon />}
              // onClick={handleSave}
              onClick={() => {
                dispatch(valueEditing(false));
                setInitialStateLoanFeatures(true);
                editableFlags[`editProp${propertyId}`] = false;
                setInitialStateLoanFeatures(true);

                setResetData(!resetData);

                changedValueFields = {};
              }}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="duplicate-property-modal"
        aria-describedby="duplicate-property-modal-description"
      >
        <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}>
              Warning
            </div>
            <CloseIcon
              htmlColor="#414169"
              style={{ width: 16, height: 16, cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <div
            style={{
              fontSize: 12,
              lineHeight: 1.5,
              color: "#32325d",
              marginTop: 40
            }}
          >
            {requestWaiverMessage}
          </div>
          <div className={classes.modalDeleteButtonConatainer}>
            <Button
              variant="contained"
              color="primary"
              data-testid="modal-delete-button"
              className={classes.modalButton}
              onClick={() => {
                dispatch(valueEditing(false));
                ruleFieldChange(changedValueFields);
                handleClose();
                editableFlags[`editProp${propertyId}`] = false;
                setInitialStateLoanFeatures(true);
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              data-testid="modal-cancel-button"
              className={classes.modalCancelButton}
              onClick={() => {
                dispatch(valueEditing(false));
                changedValueFields = {};
                handleClose();
                setResetData(!resetData);
                editableFlags[`editProp${propertyId}`] = false;
                setInitialStateLoanFeatures(true);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
