// import { produce } from "immer";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import {
//   INITIALIZE_ESCROW,
//   UPDATE_ESCROW_PARAMS
// } from "./EscrowCalculator.action";
import {
  formatData,
  formatCommaCheck,
  isNullOrEmpty,
  sortByOrder
} from "../../create-loan/tape-to-file/TapeToFileUtils";

export interface EscrowCalculatorStore {
  escrowInfoState: any;
}

export const escrowAnnualValuesFormula = (obj: any, tab: string) => {
  if (tab === "tax") {
    escrowClosingandMonthsFormula(obj, "taxEscrow", tab);
  } else if (tab === "hazardInsurance") {
    escrowClosingandMonthsFormula(obj, "hazardInsuranceEscrow", tab);
  } else if (tab === "floodInsurance") {
    escrowClosingandMonthsFormula(obj, "floodInsuranceEscrow", tab);
  }
};

export const calculateTotalAggrVal = (obj: any, level?: string) => {
  let count = 0;
  if (level === "property") {
    if (!isNullOrEmpty(obj?.escrowedTaxClosingProperty)) {
      count =
        count + parseFloat(formatCommaCheck(obj?.escrowedTaxClosingProperty));
    }
    if (!isNullOrEmpty(obj?.escrowedHazardInsuranceClosingProperty)) {
      count =
        count +
        parseFloat(
          formatCommaCheck(obj?.escrowedHazardInsuranceClosingProperty)
        );
    }
    if (!isNullOrEmpty(obj?.escrowedFloodInsuranceClosingProperty)) {
      count =
        count +
        parseFloat(
          formatCommaCheck(obj?.escrowedFloodInsuranceClosingProperty)
        );
    }
    obj.totalAggregateProperty =
      count > 0 ? formatData(count.toFixed(2), "currency") : "";
  } else if (level === "loan") {
    if (!isNullOrEmpty(obj?.escrowedTaxClosingLoan)) {
      count = count + parseFloat(formatCommaCheck(obj?.escrowedTaxClosingLoan));
    }
    if (!isNullOrEmpty(obj?.escrowedHazardInsuranceClosingLoan)) {
      count =
        count +
        parseFloat(formatCommaCheck(obj?.escrowedHazardInsuranceClosingLoan));
    }
    if (!isNullOrEmpty(obj?.escrowedFloodInsuranceClosingLoan)) {
      count =
        count +
        parseFloat(formatCommaCheck(obj?.escrowedFloodInsuranceClosingLoan));
    }
    obj.totalAggregateloan =
      count > 0 ? formatData(count.toFixed(2), "currency") : "";
  } else {
    if (!isNullOrEmpty(obj?.escrowedTaxHUD)) {
      count = count + parseFloat(formatCommaCheck(obj?.escrowedTaxHUD));
    }
    if (!isNullOrEmpty(obj?.escrowedHazardInsuranceHUD)) {
      count =
        count + parseFloat(formatCommaCheck(obj?.escrowedHazardInsuranceHUD));
    }
    if (!isNullOrEmpty(obj?.escrowedFloodInsuranceHUD)) {
      count =
        count + parseFloat(formatCommaCheck(obj?.escrowedFloodInsuranceHUD));
    }
    obj.totalAggregateHUD =
      count > 0 ? formatData(count.toFixed(2), "currency") : "";
  }
};

export const escrowMonthsFormula = (
  obj: any,
  annualPayment: any,
  tab: string
) => {
  if (tab === "tax") {
    obj = computeEscrow(obj, "taxEscrow", annualPayment.tax, "taxPayment");
  } else if (tab === "hazardInsurance") {
    obj = computeEscrow(
      obj,
      "hazardInsuranceEscrow",
      annualPayment.hazardInsurance,
      "hazardInsurancePayment"
    );
  } else if (tab === "floodInsurance") {
    obj = computeEscrow(
      obj,
      "floodInsuranceEscrow",
      annualPayment.floodInsurance,
      "floodInsurancePayment"
    );
  }
};

export const topLevelAggregation = (obj: any, tab: string, value: string) => {
  let count = 0;
  obj.propertyInfo.forEach((item: any) => {
    if (!isNullOrEmpty(item.correctEscrow[tab])) {
      count =
        parseFloat(formatCommaCheck(count)) +
        parseFloat(formatCommaCheck(item.correctEscrow[tab]));
    }
  });
  if (count > 0) {
    obj[value] = formatData(count.toFixed(2), "currency");
  } else {
    obj[value] = "";
  }
};

const initialState: EscrowCalculatorStore = {
  escrowInfoState: {}
};

const monthsinWords = [
  "January",
  "Feburary",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December"
];

const runMonthCalculation = (obj: any) => {
  if (obj?.months?.length) {
    escrowMonthsFormula(obj.months, obj.annualPayments, "tax");
    escrowMonthsFormula(obj.months, obj.annualPayments, "hazardInsurance");
    escrowMonthsFormula(obj.months, obj.annualPayments, "floodInsurance");
  }
  return obj;
};

const calculateAggregateValue = (obj: any, data: any) => {
  if (isNullOrEmpty(data?.escrowloanInfo?.loanSummary?.propertyEscrowTax)) {
    topLevelAggregation(obj, "tax", "escrowedTaxClosingProperty");
  }
  if (
    isNullOrEmpty(data?.escrowloanInfo?.loanSummary?.propertyHazardInsurance)
  ) {
    topLevelAggregation(
      obj,
      "hazardInsurance",
      "escrowedHazardInsuranceClosingProperty"
    );
  }
  if (
    isNullOrEmpty(data?.escrowloanInfo?.loanSummary?.propertyFloodInsurance)
  ) {
    topLevelAggregation(
      obj,
      "floodInsurance",
      "escrowedFloodInsuranceClosingProperty"
    );
  }
  if (isNullOrEmpty(data?.escrowloanInfo?.loanSummary?.escrowTaxesAtClosing)) {
    obj.escrowedTaxClosingLoan = obj?.loanInfo?.correctEscrow?.tax || "";
  }
  if (isNullOrEmpty(data?.escrowloanInfo?.loanSummary?.escrowHazardInsurance)) {
    obj.escrowedHazardInsuranceClosingLoan =
      obj?.loanInfo?.correctEscrow?.hazardInsurance || "";
  }
  if (isNullOrEmpty(data?.escrowloanInfo?.loanSummary?.escrowFloodInsurance)) {
    obj.escrowedFloodInsuranceClosingLoan =
      obj?.loanInfo?.correctEscrow?.floodInsurance || "";
  }
};

const calculateTotalAggregate = (obj: any) => {
  calculateTotalAggrVal(obj);
  calculateTotalAggrVal(obj, "property");
  calculateTotalAggrVal(obj, "loan");
};

const calculateEscrowValues = (obj: any) => {
  escrowAnnualValuesFormula(obj, "tax");
  escrowAnnualValuesFormula(obj, "hazardInsurance");
  escrowAnnualValuesFormula(obj, "floodInsurance");
};

const initializeMonthsData = (
  obj: any,
  firstPaymentDate: string,
  mapId: string,
  mapOrder: string
) => {
  let stringifiedDateParts, month: any, year: any;
  if (firstPaymentDate) {
    stringifiedDateParts = firstPaymentDate.split("/");
    if (stringifiedDateParts?.[0]) {
      month = parseInt(stringifiedDateParts[0]);
      month--;
    }
    if (stringifiedDateParts?.[2]) {
      year = parseInt(stringifiedDateParts[2]);
    }
    if (Number.isInteger(month) && Number.isInteger(year)) {
      for (let i = 0; i < 13; i++) {
        let objMonth: any = {
          month: "",
          year: "",
          taxPayment: "",
          taxEscrow: "",
          hazardInsurancePayment: "",
          hazardInsuranceEscrow: "",
          floodInsurancePayment: "",
          floodInsuranceEscrow: "",
          [mapId]: "",
          [mapOrder]: i
        };
        objMonth.month = monthsinWords[month % 12];
        month++;
        if (month === 13) {
          year++;
        }
        objMonth.year = year;
        obj.months.push(objMonth);
      }
      obj = runMonthCalculation(obj);
    }
  }
  return obj;
};

const computeEscrow = (obj: any, key: string, annual: any, payment: string) => {
  annual = parseFloat(formatCommaCheck(annual));
  annual = isNaN(annual) ? 0 : annual;
  let firstMonthEscrow = annual / 12;
  for (let i = 0; i < 13; i++) {
    let paymentVal: any = parseFloat(formatCommaCheck(obj[i][payment]));
    paymentVal = isNaN(paymentVal) ? null : paymentVal;
    if (i === 0) {
      obj[i][key] = firstMonthEscrow;
    } else if (isNullOrEmpty(paymentVal)) {
      obj[i][key] = obj[i - 1][key] + firstMonthEscrow;
    } else {
      obj[i][key] = obj[i - 1][key] - paymentVal;
    }
  }

  for (let i = 0; i < 13; i++) {
    obj[i][key] = formatData(obj[i][key], "currencyWithMinus");
  }
};
const escrowClosingandMonthsFormula = (obj: any, key: string, tab: string) => {
  let arr: any = [];
  obj.months.forEach((item: any) => {
    const value = isNullOrEmpty(item[key])
      ? item[key]
      : parseFloat(formatCommaCheck(item[key]));
    arr.push(value);
  });
  let arrIndex = arr.indexOf(Math.min(...arr));
  let minEle = arr[arrIndex];
  let istNegtiveValue = 0;
  if (tab === "tax" || tab === "hazardInsurance") {
    if (minEle < 0) {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i] < 0) {
          istNegtiveValue = arr[i];
          break;
        }
      }
      if (istNegtiveValue < 0) {
        obj.correctEscrow[tab] = formatData(
          -istNegtiveValue + arr[0] * 2,
          "currency"
        );
      } else {
        obj.correctEscrow[tab] = "";
      }
    } else if (minEle >= 0 && minEle < 0.1) {
      obj.correctEscrow[tab] = formatData(arr[0] * 2, "currency");
    } else if (minEle >= 0.1) {
      obj.correctEscrow[tab] = formatData(arr[0], "currency");
    }
    const monthsOfEscrowVal: any = Math.round(
      parseFloat(formatCommaCheck(obj.correctEscrow[tab])) / arr[0]
    );
    if (isNaN(monthsOfEscrowVal) || !Number.isFinite(monthsOfEscrowVal)) {
      obj.monthsOfEscrow[tab] = null;
    } else {
      obj.monthsOfEscrow[tab] = monthsOfEscrowVal;
    }
  } else if (tab === "floodInsurance") {
    if (
      isNullOrEmpty(obj.annualPayments.floodInsurance) ||
      obj.annualPayments.floodInsurance === 0
    ) {
      obj.correctEscrow[tab] = null;
    } else {
      for (let i = 0; i < arr.length - 1; i++) {
        if (arr[i] < 0) {
          istNegtiveValue = arr[i];
          break;
        }
      }
      if (istNegtiveValue < 0) {
        obj.correctEscrow[tab] = formatData(
          -istNegtiveValue + arr[0] * 2,
          "currency"
        );
      } else {
        obj.correctEscrow[tab] =
          istNegtiveValue === 0 ? "" : formatData(istNegtiveValue, "currency");
      }
    }
    const monthsOfEscrowVal: any = Math.round(
      parseFloat(formatCommaCheck(obj.correctEscrow[tab])) / arr[0]
    );
    if (isNaN(monthsOfEscrowVal) || !Number.isFinite(monthsOfEscrowVal)) {
      obj.monthsOfEscrow[tab] = null;
    } else {
      obj.monthsOfEscrow[tab] = monthsOfEscrowVal;
    }
  }
};

const findAggFromProp = (value: string, obj: any, key: string) => {
  if (!obj) return;
  let sum = 0;
  obj.forEach((obj: any) => {
    let val = parseFloat(formatCommaCheck(obj?.propertyEconomics?.[key]));
    val = isNaN(val) ? 0 : val;
    sum += val;
  });
  return formatData(sum, "currency");
};

const EscrowCalculatorSlice = createSlice({
  name: "excrowCalculator",
  initialState: initialState,
  reducers: {
    initializeEscrow: (state, action: PayloadAction<any>) => {
      const data = action.payload;
      const level =
        data?.escrowloanInfo?.loanSummary?.escrowCalculatorType || "loan";
      let escrowValuesSource =
        data?.escrowloanInfo?.loanSummary?.escrowValuesSource;
      if (!isNullOrEmpty(escrowValuesSource)) {
        escrowValuesSource = level + escrowValuesSource;
      }
      const obj: any = {
        level,
        firstPaymentDate: data?.firstPaymentDate,
        escrowValuesSource,
        totalAggregateHUD: "",
        totalAggregateProperty: "",
        totalAggregateloan: "",
        // same for both loan and property level ...
        escrowedTaxHUD:
          formatData(
            data?.escrowloanInfo?.loanSummary?.escrowTaxHUD,
            "currency"
          ) || "",
        escrowedHazardInsuranceHUD:
          formatData(
            data?.escrowloanInfo?.loanSummary?.escrowHazardInsuranceHUD,
            "currency"
          ) || "",
        escrowedFloodInsuranceHUD:
          formatData(
            data?.escrowloanInfo?.loanSummary?.escrowFloodInsuranceHUD,
            "currency"
          ) || "",
        // top loan level ---closing Escrow
        escrowedTaxClosingLoan:
          formatData(
            data?.escrowloanInfo?.loanSummary?.escrowTaxesAtClosing,
            "currency"
          ) || "",
        escrowedHazardInsuranceClosingLoan:
          formatData(
            data?.escrowloanInfo?.loanSummary?.escrowHazardInsurance,
            "currency"
          ) || "",
        escrowedFloodInsuranceClosingLoan:
          formatData(
            data?.escrowloanInfo?.loanSummary?.escrowFloodInsurance,
            "currency"
          ) || "",
        // top property level ---closing Escrow
        escrowedTaxClosingProperty:
          formatData(
            data?.escrowloanInfo?.loanSummary?.propertyEscrowTax,
            "currency"
          ) || "",
        escrowedHazardInsuranceClosingProperty:
          formatData(
            data?.escrowloanInfo?.loanSummary?.propertyHazardInsurance,
            "currency"
          ) || "",
        escrowedFloodInsuranceClosingProperty:
          formatData(
            data?.escrowloanInfo?.loanSummary?.propertyFloodInsurance,
            "currency"
          ) || "",
        loanInfo: {},
        propertyInfo: {}
      };
      // setting loan info from props if not available

      let tax = formatData(
        data?.escrowloanInfo?.loanSummary?.taxes,
        "currency"
      );
      let hazardInsurance = formatData(
        data?.escrowloanInfo?.loanSummary?.annualHazardInsurance,
        "currency"
      );
      let floodInsurance = formatData(
        data?.escrowloanInfo?.loanSummary?.annualFloodInsurance,
        "currency"
      );
      tax = findAggFromProp(
        tax,
        data?.escrowPropertyInfo,
        "annualPropertyTaxes"
      );
      hazardInsurance = findAggFromProp(
        hazardInsurance,
        data?.escrowPropertyInfo,
        "annualHazardInsurance"
      );
      floodInsurance = findAggFromProp(
        floodInsurance,
        data?.escrowPropertyInfo,
        "annualFloodInsurance"
      );

      //loan info initialzation
      let objProp: any = {
        annualPayments: {
          tax,
          hazardInsurance,
          floodInsurance
        },
        correctEscrow: {
          tax: formatData(
            data?.escrowloanInfo?.loanSummary?.correctTaxEscrow,
            "currency"
          ),
          hazardInsurance: formatData(
            data?.escrowloanInfo?.loanSummary?.correctHazardInsuranceEscrow,
            "currency"
          ),
          floodInsurance: formatData(
            data?.escrowloanInfo?.loanSummary?.correctFloodInsuranceEscrow,
            "currency"
          )
        },
        monthsOfEscrow: {
          tax: data?.escrowloanInfo?.loanSummary?.monthsOfPropertyTaxEscrow,
          hazardInsurance:
            data?.escrowloanInfo?.loanSummary?.monthsOfHazardInsurance,
          floodInsurance:
            data?.escrowloanInfo?.loanSummary?.monthsOfFloodInsurance
        },
        months: []
      };
      if (data?.escrowloanInfo?.loanEscrowMap?.length) {
        const loanEscrowMap = sortByOrder(
          data.escrowloanInfo.loanEscrowMap,
          "loanEscrowMapId",
          "loanEscrowMapOrder"
        );
        loanEscrowMap?.forEach((item: any) => {
          let objMonth: any = {
            month: item?.month,
            year: item?.year,
            taxPayment: formatData(item?.propertyTaxPayment, "currency"),
            taxEscrow: formatData(item?.propertyTaxEscrow, "currencyWithMinus"),
            hazardInsurancePayment: formatData(
              item?.hazardInsurancePayment,
              "currency"
            ),
            hazardInsuranceEscrow: formatData(
              item?.hazardInsuranceEscrow,
              "currencyWithMinus"
            ),
            floodInsurancePayment: formatData(
              item?.floodInsurancePayment,
              "currency"
            ),
            floodInsuranceEscrow: formatData(
              item?.floodInsuranceEscrow,
              "currencyWithMinus"
            ),
            loanEscrowMapId: item?.loanEscrowMapId,
            loanEscrowMapOrder: item?.loanEscrowMapOrder
          };
          objProp.months.push(objMonth);
        });
      } else {
        objProp = initializeMonthsData(
          objProp,
          data?.firstPaymentDate,
          "loanEscrowMapId",
          "loanEscrowMapOrder"
        );
      }
      objProp = runMonthCalculation(objProp);
      calculateEscrowValues(objProp);
      obj.loanInfo = objProp;
      // property info initialization
      let escrowProperty: any = [];
      data?.escrowPropertyInfo?.forEach((item: any) => {
        objProp = {
          annualPayments: {
            tax: formatData(
              item?.propertyEconomics?.annualPropertyTaxes,
              "currency"
            ),
            hazardInsurance: formatData(
              item?.propertyEconomics?.annualHazardInsurance,
              "currency"
            ),
            floodInsurance: formatData(
              item?.propertyEconomics?.annualFloodInsurance,
              "currency"
            )
          },
          correctEscrow: {
            tax: formatData(
              item?.propertySummary?.escrowTaxesAtClosing,
              "currency"
            ),
            hazardInsurance: formatData(
              item?.propertySummary?.escrowHazardInsurance,
              "currency"
            ),
            floodInsurance: formatData(
              item?.propertySummary?.escrowFloodInsurance,
              "currency"
            )
          },
          monthsOfEscrow: {
            tax: item?.propertySummary?.monthsOfPropertyTaxEscrow,
            hazardInsurance: item?.propertySummary?.monthsOfHazardInsurance,
            floodInsurance: item?.propertySummary?.monthsOfFloodInsurance
          },
          months: [],
          propToggle: false,
          loanPropertyId: item?.loanPropertyId,
          address: item?.propertyLocation
        };

        if (item?.propertyEscrowMap?.length) {
          const propertyEscrowMap = sortByOrder(
            item.propertyEscrowMap,
            "propertyEscrowMapId",
            "propertyEscrowMapOrder"
          );
          propertyEscrowMap?.forEach((item: any) => {
            let objMonth: any = {
              month: item?.month,
              year: item?.year,
              taxPayment: formatData(item?.propertyTaxPayment, "currency"),
              taxEscrow: formatData(
                item?.propertyTaxEscrow,
                "currencyWithMinus"
              ),
              hazardInsurancePayment: formatData(
                item?.hazardInsurancePayment,
                "currency"
              ),
              hazardInsuranceEscrow: formatData(
                item?.hazardInsuranceEscrow,
                "currencyWithMinus"
              ),
              floodInsurancePayment: formatData(
                item?.floodInsurancePayment,
                "currency"
              ),
              floodInsuranceEscrow: formatData(
                item?.floodInsuranceEscrow,
                "currencyWithMinus"
              ),
              propertyEscrowMapId: item?.propertyEscrowMapId,
              propertyEscrowMapOrder: item?.propertyEscrowMapOrder
            };
            objProp.months.push(objMonth);
          });
        } else {
          objProp = initializeMonthsData(
            objProp,
            data?.firstPaymentDate,
            "propertyEscrowMapId",
            "propertyEscrowMapOrder"
          );
        }
        objProp = runMonthCalculation(objProp);
        calculateEscrowValues(objProp);
        escrowProperty.push(objProp);
      });
      obj.propertyInfo = escrowProperty;
      calculateAggregateValue(obj, data);
      calculateTotalAggregate(obj);
      state.escrowInfoState = obj;
    },
    updateEscrow: (state, action: PayloadAction<any>) => {
      const { payload } = action;
      state.escrowInfoState = payload;
    }
  }
});

export const { initializeEscrow, updateEscrow } = EscrowCalculatorSlice.actions;

export const EscrowCalculatorReducer = EscrowCalculatorSlice.reducer;

// export const EscrowCalculatorReducer = (state = initialState, action: any) => {
//   switch (action.type) {
//     case INITIALIZE_ESCROW: {
//       const { data } = action.payload;
//       const level =
//         data?.escrowloanInfo?.loanSummary?.escrowCalculatorType || "loan";
//       let escrowValuesSource =
//         data?.escrowloanInfo?.loanSummary?.escrowValuesSource;
//       if (!isNullOrEmpty(escrowValuesSource)) {
//         escrowValuesSource = level + escrowValuesSource;
//       }
//       const obj: any = {
//         level,
//         firstPaymentDate: data?.firstPaymentDate,
//         escrowValuesSource,
//         totalAggregateHUD: "",
//         totalAggregateProperty: "",
//         totalAggregateloan: "",
//         // same for both loan and property level ...
//         escrowedTaxHUD:
//           formatData(
//             data?.escrowloanInfo?.loanSummary?.escrowTaxHUD,
//             "currency"
//           ) || "",
//         escrowedHazardInsuranceHUD:
//           formatData(
//             data?.escrowloanInfo?.loanSummary?.escrowHazardInsuranceHUD,
//             "currency"
//           ) || "",
//         escrowedFloodInsuranceHUD:
//           formatData(
//             data?.escrowloanInfo?.loanSummary?.escrowFloodInsuranceHUD,
//             "currency"
//           ) || "",
//         // top loan level ---closing Escrow
//         escrowedTaxClosingLoan:
//           formatData(
//             data?.escrowloanInfo?.loanSummary?.escrowTaxesAtClosing,
//             "currency"
//           ) || "",
//         escrowedHazardInsuranceClosingLoan:
//           formatData(
//             data?.escrowloanInfo?.loanSummary?.escrowHazardInsurance,
//             "currency"
//           ) || "",
//         escrowedFloodInsuranceClosingLoan:
//           formatData(
//             data?.escrowloanInfo?.loanSummary?.escrowFloodInsurance,
//             "currency"
//           ) || "",
//         // top property level ---closing Escrow
//         escrowedTaxClosingProperty:
//           formatData(
//             data?.escrowloanInfo?.loanSummary?.propertyEscrowTax,
//             "currency"
//           ) || "",
//         escrowedHazardInsuranceClosingProperty:
//           formatData(
//             data?.escrowloanInfo?.loanSummary?.propertyHazardInsurance,
//             "currency"
//           ) || "",
//         escrowedFloodInsuranceClosingProperty:
//           formatData(
//             data?.escrowloanInfo?.loanSummary?.propertyFloodInsurance,
//             "currency"
//           ) || "",
//         loanInfo: {},
//         propertyInfo: {}
//       };
//       // setting loan info from props if not available

//       let tax = formatData(
//         data?.escrowloanInfo?.loanSummary?.taxes,
//         "currency"
//       );
//       let hazardInsurance = formatData(
//         data?.escrowloanInfo?.loanSummary?.annualHazardInsurance,
//         "currency"
//       );
//       let floodInsurance = formatData(
//         data?.escrowloanInfo?.loanSummary?.annualFloodInsurance,
//         "currency"
//       );
//       tax = findAggFromProp(
//         tax,
//         data?.escrowPropertyInfo,
//         "annualPropertyTaxes"
//       );
//       hazardInsurance = findAggFromProp(
//         hazardInsurance,
//         data?.escrowPropertyInfo,
//         "annualHazardInsurance"
//       );
//       floodInsurance = findAggFromProp(
//         floodInsurance,
//         data?.escrowPropertyInfo,
//         "annualFloodInsurance"
//       );

//       //loan info initialzation
//       let objProp: any = {
//         annualPayments: {
//           tax,
//           hazardInsurance,
//           floodInsurance
//         },
//         correctEscrow: {
//           tax: formatData(
//             data?.escrowloanInfo?.loanSummary?.correctTaxEscrow,
//             "currency"
//           ),
//           hazardInsurance: formatData(
//             data?.escrowloanInfo?.loanSummary?.correctHazardInsuranceEscrow,
//             "currency"
//           ),
//           floodInsurance: formatData(
//             data?.escrowloanInfo?.loanSummary?.correctFloodInsuranceEscrow,
//             "currency"
//           )
//         },
//         monthsOfEscrow: {
//           tax: data?.escrowloanInfo?.loanSummary?.monthsOfPropertyTaxEscrow,
//           hazardInsurance:
//             data?.escrowloanInfo?.loanSummary?.monthsOfHazardInsurance,
//           floodInsurance:
//             data?.escrowloanInfo?.loanSummary?.monthsOfFloodInsurance
//         },
//         months: []
//       };
//       if (data?.escrowloanInfo?.loanEscrowMap?.length) {
//         const loanEscrowMap = sortByOrder(
//           data.escrowloanInfo.loanEscrowMap,
//           "loanEscrowMapId",
//           "loanEscrowMapOrder"
//         );
//         loanEscrowMap?.forEach((item: any) => {
//           let objMonth: any = {
//             month: item?.month,
//             year: item?.year,
//             taxPayment: formatData(item?.propertyTaxPayment, "currency"),
//             taxEscrow: formatData(item?.propertyTaxEscrow, "currencyWithMinus"),
//             hazardInsurancePayment: formatData(
//               item?.hazardInsurancePayment,
//               "currency"
//             ),
//             hazardInsuranceEscrow: formatData(
//               item?.hazardInsuranceEscrow,
//               "currencyWithMinus"
//             ),
//             floodInsurancePayment: formatData(
//               item?.floodInsurancePayment,
//               "currency"
//             ),
//             floodInsuranceEscrow: formatData(
//               item?.floodInsuranceEscrow,
//               "currencyWithMinus"
//             ),
//             loanEscrowMapId: item?.loanEscrowMapId,
//             loanEscrowMapOrder: item?.loanEscrowMapOrder
//           };
//           objProp.months.push(objMonth);
//         });
//       } else {
//         objProp = initializeMonthsData(
//           objProp,
//           data?.firstPaymentDate,
//           "loanEscrowMapId",
//           "loanEscrowMapOrder"
//         );
//       }
//       objProp = runMonthCalculation(objProp);
//       calculateEscrowValues(objProp);
//       obj.loanInfo = objProp;
//       // property info initialization
//       let escrowProperty: any = [];
//       data?.escrowPropertyInfo?.forEach((item: any) => {
//         objProp = {
//           annualPayments: {
//             tax: formatData(
//               item?.propertyEconomics?.annualPropertyTaxes,
//               "currency"
//             ),
//             hazardInsurance: formatData(
//               item?.propertyEconomics?.annualHazardInsurance,
//               "currency"
//             ),
//             floodInsurance: formatData(
//               item?.propertyEconomics?.annualFloodInsurance,
//               "currency"
//             )
//           },
//           correctEscrow: {
//             tax: formatData(
//               item?.propertySummary?.escrowTaxesAtClosing,
//               "currency"
//             ),
//             hazardInsurance: formatData(
//               item?.propertySummary?.escrowHazardInsurance,
//               "currency"
//             ),
//             floodInsurance: formatData(
//               item?.propertySummary?.escrowFloodInsurance,
//               "currency"
//             )
//           },
//           monthsOfEscrow: {
//             tax: item?.propertySummary?.monthsOfPropertyTaxEscrow,
//             hazardInsurance: item?.propertySummary?.monthsOfHazardInsurance,
//             floodInsurance: item?.propertySummary?.monthsOfFloodInsurance
//           },
//           months: [],
//           propToggle: false,
//           loanPropertyId: item?.loanPropertyId,
//           address: item?.propertyLocation
//         };

//         if (item?.propertyEscrowMap?.length) {
//           const propertyEscrowMap = sortByOrder(
//             item.propertyEscrowMap,
//             "propertyEscrowMapId",
//             "propertyEscrowMapOrder"
//           );
//           propertyEscrowMap?.forEach((item: any) => {
//             let objMonth: any = {
//               month: item?.month,
//               year: item?.year,
//               taxPayment: formatData(item?.propertyTaxPayment, "currency"),
//               taxEscrow: formatData(
//                 item?.propertyTaxEscrow,
//                 "currencyWithMinus"
//               ),
//               hazardInsurancePayment: formatData(
//                 item?.hazardInsurancePayment,
//                 "currency"
//               ),
//               hazardInsuranceEscrow: formatData(
//                 item?.hazardInsuranceEscrow,
//                 "currencyWithMinus"
//               ),
//               floodInsurancePayment: formatData(
//                 item?.floodInsurancePayment,
//                 "currency"
//               ),
//               floodInsuranceEscrow: formatData(
//                 item?.floodInsuranceEscrow,
//                 "currencyWithMinus"
//               ),
//               propertyEscrowMapId: item?.propertyEscrowMapId,
//               propertyEscrowMapOrder: item?.propertyEscrowMapOrder
//             };
//             objProp.months.push(objMonth);
//           });
//         } else {
//           objProp = initializeMonthsData(
//             objProp,
//             data?.firstPaymentDate,
//             "propertyEscrowMapId",
//             "propertyEscrowMapOrder"
//           );
//         }
//         objProp = runMonthCalculation(objProp);
//         calculateEscrowValues(objProp);
//         escrowProperty.push(objProp);
//       });
//       obj.propertyInfo = escrowProperty;
//       calculateAggregateValue(obj, data);
//       calculateTotalAggregate(obj);
//       const nextState = produce(state, (draftState) => {
//         draftState.escrowInfoState = obj;
//       });
//       return nextState;
//     }
//     case UPDATE_ESCROW_PARAMS: {
//       const { payload } = action;
//       const nextState = produce(state, (draftState) => {
//         draftState.escrowInfoState = payload;
//       });
//       return nextState;
//     }

//     default:
//       return state;
//   }
// };
