/* eslint-disable @typescript-eslint/camelcase */
import { Theme } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import createStyles from '@mui/styles/createStyles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    subHeaderContainer: {
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 2px 0 rgba(50, 50, 93, 0.21)",
      zIndex: 1,
      position: "relative",
      paddingTop: "60px",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "60px"
      }
    },
    pendingWaiverContainer: {
      backgroundColor: "#ffffff",
      boxShadow: "0 2px 2px 0 rgba(50, 50, 93, 0.21)",
      zIndex: 1,
      position: "relative",
      paddingTop: "60px",
      [theme.breakpoints.down("xs")]: {
        paddingTop: "60px"
      }
    },
    pendingWaiverSubContainer: {
      paddingRight: 0,
      paddingLeft: 0,
      height: 50,
      backgroundColor: "#fff0da",
      display: "flex",
      alignItems: "center"
    },

    discussionBadgeContainer: {
      "& .MuiBadge-badge": {
        height: "17px !important",

        width: "17px !important"
      }
    },

    discussionMessageCount: {
      border: "none",
      "&:hover": {
        background: "#ffffff"
      }
    },

    mobileViewSubHeader: {
      [theme.breakpoints.down("sm")]: {
        alignItems: "flex-start !important"
      }
    },
    pendingItem: {
      fontSize: 12,
      listStyleType: "none",
      marginTop: "2px",
      marginBottom: "2px"
    },
    removeMarginTop: {
      marginTop: "0 !important"
    },
    containerPaddingBottom: {
      paddingBottom: "50px"
    },
    dropdownContainer: {
      textAlign: "right",
      [theme.breakpoints.down("xs")]: {
        padding: "10px 0"
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: "15px"
      }
    },
    addMarginBtm: {
      [theme.breakpoints.down("xs")]: {
        marginBottom: "82px"
      }
    },
    dpBtnContainer: {
      [theme.breakpoints.down("xs")]: {
        paddingLeft: "10px !important"
      }
    },
    loanIdLabel: {
      color: "#32325d",
      fontSize: "10px",
      paddingTop: "10px"
    },
    loanIdContainer: {
      // paddingTop: 10,
      color: "#32325d",
      display: "flex"
    },
    loanOriginatorId: {
      display: "inline-block",
      fontWeight: 600,
      wordBreak: "break-word",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "calc(100% - 115px)"
    },
    badgeContainer: {
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "center",
      padding: "10px 0 0",
      justifyContent: "flex-start",
      // justifyContent: "space-between",
      // marginRight: 132,
      [theme.breakpoints.down("sm")]: {
        flexDirection: "revert",
        flexWrap: "wrap"
      },
      [theme.breakpoints.down("xs")]: {
        flexDirection: "revert",
        flexWrap: "wrap"
      }
    },
    loanIdTypoGraphy: {
      marginRight: 6,
      color: "#32325d",
      fontSize: 20,
      fontWeight: "normal"
    },
    readOnlyMessage: {
      color: "#32325d",
      fontSize: 12,
      fontWeight: "normal",
      paddingLeft: 6
    },
    readOnlyContainer: {
      padding: 6,
      paddingTop: 8,
      backgroundColor: "#e9fafd",
      borderRadius: 4,
      maxWidth: 290,
      display: "flex",
      flexDirection: "row"
    },
    itemTypography: {
      padding: "4px 7px 3px 7px",
      color: "#ffffff",
      fontSize: 10,
      fontWeight: "bold",
      backgroundColor: "#172b4d",
      borderRadius: 6,
      textTransform: "uppercase",
      marginRight: "7px",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("xs")]: {
        marginBottom: "5px"
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0 7px 5px 0"
      }
    },
    errorContainer: {
      padding: "3px 7px 3px 7px",
      backgroundColor: "#f75676",
      borderRadius: 6,
      marginLeft: 8,
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("xs")]: {
        margin: "0 7px 5px 0"
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0 7px 5px 0"
      }
    },
    errorStatusMsg: {
      display: "flex",
      padding: "13px 0px 13px 0px",
      alignItems: "center",
      /* margin-left: 6px, */
      borderRadius: 4
    },
    warningContainer: {
      padding: "3px 7px 3px 7px",
      backgroundColor: "#fa9d00",
      borderRadius: 6,
      marginLeft: 8,
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("xs")]: {
        margin: "0 7px 5px 0"
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0 7px 5px 0"
      }
    },
    warningIcon: {
      width: 13,
      height: 13
    },
    passContainer: {
      padding: "3px 7px 3px 7px",
      backgroundColor: "#2ece89",
      borderRadius: 8,
      display: "flex",
      alignItems: "center",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("xs")]: {
        margin: "0 7px 5px 0"
      },
      [theme.breakpoints.down("sm")]: {
        margin: "0 7px 5px 0"
      }
    },
    errorTypography: {
      color: "#ffffff",
      fontSize: 10,
      fontWeight: "bold",
      marginLeft: 4,
      textTransform: "uppercase"
    },
    icon: {
      width: 16,
      height: 16,
      marginLeft: "16px"
    },
    warning1Icon: {
      width: 18,
      height: 18
    },
    errorIcon: {
      width: 16,
      height: 16
    },
    passIcon: {
      width: 16,
      height: 16,
      color: "white"
    },
    tabContainer: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center"
    },
    selectedTabTypography: {
      padding: "0 4px 8px 0px",
      cursor: "pointer",
      color: "#32325d",
      fontSize: 14
    },
    validateErrorFormClicked: {
      width: 18,
      height: 18
    },
    validateTabFormClicked: {
      height: 0,
      borderRadius: 2,
      borderStyle: "solid",
      borderColor: "#5e72e4",
      backgroundColor: "#5e72e4",
      margin: "0px 6px 0px 4px",
      borderWidth: 2,
      padding: "0px 8px"
    },
    tabPadding: {
      padding: "32px 50px 0 0",
      "&:last-child": { padding: "32px 0 0 0" }
    },
    loanIdListContainer: {
      minHeight: 1000,
      backgroundColor: "#eaebf0",
      marginTop: "60px"
    },
    loanIdListContainerMinimised: {
      marginTop: "60px"
    },
    loading: {
      display: "block",
      marginLeft: "auto",
      marginRight: "auto",
      marginTop: "15%",
      width: "30px"
    },
    wh_16: {
      width: "16px !important",
      height: "16px !important"
    },
    ml_auto: {
      marginLeft: "auto"
    },
    c_pointer: {
      cursor: "pointer"
    },
    loanSizerTypeContainer: {
      backgroundColor: "#f8f9ff",
      padding: "6px 8px 6px 8px",
      borderRadius: 8,
      height: "fit-content",
      right: "0",
      position: "absolute",
      bottom: "0",
      top: "112px",
      border: "1px solid #d0d5f3",
      textAlign: "left",
      [theme.breakpoints.down("sm")]: {
        // height: "78px",
        top: "56px",
        maxWidth: "175px"
      },
      [theme.breakpoints.down("xs")]: {
        padding: "6px",
        maxWidth: "162px"
      }
    },
    loanSizerTypeText: {
      color: "#32325d",
      fontSize: 12,
      fontWeight: "normal"
    },
    removePaddingTop: {
      paddingTop: "0 !important"
    },
    tabSelected: {
      fontWeight: 600
    },
    originatorIdLabel: {
      [theme.breakpoints.down("xs")]: {
        display: "inline-flex"
      }
    },
    loanIdSeparator: {
      [theme.breakpoints.down("xs")]: {
        // display: "none",
      }
    },
    holdLoan: {
      color: "#fb623f"
    },
    hideMobileViewKeydealBtn: {
      [theme.breakpoints.down("xs")]: {
        display: "none !important"
      }
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
      margin: "0px 10px",
      padding: "0.5rem",
      fontSize: "12px"
    },
    arrow: {
      color: "#fff"
    },
    loanHistoryBtn: {
      color: "rgb(50, 50, 93)",
      border: "0px"
    },
    leftSubheader: {
      flexGrow: 0,
      maxWidth: "40%",
      flexBasis: "40%"
    },
    rightSubheader: {
      flexGrow: 0,
      maxWidth: "60%",
      flexBasis: "60%"
    }
  })
);