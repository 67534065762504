/* eslint-disable no-useless-escape */
export const sanitizeEmail = (text: string) => {
  return text.replace(/[^0-9.@a-zA-Z]/g, "");
};
export const sanitizePhoneNumber = (text: string) => {
  const onlyDigits = text?.replace(/[^0-9]/g, "");
  let result = text;
  if (onlyDigits && onlyDigits.length > 10) {
    result = onlyDigits.slice(0, 10);
  }

  if (result) {
    return result
      .replace(/[^0-9.-\s]/g, "")
      .replace(/^\./, "")
      .replace(/^\-/, "")
      .replace(/^\s/, "")
      .replace(/\s\s+/g, " ");
  }
  return "";
};

export const sanitizeNumericInput = (text: string) => {
  return text.replace(/\D/g, "");
};

export const sanitizeString = (text: string) => {
  return text.replace(/[^a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g, "");
};

// export const sanitizePercentage = (text: string) => {
//   const result = text.replace(/\.$/, "").replace(/%/, "");
//   return text !== "" ? `${result}%` : "";
// };

export const sanitizeCurrency = (text: string) => {
  const result = text.replace(/\.$/, "");
  return text !== "" && text !== "$"
    ? currencyFormatter.format(
        Number.parseFloat(result.replace(/[^0-9.]/g, ""))
      )
    : "";
};

export const sanitizeAlphaNumericInput = (text: string) => {
  return text.replace(/[^a-zA-Z0-9]/g, "");
};

export const cleanPercentage = (text: string) => {
  try {
    return (
      text
        ?.replace(/[^0-9.%]/g, "")
        // eslint-disable-next-line no-plusplus, no-param-reassign
        .replace(/\./g, (i => (m: string) => (!i++ ? m : ""))(0))
        // eslint-disable-next-line no-plusplus, no-param-reassign
        .replace(/[%]/g, (i => (m: string) => (!i++ ? m : ""))(0))
        .replace(/^\./, "")
        .replace(/^$%/, "")
        .replace(/\.(\d\d\d\d)\d?$/, ".$1")
    );
  } catch (error) {
    return "";
  }
};
export const cleanDecimal = (text: string) => {
  // ^[0-9]{1,11}(?:\.[0-9]{1,3})?$
  console.log("text", text);
  return (
    text
      .replace(/[^0-9.$]/g, "")
      // eslint-disable-next-line no-plusplus, no-param-reassign
      .replace(/[$]/g, (i => (m: string) => (!i++ ? m : ""))(0))
      // eslint-disable-next-li ne no-plusplus, no-param-reassign
      .replace(/[.]/g, (i => (m: string) => (!i++ ? m : ""))(0))
      .replace(/[$]/g, (c, i) => (i !== 0 ? "" : c))
      .replace(/^\./, "")
      .replace("$", "")
    // .replace(/\.(\d\d)\d?$/, ".1")
  );
};

export const cleanCurrency = (text: string) => {
  return (
    text
      .replace(/[^0-9.$]/g, "")
      // eslint-disable-next-line no-plusplus, no-param-reassign
      .replace(/[$]/g, (i => (m: string) => (!i++ ? m : ""))(0))
      // eslint-disable-next-line no-plusplus, no-param-reassign
      .replace(/[.]/g, (i => (m: string) => (!i++ ? m : ""))(0))
      .replace(/[$]/g, (c, i) => (i !== 0 ? "" : c))
      .replace(/^\./, "")
      .replace(/^\$\./, "$")
      .replace(/\.(\d\d)\d?$/, ".$1")
  );
};
export const isValidPercentage = (text: string) => {
  return /^\d+(\.\d+)?%{0,1}$/.test(text);
};

export const isValidNumber = (text: string) => {
  return /^[0-9\b]+$/.test(text);
};

export const isValidDecimal = (text: string) => {
  return /^[1-9]\d*(\.\d+)?$/.test(text);
};

export const isValidEmailFormat = (text: string) => {
  return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(text);
};

export const isValidPhoneNumber = (text: string) => {
  return /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(text);
};

export const isValidName = (text: string) => {
  return /^\[^a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/.test(text);
};

export const isValidCurrency = (text: string) => {
  return /^\${0,1}[0-9]\d*(((,\d{3}){1})*(\.\d{1,2})?)$/.test(text);
};

export const currencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD"
});
