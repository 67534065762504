/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState,
  useEffect,
  Suspense,
  useMemo,
  createContext
} from "react";
import {
  Typography,
  Grid,
  Container,
  CircularProgress,
  AppBar,
  Tab,
  Button,
  Badge,
  SvgIcon
} from "@mui/material";
import moment from "moment";
import HistoryIcon from "@mui/icons-material/HistoryOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import { replace } from "connected-react-router";
import queryString from "query-string";
import { useLocation, useParams } from "react-router-dom";
import { LoanStage, WarningModal, IconTooltip } from "@toorak/tc-common-fe-sdk";
import { useSelector, useDispatch } from "react-redux";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import WarningIcon from "@mui/icons-material/Warning";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import { BundleStatuses, LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import ChatIcon from "@mui/icons-material/Chat";
import { Tag } from "../ui-components/Tag";
import { RootState } from "../stores/rootReducer";
import {
  getPropertyDetails,
  primaryGuarantorOrBorrowerCheck,
  setLoanByIdFailed,
  setLoanByIdSuccess,
  updateCreateLoanStoreForSelectedLoan,
  valueEditing,
  setIsLoanAdvanceFunded,
  setTableFundingFlag,
  setWarehouseFundingFlag,
  setEditedFieldKeys,
} from "./create-loan.reducer";
import {
  computePropertyError,
  computePropertyLocationError
} from "./property-details/PropertyDetailsHelper";
import {
  getLoanById,
  errorsOnSubmit,
  validateForm,
  setActiveTabAndProperty,
  getPropertyOriginalValuesByLoanId,
  formatLoanDetailsForRedux,
  resetAllStores,
  formatPropertyForRedux,
  editCommentMissing,
  updatePreviousStage,
  keepCreditEvalSync,
  formatResponse,
  dispatchAndUpdate,
  setOnHoldLoan,
  setLoanLandMark,
  getExceptionChatID,
  setActiveInteractionsModal
} from "./create-loan.action";
import {
  countErrors,
  countFesErrors,
  calculateFesPropertyError,
  includesPrimaryBorrowerOrGuarantor,
  countLoanDetailError,
  countPropertyDetailError
} from "./ErrorCounter";
import { passedAndFailedTests } from "./evaluation-results/EvaluationHelper";
import {
  getRuleEvaluationByLoanId,
  updateEvaluationResultsStoreForSelectedLoan,
  getOverriddenOutputValues
} from "../stores/EvaluationResults/EvaluationResults.action";

import {
  LoanIdListInSession
} from "./excel-create-loan/LoanIdList";
import {
  SingleLoanData,
  changeSubmitStatusInReducer,
  resetExcelUploadReducer
} from "../stores/ExcelUpload/excel-upload.reducer";
import {
  updateLoanErrors
  // resetExcelUploadReducer
} from "../stores/ExcelUpload/excel-upload.action";
import {
  getExcelDataInSession,
  isLATUser,
  Roles,
  checkUser,
  isILP,
  isLoanOnHold,
  isPrivilege
} from "../utils/AccessManagement";
import {
  hideLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { LoaderAndExceptionsStore } from "../stores/loaderAndException/loaderAndException.reducer";
import { useStyles } from "./common-style/CreateLoan.style";
import {
  formatFesLoanDetailsForRedux,
  formatFesPropertyDetailsForRedux
} from "../stores/FrontEndSizer/fesLoanCreation.action";
import {
  getBundleLoanData
} from "../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import {
  genMaskedOrgBundleStatus,
  staticBundleStatusList
} from "./purchase-and-settlements/constants";
import { getCookie, setCookie, removeCookie } from "../utils/cookies";
import { getConfig, getLoanType } from "../config/config";
import {
  updateLoanDetailsInRedux,
  updatePropertyDetailsInRedux
} from "../stores/loanAndPropertyDetails/loanAndPropertyDetails.action";
import {
  PreSubmitStates,
  postApprovalStates,
  TABLE_FUNDING,
  ADVANCE_FUNDING
} from "../utils/constants";
import {
  getInternalUsers,
  getInternalGroups
} from "../stores/ILP/ILP.action";
import {
  formatValueByType,
  getIsAllowedToEdit,
  isEmptyValue,
  isViewOnly
} from "../utils/formatChecks";
import { SuspenseWithFallBack } from "../ui-components/SuspenseWithFallBack";
import {
  evaluateLoanRuleResults,
  getAuditInfoLightWeight,
  getRateLockOptions,
  getTakeOutPartnerList,
} from "../network/apiService";
import { ObjectType } from "../masterView/common";
import { getCustodyDocsList } from "../stores/CustodyDocuments/CustodyDocuments.action";
import { hideCustodyDoc } from "../utils/utils";
import {
  useShowToorvalErrors,
  matchRole,
  ToorvalRoles,
  onlyHasRole
} from "./appraisal/util";
import { usePriorStages } from "./appraisal/shared/loanLandmarks";
import {
  getAppraisalLandmarks,
  getAppraisalID
} from "../stores/appraisal/appraisal.action";
import {
  oneFourFamilyPropertyTypeDropDown,
  ToorakProductEnum
} from "./constants/loanCreationDropDownValues";
import CommentWidget, {
  CommentOpen
} from "../ui-components/Comments/CommentWidget";
import { getTasks, taskNames } from "../stores/tasks/Tasks.action";
import { ReactComponent as AppraisalDashBoardIcon } from "../icons/appraisal-dashboard-icon.svg";
import { DetailViewDropDown } from "../ilp/DetailViewDropDown";
import { formatLockOptions, rateExtensionLabelFormatter } from "../utils/CommonDataTransformers";
import { LoanSizerEnum } from "../frontend-sizer/FrontendSizerTemplate";
import { resetRetryRequestReducer } from "../stores/RetryRequest/retry-request.reducer";
import { setTtfStatus } from "../stores/TapeToFile/TapeToFile.reducer";
import { uploadExcelLoanExceptionChange } from "../stores/ExceptionsResults/ExceptionsReducer";
import {
  PropertyDetailsRepresentable,
  emptyPropertyBasedOnLoan
} from "./create-loan.reducerHelper";
import {
  changeEvalPanelVisibility,
  evaluateLoanSuccess,
  setRateExtensionOptions
} from "../stores/EvaluationResults/EvaluationResults.reducer";
import {
  setTabView,
  setBundleLoanData
} from "../stores/SettlementAndPurchase/SettlementAndPurchase.reducer";
import SaveEditActions from "./createLoanHeaders/SaveEditActions";
import { updateFormEditStatus, updateLoanStageStatus } from "../v2/loanDetailStore.reducer";

import { Loader } from "./Loader";
import { TabContext, TabList, TabPanel } from "@mui/lab";

export const CreditLineLoanViewContext = createContext<{
  creditLines: ObjectType[];
}>({
  creditLines: []
});

// Lazy Loading
const AutoSaveInputValues = React.lazy(() =>
  import("../frontend-sizer/AutoSaveInputValues").then(module => ({
    default: module.AutoSaveInputValues
  }))
);
const CreditRiskScoreModal = React.lazy(() =>
  import("./CreditRiskScoreModal").then(module => ({
    default: module.CreditRiskScoreModal
  }))
);
const AppraisalDocumentsForm = React.lazy(() =>
  import("./appraisal/AppraisalDocumentsForm").then(module => ({
    default: module.AppraisalDocumentsForm
  }))
);
const ResolutionNotSupported = React.lazy(() =>
  import("../ui-components/ResolutionNotsupported").then(module => ({
    default: module.ResolutionNotSupported
  }))
);
const LoanHistory = React.lazy(() =>
  import("./loan-lists/utils/LoanHistory").then(module => ({
    default: module.LoanHistory
  }))
);
const LoanIdList = React.lazy(() =>
  import("./excel-create-loan/LoanIdList").then(module => ({
    default: module.LoanIdList
  }))
);
const GuidelinesAndPricingComparision = React.lazy(() =>
  import("./guidelines-and-pricing-comparision").then(module => ({
    default: module.GuidelinesAndPricingComparision
  }))
);

const DuplicateLoanError = React.lazy(() =>
  import("./createLoanHeaders/DuplicateLoanError").then(module => ({
    default: module.DuplicateLoanError
  }))
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustodyDocuments = React.lazy(() =>
  import("./documents/CustodyDocuments/CustodyDocuments").then(module => ({
    default: module.CustodyDocuments
  }))
);

const CustodyDocFlags = React.lazy(() =>
  import("./documents/CustodyDocuments/CustodyDocFlags").then(module => ({
    default: module.CustodyDocFlags
  }))
);

const DocumentsTab = React.lazy(() =>
  import("./documents/DocumentTab").then(module => ({
    default: module.DocumentsTab
  }))
);
const FrontendSizerLoan = React.lazy(() =>
  import("../frontend-sizer/FrontendSizerLoan").then(module => ({
    default: module.FrontendSizerLoan
  }))
);
const ActionDropDown = React.lazy(() =>
  import("./createLoanHeaders/ActionDropDown").then(module => ({
    default: module.ActionDropDown
  }))
);
const DisplayLoanStageAndType = React.lazy(() =>
  import("./createLoanHeaders/DisplayLoanStageAndType").then(module => ({
    default: module.DisplayLoanStageAndType
  }))
);
const SwitchLoanStage = React.lazy(() =>
  import("./createLoanHeaders/SwitchLoanStage").then(module => ({
    default: module.SwitchLoanStage
  }))
);
const DisplayLoanId = React.lazy(() =>
  import("./createLoanHeaders/DisplayLoanId").then(module => ({
    default: module.DisplayLoanId
  }))
);
const DisplayNote = React.lazy(() =>
  import("./createLoanHeaders/DisplayNote").then(module => ({
    default: module.DisplayNote
  }))
);
const UpdateStatusLAT = React.lazy(() =>
  import("./updateStatusbyLAT/UpdateStatusLAT").then(module => ({
    default: module.UpdateStatusLAT
  }))
);

const LoanSummaryChecklistInfoModal = React.lazy(() =>
  import("./createLoanHeaders/LoanSummaryChecklistInfoModal").then(module => ({
    default: module.LoanSummaryChecklistInfoModal
  }))
);

const Inquiries = React.lazy(() =>
  import("./exceptions-and-enquiries/Inquiries").then(module => ({
    default: module.Inquiries
  }))
);
const ExceptionsAndEnquiries = React.lazy(() =>
  import("./exceptions-and-enquiries/ExceptionsAndEnquiries").then(module => ({
    default: module.ExceptionsAndEnquiries
  }))
);
const GuidelineOutputs = React.lazy(() =>
  import("./guideline-outputs/GuidelineOutputs").then(module => ({
    default: module.GuidelineOutputs
  }))
);
const SummaryForm = React.lazy(() =>
  import("./summary/SummaryForm").then(module => ({
    default: module.SummaryForm
  }))
);
const TapeToFile = React.lazy(() =>
  import("./tape-to-file/TapeToFile").then(module => ({
    default: module.TapeToFile
  }))
);

const LoanDetails = React.lazy(() =>
  import("../v2/views/LoanDetails/LoanDetails").then(module => ({
    default: module.LoanDetails
  }))
);

const PropertyDetails = React.lazy(() =>
  import("../v2/views/PropertyDetails/PropertyDetails").then(module => ({
    default: module.PropertyDetails
  }))
);

export const formatLoanDetailsResponse = (
  response: any,
  loanStage: LoanStage | string,
  ddId?: any,
  activeTab?: string
) => {
  let currentTab: string | null = null;
  if (activeTab) {
    currentTab = [
      "documents",
      "exceptionsEnquiries",
      "shadowDiligence"
    ].includes(activeTab)
      ? activeTab
      : null;
  }
  return loanStage === LoanStage.fes
    ? formatFesLoanDetailsForRedux(response)
    : formatLoanDetailsForRedux(response, null, ddId, currentTab);
};

export const formatPropertyResponse = (
  response: any,
  loanStage: LoanStage | string,
  loanType: string
) => {
  return loanStage === LoanStage.fes
    ? formatFesPropertyDetailsForRedux(response)
    : formatPropertyForRedux(response, loanType);
};

const ThirtyYearLoanDetailsForm = React.lazy(() =>
  import("./30-year-loan-details/LoanDetailsForm").then(module => ({
    default: module.LoanDetailsForm
  }))
);

const Header = React.lazy(() =>
  import("./home/Header").then(module => ({ default: module.Header }))
);
const CreateLoanHeader = React.lazy(() =>
  import("./CreateLoanHeader").then(module => ({
    default: module.CreateLoanHeader
  }))
);
const SettlementForm = React.lazy(() =>
  import("./settlement-form-tab/SettlementForm").then(module => ({
    default: module.SettlementForm
  }))
);
const LoanDetailsForm = React.lazy(() =>
  import("./bridge-loan-details/LoanDetailsForm").then(module => ({
    default: module.LoanDetailsForm
  }))
);
const Discussions = React.lazy(() =>
  import("./discussions/Discussions").then(module => ({
    default: module.Discussions
  }))
);

const KeyDealMetrics = React.lazy(() =>
  import("../key-deal-metrics/KeyDealMetrics").then(module => ({
    default: module.KeyDealMetrics
  }))
);
const PropertyDetailsForm = React.lazy(() =>
  import("./property-details/PropertyDetailsForm").then(module => ({
    default: module.PropertyDetailsForm
  }))
);
const AppraisalForm = React.lazy(() =>
  import("./appraisal/AppraisalForm").then(module => ({
    default: module.AppraisalForm
  }))
);
const AppraisalConverted = React.lazy(() =>
  import("./appraisal/AppraisalConverted").then(module => ({
    default: module.AppraisalConverted
  }))
);
const ConvertLoanButton = React.lazy(() =>
  import("./createLoanHeaders/ConvertLoanButton").then(module => ({
    default: module.ConvertLoanButton
  }))
);
const KeyMetricsButton = React.lazy(() =>
  import("./createLoanHeaders/KeyMetricsButton").then(module => ({
    default: module.KeyMetricsButton
  }))
);
const ViewCustodyDocumentModal = React.lazy(() =>
  import("./settlement-form-tab/ViewCustodyDocumentModal").then(module => ({
    default: module.ViewCustodyDocumentModal
  }))
);
const LoanSizerType = React.lazy(() =>
  import("./createLoanHeaders/LoanSizerType").then(module => ({
    default: module.LoanSizerType
  }))
);
const ConfirmLoanDetailsModal = React.lazy(() =>
  import(
    "./purchase-and-settlements/confirmLoanDetails/ConfirmLoanDetailsModal"
  ).then(module => ({
    default: module.ConfirmLoanDetailsModal
  }))
);
const CreditEvaluationWrapper = React.lazy(() =>
  import("../credit-evaluation/CreditEvaluationWrapper").then(module => ({
    default: module.CreditEvaluationWrapper
  }))
);

const ClosingWireTab = React.lazy(() =>
  import("./closing-wire-tab/index").then(module => ({
    default: module.ClosingWireTab
  }))
);

// eslint-disable-next-line import/no-mutable-exports
export let globalGetDataByLoanID: any;
const { fes, pre, post, tv } = LoanStage;
const { InitialReview } = LoanStatusEnum;
const DSCRLoan = getLoanType[1].displayValue;

interface LoanDetailsTabs {
  id: string;
  title: string;
  component: any;
  requiresEvaluationPanel: boolean;
  showIn?: string[];
  role?: string[];
  domain?: string[];
  hide?: boolean;
  showInPrior?: string[];
}

export function CreateLoan() {
  setCookie("globalSearchValue", "");
  const classes = useStyles();
  const { selectedTab }: any = queryString.parse(
    new URL(window.location.href).search
  );
  const excelUploadData = getExcelDataInSession();
  const dispatch = useDispatch();
  const { loanId = "", loanStage = "", loanType = "" } = useParams<{
    loanId: string;
    loanStage: LoanStage;
    loanType: string;
  }>();
  const [rateLockOptions, setRateLockOptions] = useState<ObjectType | null>(
    null
  );
  // const [rateExtensionOptions, setRateExtensionOptions] = useState<ObjectType[] | null>(null);
  const [rateSheetTypeData, setRateSheetTypeData] = useState<ObjectType | null>(
    null
  );
  const [creditLines, setCreditLines] = useState<ObjectType[]>([]);
  const [propInfotoorvalId, setPropInfotoorvalId] = useState<string | null>(
    null
  );
  const [openComments, setOpenComments] = useState<CommentOpen>({});
  const [isMinimised, setIsMinimised] = useState(false);
  const [errors, setErrors] = useState(0);
  const [propertyDetailsErrors, setPropertyDetailsErrors] = useState(0);
  const [loanDetailsErrors, setLoanDetailsErrors] = useState(0);
  const [passedTestsCount, setPassedTestsCount] = useState(0);
  const [failedTestsCount, setFailedTestsCount] = useState(0);
  const [errorsFormWise, setErrorsFormWise] = useState<any>([]);
  const [isCreditRiskOpen, setIsCreditRiskOpen] = useState<boolean>(false);
  const [isRateLockCarryForward, setIsRateLockCarryForward] = useState<boolean>(
    false
  );
  const [activeInteractionsTag, setActiveInteractionsTag] = useState<any>([
    {
      total: 0,
      conditions: 0,
      waivers: 0,
      inquiries: 0
    }
  ]);
  const [showWarning, setShowWarning] = useState<boolean>(false);
  const [showStatusUpdate, setShowStatusUpdate] = useState<boolean>(false);
  const [showLoanSummary, setShowLoanSummary] = useState<boolean>(false);
  const [tabIndex, setTabIndex] = useState<string>("loanDetails");
  const [openLoanDetlNew, setOpenLoanDetlNew] = useState<boolean>(false);
  const [openKeyMetrics, setOpenKeyMetrics] = useState<boolean>(false);
  const [openConfirmCustody, setOpenConfirmCustody] = useState<boolean>(false);
  const [showHistory, setShowHistory] = useState(false);
  const [orgSellerInfo, setOrgSellerInfo] = useState<ObjectType[]>([]);
  const [sessionStorageData, setssionStorageData] = React.useState<{
    excelLoanCreated: boolean;
    maintainExcelBundle: boolean;
    loanIdList: LoanIdListInSession;
    fileName: "string";
    tradeId: string;
  } | null>(excelUploadData);
  const [commentObj, setCommentObj] = useState<any>({});
  const [dataFetched,setDataFetched] = useState(false);

  const {
    loanDetails,
    sectionBeingEdited,
    missingPrimaryGuarantorOrBorrower,
    missingEditedComments,
    isViewMode,
    startCreditEvalSync,
    availableLoanStages,
    activeTab,
    isWithdrawnLoan,
    isLoanTableFunded
  } = useSelector<RootState, any>(state => state.createLoanStore);
  const {
    loanSizerType,
    loanState,
    loanDetailId
  } = loanDetails;
  const { thirtyYearLoanInformation, fesLoanInformation } = useSelector<
    RootState,
    any
  >(state => state.createLoanStore?.loanDetails);
  const { originatorPartyId } = loanDetailId;
  const loanBundleDetails = useSelector<RootState, any>(
    state => state.spStore.loanBundleDetails
  );
  const { isFormEditing, isDataEntry } = useSelector<RootState, any>((state) => state.loanDetailsStore);

  const { takeoutPartnerId, takeoutPartnerStatus } = useMemo(() => {
    if (loanStage === LoanStage.fes) {
      return fesLoanInformation;
    }
    return thirtyYearLoanInformation?.payload;
  }, [loanStage, fesLoanInformation, thirtyYearLoanInformation]);

  const shouldTakeoutPartnerBannerBeShown = useMemo(() => {
    return (
      takeoutPartnerStatus === "PENDING" &&
      !isViewMode &&
      loanState !== LoanStatusEnum.DataEntry
    );
  }, [takeoutPartnerStatus, isViewMode, loanState]);

  const propertyDetailsNewStore = useSelector<RootState, any>(
    state => state.loanAndPropertyStore.propertyDetails
  );
  const { loaderCount, loaderContext } = useSelector<
    RootState,
    LoaderAndExceptionsStore
  >(state => state.LoaderAndExceptionsStore);
  const loanIdInReducer = useSelector<RootState, any>(
    state => state.createLoanStore.loanDetails.loanId
  );
  const { bridgeIsGroundUpLoan } = useSelector<RootState, any>(
    state => state.createLoanStore.loanDetails
  );
  const { convertedTo } = useSelector<RootState, any>(
    state => state.appraisalStore.form
  );
  const {
    activeInteractionsModalCount,
    activeInteractionsModalArray
  } = useSelector<RootState, any>(state => state.createLoanStore);
  const {
    loanEvaluationResult,
    isEvaluationPanelVisible,
    waiversCreated
  } = useSelector<RootState, any>(state => state.evaluationResultsStore);

  const evaluationResults = useSelector<RootState, any>(
    state => state.evaluationResultsStore.loanEvaluationResult
  );
  const { exceptionsResult } = useSelector<RootState, any>(
    state => state.exceptionsResultsStore
  );

  const { comments: allCommentsFromRedux } = useSelector<RootState, any>(
    state => state.commentStore
  );
  const uploadExcelStoreData = useSelector<
    RootState,
    { [key: string]: SingleLoanData }
  >(state => state.excelUploadStore.data);
  const { loanDeleted, rerenderLoanIdList } = useSelector<RootState, any>(
    state => state.excelUploadStore
  );
  const isExcelLoanSubmitted = useSelector<RootState, boolean | undefined>(
    state => state.excelUploadStore.isSubmitted
  );
  const [rescindWaiverModel, setRescindWaiverModel] = useState<boolean>(false);
  const [bundleStatusIndex, setBundleStatusIndex] = useState<number>(-1);
  // const [setFetchingLoanData, setSetFetchingLoanData] = useState<boolean>(false);
  const validateFormClicked = useSelector<
    RootState,
    { isSubmitValidation: boolean; validate: boolean }
  >(state => state.createLoanStore.validateForm);

  const appraisalID = getAppraisalID();
  const { toorvalErrors } = useShowToorvalErrors();
  const priorStages = usePriorStages();
  const path: any = useLocation();
  let isRescind = false;

  const { isLoanAdvanceFunded, isLoanTableFunding, isWarehouseFunded, ttfVersionId, originatorId } = useMemo(() => {
    const isLoanAdvanceFunded = ["advance_funding", "Advance Funding"].includes(
      loanDetails?.loanDetailId?.fundingType
    );
    const isLoanTableFunding = ["table_funding", "Table Funding"].includes(
      loanDetails?.loanDetailId?.fundingType
    );
    const isWarehouseFunded = loanDetails?.loanDetailId?.isWarehouseFunded;
    const ttfVersionId = loanDetails?.loanDetailId?.loanConfigId?.ttfVersionId;
    const originatorId = loanType === DSCRLoan
      ? loanDetails.thirtyYearLoanInformation.payload.primaryLoanID
      : loanDetails.bridgeLoanInformation.payload.primaryLoanID;
    return { isLoanAdvanceFunded, isLoanTableFunding, isWarehouseFunded, ttfVersionId, originatorId };
  }, [loanDetails, loanType]);

  const riskScores = useMemo(() => {
    return (
      evaluationResults?.loanResult &&
      evaluationResults?.loanResult?.[loanId]?.riskScores
    );
  }, [evaluationResults, loanId]);

  const riskBucketColor = useMemo(() => {
    let bucketColor = "#2ece89";
    switch (riskScores?.riskBucket) {
      case "1":
      case "2":
        bucketColor = "#2ece89";
        break;
      case "3":
        bucketColor = "orange";
        break;
      case "4":
      case "5":
        bucketColor = "#f75676";
        break;
      default:
        bucketColor = "#2ece89";
    }
    return bucketColor;
  }, [riskScores]);

  const unreadDiscussionCount = useMemo(() => {
    return allCommentsFromRedux[loanId]?.count || 0;
  }, [allCommentsFromRedux, loanId]);

  const tabs: LoanDetailsTabs[] = useMemo(() => {
    return [
      {
        id: "settlement",
        title: "Settlement",
        component: () => <SettlementForm />,
        requiresEvaluationPanel: true,
        showIn: [post, pre],
        hide: isLATUser()
          ? !(bundleStatusIndex >= 2 && bundleStatusIndex < 5)
          : !(bundleStatusIndex >= 3 && bundleStatusIndex < 5)
      },
      {
        id: "summary",
        title: "Summary",
        component: () => (
          <SummaryForm rateSheetTypeDate={rateSheetTypeData?.value ?? ""} />
          // <Summary />
        ),
        requiresEvaluationPanel: true,
        showIn: [pre, post],
        hide: !isLATUser()
      },
      {
        id: "settlement",
        title: "Settlement",
        component: () => <SettlementForm />,
        requiresEvaluationPanel: true,
        showIn: [post, pre],
        hide: !(bundleStatusIndex >= 5)
      },
      {
        id: "creditEvaluation",
        title: "Credit Evaluation",
        component: () => <CreditEvaluationWrapper />,
        role: ["LAT"],
        domain: [
          "uat.toorakcapital.info",
          "localhost",
          "dev.toorakcapital.info",
          "stage.toorakcapital.info",
          "qa.toorakcapital.info"
        ],
        requiresEvaluationPanel: true,
        showIn: [post]
      },
      {
        id: "loanDetails",
        title: "Loan Details",
        component: (isEditEnabled: any) => (
          <LoanDetails />
          // <ThirtyYearLoanDetailsForm isEditEnabled={isEditEnabled} />
        ),
        requiresEvaluationPanel: false,
        showIn: [fes, pre, post]
      },
      {
        id: "propertyDetails",
        title: "Property Details",
        component: (isEditEnabled: any) => (
          // <PropertyDetailsForm isEditEnabled={isEditEnabled}/>
          // uncomment below for new property details
          <PropertyDetails />
        ),
        requiresEvaluationPanel: false,
        showIn: [fes, pre, post]
      },
      {
        id: "toorval",
        title: "Toorval",
        component: () => <AppraisalForm />,
        requiresEvaluationPanel: false,
        showIn: [tv]
      },
      {
        id: "toorval",
        title: "Toorval",
        component: () => <AppraisalConverted />,
        requiresEvaluationPanel: false,
        showInPrior: [tv]
      },
      {
        id: "documents",
        title: "Documents",
        component: () => <AppraisalDocumentsForm />,
        requiresEvaluationPanel: false,
        showIn: [tv]
      },
      {
        id: "documents",
        title: "Documents",
        component: () => <DocumentsTab />,
        requiresEvaluationPanel: false,
        showIn: [pre, post]
      },
      {
        id: "guidelineOutputs",
        title: "Guideline Outputs",
        component: () => (
          <GuidelineOutputs />
        ),
        requiresEvaluationPanel: true,
        showIn: [fes, pre, post]
      },
      {
        id: "multiTakeOutPartners",
        title: "Take-Out Partners",
        component: () => (
          <GuidelinesAndPricingComparision
            programSelectConfig={{
              isProgramSelectView: false,
              setShowWaiverPage: () => { }
            }}
          />
        ),
        requiresEvaluationPanel: true,
        showIn: [fes, pre, post],
        hide:
          loanType !== DSCRLoan ||
          !isLATUser() ||
          !isPrivilege("takeout_partner")
      },
      {
        id: "exceptionsEnquiries",
        title: isLATUser() ? "Conditions & Inquiries" : "Conditions",
        component: () => <ExceptionsAndEnquiries />,
        requiresEvaluationPanel: true,
        showIn: [pre, post],
        hide: isILP()
      },
      {
        id: "shadowDiligence",
        title: "Shadow Diligence",
        component: () => <Inquiries />,
        requiresEvaluationPanel: true,
        showIn: [pre, post],
        hide: !isILP()
      },
      {
        id: "custodyDocuments",
        title: "Custody Documents",
        component: () => <CustodyDocuments />,
        requiresEvaluationPanel: true,
        showIn: [post],
        hide: hideCustodyDoc()
      },
      {
        id: "discussions",
        title: "Discussions",
        component: () => <Discussions />,
        requiresEvaluationPanel: true,
        showIn: [pre, post]
      },
      {
        id: "tapeToFile",
        title: "Tape To File",
        component: () => <TapeToFile />,
        requiresEvaluationPanel: true,
        showIn: [pre, post],
        hide: !(isLATUser() && ttfVersionId == null)
      },
      {
        id: "closingWire",
        title: "Closing Wire",
        component: () => (
          <ClosingWireTab />
        ),
        requiresEvaluationPanel: false,
        showIn: [post, pre],
        hide: !(isLoanTableFunding || isLoanAdvanceFunded)
      }
    ];
  }, [
    isEvaluationPanelVisible,
    bundleStatusIndex,
    loanBundleDetails,
    isLoanTableFunding,
    priorStages,
    ttfVersionId,
    selectedTab
  ]);

  const filteredTabs: LoanDetailsTabs[] = useMemo(() => {
    const deduplicatedTabs = new Map();
    tabs.forEach((item: any) => {
      const alreadyShown = deduplicatedTabs.has(item?.title?.toLowerCase());
      const shouldNotFilter =
        !item.hide &&
        (item.showInPrior?.some(
          (stage: string) => priorStages.has(stage) || propInfotoorvalId
        ) ||
          item.showIn?.includes(loanStage)) &&
        ((item.requiresEvaluationPanel === true && isEvaluationPanelVisible) ||
          item.requiresEvaluationPanel === false) &&
        (!item.role ||
          (item.role && item.role?.includes(getCookie("loginFlow")))) &&
        (!item.domain || item.domain?.includes(window.location.hostname));
      if (shouldNotFilter && !alreadyShown)
        deduplicatedTabs.set(item?.title.toLowerCase(), item);
    });
    return [...deduplicatedTabs.entries()].map(([_key, value]) => value);
  }, [tabs]);

  useEffect(() => {
    setPropInfotoorvalId(propertyDetailsNewStore[0]?.propertyinfo?.toorvalId);
  }, [propertyDetailsNewStore]);

  useEffect(() => {
    getTakeOutPartnerList()
      .then((res: any) => {
        setCreditLines([...creditLines, ...(res?.data ?? [])]);
      })
      .catch(err => {
        console.error("error is ", err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loanId || loanType !== getLoanType[1].displayValue || !loanDetails)
      return;
    const orgPartyId = sessionStorage.getItem("originatorPartyIdNew") || "";
    const rateAdjustmentsRuleVersion =
      loanDetails?.loanRuleVersions?.filter(
        (item: ObjectType) =>
          item.name === "RATE_ADJUSTMENTS" || item.name === "RATE_SHEET_TYPE"
      ) ?? [];
    if (rateAdjustmentsRuleVersion.length === 0) return;
    const rateSheetData = getRateLockOptions(
      orgPartyId,
      rateAdjustmentsRuleVersion,
      takeoutPartnerId
    );
    const pricingtype =
      loanStage === fes ? "RATE_LOCK_ADJUSTMENT_FES" : "RATE_LOCK_ADJUSTMENT";
    rateSheetData.then((res: any) => {
      const respMap: any = new Map(
        res?.data?.data.map((it: ObjectType) => [it.name, it])
      );
      const options = respMap
        .get("RATE_ADJUSTMENTS")
        ?.lookupData?.filter(
          (item: ObjectType) => item.pricingType === pricingtype
        );
      const rateLockDropDownOptions: ObjectType[] = formatLockOptions(
        options,
        orgPartyId
      );
      setRateLockOptions({
        [loanId]: {
          lockValues: rateLockDropDownOptions,
          assignedPartner: takeoutPartnerId
        }
      });
      const orgSpecificRateSheetData = respMap
        .get("RATE_SHEET_TYPE")
        ?.lookupData?.filter((it: ObjectType) => {
          return it?.originatorId === orgPartyId;
        });
      const rateSheetTypeData = orgSpecificRateSheetData?.length
        ? orgSpecificRateSheetData
        : respMap.get("RATE_SHEET_TYPE")?.lookupData?.[0];
      setRateSheetTypeData(
        Array.isArray(rateSheetTypeData)
          ? rateSheetTypeData[0]
          : rateSheetTypeData
      );
      const rateExtensions = respMap
        .get("RATE_ADJUSTMENTS")
        ?.lookupData?.filter(
          (item: ObjectType) => item.pricingType === "EXTENSION_PERIOD_ADJUSTMENT"
        );
      dispatch(setRateExtensionOptions(formatLockOptions(rateExtensions, orgPartyId, rateExtensionLabelFormatter)));
    });
  }, [
    loanId,
    loanType,
    loanStage,
    loanDetails.loanRuleVersions,
    takeoutPartnerId
  ]);

  useEffect(() => {
    const conditions = activeInteractionsModalArray?.filter((item: any) => {
      return (
        item?._source?.resource === "exception" &&
        (item?._source?.exception?.type.toLowerCase() === "exception" ||
          item?._source?.exception?.type === "")
      );
    });
    const waivers = activeInteractionsModalArray?.filter((item: any) => {
      return (
        (loanType !== DSCRLoan && item?._source?.resource === "waiver") ||
        (item?._source?.resource === "waiver" &&
          item?._source?.waiver?.takeoutPartnerId === takeoutPartnerId)
      );
    });
    const inquiry = activeInteractionsModalArray?.filter((item: any) => {
      return (
        item?._source?.resource === "exception" &&
        item?._source?.exception?.type.toLowerCase() === "inquiry"
      );
    });
    setActiveInteractionsTag({
      total: activeInteractionsModalCount,
      conditions: conditions?.length,
      waivers: waivers?.length,
      inquiries: inquiry?.length
    });
  }, [activeInteractionsModalCount]);

  const loanOnHold = useMemo(() => {
    const holdValue: boolean = isLoanOnHold(loanDetails?.onHoldBy);
    return holdValue;
  }, [loanDetails?.onHoldBy]);

  useEffect(() => {
    if (
      loanStage === fes ||
      loanType !== DSCRLoan ||
      !loanDetails?.loanLandmarks
    )
      return;
    if (
      loanDetails?.loanLandmarks.filter(
        (item: any) => item.name === "loan_conversion"
      ).length &&
      loanDetails?.loanLandmarks.filter(
        (item: any) => item.name === "loan_creation" && item.value === "FES"
      ).length &&
      loanDetails?.sizerRateLockExpiryDate
    ) {
      setIsRateLockCarryForward(true);
    }
  }, [
    loanDetails?.loanLandmarks,
    loanDetails?.sizerRateLockExpiryDate,
    loanStage,
    loanType
  ]);

  useEffect(() => {
    if (
      getCookie("excelSubmitPressed") ||
      (loanId && !loanId.includes(loanIdInReducer))
    ) {
      removeCookie("excelSubmitPressed");
      if (excelUploadData?.maintainExcelBundle) {
        if (excelUploadData.isSubmitted && !isExcelLoanSubmitted) {
          dispatch(changeSubmitStatusInReducer());
        }
        try {
          if (uploadExcelStoreData[loanId]?.createLoanStore?.storeValue) {
            dispatch(
              updateCreateLoanStoreForSelectedLoan({
                data: uploadExcelStoreData[loanId].createLoanStore.storeValue,
                fullReplace: true
              })
            );
            if (uploadExcelStoreData[loanId]?.evaluationResultsStore) {
              dispatch(
                updateEvaluationResultsStoreForSelectedLoan(
                  uploadExcelStoreData[loanId].evaluationResultsStore
                )
              );
            }

            if (uploadExcelStoreData[loanId]?.exceptionsResultsStore) {
              dispatch(
                uploadExcelLoanExceptionChange(
                  uploadExcelStoreData[loanId].exceptionsResultsStore
                )
              );
            }
          } else {
            dispatch(resetAllStores());
            getDataByLoanID(loanId, loanStage, true, callBack);
          }
        } catch (error) {
          console.error(error);
        }
      }
      if (!excelUploadData?.maintainExcelBundle) {
        getDataByLoanID(loanId, loanStage, false, callBack);
      }
    }
    else{
      callBack();
    }
  }, [loanId, loanStage, isExcelLoanSubmitted, dispatch]);

  useEffect(() => {
    if (path.state?.isRescind) {
      isRescind = true;
      dispatch(
        replace({
          ...path,
          state: undefined
        })
      );
    }
  }, [path, dispatch]);

  useEffect(() => {
    if (excelUploadData?.isSubmitted) setssionStorageData(excelUploadData);
  }, [excelUploadData?.isSubmitted]);

  useEffect(() => {
    if (loanDeleted || rerenderLoanIdList) setssionStorageData(excelUploadData);
  }, [loanDeleted, rerenderLoanIdList]);

  useEffect(() => {
    if (startCreditEvalSync) {
      dispatch(keepCreditEvalSync(false));
      const promise1 = getLoanById(loanId, loanStage);
      promise1
        .then((response: any) => {
          dispatch(
            setTtfStatus({
              payload: response.data?.tapeToFileStatus
            })
          );
          updateLoanDetailsInRedux(response.data, dispatch); // credit-eval reducer
        })
        .catch((e) => console.error(e));
      const promise2 = getPropertyOriginalValuesByLoanId(loanId, loanStage);
      promise2
        .then((resp: any) => {
          updatePropertyDetailsInRedux(resp.data, dispatch);
        })
        .catch((e) => console.error(e));
    }
  }, [startCreditEvalSync, dispatch]);

  const checkSubmission = (loanState: any) => {
    return ![
      LoanStatusEnum.DataEntry,
      LoanStatusEnum.ExcelError,
      LoanStatusEnum.ExcelSuccess
    ].includes(loanState);
  };

  const getDataByLoanID = async (
    loanId: string,
    loanStage: string | LoanStage,
    excelUploadFlow?: boolean,
    callBack?: Function
  ) => {
    try {
      let formattedLoanData: any = null;
      let formattedPropertyData: any = null;
      const promise1 = getLoanById(loanId, loanStage);
      promise1
        .then(async (response: any) => {
          const loanDetails = formatResponse(response.data);
          updateLoanDetailsInRedux(loanDetails, dispatch); // credit-eval reducer
          dispatch(setLoanLandMark(response.data?.loanDetailId?.loanLandmarks));
          formattedLoanData = formatLoanDetailsResponse(
            response.data,
            loanStage
          );
          dispatch(
            setLoanByIdSuccess({
              loan: formattedLoanData,
              keepTab: true
            })
          );
        })
        .catch((e: any) => {
          console.error(e);
          dispatch(hideLoader());
          dispatch(setLoanByIdFailed());
        });

      const promise2 = getPropertyOriginalValuesByLoanId(loanId, loanStage);
      promise2
        .then((resp: any) => {
          updatePropertyDetailsInRedux(resp.data, dispatch);
          formattedPropertyData = formatPropertyResponse(
            resp.data,
            loanStage,
            loanType
          );
          dispatch(
            getPropertyDetails({
              propertyDetails: formattedPropertyData,
              thirtyYearLoanPropertyDetails: formattedPropertyData
            })
          );
        })
        .catch((e) => {
          console.error(e);
        });

      Promise.all([promise1, promise2])
        .then(async (response: any) => {
          let isSubmitted: any = checkSubmission(response[0].data.loanState);
          const takeoutPartnerId = response[0]?.data?.loanInfo?.takeoutPartner;
          const excelFlow =
            excelUploadData?.maintainExcelBundle ||
            sessionStorage.getItem("singleLoanExcelCreated") === "true";
          if (
            excelUploadFlow ||
            sessionStorage.getItem("singleLoanExcelCreated") === "true"
          ) {
            sessionStorage.removeItem("singleLoanExcelCreated");
            setTimeout(() => {
              dispatch(validateForm());
            }, 100);
          }
          if (
            !isSubmitted &&
            ((excelFlow &&
              !excelUploadData?.loanIdList[loanId]?.rulesExecuted) ||
              getCookie("triggerRules"))
          ) {
            removeCookie("triggerRules");
            const typeOfLoan =
              loanType === getLoanType[0].displayValue ? "bridge" : "30year";
            const resp: any = await evaluateLoanRuleResults(loanId, loanStage, typeOfLoan);
            dispatch(evaluateLoanSuccess({
              loanEvaluationResult: resp?.data,
              reasonForFail: {},
              errorAddressList: {},
              isSubmitted
            }));
            const excelUploadData = getExcelDataInSession();
            let tempObj = excelUploadData?.loanIdList[loanId];
            if (tempObj) {
              tempObj = { ...tempObj, ...{ rulesExecuted: true } };
              excelUploadData.loanIdList[loanId] = tempObj;
              sessionStorage.setItem("excelUpload", JSON.stringify(excelUploadData));
            }
          }
          if (
            loanType !== DSCRLoan ||
            (loanType === DSCRLoan && takeoutPartnerId)
          ) {
            dispatch(
              getRuleEvaluationByLoanId(
                loanId,
                loanStage as LoanStage,
                takeoutPartnerId,
                isSubmitted,
                formattedPropertyData,
                loanType,
                true
              )
            );
            dispatch(getOverriddenOutputValues(loanId, loanStage as LoanStage, takeoutPartnerId)); // Update the type of loanStage parameter
          }
          if (loanType === DSCRLoan && !takeoutPartnerId) {
            const typeOfLoan =
              loanType === getLoanType[0].displayValue ? "bridge" : "30year";
            const resp: any = await evaluateLoanRuleResults(loanId, loanStage, typeOfLoan);
            dispatch(evaluateLoanSuccess({
              loanEvaluationResult: resp?.data,
              reasonForFail: {},
              errorAddressList: {},
              isSubmitted
            }));
          }
          if (isSubmitted) {
            dispatch(changeEvalPanelVisibility(true));
            getAuditInfoLightWeight({
              resources: [
                {
                  resourceType: "loans",
                  resourceId: `${loanId}_${loanStage}`
                }
              ]
            }).then((resp: any) => {
              const keys = Object.keys(resp?.data?.[`loans_${loanId}_${loanStage}`] ?? {});
              dispatch(setEditedFieldKeys(keys));
            })
          }
          const loanDetails = response[0].data;
          dispatch(setTtfStatus({ payload: response.data?.tapeToFileStatus }));
          dispatch(updatePreviousStage(loanDetails, loanStage as LoanStage, loanId));
          dispatch(setOnHoldLoan(loanDetails?.onHoldBy));
          dispatch(setTableFundingFlag(loanDetails?.loanDetailId?.fundingType === TABLE_FUNDING));
          dispatch(setWarehouseFundingFlag(loanDetails?.loanDetailId?.isWarehouseFunded));
          dispatch(setIsLoanAdvanceFunded(loanDetails?.loanDetailId?.fundingType === ADVANCE_FUNDING));
          if (isRescind) {
            setRescindWaiverModel(true);
            isRescind = false;
          }

          /** Bundle Fetch in case of Settelment and PA signed - Starts */
          if (
            response.length &&
            postApprovalStates.includes(response[0]?.data?.loanState)
          ) {
            let isSettlementFinalized: boolean = false;
            getBundleLoanData(loanId, isLATUser()).then((res: any) => {
              const bundleDetails = res;
              const bundleStatus: BundleStatuses =
                bundleDetails?.loanbundleData?.bundleDetails?.bundleStatus;
              const bundleStatusIndex = staticBundleStatusList.indexOf(bundleStatus);
              setBundleStatusIndex(bundleStatusIndex);
              if (bundleStatusIndex >= 2 && bundleStatusIndex < 7) {
                isSettlementFinalized = true;
              }
              if (bundleDetails?.loanbundleData) {
                dispatch(setBundleLoanData(bundleDetails));
              } else {
                dispatch(
                  setBundleLoanData({
                    loanbundleData: {
                      bundleDetails: { bundleId: bundleDetails.bundleId, takeoutPartner: bundleDetails.takeoutPartner }
                    },
                    calcFields: {},
                    bundleStatus: bundleDetails?.bundleStatus || ""
                  })
                );
              }
              getAuditInfoLightWeight({
                resources: [
                  { resourceType: "bundles", resourceId: bundleDetails.bundleId },
                ]
              }).then((resp: any) => {
                console.log(resp);
                const keys = Object.keys(resp?.data?.[`bundles_${bundleDetails.bundleId}`] ?? {});
                dispatch(setEditedFieldKeys(keys));
              })
              const isSettelmentTabActive: any = isLATUser() && isSettlementFinalized;
              if (isSettelmentTabActive) {
                dispatch(setActiveTabAndProperty(selectedTab || "settlement"));
              }
            })
              .catch((e: any) => {
                console.error(e);
              });
          }
          /** Bundle Fetch in case of Settelment and PA signed - ends */
          callBack?.();
        })
        .catch((e) => console.error(e));
    } catch (error) {
      console.error(error);
    }
  };

  globalGetDataByLoanID = getDataByLoanID;

  const callBack = () => {
    setDataFetched(true);
    if (
      !PreSubmitStates.includes(loanDetails.loanState)
    ) {
      dispatch(setActiveInteractionsModal(false, loanId, loanStage));
    }
  };

  useEffect(() => {
    if (isILP()) {
      dispatch(getInternalGroups());
      if (ttfVersionId != null) {
        getTasks(
          dispatch,
          {
            resourceTypes: ["LOAN"],
            resourceIds: [loanId],
            taskNames: [
              taskNames.TAG,
              taskNames.SPLIT,
              taskNames.REVIEW,
              taskNames.RECONCILE,
              taskNames.BUDGET_REVIEW,
              taskNames.BUDGET_APPROVE
            ]
          },
          loanDetails?.loanSummary
        );
      }
      if (isLATUser()) {
        dispatch(getInternalUsers());
      }
    }
  }, [loanId, ttfVersionId]);

  useEffect(() => {
    if (loanStage === LoanStage.post) {
      dispatch(getCustodyDocsList(loanId, loanStage, loanType));
    }
  }, [loanId, loanStage, loanType, dispatch]);

  const isWaiverAndResultsLoaded = (results: any, waiver: any) => {
    if (
      !Array.isArray(waiver) ||
      (!results &&
        (Object.keys(results.propertiesResults).length === 0 ||
          Object.keys(results.loanResult).length === 0))
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    let passedTest: string[] = [];
    let failedTest: string[] = [];
    if (
      isEvaluationPanelVisible &&
      isWaiverAndResultsLoaded(loanEvaluationResult, waiversCreated)
    ) {
      if (
        loanEvaluationResult.loanResult &&
        Object.values(loanEvaluationResult.loanResult).length > 0 &&
        Object.values(loanEvaluationResult.loanResult)[0]
      ) {
        const { passed, failed } = passedAndFailedTests(
          Object.values(loanEvaluationResult.loanResult)[0]
        );
        passedTest = [...passedTest, ...passed];
        failedTest = [...failedTest, ...failed];
        setLoanDetailsErrors(failed.length);
      }
      if (loanEvaluationResult.propertiesResults) {
        Object.keys(loanEvaluationResult.propertiesResults).forEach(key => {
          const { passed, failed } = passedAndFailedTests(
            loanEvaluationResult.propertiesResults[key]
          );
          passedTest = [...passedTest, ...passed];
          failedTest = [...failedTest, ...failed];
          setPropertyDetailsErrors(failed.length);
        });
      }
      const totalPass = passedTest.filter(
        (thing, index, self) => index === self.findIndex(t => t === thing)
      ).length;
      failedTest = failedTest.filter(
        (thing, index, self) => index === self.findIndex(t => t === thing)
      );
      const totalFail = failedTest.length;
      setPassedTestsCount(totalPass > 0 ? totalPass : 0);
      setFailedTestsCount(totalFail > 0 ? totalFail : 0);
      const data = getExcelDataInSession();
      if (isExcelLoanSubmitted && data?.maintainExcelBundle) {
        if (data?.loanIdList) {
          const obj = {
            ...data.loanIdList[loanId],
            ...{
              failedTests: totalFail > 0
            }
          };
          const newLoanListObj = {
            ...data.loanIdList,
            ...{ [loanId]: obj }
          };
          const updatedSessionValue = {
            ...data,
            ...{ loanIdList: newLoanListObj }
          };
          sessionStorage.setItem(
            "excelUpload",
            JSON.stringify(updatedSessionValue)
          );
          setssionStorageData(updatedSessionValue);
        }
      }
    }
  }, [isEvaluationPanelVisible, loanEvaluationResult, waiversCreated]);

  useEffect(() => {
    const ErrorArray = {
      loanDetails: loanDetailsErrors,
      propertyDetails: propertyDetailsErrors,
      guidelineOutputs: failedTestsCount
    };
    setErrorsFormWise(ErrorArray);
  }, [loanDetailsErrors, propertyDetailsErrors, loanState, failedTestsCount]);

  useEffect(() => {
    if (validateFormClicked.validate) {
      if (validateFormClicked.isSubmitValidation) dispatch(validateForm(false));
      let loanDetailsErrorCount = 0;
      let propertyDetailsErrorCount = 0;
      let propertyRendered = false;
      const propertyData = loanDetails?.propertyDetails?.length
        ? loanDetails.propertyDetails
        : [emptyPropertyBasedOnLoan(loanType, loanStage as LoanStage)];
      loanDetailsErrorCount = countLoanDetailError(loanStage, loanType, loanDetails, {}, loanEvaluationResult, loanId)?.loanErrorCount
      if (loanStage !== LoanStage.fes) {
        propertyData.forEach((element: PropertyDetailsRepresentable) => {
          propertyRendered = true;
          const propertyInfoAndEconomicsError = countPropertyDetailError(loanStage, loanType, loanDetails, {}, element)?.propertyErrorCount;
          const propertyLocationError = Object.values(
            computePropertyLocationError(
              element.propertyLocation.payload,
              propertyData.map((item: any) => {
                if (item.propertyId === element.propertyId) {
                  return null;
                }
                if (
                  typeof item.propertyLocation?.payload?.address === "string"
                ) {
                  return {
                    city: item.propertyLocation.payload.city,
                    state: item.propertyLocation.payload.state,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    street_line: item.propertyLocation.payload.address,
                    zipcode: item.propertyLocation.payload.zipCode
                  };
                }
                return item.propertyLocation?.payload?.address;
              })
            )
          ).filter(item => item !== "").length;
          propertyDetailsErrorCount +=
            propertyInfoAndEconomicsError + propertyLocationError;
        });
      } else {
        propertyData.forEach((element: PropertyDetailsRepresentable) => {
          propertyRendered = true;
          const propertyLocationError = Object.values(
            computePropertyLocationError(
              element?.propertyLocation?.payload,
              propertyData.map((item: any) => {
                if (item.propertyId === element.propertyId) {
                  return null;
                }
                if (typeof item?.propertyLocation?.payload.address === "string") {
                  return {
                    city: item?.propertyLocation?.payload.city,
                    state: item?.propertyLocation?.payload.state,
                    // eslint-disable-next-line @typescript-eslint/camelcase
                    street_line: item?.propertyLocation?.payload.address,
                    zipcode: item?.propertyLocation?.payload.zipCode
                  };
                }
                return item?.propertyLocation?.payload.address;
              })
            )
          ).filter(item => item !== "").length;
          const propertyInfoAndEconomicsError = countPropertyDetailError(loanStage, loanType, loanDetails, {}, element)?.propertyErrorCount;
          propertyDetailsErrorCount +=
            propertyInfoAndEconomicsError + propertyLocationError;
        });
      }
      const errorCount = loanDetailsErrorCount + propertyDetailsErrorCount;
      if (!isEvaluationPanelVisible) {
        setLoanDetailsErrors(loanDetailsErrorCount);
        setPropertyDetailsErrors(propertyDetailsErrorCount);
        setErrors(errorCount);
      }
      const data = getExcelDataInSession();
      if (data?.maintainExcelBundle) {
        const {
          bridgeLoanBorrowerInformation,
          bridgeLoanGuarantorInformation
        } = loanDetails;
        const hasPrimaryBorrowerOrGuarantor = includesPrimaryBorrowerOrGuarantor(
          bridgeLoanBorrowerInformation as any,
          bridgeLoanGuarantorInformation,
          loanType
        );
        if (data?.loanIdList) {
          const obj = {
            ...data.loanIdList[loanId],
            ...{
              doesNotHaveIndividualUser: !hasPrimaryBorrowerOrGuarantor
            }
          };
          const newLoanListObj = {
            ...data.loanIdList,
            ...{ [loanId]: obj }
          };
          const updatedSessionValue = {
            ...data,
            ...{ loanIdList: newLoanListObj }
          };
          sessionStorage.setItem(
            "excelUpload",
            JSON.stringify(updatedSessionValue)
          );
        }
        dispatch(
          updateLoanErrors(loanId, {
            loanLevelErrors: loanDetailsErrorCount,
            propertyLevelErrors: propertyDetailsErrorCount
          })
        );
      }
      if (
        validateFormClicked.isSubmitValidation &&
        propertyRendered &&
        loanDetailsErrorCount === 0 &&
        propertyDetailsErrorCount === 0
      ) {
        dispatch(
          errorsOnSubmit({
            loanErrors: loanDetailsErrorCount,
            propertyErrors: propertyDetailsErrorCount,
            clicked: errorCount === 0
          })
        );
      }
    }
  }, [loanDetails, validateFormClicked, dispatch]);

  const getComponent = () => {
    let component: any;
    const indexOfActiveTab = filteredTabs.findIndex(
      (item: any) => item.id === activeTab
    );
    if (activeTab === "loanDetails") {
      if (loanStage === LoanStage.fes) {
        component = (
          <LoanDetails />
        );
      } else {
        component =
          loanType === getLoanType[0].displayValue ? (
            // <LoanDetailsForm
            //   isEvaluationPanelVisible={isEvaluationPanelVisible}
            //   orgSellerInfo={orgSellerInfo}
            //   bundleStatusIndex={bundleStatusIndex}
            // />
            <LoanDetails />
          ) : (
            // <ThirtyYearLoanDetailsForm />
            <LoanDetails />
          );
      }
    } else if (indexOfActiveTab >= 0) {
      component = filteredTabs[indexOfActiveTab].component();
    }
    return component;
  };

  const handleExpandCollapse = () => {
    setIsMinimised(!isMinimised);
  };

  const getErrorIndigator = (tabDet: any, index: number) => {
    if (isViewMode) return false;
    if (tabDet.title === "Documents") return false
    if (tabDet.title === "Toorval") {
      return loanState === LoanStatusEnum.DataEntry && toorvalErrors !== 0;
    }
    if (errorsFormWise[tabDet.id]) {
      return (tabDet.title === "Loan Details" ||
        tabDet.title === "Property Details") &&
        loanState === LoanStatusEnum.DataEntry
        ? validateFormClicked.validate && errorsFormWise[tabDet.id] !== 0
        : errorsFormWise[tabDet.id] !== 0;
    }
    return false;
  };

  const getErrorUI = (text: string, count: any) => {
    return (
      <div className={classes.errorContainer}>
        <WarningRoundedIcon className={classes.errorIcon} htmlColor="#ffffff" />
        <Typography key={0} variant="h5" className={classes.errorTypography}>
          {text} - {count}
          {/* Fail - {failedTestsCount} */}
        </Typography>
      </div>
    );
  };

  useEffect(() => {
    if (!selectedTab) return;
    dispatch(setActiveTabAndProperty(selectedTab));
  }, [selectedTab]);

  useEffect(() => {
    if (excelUploadData?.maintainExcelBundle) {
      const isLoanPresent = excelUploadData.loanIdList[loanId];
      const isLoanStageSame = loanStage === excelUploadData.loanStage;
      if (!isLoanPresent || !isLoanStageSame) {
        dispatch(resetAllStores());
        dispatch(resetExcelUploadReducer());
        dispatch(resetRetryRequestReducer());
        const newData = {
          ...excelUploadData,
          ...{ maintainExcelBundle: false }
        };
        sessionStorage.setItem("excelUpload", JSON.stringify(newData));
        window.location.reload();
      }
    }
  }, [loanId, loanStage, dispatch]);

  useEffect(() => {
    if (!appraisalID) return;
    dispatch(getAppraisalLandmarks(appraisalID));
  }, [appraisalID, dispatch]);

  const { rateLockExpiryObj, isRateLockActive } = useMemo(() => {
    let expiry = "";
    let daysTillExpiry: any = null;
    const expiryDate =
      loanDetails?.rateLockExpiryDate ?? loanDetails?.sizerRateLockExpiryDate;
    if (expiryDate) {
      expiry = moment.utc(expiryDate).format("MM/DD/YYYY");
      // remove time stamp and compare dates.
      const now = moment().format("MM/DD/YYYY");
      daysTillExpiry = moment(expiry).diff(now, "days");
      expiry = `Rate Lock Expiry - ${expiry}`;
    }
    const getColor = () => {
      if (!isEmptyValue(daysTillExpiry) && daysTillExpiry < 0) {
        return "#f75676";
      }
      if (!isEmptyValue(daysTillExpiry) && daysTillExpiry <= 5) {
        return "#fa9d00";
      }
      return "#172b4d";
    };
    return {
      rateLockExpiryObj: {
        label: expiry,
        show:
          (expiryDate && !PreSubmitStates.includes(loanState)) ||
          (PreSubmitStates.includes(loanState) && isRateLockCarryForward),
        style: {
          display: "flex",
          alignItems: "center",
          backgroundColor: getColor()
        },
        tooltipLabel:
          !isEmptyValue(daysTillExpiry) && daysTillExpiry >= 0
            ? `${daysTillExpiry} Days left to expire`
            : null
      },
      isRateLockActive:
        (daysTillExpiry && daysTillExpiry > 0) || daysTillExpiry === 0
    };
  }, [loanDetails, InitialReview, isRateLockCarryForward]);

  const { dueDepositAmountObj } = useMemo(() => {
    const loanPricing =
      evaluationResults?.loanResult &&
      evaluationResults?.loanResult[loanId]?.loanPricing;
    const bar = loanPricing?.besDueDepositAmount || null;
    const value = formatValueByType(bar, "currency");
    return {
      dueDepositAmountObj: {
        label: `Due Deposit Amount - ${value}`,
        show:
          value &&
          (!PreSubmitStates.includes(loanState) ||
            (PreSubmitStates.includes(loanState) && isRateLockCarryForward)),
        style: {
          display: "flex",
          alignItems: "center",
          backgroundColor: "#fb623f"
        }
      }
    };
  }, [evaluationResults, isRateLockCarryForward, loanId]);

  const {
    showRiskScoreTag,
    showHistoryButton,
    showCustodyDocs,
    showKDM,
    showILPAssignmentComments,
    showSummaryCheckListButton,
    shouldShowActionDropDown,
    shouldShowHeader
  } = useMemo(() => {
    return {
      showRiskScoreTag:
        !PreSubmitStates.includes(loanState) &&
        loanType === getLoanType[0].displayValue &&
        (
          (loanDetails.bridgeLoanInformation.payload.selectedToorakProduct !==
            ToorakProductEnum.GroundUp &&
            oneFourFamilyPropertyTypeDropDown.includes(
              loanDetails.propertyDetails?.[0]?.propertyInformation?.payload
                ?.propertyType)) ||
          (loanStage === LoanStage.fes && loanDetails.loanSizerType === LoanSizerEnum.oneFourFamily)
        ),
      showHistoryButton:
        (loanStage === LoanStage.tv &&
          ![LoanStatusEnum.DataEntry].includes(loanState)) ||
        (loanStage !== LoanStage.tv &&
          ![
            LoanStatusEnum.DataEntry,
            LoanStatusEnum.ExcelError,
            LoanStatusEnum.ExcelSuccess
          ].includes(loanState)),
      showCustodyDocs:
        loanStage === LoanStage.post &&
        ![
          LoanStatusEnum.DataEntry,
          LoanStatusEnum.ExcelError,
          LoanStatusEnum.ExcelSuccess
        ].includes(loanState) &&
        !hideCustodyDoc(),
      showKDM:
        ![
          LoanStatusEnum.DataEntry,
          LoanStatusEnum.ExcelError,
          LoanStatusEnum.ExcelSuccess
        ].includes(loanState) && loanStage !== LoanStage.tv,
      showILPAssignmentComments:
        isLATUser() &&
        isILP() &&
        ![
          LoanStatusEnum.DataEntry,
          LoanStatusEnum.ExcelError,
          LoanStatusEnum.Submit,
          LoanStatusEnum.ExcelSuccess
        ].includes(loanState) &&
        ![LoanStage.tv, LoanStage.fes].includes(loanStage as LoanStage),
      showSummaryCheckListButton:
        isLATUser() &&
        ![
          LoanStatusEnum.DataEntry,
          LoanStatusEnum.ExcelError,
          LoanStatusEnum.Submit,
          LoanStatusEnum.ExcelSuccess
        ].includes(loanState) &&
        ![LoanStage.tv, LoanStage.fes].includes(loanStage as LoanStage),
      shouldShowActionDropDown: ((
        loanStage === LoanStage.tv &&
        loanState !== LoanStatusEnum.UnderReview &&
        loanState !== LoanStatusEnum.Submit
      ) || (loanStage === LoanStage.tv &&
        // appraisalFormStatus !== LoanStatusEnum.dataEntry &&
        onlyHasRole(ToorvalRoles.ORIGINATOR))) ? false : true,
      shouldShowHeader: (
        isEvaluationPanelVisible ||
        ((sessionStorageData?.maintainExcelBundle ?? true) && isExcelLoanSubmitted) ||
        (loanStage === LoanStage.tv && loanState !== LoanStatusEnum.DataEntry)
      ) ? true : false
    };
  }, [loanDetails, loanState, isEvaluationPanelVisible, loanStage, isExcelLoanSubmitted]);

  const showConvertLoanButton = useMemo(() => {
    return (
      (loanStage === LoanStage.tv &&
        checkUser([Roles.Originator]) &&
        ![LoanStatusEnum.DataEntry].includes(loanState) &&
        !convertedTo &&
        !loanOnHold &&
        !isLoanTableFunding) ||
      (![LoanStage.post, LoanStage.tv].includes(loanStage as LoanStage) &&
        checkUser([Roles.Originator]) &&
        ![
          LoanStatusEnum.DataEntry,
          LoanStatusEnum.ExcelError,
          LoanStatusEnum.ExcelSuccess
        ].includes(loanState) &&
        !loanOnHold &&
        !(isLoanTableFunding || isLoanAdvanceFunded))
    );
  }, [loanStage, loanState, loanOnHold, convertedTo, isLoanTableFunding]);

  const options = useMemo(
    () => {
      return [
        {
          title: (
            <CommentWidget
              sIden="DOC_REVIEW_ASSIGNMENT_COMMENTS"
              loanId={loanId}
              isReadOnly
              buttonLabel="ILP Assignment Comments"
              roles={["LAT_ADMIN", "LAT_IR", "LAT_QC", "LAT_VM"]}
              showAs={"text"}
              openComments={openComments}
              setOpenComments={setOpenComments}
            />
          ),
          role: [Roles.LAT],
          showInStage: [LoanStage.post, LoanStage.pre],
          loanType: ["BridgeLoan", "InvestorDSCR"],
          isShown: showILPAssignmentComments
        }
      ].filter((col: any) => Boolean(col.isShown));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [showILPAssignmentComments, openComments]
  );

  const redirectToAppraisalDashboard = () => {
    const refreshTokenId = getCookie("refresh_token");
    window.open(`${getConfig().appraisalDashboardUrl}loan/${loanId}?token=${refreshTokenId}`);
  };

  const handleDiscussionNotifierClick = () => {
    const toorvalTabVisible = filteredTabs.some(({ id }) => id === "toorval");
    if (toorvalTabVisible) {
      dispatch(setActiveTabAndProperty("toorval", "0", () =>
        setTimeout(() => {
          // eslint-disable-next-line no-unused-expressions
          document.getElementById("discussions")?.scrollIntoView();
        }, 250)
      ))
    }
  };

  const handleChange = (event: any, newValue: any) => {
    if (sectionBeingEdited || (isFormEditing && !isDataEntry)) {
      // showing warning
      // use state show warning true
      setShowWarning(true);
      setTabIndex(newValue);
    } else {
      // setValue(newValue);
      dispatch(setActiveTabAndProperty(newValue));

    }
  };

  const openCreditRiskPopup = () => {
    // setIsCreditRiskOpen(true); // Commented for TA-2058
  };
  const closeCreditRiskPopup = () => {
    setIsCreditRiskOpen(false);
  };

  const finalReviewEligibility = (exceptions: any[], grades: any[]) => {
    let hasPassingGrades: boolean = false;
    let hasOpenConditions: boolean = false;
    const passingGrades = ["A", "B", "CLEAR"];
    if (grades.length) {
      hasPassingGrades = grades.some(
        (ele: any) =>
          ele.name === "SECURITIZATION_GRADE" &&
          passingGrades.includes(ele.value.toUpperCase())
      );
    }
    if (hasPassingGrades) {
      const openConditions = exceptions.filter(
        ele => ele.status.toUpperCase() === "OPEN"
      );
      if (openConditions.length) {
        hasOpenConditions = true;
      }
    }
    return [hasPassingGrades, hasOpenConditions];
  };

  useEffect(() => {
    if (loanState === LoanStatusEnum.DueDiligenceReview) {
      //! if none of the conditions are OPEN and grade is A/B/CLEAR move to final review
      getExceptionChatID(loanId, loanStage).then((res: any) => {
        const { exceptions } = res.data;
        const { grades } = res.data;
        const [hasPassingGrades, hasOpenConditions] = finalReviewEligibility(
          exceptions,
          grades
        );
        if (
          hasPassingGrades &&
          !hasOpenConditions &&
          !isViewOnly(loanStage, loanId)
        ) {
          const bySystem = true;
          dispatch(
            dispatchAndUpdate(
              loanId,
              LoanStatusEnum.FinalReview,
              loanStage,
              undefined,
              bySystem
            )
          );
        }
      });
    }
  }, [loanState, dispatch, loanId]);

  useEffect(() => {
    if ((loanState === LoanStatusEnum.ExcelError || loanState === LoanStatusEnum.ExcelSuccess) || (loanState === LoanStatusEnum.DataEntry) || (loanState === LoanStatusEnum.DataEntry && loanStage === LoanStage.tv) || (loanState === LoanStatusEnum.Submit && loanStage !== LoanStage.fes)) {
      dispatch(updateLoanStageStatus({ value: true }));
    } else {
      dispatch(updateLoanStageStatus({ value: false }));
    }
  }, [loanState, loanStage,]);

  const showEditButton = useMemo(() => {
    const shouldExclude = !["loanDetails", "propertyDetails"].includes(activeTab);
    return getIsAllowedToEdit(loanStage as LoanStage, loanState, isViewMode || isWithdrawnLoan || shouldExclude);
  }, [loanState, loanStage, isViewMode, isWithdrawnLoan, activeTab]);

  if (!loanState || !dataFetched) return <Loader />

  return (
    <>
      <CreditLineLoanViewContext.Provider
        value={{
          creditLines
        }}
      >
        <SuspenseWithFallBack >
          <WarningModal
            isOpen={rescindWaiverModel}
            handleClose={() => setRescindWaiverModel(false)}
            primaryBtnName="OK"
            header="Waiver Rescinded Successfully"
            body={
              'This waiver has been rescinded successfully. Please Click on "Rescind Waiver History" to see datails.'
            }
          />
          <CreditRiskScoreModal
            isOpen={isCreditRiskOpen}
            hidePopup={closeCreditRiskPopup}
            riskScores={riskScores}
            riskBucketColor={riskBucketColor}
            evaluationResults={evaluationResults}
            loanDetails={loanDetails}
          />
          {showHistory && (
            <LoanHistory
              loanId={loanId}
              originatorId={originatorId}
              onClose={setShowHistory}
              isLoanTableFunding={isLoanTableFunded}
              isLoanAdvanceFunding={isLoanAdvanceFunded}
            />
          )}
          {!isEvaluationPanelVisible && (
            <AutoSaveInputValues />
          )}
          {openLoanDetlNew && (
            <ConfirmLoanDetailsModal
              open={openLoanDetlNew}
              setOpen={setOpenLoanDetlNew}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              confirmLoanDetails={() => { }}
              fromLoanDetails
              bundleInfo={loanBundleDetails?.loanbundleData?.bundleDetails}
            />
          )}
          {openKeyMetrics && (
            <KeyDealMetrics
              loanId={loanId}
              loanStage={loanStage as LoanStage}
              loanType={loanType}
              onClose={() => {
                setOpenKeyMetrics(false);
              }}
              takeoutPartnerId={takeoutPartnerId}
            />
          )}
          {openConfirmCustody && (
            <ViewCustodyDocumentModal
              open={openConfirmCustody}
              setOpen={setOpenConfirmCustody}
              bundleId={
                loanBundleDetails?.loanbundleData?.bundleDetails?.bundleId
              }
            />
          )}
          {missingEditedComments && (
            <WarningModal
              isOpen
              handleClose={() => {
                dispatch(editCommentMissing(false));
              }}
              header="Warning"
              body="Please Fill in Comments For all Edited Fields."
            />
          )}
          {missingPrimaryGuarantorOrBorrower && (
            <WarningModal
              isOpen
              handleClose={() => {
                dispatch(primaryGuarantorOrBorrowerCheck(false));
              }}
              header="Warning"
              body="Loan should contain atleast one individual borrower or combination of entity borrower and a guarantor."
            />
          )}
          {showWarning && (
            <WarningModal
              isOpen={showWarning}
              handleClose={() => {
                setShowWarning(false);
                dispatch(setActiveTabAndProperty(tabIndex));
                dispatch(valueEditing(false));
                dispatch(updateFormEditStatus({ value: false, editStatus: "notEditing" }));
              }}
              header="Warning"
              body="Changing tab while editing will result in losing changes. Are you sure you want to continue?"
              primaryBtnName="Yes"
              handleOptional={() => setShowWarning(false)}
            />
          )}
          {isLATUser() && (
            <Suspense fallback={<span>Loading...</span>}>
              <UpdateStatusLAT
                loanStage={loanStage}
                loanState={loanState}
                loanDetails={loanDetails}
                showStatusUpdate={showStatusUpdate}
                updateShowStatusUpdate={(i: boolean) =>
                  setShowStatusUpdate(i)
                }
                closeStatusUpdate={() => setShowStatusUpdate(false)}
                waivers={waiversCreated}
                exceptionsResult={exceptionsResult}
                failedTestsCount={failedTestsCount}
              />
            </Suspense>
          )}
          {isLATUser() && showLoanSummary && (
            <Suspense fallback={<span>Loading...</span>}>
              <LoanSummaryChecklistInfoModal
                loanState={loanState}
                showLoanSummary={showLoanSummary}
                setShowLoanSummary={setShowLoanSummary}
              />
            </Suspense>
          )}
          {/* {showNoratelockAlert && 
                      <div style={{
                        display: "flex",
                        justifyContent: "center"
                      }}>
                        <Alert variant="filled" severity="warning" style={{ position: "absolute", padding: "2px 4px", backgroundColor: "#fff0da", color: "#32325d" }}>
                          Currently rate locks are suspended. Please reach to Toorak team for any queries
                        </Alert></div>
                    } */}
          <Grid container>
            {!isViewMode && (
              <>
                {shouldShowHeader ? (
                  <Header
                    value={isILP() ? 0 : 1}
                    setTabValue={setTabView}
                    showNotificationIcon={!isILP()}
                  />
                ) : null}
                <CreateLoanHeader
                  creditLines={creditLines}
                  lockinValues={rateLockOptions}
                  rateSheetTypeData={rateSheetTypeData}
                  setLockInValues={setRateLockOptions}
                  hideLoanHeader={checkSubmission(loanState)}
                  takeoutPartnerId={takeoutPartnerId}
                  takeoutPartnerStatus={takeoutPartnerStatus}
                  failedTestsCount={failedTestsCount}
                />
              </>
            )}
            {sessionStorageData?.maintainExcelBundle && (
              <Grid
                xs={isMinimised ? 12 : 4}
                sm={isMinimised ? 1 : 2}
                className={
                  isMinimised
                    ? classes.loanIdListContainerMinimised
                    : classes.loanIdListContainer
                }
              >
                <Grid xs={isMinimised ? 1 : 12} sm={12}>
                  <LoanIdList
                    loanIdList={sessionStorageData.loanIdList}
                    minimised={isMinimised}
                    tradeId={sessionStorageData.tradeId}
                    minOrMaxClick={handleExpandCollapse}
                  />
                </Grid>
              </Grid>
            )}
            <TabContext value={activeTab}>
              {sessionStorageData?.maintainExcelBundle &&
                loaderContext.includes("switchExcelLoan") &&
                loaderCount > 0 ? (
                <Grid
                  xs={
                    sessionStorageData?.maintainExcelBundle && !isMinimised
                      ? 8
                      : 12
                  }
                  sm={
                    sessionStorageData?.maintainExcelBundle && !isMinimised
                      ? 10
                      : 12
                  }
                  style={{ zIndex: 0 }}
                >
                  <div className={classes.loading}>
                    <CircularProgress size={35} />
                  </div>
                  <h3 style={{ textAlign: "center" }}>Fetching Loan</h3>
                </Grid>
              ) : (
                <Grid
                  xs={
                    sessionStorageData?.maintainExcelBundle && !isMinimised
                      ? 8
                      : 11
                  }
                  sm={
                    // eslint-disable-next-line no-nested-ternary
                    sessionStorageData?.maintainExcelBundle && !isMinimised
                      ? 10
                      : isMinimised
                        ? 11
                        : 12
                  }
                  className={isViewMode ? classes.containerPaddingBottom : ""}
                  style={{ zIndex: 0 }}
                >
                  <Grid
                    container
                    className={`${classes.subHeaderContainer} ${isViewMode ? classes.removeMarginTop : ""
                      }`}
                    style={{
                      paddingTop: shouldTakeoutPartnerBannerBeShown ? 0 : 60
                    }}
                  >
                    <Container style={{maxWidth: 1280}}>
                      <SwitchLoanStage
                        availableLoanStages={availableLoanStages}
                        isViewMode={isViewMode}
                        loanStage={loanStage as LoanStage}
                      />
                      {shouldTakeoutPartnerBannerBeShown && (
                        <Grid
                          container
                          className={`${classes.pendingWaiverContainer}`}
                        >
                          <Container
                            maxWidth="xl"
                            className={classes.pendingWaiverSubContainer}
                          >
                            <Container
                              style={{maxWidth: 1200}}
                              data-testid="take-out-partner-warning"
                            >
                              <div style={{ display: "flex", alignItems: "center" }}>
                                <NotificationImportantIcon
                                  style={{ color: "rgb(238 144 39)" }}
                                />
                                <span style={{ color: "blue", fontWeight: 600 }}>
                                  The loan does not qualify for the program. Please
                                  review the failed tests and take appropriate action.
                                </span>
                              </div>
                            </Container>
                          </Container>
                        </Grid>
                      )}
                      <Grid
                        container
                        style={{ alignItems: "flex-end", position: "relative" }}
                        className={classes.mobileViewSubHeader}
                      >
                        <Grid item className={classes.leftSubheader}>
                          <DisplayLoanId
                            loanId={loanId}
                            isViewMode={isViewMode}
                            loanStage={loanStage as LoanStage}
                          />
                        </Grid>
                        <Grid
                          item
                          className={`${!isEvaluationPanelVisible ? classes.addMarginBtm : ""
                            } ${classes.dropdownContainer} ${classes.rightSubheader
                            }`}
                        >
                          {/* <Grid item className={classes.rightSubheader}> */}
                          <Grid item sx={{ marginTop: "32px" }}>
                            {showEditButton && <SaveEditActions currentTab={activeTab} commentObj={commentObj} setCommentObj={setCommentObj} />}
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              minHeight: "45px"
                            }}
                          >
                            {loanStage === LoanStage.tv &&
                              matchRole(ToorvalRoles.ORIGINATOR) && (
                                <Grid
                                  item
                                  style={{
                                    display: "flex",
                                    height: 30,
                                    width: 200,
                                    paddingTop: "0.5rem",
                                    paddingBottom: "0.5rem",
                                    paddingRight: "0.75rem",
                                    paddingLeft: "0.75rem",
                                    borderRadius: "5px",
                                    backgroundColor: "#e9fafd"
                                  }}
                                >
                                  <span style={{ paddingRight: "5px" }}>
                                    <IconTooltip
                                      topic="Information"
                                      infoIconData={" "}
                                    />
                                  </span>
                                  Appraisal review fee: $400
                                </Grid>
                              )}
                          </Grid>
                          <Grid
                            container
                            style={{
                              justifyContent: "flex-end",
                              alignItems: "center"
                            }}
                          >
                            {![LoanStatusEnum.DataEntry].includes(loanState) &&
                              isLATUser() && (
                                <Button
                                  className={classes.loanHistoryBtn}
                                  startIcon={
                                    <SvgIcon
                                      viewBox="0 0 16 16"
                                      component={AppraisalDashBoardIcon}
                                    />
                                  }
                                  onClick={redirectToAppraisalDashboard}
                                  style={{ marginRight: 20 }}
                                >
                                  Appraisal Dashboard
                                </Button>
                              )}
                            {showHistoryButton && (
                              <Button
                                className={classes.loanHistoryBtn}
                                startIcon={<HistoryIcon fontSize="large" />}
                                onClick={() => {
                                  setShowHistory(true);
                                }}
                                style={{ marginRight: 20 }}
                              >
                                Loan History
                              </Button>
                            )}
                            {showConvertLoanButton && (
                              <Grid item style={{ display: "flex" }}>
                                <ConvertLoanButton />
                              </Grid>
                            )}
                            {showKDM && (
                              <Grid
                                item
                                style={{ display: "flex", marginRight: 10 }}
                                className={classes.hideMobileViewKeydealBtn}
                              >
                                <KeyMetricsButton
                                  onPress={() => {
                                    setOpenKeyMetrics(true);
                                  }}
                                />
                              </Grid>
                            )}
                            {showSummaryCheckListButton && (
                              <Button
                                className={classes.loanHistoryBtn}
                                startIcon={
                                  <ViewListOutlinedIcon fontSize="large" />
                                }
                                onClick={() => {
                                  setShowLoanSummary(true);
                                }}
                              >
                                Summary Checklist
                              </Button>
                            )}
                            {!isILP() && (
                              <>
                                {loanStage === tv && (
                                  <Grid
                                    className={classes.dpBtnContainer}
                                    item
                                    style={{ paddingLeft: 20 }}
                                  >
                                    <Button
                                      variant="outlined"
                                      startIcon={
                                        <Badge
                                          badgeContent={unreadDiscussionCount}
                                          color="error"
                                          className={
                                            classes.discussionBadgeContainer
                                          }
                                        >
                                          <ChatIcon
                                            style={{
                                              height: "30px",
                                              width: "25px"
                                            }}
                                          />
                                        </Badge>
                                      }
                                      type="submit"
                                      onClick={handleDiscussionNotifierClick}
                                      className={classes.discussionMessageCount}
                                    />
                                  </Grid>
                                )}
                                {shouldShowActionDropDown && (
                                  <Grid
                                    className={classes.dpBtnContainer}
                                    item
                                    style={{ paddingLeft: 20 }}
                                  >
                                    <ActionDropDown
                                      isEvaluationPanelVisible={
                                        isEvaluationPanelVisible
                                      }
                                      StatusUpdate={() =>
                                        setShowStatusUpdate(true)
                                      }
                                      loanState={loanState}
                                      loanStage={loanStage as LoanStage}
                                      setShowLoanSummary={() =>
                                        setShowLoanSummary(true)
                                      }
                                      assignedTo={loanDetails.assignedTo}
                                      qcRequired={loanDetails.qcRequired}
                                      roleOfAssignee={loanDetails.roleOfAssignee}
                                      loanProcessType={
                                        loanDetails?.loanProcessType
                                      }
                                      loanId={loanId}
                                      loanType={loanType}
                                      isViewMode={isViewMode}
                                      availableLoanStages={availableLoanStages}
                                      activeTab={activeTab}
                                      setOpenLoanDetlNew={setOpenLoanDetlNew}
                                      setOpenConfirmCustody={
                                        setOpenConfirmCustody
                                      }
                                      isWithdrawnLoan={isWithdrawnLoan}
                                      originatorId={
                                        loanDetails?.loanDetailId
                                          ?.originatorPartyId
                                      }
                                    />
                                  </Grid>
                                )}
                              </>
                            )}
                            {![
                              LoanStatusEnum.DataEntry,
                              LoanStatusEnum.ExcelError,
                              LoanStatusEnum.ExcelSuccess
                            ].includes(loanState) && (
                                <>
                                  <DetailViewDropDown options={options} />
                                </>
                              )}
                          </Grid>
                          {!isEvaluationPanelVisible &&
                            loanStage === LoanStage.fes &&
                            loanType === getLoanType[0].displayValue && (
                              <LoanSizerType loanSizerType={loanSizerType} />
                            )}
                        </Grid>
                      </Grid>
                      {!isViewMode && (
                        <Grid xs={12} sm={12} className={classes.badgeContainer}>
                          <DisplayLoanStageAndType
                            loanState={loanState}
                            loanDetails={loanDetails}
                            ttfVersionId={ttfVersionId}
                            bundleStatus={genMaskedOrgBundleStatus(
                              loanBundleDetails?.bundleStatus
                            )}
                            isLoanAdvanceFunded={isLoanAdvanceFunded}
                            isTableFunding={isLoanTableFunded}
                            isWarehouseFunding={isWarehouseFunded}
                            rateLockExpirtDateObj={rateLockExpiryObj}
                            dueDepositAmountObj={dueDepositAmountObj}
                            activeInteractions={activeInteractionsTag}
                            needEnhancedReview={
                              loanEvaluationResult?.loanResult?.[loanId]
                                ?.loanCharacterisations?.needEnhancedReview ??
                              loanEvaluationResult?.loanResult?.[loanId]
                                ?.loanCharacterization?.needEnhancedReview
                            }
                            takeoutPartner={
                              takeoutPartnerStatus === "PENDING"
                                ? null
                                : takeoutPartnerId
                            }
                          />
                          {!isEvaluationPanelVisible &&
                            validateFormClicked.validate &&
                            errors !== 0 &&
                            getErrorUI("Field errors", errors)}
                          {!isEvaluationPanelVisible &&
                            toorvalErrors !== 0 &&
                            getErrorUI("Field errors", toorvalErrors)}
                          {isEvaluationPanelVisible && passedTestsCount > 0 && (
                            <div
                              className={classes.passContainer}
                              style={{ marginLeft: 0 }}
                            >
                              <CheckRoundedIcon
                                className={classes.passIcon}
                                htmlColor="#2ece89"
                              />
                              <Typography
                                key={0}
                                variant="h5"
                                className={classes.errorTypography}
                              >
                                Pass - {passedTestsCount}
                              </Typography>
                            </div>
                          )}
                          {isEvaluationPanelVisible &&
                            failedTestsCount > 0 &&
                            getErrorUI("Fail", failedTestsCount)}
                          {showRiskScoreTag && (
                            <div style={{ marginLeft: "5px", display: "flex" }}>
                              <Tag
                                data={{
                                  label: `Loan Risk Bucket: ${riskScores?.riskBucket ??
                                    "-"} (${riskScores?.totalAggregateRisk ??
                                    "-"})`,
                                  onClick: openCreditRiskPopup,
                                  style: {
                                    backgroundColor: riskBucketColor
                                    // cursor: "pointer"
                                  }
                                }}
                              />
                            </div>
                          )}
                          {showCustodyDocs && <CustodyDocFlags />}
                          {(isEvaluationPanelVisible ||
                            validateFormClicked.validate) && (
                              <div>
                                <DuplicateLoanError
                                  properties={loanDetails.propertyDetails}
                                />
                              </div>
                            )}
                        </Grid>
                      )}
                      <div>
                        <AppBar
                          position="static"
                          color="default"
                          className="communicationsTabHeader"
                        >
                          <TabList
                            onChange={handleChange}
                            aria-label="loan tabs list"
                            indicatorColor="primary"
                            textColor="primary"
                            variant="scrollable"
                            scrollButtons="auto"
                            className="loans-tab-container"
                            style={{ minHeight: 43 }}
                          >
                            {filteredTabs.map(
                              (item: any, index: any) => (
                                <Tab
                                  label={item.title}
                                  value={item.id}
                                  key={item.id}
                                  icon={getErrorIndigator(item, index) ? <WarningIcon
                                    className={classes.validateErrorFormClicked}
                                    htmlColor="#f75676"
                                  /> : <></>}
                                  iconPosition="end"
                                  style={{ textTransform: "none", paddingBottom: "0px" }}
                                />
                              )
                            )}
                          </TabList>
                        </AppBar>
                      </div>
                    </Container>
                  </Grid>
                  {filteredTabs.map((item: any) => (
                    <TabPanel style={{ paddingTop: "5px" }} value={item.id} key={item.id}>
                      <Suspense fallback={<Loader />}>
                        {getComponent()}
                      </Suspense>
                    </TabPanel>
                  ))}
                </Grid>
              )}
            </TabContext>
            {!isEvaluationPanelVisible ? <ResolutionNotSupported /> : <></>}
          </Grid>
          <DisplayNote loanId={loanId} />
        </SuspenseWithFallBack>
      </CreditLineLoanViewContext.Provider>
    </>
  );
}

// function TabPanel(props: TabPanelProps) {
//   const { children, value, index, ...other } = props;
//   return (
//     <div
//       role="tabpanel"
//       hidden={value !== index}
//       id={`scrollable-auto-tabpanel-${index}`}
//       aria-labelledby={`scrollable-auto-tab-${index}`}
//       // eslint-disable-next-line react/jsx-props-no-spreading
//       {...other}
//     >
//       {value === index && <Box>{children}</Box>}
//     </div>
//   );
// }
