/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
import React, { useEffect } from "react";
import jwt_decode from "jwt-decode";
import axios from "axios"; // eslint-disable-line import/no-extraneous-dependencies
// import { push } from "connected-react-router";
import { useNavigate } from "react-router-dom";
import { publicIpv4 } from "public-ip";
import { StringDecoder } from "string_decoder";
import { getConfig } from "../config/config";
import {
  getCookie,
  removeCookie,
  setCookie,
  deleteAllCookies
} from "../utils/cookies";
import { detectBrowser } from "../utils/utils";
import { getToken } from "../network/apiService";
import { ObjectType } from "../masterView/common";
import eventEmitter from '../eventEmitter';
// const url = window.location.href;

// Time in minutes to refetch token before the expire time
const refetchTokenBefore: number = 2;

const configParams = getConfig();
let person_id: StringDecoder;

export const checkIfTokenExpired = () => {
  const currentDateInMilliSeconds = new Date().getTime();
  const expirationDateinMilliSeconds = getCookie("expires_in");
  return currentDateInMilliSeconds > Number(expirationDateinMilliSeconds);
};

const getTokenExpiry = (expires_in: number | string) => {
  const currentDate = new Date();
  currentDate.setSeconds(
    // Modify refetch token expiry date
    currentDate.getSeconds() + Number(expires_in) - refetchTokenBefore * 60
  );
  return currentDate.getTime();
};

export function setTokenExpiry(token: string) {
  const parsedToken: any = token && jwt_decode(token);
  const { exp } = parsedToken;
  localStorage.setItem("expires_in", exp);
}

export const refreshToken = () => {
  return axios({
    method: "POST",
    url: `${configParams.keyClockUrl}/token`,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Basic ${configParams.clientId}`
    },
    params: {
      grant_type: "refresh_token",
      refresh_token: getCookie("refresh_token")
    },
    data: {}
  });
};

export const setRefreshDataInCookie = (result: any) => {
  if (result.status !== 200) return;
  setCookie("isUserLoggedIn", "true");
  setCookie("tokenFetched", "true");
  setCookie(
    "access_token",
    result.data.access_token,
    configParams.appraisalDashboardUrl
  );
  // setCookie("access_token", result.data.access_token);
  setCookie("id_token", result.data.access_token);
  setCookie("box_id_token", result.data.access_token, configParams.boxRedirectUrl);
  setCookie(
    "refresh_token",
    result.data.refresh_token,
    configParams.appraisalDashboardUrl
  );
  setCookie("refresh_token", result.data.refresh_token);
  setTokenExpiry(result.data.access_token);
  setCookie(
    "id_token_expires_in",
    getTokenExpiry(result.data.expires_in),
    configParams.appraisalDashboardUrl
  );
  setCookie("id_token_expires_in", getTokenExpiry(result.data.expires_in));
  setCookie(
    "ad_access_token",
    result.data.access_token,
    configParams.appraisalDashboardUrl
  );
  setCookie(
    "ad_id_token",
    result.data.id_token,
    configParams.appraisalDashboardUrl
  );
  setCookie(
    "ad_refresh_token",
    result.data.refresh_token,
    configParams.appraisalDashboardUrl
  );
  setCookie(
    "ad_id_token_expires_in",
    getTokenExpiry(result.data.expires_in),
    configParams.appraisalDashboardUrl
  );
};


export const fetchRefreshToken = async () => {
  const refreshToken: any = getCookie("refresh_token");
  let formBody: any = [];
  const loginObject: ObjectType = {
      client_id: 'login',
      grant_type: "refresh_token",
      refresh_token: refreshToken,
  };
  Object.keys(loginObject).forEach((property) => {
      const encodedKey = encodeURIComponent(property);
      const encodedValue = encodeURIComponent(loginObject[property]);
      formBody.push(`${encodedKey}=${encodedValue}`);
  });
  formBody = formBody.join("&");
  const headers = {
      "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  };
  // const url = `${process.env.VITE_APP_REALMS}/token`;

  return getToken(formBody, headers);
};

export const AuthCallbackPage = () => {
  const navigate = useNavigate();
  setCookie("fetchingToken", false);

  async function generateToken() {
    try {
      const loginObject: any = {
        client_id: 'login',
        grant_type: "authorization_code",
        code: getCookie("code"),
        redirect_uri: `${getConfig().redirectUrl}callBack`,
      };
      let formBody: any = [];
      Object.keys(loginObject).forEach((property) => {
          const encodedKey = encodeURIComponent(property);
          const encodedValue = encodeURIComponent(loginObject[property]);
          formBody.push(`${encodedKey}=${encodedValue}`);
      });
      formBody = formBody.join("&");
      const headers = {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
      };
      const response: any = await getToken(formBody, headers);

      if (response?.data && response?.data?.access_token) {

        sessionStorage.clear();
        // localStorage.clear();
        deleteAllCookies();
        setCookie("isUserLoggedIn", "true");
        //id_token was used from wso2 now we integrated with keycloak we
        // will use access_token while setting id_token in cookie
        // setCookie("access_token", response.data.access_token);
        setCookie(
          "access_token",
          response.data.access_token,
          configParams.appraisalDashboardUrl
        );
        setCookie("id_token", response.data.access_token);
        setCookie(
          "box_id_token",
          response.data.access_token,
          configParams.boxRedirectUrl.replace("http://", "")
        );
        setCookie("id_token_expires_in", getTokenExpiry(response.data.expires_in));
        setCookie(
          "id_token_expires_in",
          getTokenExpiry(response.data.expires_in),
          configParams.appraisalDashboardUrl
        );
        setCookie("refresh_token", response.data.refresh_token);
        setCookie(
          "refresh_token",
          response.data.refresh_token,
          configParams.appraisalDashboardUrl
        );
        setCookie(
          "ad_access_token",
          response.data.access_token,
          configParams.appraisalDashboardUrl
        );
        setCookie(
          "ad_id_token",
          response.data.access_token,
          configParams.appraisalDashboardUrl
        );
        setCookie(
          "ad_refresh_token",
          response.data.refresh_token,
          configParams.appraisalDashboardUrl
        );
        setCookie(
          "ad_id_token_expires_in",
          getTokenExpiry(response.data.expires_in),
          configParams.appraisalDashboardUrl
        );
        setTokenExpiry(response.data.access_token);
        getAccessDetails();
        // await getCustomerData(response?.data?.access_token);
      } else {
        console.error("Invalid response data.");
      }
    } catch (error) {
      console.error("Error generating token:", error);
    }
  }

  useEffect(() => {
    const sessionToken: any = getCookie("id_token");
    const getToken = async () => {
      setCookie("fetchingToken", true);
      await generateToken();
      setCookie("fetchingToken", false);
    };
    if(getCookie("isUserLoggedIn") === "false") return;
    if (!sessionToken && getCookie("fetchingToken") !== "true") {
      getToken();
    } else {
      const isTokenExpired = checkIfTokenExpired();

      console.log(" is Token expoired ", isTokenExpired);
      if (isTokenExpired) {
        fetchRefreshToken()
          .then((res)=>{
            setRefreshDataInCookie(res);
            getAccessDetails();
          })
          .catch((error) => {
            
          })
        }
      }
  }, []);

  function getAccessDetails() {
    const id_token = getCookie("id_token");
    const token: any = id_token && jwt_decode(id_token);
    const { email, roles, name, given_name, family_name, partyId } = token;
    setCookie("firstName", given_name);
    setCookie("lastName", family_name);
    setCookie("email", email);
    setCookie("displayUserName", name);
    setCookie("person_id", partyId);
    eventEmitter.dispatchEvent(new Event('userLogin'));
    if (roles !== undefined && roles.length > 0) {
      getRoles(email);
    } else {
      navigate("/loans/list?tab=3");
    }
    //
  }

  const appRedirection = () => {
    const role: any = getCookie("roles");
    const refreshTokenId = getCookie("refresh_token");
    const privilegesArray: any = getCookie("privilages");
    const isDDReview = role.includes("DD_REVIEW") && !role.includes("LAT");
    if (privilegesArray.includes("view_workflow")) {
      navigate(`/home`);
    } else if (isDDReview) {
      // to run the box monitoring app
      window.location.href = `${configParams.boxRedirectUrl}/callBack?token=${refreshTokenId}`;
    } else {
      navigate(`/loans/list?tab=0`);
    }
  };

  async function getRoles(email: string) {
    const id_token = getCookie("id_token");
    const partInfo = await axios({
      method: "GET",
      url: `${getConfig().apiUrl}/customer/${email}`,
      headers: {
        Authorization: `Bearer ${id_token}`
      },
      data: {}
    });
    //  return result;
    setCookie("firstName", partInfo.data.firstName);
    setCookie("isTestParty", partInfo.data.isTestParty);
    setCookie("lastName", partInfo.data.lastName);
    const organisationInfo = await axios({
      method: "GET",
      url: `${getConfig().apiUrl}/customer/${partInfo.data.partyId}/roles`,
      headers: {
        Authorization: `Bearer ${id_token}`
      },
      data: {}
    });
    const roles = await axios({
      method: "GET",
      url: `${getConfig().apiUrl}/access/person/${
        partInfo.data.partyId
      }/access?accountId=${
        organisationInfo.data[0].accountId
      }&includePrivileges=true`,
      headers: {
        Authorization: `Bearer ${id_token}`
      },
      data: {}
    });
    const orgData = await axios({
      method: "GET",
      url: `${getConfig().apiUrl}/customer/${
        organisationInfo.data[0].accountId
      }`,
      headers: {
        Authorization: `Bearer ${id_token}`
      },
      data: {}
    });
    setCookie("organizationName", orgData.data.accountName);
    setCookie("org_id", organisationInfo.data[0].accountId);
    person_id = organisationInfo.data[0].personId;
    const rolesObj = roles.data.data.roles;
    const rolesArray: any[] = [];
    const privilegesArray: any[] = [];
    Object.keys(rolesObj).forEach(function (key) {
      rolesArray.push(rolesObj[key].roleName);
      if (rolesObj[key].privilages) {
        Object.keys(rolesObj[key].privilages).forEach(function (index) {
          if (
            !privilegesArray.includes(
              rolesObj[key].privilages[index].privilageName
            )
          ) {
            privilegesArray.push(rolesObj[key].privilages[index].privilageName);
          }
        });
      }
    });
    // const rolesArray = Object.values(roles.data.data.roles.roleName);
    setCookie("roles", JSON.stringify(rolesArray));
    setCookie("privilages", JSON.stringify(privilegesArray));
    if (roles.data.data.roles[0].roleName.includes("ORIGINATOR")) {
      setCookie("loginFlow", "originator");
    } else {
      setCookie("loginFlow", "LAT");
      const accountInfo = await axios({
        method: "POST",
        url: `${getConfig().apiUrl}/custom/LAT_LOAN_LIST/values/fetch`,
        headers: {
          Authorization: `Bearer ${id_token}`
        },
        data: {
          userId: person_id
        }
      });
      setCookie("account_ids", JSON.stringify(accountInfo.data));
      localStorage.setItem("account_ids", JSON.stringify(accountInfo.data));
    }
    removeCookie("view");
    // /    const clientIp: any = await publicIp.v4();
    const clientIp: any = await publicIpv4();
    const token: any = id_token && jwt_decode(id_token);
    const { partyId, sid, name, given_name, family_name } = token;
    const logData = {
      userPartyId: partyId,
      sessionId: sid || "",
      platform: "Toorak Platform",
      browser: detectBrowser(),
      ipAddress: clientIp,
      userName: name || "",
      firstName: given_name || "",
      lastName: family_name || "",
      date: new Date().toString()
    };
    try {
      await axios({
        method: "POST",
        url: `${getConfig().apiUrl}/access/audit/login`,
        headers: {
          Authorization: `Bearer ${id_token}`
        },
        data: logData
      });
    } catch (e) {
      console.log("something went wrong");
    }
    if (getCookie("privateRoute")) {
      const redirectRoute: any = getCookie("privateRoute");
      removeCookie("privateRoute");
      if (window.location.hostname !== "localhost") {
        window.location.replace(
          `https://${window.location.hostname}${redirectRoute}`
        );
      } else {
        window.location.replace(
          `http://${window.location.hostname}:3000${redirectRoute}`
        );
      }
    } else {
      appRedirection();
    }
  }
  // eslint-disable-next-line
  return (
    <>
      <div />
    </>
  );
};
