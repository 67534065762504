import { Grid } from "@mui/material";
import React, { useReducer } from "react";
import queryString from "query-string";
import {
  documentReviewInitialState,
  documentReviewReducer
} from "../create-loan/ttf-review/document-review/DocumentReviewLocalReducer";
import { PDFViewer } from "../create-loan/ttf-review/document-review/pdf-viewer/PDFViewer";
import { useParams } from "react-router-dom";
import {
  EXCEL_FILE_TYPE,
  PDF_FILE_TYPE
} from "../create-loan/ttf-review/constants";
import { downloadDocsType } from "../utils/constants";
import { clickAndDownloadFile } from "../create-loan/create-loan.action";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { getPresignedURLForDrawDocs } from "../network/apiService";

export const downloadMulipleDrawDocs = async (
  drawId: string,
  docId: string,
  topic: downloadDocsType,
  name: string,
  dispatch: any
) => {
  try {
    const response: any = await getPresignedURLForDrawDocs(
      drawId,
      Number(docId)
    );
    if (response?.status === 200) {
      if (topic === downloadDocsType.preview) {
        return Promise.resolve({
          data: { url: response.data?.downloadLinks?.[0].url }
        });
      } else {
        response?.data?.downloadLinks?.forEach(
          async (item: any, index: number) => {
            dispatch(showLoader());
            await clickAndDownloadFile(item?.url, name);
            dispatch(hideLoader());
          }
        );
        return Promise.resolve();
      }
    } else {
      dispatch(hideLoader());
      return Promise.reject();
    }
  } catch (e) {
    dispatch(hideLoader());
    // console.log("error while multiple download", e);
    return Promise.reject();
  }
};

export const DocumentViewerInNewTab = () => {
  const urlParams: any = queryString.parse(
    new URL(window.location.href).search
  );
  const data: any = urlParams.data ? JSON.parse(urlParams.data) : null;
  const initialState = documentReviewInitialState;
  if (data) {
    initialState[initialState.activeTab].activePageNumber = data?.[0].pageNo;
    initialState[initialState.activeTab].highlightCoordinates = data;
  }
  const [documentReviewState, documentReviewDispatch] = useReducer(
    documentReviewReducer,
    initialState
  );
  const { cacheKey, name, drawId, tcDoc } = useParams<any>() as any;

  console.log("file typee ", name);
  const fileType = name.split(".").pop();
  console.log("file typee ", fileType);

  return (
    <Grid
      container
      item
      xs={12}
      sm={12}
      md={12}
      lg={12}
      xl={12}
      style={{ height: "100vh", width: "100vw", position: "absolute" }}
    >
      {PDF_FILE_TYPE.includes(fileType) && (
        <PDFViewer
          state={documentReviewState}
          name={name}
          cacheKey={cacheKey}
          drawId={drawId}
          overRideDrawIdCheck={tcDoc === "TC"}
          documentReviewDispatch={documentReviewDispatch}
        />
      )}
    </Grid>
  );
};
