/* eslint-disable no-shadow */
import { LoanStage, publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig } from "../../config/config";
// import { getErrorCount } from "../../create-loan/exceptions-and-enquiries/ExceptionsAndEnquiries";
import { getErrorConfigObject } from "../../ErrorHandler/ErrorConfigData";
import { isLATUser, isILP } from "../../utils/AccessManagement";
import {
  ApproverActionsEnum,
  InquiryStatusEnum,
  InternalStatusEnum,
  ResponseTypeEnum
} from "../../utils/constants";
import { getCookie } from "../../utils/cookies";
import { setIntervalX, uuidV4 } from "../../utils/utils";
import { updateLastReadComment } from "../commentsSection/comment.action";
import { getPersonId } from "../dashboardSection/dashboard.action";
import {
  // clearLoader,
  hideLoader,
  showLoader
} from "../loaderAndException/loaderAndException.action";
// import {
//   FETCH_EXCEPTIONS_ERROR,
//   FETCH_EXCEPTIONS_LOADING,
//   SET_GRADE
// } from "../ILP/ILP.action";
import {
  // ExceptionsStore,
  addEditRecommendation,
  addEnquiry,
  addResponseToExceptionsArray,
  cancelActionByActionId,
  // deleteDocsFromFileList,
  deleteExceptionByResponse,
  deleteQuery,
  getExceptionEnquiriesLoading,
  getExceptionsByIdFailed,
  // getResponsesByChatSequenceId,
  postChatById,
  postResponseByChatSequenceId,
  postResponseByPersonPartyId,
  resetBulkSatisfyPayload,
  resetExceptionStore,
  setAskAQuestion,
  setCommunicationData,
  setEnquiryDocs,
  setExceptionByExceptionId,
  setExceptionsByIdSuccess,
  setInternalCommentsByChatSequenceId,
  setQuery,
  setResponsesByChatSequenceId,
  setResponsesByExceptionId,
  setSatisfyExceptions,
  setSelectedConditionClicked,
  // setSelectedConditionClicked,
  setWaiverByLoanId,
  setWaiverRequest,
  updateFetchedEventsCounts,
  initailCommentSoftUpdateLoan
} from "./ExceptionsReducer";
import { getChatsAndResponse } from "../../create-loan/create-loan.action";
import { clearLoader } from "../loaderAndException/loaderAndException.reducer";
import {
  fetchExceptionsError,
  fetchExceptionsLoading,
  setGradeRedux
} from "../ILP/ILP.reducer";
import { internalCommentSuccess } from "../commentsSection/comment.reducer";
import { initailCommentSoftUpdateBulk } from "../ApproverDashboard/ApproverDashboard.reducer";

// export const GET_EXCEPTION_ENQUIRIES_LOADING =
//   "GET_EXCEPTION_ENQUIRIES_LOADING";
// export const GET_EXCEPTIONS_BY_ID_SUCCESS = "GET_EXCEPTIONS_BY_ID_SUCCESS";
// export const GET_EXCEPTIONS_BY_ID_FAILED = "GET_EXCEPTIONS_BY_ID_FAILED";
// export const GET_EXCEPTIONS_RESPONSES_BY_ID = "GET_EXCEPTIONS_RESPONSES_BY_ID";
// export const SET_ASK_A_QUESTION = "SET_ASK_A_QUESTION";
// export const SET_SATISFY_EXCEPTIONS = "SET_SATISFY_EXCEPTIONS";
// export const SET_WAIVER_REQUEST = "SET_WAIVER_REQUEST";
// export const SET_CLOSE_CONDITION = "SET_CLOSE_CONDITION";
// export const SET_WAIVE_CONDITION = "SET_WAIVE_CONDITION";
// export const SET_RESCIND_CONDITION = "SET_RESCIND_CONDITION";
// export const UPDATE_ACTION_CLICKS = "UPDATE_ACTION_CLICKS";
// export const UPDATE_ACTIONS = "UPDATE_ACTIONS";
// export const POST_CHAT_BY_ID = "POST_CHAT_BY_ID";
// export const POST_CHAT_DETAILS_BY_CHAT_ID = "POST_CHAT_DETAILS_BY_CHAT_ID";
// export const POST_RESPONSE_BY_CHAT_SEQUENCE_ID =
//   "POST_RESPONSE_BY_CHAT_SEQUENCE_ID";
// export const SET_FILTERED_EXCEPTIONS = "SET_FILTERED_EXCEPTIONS";
// export const POST_RESPONSE_BY_PERSON_PARTY_ID =
//   "POST_RESPONSE_BY_PERSON_PARTY_ID";
// export const GET_RESPONSES_BY_EXCEPTION_ID = "GET_RESPONSES_BY_EXCEPTION_ID";
// export const GET_RESPONSES_BY_CHAT_SEQUENCE_ID =
//   "GET_RESPONSES_BY_CHAT_SEQUENCE_ID";
// export const GET_INTERNAL_COMMENTS_BY_CHAT_SEQUENCE_ID =
//   "GET_INTERNAL_COMMENTS_BY_CHAT_SEQUENCE_ID";
// export const CANCEL_ACTION_BY_ACTION_ID = "CANCEL_ACTION_BY_ACTION_ID";
// export const UPDATE_DOCS_IN_ACTIONS_CLICKS_BY_ACTION_ID =
//   "UPDATE_DOCS_IN_ACTIONS_CLICKS_BY_ACTION_ID";
// export const UPLOAD_EXCEL_LOAN_EXCEPTION_CHANGE =
//   "UPLOAD_EXCEL_LOAN_EXCEPTION_CHANGE";
// export const UPDATE_EXCEPTION_STATUS = "UPDATE_EXCEPTION_STATUS";
// export const UPDATE_FETCHED_EVENTS_COUNTS = "UPDATE_FETCHED_EVENTS_COUNTS";
// export const DELETE_DOCS_FROM_FILELIST = "DELETE_DOCS_FROM_FILELIST";
// export const DELETE_ENQUIRY_BY_ID = "DELETE_ENQUIRY_BY_ID";
// export const RESET_EXCEPTION_STORE = "RESET_EXCEPTION_STORE";
// export const RESET_EXCEPTION_RESPONSE = "RESET_EXCEPTION_RESPONSE";
// export const GET_WAIVER_BY_LOAN_ID = "GET_WAIVER_BY_LOAN_ID";
// export const UPDATE_DOC_BY_CHAT_SEQUENCE_ID = "UPDATE_DOC_BY_CHAT_SEQUENCE_ID";
// export const UPDATE_RESPONSE_POST_SUBMIT = "UPDATE_RESPONSE_POST_SUBMIT";
// export const DELETE_EXCEPTION_BY_RESPONSE = "DELETE_EXCEPTION_BY_RESPONSE";
// export const GET_EXCEPTION_BY_EXCEPTION_ID = "GET_EXCEPTION_BY_EXCEPTION_ID";
// export const DELETE_ACTION = "DELETE_ACTION";
// export const DELETE_WAIVER_BY_ID = "DELETE_WAIVER_BY_ID";
// export const UPDATE_WAIVER_APPROVAL_STATUS = "UPDATE_WAIVER_APPROVAL_STATUS";
// export const POST_ENQUIRY = "POST_ENQUIRY";
// export const ADD_ENQUIRY = "ADD_ENQUIRY";
// export const UPDATE_EXCEPTION = "UPDATE_EXCEPTION";
// export const ADD_EDIT_RECOMMENDATION = "ADD_EDIT_RECOMMENDATION";
// export const CONVERT_INQUIRY = "CONVERT_INQUIRY";
// export const UPDATE_WAIVER_IN_REDUX = "UPDATE_WAIVER_IN_REDUX";
// export const UPDATE_WAIVER_DOC_BY_WAIVER_ID = "UPDATE_WAIVER_DOC_BY_WAIVER_ID";
// export const UPDATE_DOCS_IN_ENQUIRY_BY_ID = "UPDATE_DOCS_IN_ENQUIRY_BY_ID";
// export const UPDATE_UNREAD_COUNTS = "UPDATE_UNREAD_COUNTS";
// export const GET_ENQUIRY_DOCS = "GET_ENQUIRY_DOCS";
// export const GET_QUERY = "GET_QUERY";
// export const DELETE_QUERY = "DELETE_QUERY";
// export const GET_COMMUNICATION_DATA = "GET_COMMUNICATION_DATA";
// export const COMPLETE_INQUIRY_STATUS = "COMPLETE_INQUIRY_STATUS";
// export const DISABLE_CONDT_RESET_FILTER = "DISABLE_CONDT_RESET_FILTER";
// export const SET_FILTERS = "SET_FILTERS";
// export const SET_FILTERED_EXCEPTION = "SET_FILTERED_EXCEPTION";

// //const personPartyId: string | any = getCookie("person_id");
// export const ADD_RESPONSE_TO_EXCEPTIONS_ARRAY =
//   "ADD_RESPONSE_TO_EXCEPTIONS_ARRAY";
// export const SET_SELECTED_CONDITION = "SET_SELECTED_CONDITION";
// export const ADD_TO_BULK_SATISFY_PAYLOAD = "ADD_TO_BULK_SATISFY_PAYLOAD";
// export const DELETE_FROM_BULK_SATISFY_PAYLOAD =
//   "DELETE_FROM_BULK_SATISFY_PAYLOAD";
// export const RESET_BULK_SATISFY_PAYLOAD = "RESET_BULK_SATISFY_PAYLOAD";

export function getMultiFetchQuery(data: any, loanId: string) {
  const obj: any = [];
  if (data && data instanceof Array) {
    data.forEach((item: any) => {
      const exception = {
        parentId: loanId,
        resourceKey: "EXCEPTION",
        resourceValue: item?.exceptionId
      };
      obj.push(exception);
    });
  }
  return obj;
}

// export function updateConditionsFilters(
//   visiblitySelected: string[],
//   waiverSelected: string[],
//   satisfiedSelected: string[]
// ): any {
//   return async (disaptch: any) => {
//     disaptch({
//       type: SET_FILTERS,
//       payload: {
//         visiblitySelected,
//         waiverSelected,
//         satisfiedSelected
//       }
//     });
//   };
// }

// export const disableCondtResetFilter = (val: boolean): any => {
//   return (dispatch: any) => {
//     dispatch({
//       type: DISABLE_CONDT_RESET_FILTER,
//       payload: {
//         disableCondtReset: val
//       }
//     });
//   };
// };

// export function setFilteredExceptionsArr(exceptions: any): any {
//   return async (disaptch: any) => {
//     disaptch({
//       type: SET_FILTERED_EXCEPTION,
//       payload: {
//         exceptions
//       }
//     });
//   };
// }

export function getExceptionsAndEnquiriesByLoanId(
  loanId: string,
  loanStage: string,
  latestComment = false,
  loader?: boolean
): any {
  return async (dispatch: any) => {
    try {
      if (loader) dispatch(showLoader(true));
      let url = `${
        getConfig().apiUrl
      }/aggregate/exceptionservice/${loanId}?loanStage=${loanStage}&limit=200&includeChat=true&type=internal,external`;
      if (latestComment) {
        url += "&latestComment=true";
      }
      const response = await publicClient.get(url);
      // if (filteredExceptions) {
      //
      //   const filterExceptions: any = response.data.exceptions.exceptions;

      //   response.data.exceptions.exceptions = filterExceptions.filter(
      //     (item: any) => {
      //       return filteredExceptions.includes(item.exceptionId);
      //     }
      //   );
      // }
      if (!isILP()) {
        dispatch(await multiFetch(response.data.exceptions.exceptions, loanId));
      }
      if (response) {
        // dispatch({
        //   type: GET_EXCEPTIONS_BY_ID_SUCCESS,
        //   payload: {
        //     exceptionResults: response.data
        //   }
        // });
        dispatch(setExceptionsByIdSuccess({ exceptionResults: response.data }));
        if (loader) dispatch(hideLoader(true));
        // if (!isILP()) {
        //   const counts: any[] =
        //     getErrorCount(response.data.exceptions.exceptions) || [];
        //   dispatch(updateErrorCount([counts[0], counts[1]], loanId));
        // }
      } else {
        dispatch(getExceptionEnquiriesLoading());
        if (loader) dispatch(hideLoader(true));
      }
    } catch (err) {
      const e: any = err;
      if (loader) dispatch(hideLoader(true));
      console.error(e);
      dispatch(getExceptionsByIdFailed());
      // dispatch({
      //   type: GET_EXCEPTIONS_BY_ID_FAILED,
      //   error: {
      //     message: e.message
      //   }
      // });
    }
  };
}
export function setSatisfyExceptionsClicked(clicked: boolean, id: string): any {
  return async (dispatch: any) => {
    dispatch(setSatisfyExceptions({ clicked, id }));
  };
}
export function setAskAQuestionClicked(clicked: boolean, id: string): any {
  return async (dispatch: any) => {
    dispatch(setAskAQuestion({ clicked, id }));
  };
}

// export function setCloseConditionClicked(clicked: boolean, id: string): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_CLOSE_CONDITION,
//       payload: { clicked, id }
//     });
//   };
// }

// export function setWaiveConditionClicked(clicked: boolean, id: string): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_WAIVE_CONDITION,
//       payload: { clicked, id }
//     });
//   };
// }

// export function setRescindConditionClicked(clicked: boolean, id: string): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: SET_RESCIND_CONDITION,
//       payload: { clicked, id }
//     });
//   };
// }

export function setSelectedCondition(selectedConditionsArray: any): any {
  return async (dispatch: any) => {
    dispatch(setSelectedConditionClicked({ selectedConditionsArray }));
  };
}
export function setWaiverRequestedClicked(clicked: boolean, id: string): any {
  return async (dispatch: any) => {
    dispatch(setWaiverRequest({ clicked, id }));
  };
}

export const getCompensatingFactor = async (
  loanId: any,
  loanStage: any,
  id: any,
  cardType: any
) => {
  const url = `${
    getConfig().apiUrl
  }/aggregate/loans/${loanId}/resource/${id}?resourceType=${cardType}&secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}&loanStage=${loanStage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .get(url)
      .then((res: any) => {
        resolve(res.data);
      })
      .catch(reject);
  });
};

export const getViewMoreDetails: any = (
  loanId: any,
  loanStage: any,
  visibility: any,
  id: any,
  cardType: any,
  onlyExternal: boolean = false,
  setViewMore?: boolean
) => {
  return async (dispatch: any) => {
    try {
      let scrollPosHTML = 0;
      const htmlEle = document.getElementsByTagName("html")[0];
      scrollPosHTML = htmlEle.scrollTop;
      dispatch(showLoader(true));
      const visibilityVal = onlyExternal
        ? "external"
        : isLATUser()
        ? "internal,external"
        : visibility;
      const url = `${
        getConfig().apiUrl
      }/aggregate/loans/${loanId}/resource/${id}?resourceType=${cardType}&secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}&includeHistory=true&loanStage=${loanStage}&visibility=${visibilityVal}`;
      const response = await publicClient.get(url);
      dispatch(
        addResponseToExceptionsArray({ data: response.data, id, setViewMore })
      );
      dispatch(hideLoader(true));
      setIntervalX(
        () => {
          const loaderEle: any = document.querySelector(
            '[data-testid="loader"]'
          );
          if (loaderEle?.style?.opacity === "1") {
            htmlEle.scrollTop = scrollPosHTML;
          }
        },
        250,
        14
      );
    } catch (err) {
      const e: any = err;
      console.error(e);
      dispatch(hideLoader(true));
    }
  };
};

// export function setActionClicks(type: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_ACTION_CLICKS,
//       payload: {
//         type
//       }
//     });
//   };
// }
export function postChatByIdAndUpdateResponseType(
  loanStage: string,
  exceptionId: string,
  type: string,
  loanId: string,
  visibility: string,
  actionId: string,
  actionType?: string,
  hasDocuments?: boolean,
  lineText?: any,
  chatUploadCallBack?: any,
  partyId?: string,
  activeInteractionsModal?: any,
  waiverId?: any,
  condtType?: any,
  from?: any // adding this to stop the redundant calls for approver sdd while asking a question
): any {
  return async (dispatch: any) => {
    try {
      const personPartyId: string | any = getCookie("person_id");
      const showVisibility =
        visibility !== null && visibility !== undefined
          ? visibility
          : "external";
      dispatch(getExceptionEnquiriesLoading());
      const url = `${
        getConfig().apiUrl
      }/chatservice/EXCEPTION/${exceptionId}/chats?secondaryIdentifierName=${type}&secondaryIdentifierValue=${loanId}`;
      const response = await publicClient.post(url, {});
      const chatIdObject = response.data;
      // dispatch({
      //   type: POST_CHAT_BY_ID,
      //   payload: {
      //     exceptionId,
      //     chatIdObject,
      //     actionId
      //   }
      // });

      dispatch(postChatById({ chatIdObject, actionId }));
      if (chatIdObject) {
        const chatSequenceId = chatIdObject.id;
        try {
          const requestObject = {
            exceptionResponseId: chatIdObject.chatSequenceId,
            chatSequenceId,
            responseType:
              actionType && actionType.replace(/ /g, "_").toUpperCase()
          };
          const responseType = await publicClient.post(
            `${
              getConfig().apiUrl
            }/exceptionservice/${loanId}/exceptions/${exceptionId}/responses?loanStage=${loanStage}&visibility=${showVisibility}`,
            [requestObject]
          );
          const responseTypeObject = responseType.data[0];
          // dispatch({
          //   type: POST_RESPONSE_BY_CHAT_SEQUENCE_ID,
          //   payload: {
          //     responseTypeObject,
          //     actionId
          //   }
          // });

          dispatch(
            postResponseByChatSequenceId({ responseTypeObject, actionId })
          );

          if (responseType) {
            try {
              const requestObjectTextArea = {
                partyId: partyId || personPartyId,
                lineText: !lineText || lineText === "" ? "-" : lineText,
                hasDocuments
              };
              const comment = await publicClient.post(
                `${getConfig().apiUrl}/chatservice/${chatSequenceId}/lines`,
                requestObjectTextArea
              );
              if (hasDocuments) {
                chatUploadCallBack(
                  chatSequenceId,
                  comment?.data?.chatLineSequenceId,
                  responseTypeObject?.responseType
                ).then(() => {
                  if (!from) {
                    dispatch(
                      getChatsAndResponse(
                        loanId,
                        loanStage,
                        showVisibility,
                        exceptionId,
                        "Condition",
                        condtType,
                        waiverId
                      )
                    );
                  }
                });
              } else {
                if (activeInteractionsModal) {
                  dispatch(
                    getChatsAndResponse(
                      loanId,
                      loanStage,
                      showVisibility,
                      exceptionId,
                      "Condition",
                      condtType,
                      waiverId
                    )
                  );
                }
                if (!from) {
                  dispatch(
                    getResponsesByExceptionId(
                      loanId,
                      loanStage,
                      exceptionId,
                      personPartyId
                    )
                  );
                }
                dispatch(hideLoader(true));
              }
            } catch (err) {
              console.log("error in responseType", err);
              dispatch(hideLoader(true));
            }
          }
        } catch (err) {
          console.log("error chatIdObject ", err);
          dispatch(hideLoader());
        }
      }
    } catch (err) {
      console.log("error chatID", err);
      dispatch(hideLoader());
    }
  };
}
export function postChatBodyByChatId(
  actionId: string,
  lineText: string,
  hasDocuments: boolean
): any {
  return async (dispatch: any) => {
    const personPartyId: string | any = getCookie("person_id");
    // dispatch({
    //   type: POST_RESPONSE_BY_PERSON_PARTY_ID,
    //   payload: {
    //     actionId,
    //     lineText,
    //     personPartyId,
    //     hasDocuments
    //   }
    // });
    dispatch(
      postResponseByPersonPartyId({
        actionId,
        lineText,
        personPartyId,
        hasDocuments
      })
    );
  };
}
export function getResponsesByExceptionId(
  loanId: string,
  loanStage: any,
  exceptionId: string,
  partyId?: string,
  loader?: boolean,
  isCountNotRequired?: boolean
): any {
  return async (dispatch: any) => {
    try {
      const personPartyId: string | any = getCookie("person_id");
      if (!exceptionId) return null;
      const url = `${
        getConfig().apiUrl
      }/exceptionservice/${loanId}/exceptions/${exceptionId}/responses?loanStage=${loanStage}`;
      const responses = await publicClient.get(url);
      if (responses.data.length === 0) return null;
      dispatch(showLoader());
      // dispatch({
      //   type: GET_RESPONSES_BY_EXCEPTION_ID,
      //   payload: {
      //     responses: responses.data
      //   }
      // });

      dispatch(setResponsesByExceptionId({ responses: responses.data }));
      if (responses && responses.data.length > 0) {
        responses.data.map(async (item: any) => {
          try {
            const chatLinesUrl = `${getConfig().apiUrl}/aggregate/chatservice/${
              item.chatSequenceId
            }/lines`;
            const prommiseArr = [];
            const unreadCountUrl = `${getConfig().apiUrl}/chatservice/${
              personPartyId || partyId
            }/chats?chatIds=${item.chatSequenceId}&type=unread`;
            const promise1 = publicClient.get(chatLinesUrl);
            prommiseArr.push(promise1);
            const promise2 = publicClient.get(unreadCountUrl);
            if (isCountNotRequired) {
              prommiseArr.push(promise2);
            }
            (Promise as any)
              .allSettled(prommiseArr)
              .then((response: any) => {
                if (response[0]?.value?.data || response[1]?.value?.data) {
                  // dispatch({
                  //   type: GET_RESPONSES_BY_CHAT_SEQUENCE_ID,
                  //   payload: {
                  //     chatLines: response[0]?.value?.data,
                  //     chatSequenceId: item.chatSequenceId,
                  //     count: response[1]?.value?.data[0]?.count || 0,
                  //     filter: false
                  //   }
                  // });

                  dispatch(
                    setResponsesByChatSequenceId({
                      chatLines: response[0]?.value?.data,
                      chatSequenceId: item.chatSequenceId,
                      count: response[1]?.value?.data[0]?.count || 0,
                      filter: false
                    })
                  );
                  if (item.chatSequenceId) {
                    console.log(
                      "updateLastReadComment exception action",
                      item.chatSequenceId
                    );
                    updateLastReadComment(item.chatSequenceId, personPartyId);
                  }
                } else {
                  dispatch(hideLoader());
                }
              })
              .catch((err: any) => {
                console.log("err in promise", err);
              });
            if (isLATUser()) {
              // eslint-disable-next-line no-shadow
              const chatLinesUrl = `${
                getConfig().apiUrl
              }/aggregate/chatservice/${
                item.chatSequenceId
              }/lines?type=internal`;
              const response: any = await publicClient.get(chatLinesUrl);
              if (response) {
                // dispatch({
                //   type: GET_INTERNAL_COMMENTS_BY_CHAT_SEQUENCE_ID,
                //   payload: {
                //     chatLines: response.data,
                //     documents: response.data.documents,
                //     chatSequenceId: item.chatSequenceId,
                //     filter: false
                //   }
                // });
                dispatch(
                  setInternalCommentsByChatSequenceId({
                    chatLines: response.data,
                    // documents: response.data.documents,
                    chatSequenceId: item.chatSequenceId,
                    filter: false
                  })
                );
                if (response.data.chatLines.length > 0) {
                  // for Approver view commentBlock
                  dispatch(
                    internalCommentSuccess({
                      // type: INTERNAL_COMMENT_SUCCESS,
                      // payload: {
                      buckets: response.data,
                      chatId: item.chatSequenceId
                      // type: commentData.type,
                      // hostId: commentData.hostId
                      // }
                    })
                  );
                }
              }
            }
            dispatch(hideLoader(true));
          } catch (err) {
            console.log("err", err);
            dispatch(hideLoader(true));
          }
        });
      } else if (loader) {
        dispatch(hideLoader(true));
      }
    } catch (err) {
      console.log("err in response by exception id", err);
      dispatch(hideLoader(true));
    }
  };
}
export function getExceptionByExceptionId(
  loanStage: string,
  loanId: string,
  exceptionId: string,
  editPopup?: any,
  visibilityType?: any
): any {
  return async (dispatch: any) => {
    let url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/${loanId}/exceptions/${exceptionId}/exception?loanStage=${loanStage}`;
    if (isILP()) {
      const visibility = visibilityType ?? "internal";
      url += `&limit=200&includeChat=true&type=${visibility}&includeDocuments=true`;
    }
    const enquiryDocUrl = `${
      getConfig().apiUrl
    }/documentdetails/EXCEPTION/${exceptionId}/documents`;
    try {
      if (!exceptionId) return null;
      dispatch(showLoader(true));
      const exceptionResponse: any = await publicClient.get(url);
      const exceptionDocs: any = await publicClient.get(enquiryDocUrl);
      if (exceptionResponse && !editPopup) {
        // dispatch({
        //   type: GET_EXCEPTION_BY_EXCEPTION_ID,
        //   payload: {
        //     exceptionResults: exceptionResponse.data,
        //     docs: exceptionDocs.data
        //   }
        // });
        dispatch(
          setExceptionByExceptionId({
            exceptionResults: exceptionResponse.data,
            docs: exceptionDocs.data
          })
        );

        dispatch(hideLoader(true));
      } else {
        // dispatch({
        //   type: GET_ENQUIRY_DOCS,
        //   payload: {
        //     docs: exceptionDocs.data
        //   }
        // });
        dispatch(
          setEnquiryDocs({
            docs: exceptionDocs.data
          })
        );
        dispatch(hideLoader(true));
      }
      dispatch(clearLoader());
    } catch (e) {
      console.error(e);
      dispatch(hideLoader(true));
    }
  };
}
export function getChatLinesByChatSequenceId(
  chatSequenceId: string,
  partyId?: string
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const prommiseArr = [];
      const chatLinesUrl = `${
        getConfig().apiUrl
      }/aggregate/chatservice/${chatSequenceId}/lines`;
      const promise1 = await publicClient.get(chatLinesUrl);
      const url = `${
        getConfig().apiUrl
      }/chatservice/${partyId}/chats?chatIds=${chatSequenceId}&type=unread`;
      const promise2 = await publicClient.get(url);
      prommiseArr.push(promise1);
      if (promise2.data.length > 0) {
        prommiseArr.push(promise2);
      }
      Promise.all(prommiseArr)
        .then((response) => {
          if (response) {
            // dispatch({
            //   type: GET_RESPONSES_BY_CHAT_SEQUENCE_ID,
            //   payload: {
            //     chatLines: response[0]?.data,
            //     documents: response[0]?.data?.documents,
            //     chatSequenceId,
            //     count: response[1]?.data[0]?.count || 0,
            //     filter: true
            //   }
            // });
            dispatch(
              setResponsesByChatSequenceId({
                chatLines: response[0]?.data,
                documents: response[0]?.data?.documents,
                chatSequenceId,
                count: response[1]?.data[0]?.count || 0,
                // count: response[1]?.data[0]?.count || 0, //removing count as it is duplicate data, commentStore.comments.count
                filter: true
              })
            );
          }
        })
        .catch((err) => {
          console.log("err in promise get chatlines url", err);
        });
      if (isLATUser()) {
        // eslint-disable-next-line no-shadow
        const chatLinesUrl = `${
          getConfig().apiUrl
        }/aggregate/chatservice/${chatSequenceId}/lines?type=internal`;
        const response: any = await publicClient.get(chatLinesUrl);
        if (response) {
          // dispatch({
          //   type: GET_INTERNAL_COMMENTS_BY_CHAT_SEQUENCE_ID,
          //   payload: {
          //     chatLines: response.data,
          //     documents: response.data.documents,
          //     chatSequenceId,
          //     filter: true
          //   }
          // });
          dispatch(
            setInternalCommentsByChatSequenceId({
              chatLines: response.data,
              // documents: response.data.documents,
              chatSequenceId,
              filter: true
            })
          );
        }
      }
      dispatch(hideLoader());
    } catch (e) {
      console.error(e);
    }
  };
}
export function deleteResponseByResponseId(
  loanStage: string,
  loanId: string,
  exceptionId: string,
  reponseId: string,
  chatSequenceId: string
): any {
  return async (dispatch: any) => {
    try {
      const url = `${
        getConfig().apiUrl
      }/exceptionservice/${loanId}/exceptions/${exceptionId}/responses/${reponseId}?loanStage=${loanStage}`;
      await publicClient.delete(url);
      dispatch(
        deleteExceptionByResponse({
          chatSequenceId
        })
      );
    } catch (e) {
      console.error("deleteResponseByResponseId", e);
    }
  };
}
export function getWaiverByLoanId(
  loanId: string,
  loanStage: LoanStage,
  waiverId?: string,
  files?: any,
  exceptions?: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    let waiverRequestUrl = `${
      getConfig().apiUrl
    }/aggregate/waiverservice/LOAN/${loanId}/waiver?secondaryIdentifierName=LOAN_STAGE&secondaryIdentifierValue=${loanStage}`;
    waiverRequestUrl =
      exceptions && exceptions.length
        ? `${waiverRequestUrl}&includeHistory=true&excludeDetails=true&waiverType=Exception&waiverKeys=${exceptions.join()}`
        : waiverRequestUrl;
    const waiverRequestDetails = await publicClient.get(waiverRequestUrl);
    if (!waiverId) {
      // dispatch({
      //   type: GET_WAIVER_BY_LOAN_ID,
      //   payload: {
      //     waiver: waiverRequestDetails.data,
      //     tempWaiver: [],
      //     documents: [],
      //     waiverId
      //   }
      // });
      dispatch(
        setWaiverByLoanId({
          waiver: waiverRequestDetails.data,
          tempWaiver: [],
          documents: []
          // waiverId
        })
      );
      dispatch(hideLoader());
    }
    const promises: any = [];
    let waiverDocUrl: any = "";
    const tempWaiver: any[] | any = [];
    if (waiverId) {
      waiverRequestDetails?.data.waivers.map((item: any) => {
        if (item && item?.waiverKeys && item?.waiverKeys[0] === waiverId) {
          waiverDocUrl = `${getConfig().apiUrl}/documentdetails/WAIVER/${
            item.waiverId
          }/documents`;
          tempWaiver.push(item);
          promises.push(publicClient.get(waiverDocUrl));
        }
        // if (waiverDocUrl) {
        // promises.push(publicClient.get(waiverDocUrl));
        // }
      });
      Promise.all(promises)
        .then((docs: any) => {
          if (docs) {
            // dispatch({
            //   type: GET_WAIVER_BY_LOAN_ID,
            //   payload: {
            //     waiver: waiverRequestDetails.data,
            //     tempWaiver,
            //     documents: docs,
            //     waiverId
            //   }
            // });
            dispatch(
              setWaiverByLoanId({
                waiver: waiverRequestDetails.data,
                tempWaiver,
                documents: docs
                // waiverId
              })
            );
            dispatch(hideLoader());
            dispatch(hideLoader(true));
          } else {
            dispatch(hideLoader());
            dispatch(hideLoader(true));
          }
        })
        .catch((err: any) => {
          dispatch(hideLoader(true));
          dispatch(hideLoader());
          console.log("err in promise", err);
        });
    }
  };
}
// export function updateWaiverInRedux(waiverDetails: string): any {  //not getting used
//   return async (dispatch: any) => {
//     try {
//       dispatch({
//         type: UPDATE_WAIVER_IN_REDUX,
//         payload: {
//           waiverDetails
//         }
//       });
//     } catch (e) {
//       console.error("update waiver error", e);
//     }
//   };
// }
// export function exceptionsWaiverFromRedux(waiverId: string): any {
//   return async (dispatch: any) => {
//     try {
//       dispatch({
//         type: DELETE_WAIVER_BY_ID,
//         payload: {
//           waiverId
//         }
//       });
//     } catch (e) {
//       console.error("deleteResponseByWaiver Id", e);
//     }
//   };
// }
// export function postDocumentByChatSequenceId(
//   loanId: string,
//   loanStage: string,
//   chatSequenceId: string,
//   chatLineSequenceId: string,
//   fileDetail: any,
//   exceptionId?: any
// ) {
//   return async () => {
//     const header = {
//       headers: { "Content-Type": "text/plain" }
//     };
//     const personPartyId: string | any = getCookie("person_id");
//     if (fileDetail?.length) {
//       fileDetail.forEach((item: any) => (item.chatDocument = "true"));
//     }
//     const url = `${
//       getConfig().apiUrl
//     }/docs/CHAT/${chatSequenceId}/documents?secondary_identifier_name=CHAT_SEQUENCE&secondary_identifier_value=${chatLineSequenceId}`;
//     const response = await publicClient.post(url, fileDetail, header);
//     if (response)
//       getResponsesByExceptionId(loanId, loanStage, exceptionId, personPartyId);
//   };
// }
export function uploadDocToBoxCom(
  loanId: string,
  exceptionId: string,
  responseType: string
): any {
  return async () => {
    const header = {
      headers: { "Content-Type": "text/plain" }
    };
    const url = `${
      getConfig().apiUrl
    }/documentaggregatorservice/${loanId}/${exceptionId}/uploadexception?exceptionResponseType=${responseType}`;
    await publicClient.post(url, header);
  };
}
export function postApproveException(
  exceptionResult: any,
  loanId: string,
  loanStage: string,
  exceptionId: string,
  approvalType?: string,
  comment?: string,
  approvalStatus?: string,
  partyId?: string
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    const header = {
      headers: { "Content-Type": "text/plain" }
    };
    const personPartyId: string | any = getCookie("person_id");
    const url = `${
      getConfig().apiUrl
    }/exceptionservice/${loanId}?loanStage=${loanStage}`;
    // eslint-disable-next-line no-multi-assign
    const requestBodyToApprove = {
      exceptions: [
        {
          ExceptionId: exceptionResult.exceptionId,
          loanId,
          loanStage,
          description: exceptionResult.description,
          status: approvalType,
          type: exceptionResult.type,
          name: "",
          externalExceptionId: exceptionResult.externalExceptionId,
          comment,
          initialComment: exceptionResult.initialComment,
          conclusionComment: exceptionResult.conclusionComment,
          clientResponse: exceptionResult.clientResponse,
          exceptionCategory: "",
          exceptionSubCategory: "",
          ddLastUpdate: "",
          tags: exceptionResult.tags,
          response: [],
          statuses: [
            {
              status: approvalStatus,
              comments: comment,
              created_by: personPartyId !== null ? personPartyId : partyId
            }
          ]
        }
      ],
      grades: []
    };
    const response = await publicClient.put(url, requestBodyToApprove, header);
    if (response) {
      // dispatch({
      //   type: UPDATE_EXCEPTION_STATUS,
      //   payload: {
      //     status: approvalType
      //   }
      // });
      dispatch(hideLoader());
      dispatch(
        getExceptionsAndEnquiriesByLoanId(loanId, loanStage, false, true)
      );
      if (exceptionResult.loanId) {
        dispatch(getExceptionByExceptionId(loanStage, loanId, exceptionId));
      }
    }
  };
}
export function cancelAction(
  exceptionId: string,
  actionId: string,
  actionType?: string
): any {
  return async (dispatch: any) => {
    // dispatch({
    //   type: CANCEL_ACTION_BY_ACTION_ID,
    //   payload: {
    //     exceptionId,
    //     actionId,
    //     actionType
    //   }
    // });
    dispatch(
      cancelActionByActionId({
        exceptionId,
        actionId,
        actionType
      })
    );
    dispatch(hideLoader());
  };
}
// export function deleteUploadedDocumentException(
//   file: any,
//   actionId: string
// ): any {
//   // const { docsId } = file;
//   return async (dispatch: any) => {
//     // dispatch({
//     //   type: DELETE_DOCS_FROM_FILELIST,
//     //   payload: { docsId, actionId }
//     // });
//     dispatch(deleteDocsFromFileList({ actionId }));
//   };
// }
// export function updateDocumentInException(filesList: any, actionId: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPDATE_DOCS_IN_ACTIONS_CLICKS_BY_ACTION_ID,
//       payload: {
//         actionId,
//         filesList
//       }
//     });
//   };
// }
// export function updateExceptionsResultsStoreForSelectedLoan(  //not getting used anywhere
//   data: ExceptionsStore
// ): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: UPLOAD_EXCEL_LOAN_EXCEPTION_CHANGE,
//       payload: { data }
//     });
//   };
// }
export function postLoanEnquiry(
  description: string,
  tags: any,
  loanId: string,
  loanStage: string,
  partyId: string,
  inquiryType: string,
  callBack: any,
  type?: string,
  status?: string,
  takeoutPartnerId?: string
): any {
  const inquiryVisibility =
    inquiryType === "VisibleToToorak" ? "internal" : "external";
  return async (dispatch: any) => {
    const header = {
      headers: { "Content-Type": "text/plain" }
    };
    const id = uuidV4();
    const url = `${
      getConfig().apiUrl
    }/exceptionservice/${loanId}?loanStage=${loanStage}&visibility=${inquiryVisibility}`;
    const requestBody = {
      loanExceptionStatus: "",
      dueDiligenceStatus: "",
      loanId,
      loanStage,
      exceptions: [
        {
          description,
          status: status || "Inquiry Raised",
          type: type || "Inquiry",
          externalExceptionId: id,
          ddLastUpdate: "",
          name: "",
          takeoutPartnerId,
          initialComment: "",
          conclusionComment: "",
          hold: false,
          clientResponse: "",
          exceptionCategory: "",
          exceptionSubCategory: "",
          createdBy: partyId,
          tags,
          response: [],
          statuses: [],
          source: isILP() ? "WORKFLOW" : "CONNECT"
        }
      ],
      grades: []
    };
    const response = await publicClient.post(url, requestBody, header);
    callBack(response?.data?.exceptions[0]?.exceptionId);
    if (response) {
      // dispatch({
      //   type: ADD_ENQUIRY,
      //   payload: response.data
      // });
      dispatch(addEnquiry(response.data));
    }
  };
}
export function addInquiryILP(
  description: string,
  tags: any,
  loanId: string,
  loanStage: string,
  partyId: string,
  datapoints: any,
  recommendationObj: any,
  callBack: any,
  flag: boolean,
  takeoutPartnerId: boolean,
  visibility?: any,
  isHoldInquiry?: boolean
): any {
  const inquiryVisibility = visibility ?? "internal";
  return async (dispatch: any) => {
    const id = uuidV4();
    let url = ``;
    let requestBody: any = {};
    const commonReqBody = {
      loanExceptionStatus: "",
      dueDiligenceStatus: "",
      loanId,
      loanStage,
      exceptions: [
        {
          description,
          status: InternalStatusEnum.Open,
          type: "Inquiry",
          externalExceptionId: id,
          ddLastUpdate: "",
          name: "",
          initialComment: "",
          conclusionComment: "",
          hold: isHoldInquiry || false,
          clientResponse: "",
          exceptionCategory: "",
          exceptionSubCategory: "",
          createdBy: partyId,
          tags,
          response: [],
          statuses: [],
          datapoints,
          source: isILP() ? "WORKFLOW" : "CONNECT",
          takeoutPartnerId
        }
      ],
      grades: []
    };
    if (recommendationObj?.lineText) {
      url = `${
        getConfig().apiUrl
      }/aggregate/exceptionservice/${loanId}/exception?loanStage=${loanStage}&limit=200&type=internal&includeChat=true`;
      requestBody = {
        inquiry: commonReqBody,
        response: {
          lineText: recommendationObj.lineText,
          tags: recommendationObj.tags
        }
      };
      if (flag) {
        requestBody.inquiry.exceptions[0].initialDescription = description;
        requestBody.inquiry.exceptions[0].description = "";
      }
    } else {
      url = `${
        getConfig().apiUrl
      }/exceptionservice/${loanId}?loanStage=${loanStage}&visibility=${inquiryVisibility}`;
      requestBody = commonReqBody;
      if (flag) {
        requestBody.exceptions[0].initialDescription = description;
        requestBody.exceptions[0].description = "";
      }
    }
    const response = await publicClient.post(url, requestBody);
    callBack(response?.data?.exceptions[0]?.exceptionId);
    if (response) {
      if (recommendationObj?.lineText) {
        // dispatch({
        //   type: ADD_EDIT_RECOMMENDATION,
        //   payload: {
        //     exceptionResults: response.data
        //   }
        // });
        dispatch(addEditRecommendation({ exceptionResults: response.data }));
      } else {
        // dispatch({
        //   type: ADD_ENQUIRY,
        //   payload: response.data
        // });
        dispatch(addEnquiry(response.data));
      }
    }
  };
}
export function internalToOriginator(
  loanId: any,
  loanStage: any,
  exceptionId: string
) {
  const url = `${
    getConfig().apiUrl
  }/exceptionservice/${loanId}/exceptions/${exceptionId}?loanStage=${loanStage}`;
  const requestBody = {
    visibility: "external"
  };
  return new Promise((resolve, reject) => {
    publicClient
      .put(url, requestBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}
export function updateResponseByResponseId(
  responseId: string,
  exceptionId: string,
  loanStage: string,
  loanId: string
): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/exceptionservice/${loanId}/exceptions/${exceptionId}/responses/${responseId}?loanStage=${loanStage}`;
    await publicClient.put(url);
  };
}
export function deleteEnquiryById(
  loanId: string,
  exceptionId: string,
  loanStage: string
) {
  const url = `${
    getConfig().apiUrl
  }/exceptionservice/${loanId}/exceptions/${exceptionId}?loanStage=${loanStage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .delete(url)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}
export function updateErrorCount(counts: any, loanId: string): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/loans/${loanId}/errors`;
    let reqBody = {};
    reqBody = {
      exceptionsPending: counts[1] || 0,
      enquiriesPending: counts[0] || 0,
      fieldsPending: 0
    };
    await publicClient.put(url, reqBody, {
      errorConfig: getErrorConfigObject({
        skipErrorHandling: true
      })
    } as any);
  };
}

export function updateLoanErrorsPending(
  loanId: string,
  updates: { [k: string]: any }
): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/loans/${loanId}/errors`;
    await publicClient.put(url, updates, {
      errorConfig: getErrorConfigObject({
        skipErrorHandling: true
      })
    } as any);
  };
}

export function multiFetch(
  data: any,
  loanId: string,
  isViewDetails: any = false
): any {
  return async (dispatch: any) => {
    const partyId = getPersonId();
    const payload: any = getMultiFetchQuery(data, loanId);
    const url = `${
      getConfig().apiUrl
    }/notification/${partyId}/events/multi-fetch`;
    const response = await publicClient.post(url, payload, {
      headers: {
        "Content-Type": "application/json"
      }
    });
    if (response && data && data instanceof Array) {
      data.forEach((exception: any) => {
        const responseCount = response.data.filter(
          (item: any) => item?.resource?.resourceValue === exception.exceptionId
        );
        if (responseCount?.length) {
          const resourceId = responseCount[0]?.resource?.resourceId;
          if (isViewDetails) {
            dispatch(
              updateReadStatus(exception.exceptionId, resourceId, partyId)
            );
          } else {
            // dispatch({
            //   type: UPDATE_FETCHED_EVENTS_COUNTS,
            //   payload: {
            //     exceptionId: exception.exceptionId,
            //     count: responseCount?.length,
            //     resourceId
            //   }
            // });
            dispatch(
              updateFetchedEventsCounts({
                exceptionId: exception.exceptionId,
                count: responseCount?.length,
                resourceId
              })
            );
          }
        }
      });
    }
  };
}
export function fetchEventForResource(
  partyId: string,
  loanId: string,
  resourceKey: string,
  resourceValue: any
): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/notification/${partyId}/events/fetch`;
    const body = {
      parentId: loanId,
      resourceKey,
      resourceValue
    };
    const response = await publicClient.post(url, body);
    const responseCount = response?.data.filter(
      (item: any) => item?.resource?.resourceValue === resourceValue
    );
    const resourceId = responseCount[0]?.resource?.resourceId;
    if (response) {
      // dispatch({
      //   type: UPDATE_FETCHED_EVENTS_COUNTS,
      //   payload: {
      //     exceptionId: resourceValue,
      //     count: responseCount?.length,
      //     resourceId
      //   }
      // });
      dispatch(
        updateFetchedEventsCounts({
          exceptionId: resourceValue,
          count: responseCount?.length,
          resourceId
        })
      );
    }
  };
}

export function updateReadStatus(
  exceptionId: string,
  resourceId: string,
  partyId: any
): any {
  return async (dispatch: any) => {
    try {
      const url = `${getConfig().apiUrl}/notification/${partyId}/events`;
      const body = [{ resourceId }];
      const response = await publicClient.post(url, body);
      if (response) {
        // dispatch({
        //   type: UPDATE_FETCHED_EVENTS_COUNTS,
        //   payload: {
        //     exceptionId,
        //     count: 0,
        //     resourceId
        //   }
        // });
        dispatch(
          updateFetchedEventsCounts({
            exceptionId,
            count: 0,
            resourceId
          })
        );
      }
    } catch (err) {
      console.log("err in notification api", err);
    }
  };
}

export function updateReadStatusDash(resourceId: string, partyId: any): any {
  return async (dispatch: any) => {
    const url = `${getConfig().apiUrl}/notification/${partyId}/events`;
    const body = resourceId;
    const response = await publicClient.post(url, body);
    return response;
  };
}

// export function setFilteredExceptions(exceptions: any): any {      //not getting used
//   return (dispatch: any) => {
//     dispatch({
//       type: SET_FILTERED_EXCEPTIONS,
//       payload: {
//         data: exceptions
//       }
//     });
//   };
// }

// export function resetExceptionStore(): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: RESET_EXCEPTION_STORE
//     });
//     dispatch(hideLoader());
//   };
// }

// export function resetExceptionResponse(): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: RESET_EXCEPTION_RESPONSE
//     });
//     dispatch(hideLoader());
//   };
// }

export function updateInquiryStatus(
  loanID: string,
  loanStage: string,
  inquiryID: string,
  workflowState: string
): any {
  return async (dispatch: any) => {
    try {
      const url = `${
        getConfig().apiUrl
      }/exceptionservice/${loanID}/exceptions/${inquiryID}/status?loanStage=${loanStage}`;
      publicClient.put(url, {
        workflowState
      });
    } catch (e) {
      console.log("update enquiry error", e);
    }
  };
}

export async function updateInquiries(
  payload: any,
  loanId: string,
  loanStage: string,
  exceptionId: string,
  dispatch: any,
  callBack?: any,
  softUpdateRequired?: boolean,
  isBulk?: boolean
) {
  try {
    dispatch(showLoader());
    const url = `${
      getConfig().apiUrl
    }/exceptionservice/${loanId}?loanStage=${loanStage}&replaceTag=true`;
    const requestBody = {
      exceptions: [payload]
    };
    const response = await publicClient.put(url, requestBody);
    dispatch(hideLoader());
    if (response && callBack) {
      callBack(exceptionId);
    }
    /** this is done to update the exception initialcomment in store when lat responds to the org actions */
    if (softUpdateRequired) {
      if (isBulk) {
        dispatch(
          initailCommentSoftUpdateBulk({
            initialComment: payload.initialComment,
            exceptionId,
            loanId
          })
        );
      } else {
        dispatch(
          initailCommentSoftUpdateLoan({
            initialComment: payload.initialComment,
            exceptionId,
            loanId
          })
        );
        // dispatch({
        //   type: isBulk
        //     ? INITIAL_COMMENT_SOFT_UPDATE_BULK
        //     : INITIAL_COMMENT_SOFT_UPDATE_LOAN,
        //   payload: {
        //     initialComment: payload.initialComment,
        //     exceptionId,
        //     loanId
        //   }
        // });
      }
    }
  } catch (e) {
    dispatch(hideLoader());
    console.log("update enquiry error", e);
  }
}

export async function createEmptyException(
  loanId: string,
  loanStage: string,
  visibility: string
) {
  const url = `${
    getConfig().apiUrl
  }/exceptionservice/${loanId}?loanStage=${loanStage}&visibility=${visibility}`;
  try {
    await publicClient.post(url, {
      loanExceptionStatus: "",
      dueDiligenceStatus: "",
      loanId: loanId,
      loanStage: loanStage,
      exceptions: [],
      grades: []
    });
  } catch (e) {
    console.error("Error setting up empty exception", e);
  }
}

export function exportFromLoansList(
  category: any,
  loanId: any,
  loanStage: any,
  oldLoanId?: any
): Promise<any> {
  const oldLoanIdArr: any = [];
  if (oldLoanId) {
    oldLoanIdArr.push(oldLoanId);
  }
  return new Promise((resolve, reject) => {
    const reqPayload: any = {
      searchQuery: {
        query: {
          bool: {
            must: [
              {
                terms: {
                  "identifierName.raw": ["LOAN"]
                }
              },
              {
                terms: {
                  "identifierValue.raw": [loanId, ...oldLoanIdArr]
                }
              },
              {
                terms: {
                  "loanStage.raw": [loanStage]
                }
              },
              {
                terms: {
                  "resource.raw": ["exception"]
                }
              }
            ],
            must_not: [
              {
                terms: {
                  "exception.visibility.raw": ["internal"]
                }
              }
            ]
          }
        },
        _source: ["*"],
        sort: [
          {
            updatedOn: "desc"
          },
          "_score"
        ],
        size: 100,
        from: 0
      }
    };

    if (!isLATUser())
      reqPayload.searchQuery.query.bool.must.push({
        terms: {
          "originatorId.raw": [getCookie("org_id")]
        }
      });
    switch (category) {
      case "conditions":
        reqPayload.searchQuery.query.bool.must.push({
          terms: {
            "exception.type.raw": ["EXCEPTION"]
          }
        });
        break;
      case "inquiries":
        reqPayload.searchQuery.query.bool.must.push({
          terms: {
            "exception.type.raw": ["Inquiry"]
          }
        });
        break;
      default:
        return reqPayload;
    }

    const url = `${getConfig().apiUrl}/aggregate/export/resources/${category}`;
    publicClient
      .post(url, reqPayload, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      .then((res: any) => resolve(res.data))
      .catch(reject);
  });
}

export function setGrade(
  loanId: string,
  loanStage: string,
  grade: string,
  personPartyId: string,
  currDateTime: string
): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/exceptionservice/${loanId}/grades?loanStage=${loanStage}`;
    try {
      dispatch(fetchExceptionsLoading());
      const response = await publicClient.post(url, [
        {
          name: "WorkFlow grade",
          value: grade,
          created_on: currDateTime,
          created_by: personPartyId
        }
      ], {
        errorConfig: getErrorConfigObject({
          skipForStatus: [400]
        })
      } as any);
      if (response.data) {
        dispatch(setGradeRedux(grade));
        dispatch(fetchExceptionsError());
      }
    } catch (e) {
      const err: any = e;
      if (err?.response?.data === "Enter the correct loanID or loan Stage") {
        try {
          await createEmptyException(loanId, loanStage, "internal");
          dispatch(
            setGrade(loanId, loanStage, grade, personPartyId, currDateTime)
          );
        } catch (e) {
          console.error("Error setting up grade", e);
        }
      }
      dispatch(fetchExceptionsError());
      console.log("Error setting up grade", err);
    }
  };
}

export function updateRecommendationInActionsTab(
  loanId: string,
  loanStage: string,
  lineText: string,
  tags: string,
  exceptionResponseId: number,
  chatId: number,
  chatLineSequenceId: number,
  exceptionId: string,
  type: any,
  status: string,
  visibility: any,
  currInquiryStatus: any,
  callback: any
): any {
  return async (dispatch: any) => {
    const responseType =
      type === "approver"
        ? ResponseTypeEnum.Approver
        : ResponseTypeEnum.Recommendation;
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/responses?responseType=${responseType}`;
    try {
      const response: any = await publicClient.post(url, {
        update: [
          {
            loanId,
            loanStage,
            lineText,
            tags: [tags],
            exceptionResponseId,
            chatId,
            chatLineSequenceId,
            responseType,
            exceptionId,
            ...(type === "approver" && {
              status
            }),
            ...(type === "approver" &&
              ((tags === ApproverActionsEnum.MakeitVisibletoOriginator &&
                visibility === "internal") ||
                (tags !== ApproverActionsEnum.MakeitVisibletoOriginator &&
                  currInquiryStatus === InquiryStatusEnum.Pending)) && {
                workflowState:
                  tags === ApproverActionsEnum.MakeitVisibletoOriginator &&
                  visibility === "internal"
                    ? InquiryStatusEnum.Pending
                    : "null"
              })
          }
        ]
      });
      if (response) {
        callback(response);
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
}
export function updateRecommendationInActionForBulkEdit(
  updateArray: any,
  callback: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/responses?responseType=${
      ResponseTypeEnum.Approver
    }`;
    try {
      const response: any = await publicClient.post(url, {
        update: updateArray
      });
      if (response?.data) {
        callback(response.data);
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
}
export function updateRecommendationForBulkEdit(
  loanId: string,
  loanStage: string,
  updateArray: any,
  callback: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/${loanId}/responses?loanStage=${loanStage}&includeChat=true&limit=200&type=internal,external&responseType=${
      ResponseTypeEnum.Approver
    }`;
    try {
      const response: any = await publicClient.post(url, {
        update: updateArray
      });
      if (response?.data) {
        callback(response.data);
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
}
export function updateRecommendation(
  loanId: string,
  loanStage: string,
  lineText: string,
  tag: string,
  exceptionResponseId: number,
  chatId: number,
  chatLineSequenceId: number,
  exceptionId: string,
  type: any,
  status: string,
  visibility: any,
  currInquiryStatus: any,
  callback: any
): any {
  return async (dispatch: any) => {
    const responseType =
      type === "approver"
        ? ResponseTypeEnum.Approver
        : ResponseTypeEnum.Recommendation;
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/${loanId}/responses?loanStage=${loanStage}&includeChat=true&limit=200&type=internal,external&responseType=${responseType}`;
    try {
      const response: any = await publicClient.post(url, {
        update: [
          {
            lineText,
            tag,
            exceptionResponseId,
            chatId,
            chatLineSequenceId,
            responseType,
            exceptionId,
            visibility,
            ...(type === "approver" && {
              status
            }),
            ...(type === "approver" &&
              ((tag === ApproverActionsEnum.MakeitVisibletoOriginator &&
                visibility === "internal") ||
                (tag !== ApproverActionsEnum.MakeitVisibletoOriginator &&
                  currInquiryStatus === InquiryStatusEnum.Pending)) && {
                workflowState:
                  tag === ApproverActionsEnum.MakeitVisibletoOriginator &&
                  visibility === "internal"
                    ? InquiryStatusEnum.Pending
                    : "null"
              })
          }
        ]
      });
      if (response?.data) {
        callback(response.data);
      } else {
        console.log(response);
      }
    } catch (e) {
      console.log(e);
    }
  };
}

export function deleteRecommendation(
  loanID: string,
  loanStage: string,
  exceptionID: string,
  exceptionResponseID: string,
  chatSequenceId: string,
  isPending: boolean,
  callback: any
): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/${loanID}/exceptions/${exceptionID}/responses/${exceptionResponseID}/lines/${chatSequenceId}?loanStage=${loanStage}`;
    try {
      const response: any = await publicClient.delete(url);
      if (response) {
        if (isPending) {
          updateInquiryStatus(loanID, loanStage, exceptionID, "null");
        }
        callback(true);
      }
    } catch (e) {
      callback(false);
    }
  };
}

export function postQuery(
  loanId: any,
  loanStage: any,
  exceptionId: any,
  value: string,
  tagsToSend: any[],
  callBack?: any,
  queryType?: string,
  visibility?: string
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/${loanId}/exceptions/${exceptionId}/recommendation?loanStage=${loanStage}`;
    const reqBody = {
      lineText: value,
      visibility: visibility || "internal",
      hasDocuments: true,
      tag: "",
      responseType: queryType || ResponseTypeEnum.Query,
      status: "Open",
      tagUsers: [...tagsToSend]
    };
    const response = await publicClient.post(url, reqBody);
    if (response) {
      callBack(
        response?.data[0]?.chatSequenceId,
        response?.data[0]?.exceptionResponseId
      );
    }
  };
}
export function getQuery(
  loanId: any,
  loanStage: any,
  exceptionId: any,
  type?: string,
  cb?: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      // dispatch(showLoader())
      const queryType = type || "internal";
      const url = `${
        getConfig().apiUrl
      }/aggregate/exceptionservice/${loanId}/exceptions/${exceptionId}/exception?loanStage=${loanStage}&includeChat=true&type=${queryType}&includeDocuments=true`;
      const response = await publicClient.get(url);
      if (response) {
        // dispatch({
        //   type: GET_QUERY,
        //   payload: {
        //     queryResponse: response.data
        //   }
        // });
        dispatch(
          setQuery({
            queryResponse: response.data
          })
        );
        dispatch(clearLoader());
        cb && cb(response.data);
      }
    } catch (err) {
      console.log("get query error", err);
    }
  };
}

export function deleteQueryById(
  loanId: string,
  loanStage: string,
  exceptionId: string,
  exceptionResponseId: string,
  chatId: string
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader(true));
      const url = `${
        getConfig().apiUrl
      }/aggregate/exceptionservice/${loanId}/exceptions/${exceptionId}/responses/${exceptionResponseId}/lines/${chatId}?loanStage=${loanStage}`;
      await publicClient.delete(url);
      // dispatch({
      //   type: DELETE_QUERY,
      //   payload: {
      //     chatId
      //   }
      // });
      dispatch(
        deleteQuery({
          chatId
        })
      );
      dispatch(hideLoader(true));
    } catch (e) {
      console.error("deleteQuery", e);
    }
  };
}

export function changeQueryStatus(chatId: string) {
  const url = `${getConfig().apiUrl}/chatservice/${chatId}/status`;
  const requestBody = {
    status: "Close"
  };
  return new Promise((resolve, reject) => {
    publicClient
      .put(url, requestBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function updateQuery(
  loanId: any,
  loanStage: any,
  exceptionId: any,
  chatSequenceid: any,
  chatLineSequenceId: any,
  id: any,
  lineText: any,
  deleteTags: any,
  tagsToSend: any,
  exceptionResponseId?: any,
  callBack?: any,
  updateComment?: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${
        getConfig().apiUrl
      }/aggregate/exceptionservice/${loanId}/exceptions/${exceptionId}/responses/${exceptionResponseId}?loanStage=${loanStage}`;
      const requestBody = {
        updatedBy: "4364310b-d46e-4c35-9236-389b856cf478",
        lineText,
        id,
        add: tagsToSend,
        delete: deleteTags,
        chatLineSequenceId,
        chatId: chatSequenceid,
        responseType: ResponseTypeEnum.Query
      };
      const response = await publicClient.put(url, requestBody);
      if (updateComment) {
        const commentResponse = {
          chatLineSequenceId,
          id
        };
        callBack(commentResponse);
      } else if (response) {
        callBack(chatSequenceid, exceptionResponseId);
      }
    } catch (e) {
      console.log("update enquiry error", e);
    }
  };
}

// export function updateExceptionAfterDelete(exceptionId: string): any {
//   return (dispatch: any) => {
//     dispatch({
//       type: DELETE_ENQUIRY_BY_ID,
//       payload: {
//         exceptionId
//       }
//     });
//   };
// }

export function convertInternalQuery(
  loanId: string,
  exceptionID: string,
  queryText: string,
  loanStage: string
) {
  const requestObj = {
    lineText: queryText,
    visibility: "external",
    responseType: ResponseTypeEnum.OriginatorQuery
  };
  const url = `${
    getConfig().apiUrl
  }/aggregate/exceptionservice/${loanId}/exceptions/${exceptionID}/recommendation?loanStage=${loanStage}`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, requestObj)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function convertInquiryToQueries(
  loanId: string,
  loanStage: string,
  reqBody: any
) {
  const url = `${
    getConfig().apiUrl
  }/aggregate/exceptionservice/${loanId}/responses?loanStage=${loanStage}&includeChat=true&limit=200&type=internal,external`;
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, reqBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}
// export function exceptionData(exceptionObj: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: CONVERT_INQUIRY,
//       payload: {
//         exceptionResults: exceptionObj
//       }
//     });
//   };
// }

export function sendQueriesToOriginator(
  loanId: string,
  loanStage: string,
  reqBody: any,
  getLatestComment?: boolean
) {
  let url = `${
    getConfig().apiUrl
  }/aggregate/exceptionservice/${loanId}/responses?loanStage=${loanStage}&includeChat=true&limit=200&type=internal,external&responseType=${
    ResponseTypeEnum.SendtoOriginator
  }`;
  if (getLatestComment) {
    url += `&latestComment=${getLatestComment}`;
  }
  return new Promise((resolve, reject) => {
    publicClient
      .post(url, reqBody)
      .then((res: any) => {
        resolve(res);
      })
      .catch(reject);
  });
}

export function deleteDocuments(
  documentId: string,
  callback?: any,
  inquiryDoc?: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    let url = "";
    if (inquiryDoc) {
      url = `${
        getConfig().apiUrl
      }/deletedoc/identifierName/EXCEPTION/documents/${documentId}`;
    } else {
      url = `${
        getConfig().apiUrl
      }/deletedoc/identifierName/CHAT/documents/${documentId}`;
    }
    const response = await publicClient.delete(url);
    if (response) {
      callback();
    }
  };
}

export function postQueryComment(
  loanId: any,
  loanStage: any,
  exceptionId: any,
  chatSequenceId: any,
  value: string,
  tagsToSend: any[],
  callBack?: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/${loanId}/exceptions/${exceptionId}/recommendation?loanStage=${loanStage}&chatId=${chatSequenceId}`;
    const reqBody = {
      lineText: value,
      visibility: "internal",
      responseType: ResponseTypeEnum.Comment,
      tagUsers: [...tagsToSend]
    };
    const response = await publicClient.post(url, reqBody);
    if (response) {
      callBack(response?.data);
      dispatch(hideLoader());
    }
  };
}

export function deleteQueryComment(
  id: any,
  chatSequenceId: string,
  users?: any,
  callback?: any
): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader(true));
      const userUrl = `${getConfig().apiUrl}/chatservice/${id}/users`;
      const commentUrl = `${
        getConfig().apiUrl
      }/chatservice/${chatSequenceId}/lines/${id}`;
      if (users > 0) {
        await publicClient.delete(userUrl);
      }
      const commentResponse = await publicClient.delete(commentUrl);
      if (commentResponse) {
        callback();
      }
      dispatch(hideLoader(true));
    } catch (e) {
      console.error("deleteComment", e);
    }
  };
}

export function getCommunicationData(partyId: any, type?: string): any {
  return async (dispatch: any) => {
    try {
      dispatch(showLoader());
      const url = `${
        getConfig().apiUrl
      }/aggregate/chatservice/${partyId}/tagged-chats?type=${type}`;
      const response = await publicClient.get(url);
      if (response) {
        // dispatch({
        //   type: GET_COMMUNICATION_DATA,
        //   payload: response.data
        // });
        dispatch(
          setCommunicationData({
            chats: response.data.chats,
            exceptionDetails: response.data.exceptionDetails
          })
        );

        dispatch(hideLoader());
      }
    } catch (err) {
      console.log("get communication error", err);
    }
  };
}

export function postBulkAction(
  loanId: string,
  loanStage: string,
  payload: any,
  isApprover: boolean,
  callback: any,
  exceptionId?: any
): any {
  return async (dispatch: any) => {
    dispatch(showLoader());
    let url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/${loanId}/responses?loanStage=${loanStage}&includeChat=true&limit=200&type=internal,external`;
    if (isApprover) {
      url += `&responseType=${ResponseTypeEnum.Approver}`;
    }
    const response = await publicClient.post(url, { add: payload });
    if (response) {
      // dispatch({
      //   type: ADD_EDIT_RECOMMENDATION,
      //   payload: {
      //     exceptionResults: response.data,
      //     exceptionId
      //   }
      // });
      dispatch(
        addEditRecommendation({ exceptionResults: response.data, exceptionId })
      );
      callback(response?.data, payload);
    } else {
      dispatch(hideLoader());
    }
  };
}

export function postBulkInternalQuery(
  loanId: string,
  loanStage: string,
  payload: any
): any {
  return async (dispatch: any) => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/${loanId}/responses?loanStage=${loanStage}&includeChat=true&limit=200&type=internal,external`;
    publicClient.post(url, { add: payload });
    dispatch(hideLoader());
  };
}

export function postBulkInternalQueryAcrossLoans(payload: any, cb?: any): any {
  return async () => {
    const url = `${
      getConfig().apiUrl
    }/aggregate/exceptionservice/responses?responseType=${
      ResponseTypeEnum.Approver
    }`;
    publicClient.post(url, { add: payload }).then((resp: any) => {
      cb && cb(resp.data, payload);
    });
  };
}

// export function addToBulkSatisfyPayload(
//   exceptionId: any,
//   filesList: any,
//   lineText: string,
//   loanId: any,
//   loanStage: any,
//   visibility: any
// ): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: ADD_TO_BULK_SATISFY_PAYLOAD,
//       payload: {
//         exceptionId,
//         filesList,
//         lineText,
//         loanId,
//         loanStage,
//         visibility
//       }
//     });
//   };
// }

// export function deleteFromBulkSatisfyPayload(exceptionId: any): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: DELETE_FROM_BULK_SATISFY_PAYLOAD,
//       payload: {
//         exceptionId
//       }
//     });
//   };
// }

// export function resetBulkSatisfyPayload(): any {
//   return async (dispatch: any) => {
//     dispatch({
//       type: RESET_BULK_SATISFY_PAYLOAD
//     });
//   };
// }

export function bulkSatisfyException(payload: any): any {
  return async (dispatch: any) => {
    const finalPayload = payload.map((val: any) => {
      const { filesList, ...others } = val;
      return {
        ...others
      };
    });
    dispatch(showLoader());
    const url = `${getConfig().apiUrl}/aggregate/exceptionservice/responses`;
    try {
      await publicClient.post(url, { add: finalPayload });
      dispatch(resetExceptionStore());
      dispatch(resetBulkSatisfyPayload());
      dispatch(hideLoader());
    } catch (e) {
      dispatch(hideLoader());
    }
  };
}
