import { v1 as uuidv1 } from "uuid";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import {
  getPreAssignedURL,
  uploadAWSFile,
  insertDocs,
  downloadFileNew,
  insertQueryDocs,
  setDocumentUplaodUrl
} from "../create-loan.action";
import { insertTypeDocs as insertWaiverDocs } from "../../stores/WaiverRequest/WaiverRequest.action";
import { getFileUpladBody } from "../../utils/AccessManagement";
import { extensionList } from "../../utils/constants";
import {
  showLoader,
  hideLoader
} from "../../stores/loaderAndException/loaderAndException.action";

export const handleFileUpload = (event: any, props: any) => {
  const {
    setOpenDialog,
    filesList,
    setDialogdetails,
    setFilesList,
    callBack,
    loanId,
    disableSumit,
    setSubmitDisable,
    setIsDocumentUploaded,
    isWaiverType,
    xlsxRequired,
    dragFiles,
    maxFileSize = 20971520,
    maxFileCount = 50,
    isDocSelfServe,
    dispatch
  } = props;
  // Docs number limit check
  const dialogData = {
    topic: "Validation Fail",
    head: "Validation",
    btnText: "Ok",
    contentText: "",
    isCancelDisable: true,
    btnFunction: () => setOpenDialog(false)
  };
  const files = dragFiles ? event : event.target.files;
  if (isDocSelfServe && filesList.length + files.length > 1) {
    dialogData.contentText =
      "Max file upload count reached \n (allowed upto only 1)";
    setDialogdetails(dialogData);
    setOpenDialog(true);
    return;
  }

  let newFileList: any[] = [];
  const fileKeys = Object.keys(files);

  fileKeys.forEach((key, index) => {
    const file = files[index];
    if (
      filesList?.length >= maxFileCount ||
      fileKeys?.length + filesList?.length > maxFileCount
    ) {
      // alert("Max file count reached!!!");
      dialogData.contentText = `Max file upload count reached \n (allowed upto only ${maxFileCount})`;
      setDialogdetails(dialogData);
      setOpenDialog(true);
      return;
    }

    // files size check. max 5 MB allowed
    // remove the excedding file
    if (file.size > maxFileSize) {
      // alert("File size is more than 5MB")
      dialogData.contentText = `Max File upload size allowed is ${maxFileSize /
        1048576}MB`;
      setDialogdetails(dialogData);
      setOpenDialog(true);
      return;
    }
    // file format check
    if (
      xlsxRequired &&
      file.name
        .split(".")
        .pop()
        .toLowerCase() === "xlsx"
    ) {
      file.uploading = true;
      file.uploaded = false;
      file.percentCompleted = 100;
      file.tag = [];
      file.tagCodeList = [];
    } else if (
      !xlsxRequired &&
      extensionList.indexOf(
        file.name
          .split(".")
          .pop()
          .toLowerCase()
      ) > -1
    ) {
      file.uploading = true;
      file.uploaded = false;
      file.percentCompleted = 100;
      file.tag = [];
      file.tagCodeList = [];
    } else {
      dialogData.contentText = xlsxRequired
        ? "xlsx file required"
        : "Wrong file format";
      setDialogdetails(dialogData);
      setOpenDialog(true);
      return;
    }
    file.docsId = uuidv1();
    file.uniqueId = uuidv1();
    if (disableSumit) {
      disableSumit(file.uniqueId, true);
    }
    if (setSubmitDisable) {
      setSubmitDisable(file.uniqueId, true);
    }
    // const body = getFileUpladBody(file.name, loanId);
    const insertBodyCallBack = (obj: any, error: any) => {
      file.error = error !== "" ? error : null;
      if (obj !== null) {
        file.body = obj;
      }
      if (disableSumit) {
        disableSumit(file.uniqueId, false, error);
      }
      if (setSubmitDisable) {
        setSubmitDisable(file.uniqueId, false, error);
      }
      if (isDocSelfServe) {
        setIsDocumentUploaded(true);
        if (error) {
          dialogData.contentText = "Document not uploaded Successfully";
          setDialogdetails(dialogData);
          setOpenDialog(true);
        }
      }
    };
    if (!isWaiverType) {
      sendFilesObject(
        index,
        file?.name,
        file?.type,
        file,
        filesList,
        setFilesList,
        loanId,
        insertBodyCallBack,
        isDocSelfServe,
        dispatch
      );
    }
    // file.body = body;
    newFileList.push(file);
    if (callBack) {
      setFilesList([...filesList, ...newFileList]);
      callBack(filesList.length, [...filesList, ...newFileList], setFilesList);
    }
  });
  newFileList = [...filesList, ...newFileList];
  if (!callBack) setFilesList(newFileList);
  return newFileList;
};

export const updatePercentage = (
  uniqueId: any,
  percentage: number,
  filesList: any,
  setFilesList: any
) => {
  const temp = [...filesList];
  temp.map(item => {
    if (item.uniqueId === uniqueId) {
      item.percentCompleted = percentage;
    }
    return item;
  });
  setFilesList(temp);
};

const filePiles: any = [];

export const sendFileExceptions = async (
  count: number,
  chatId: any,
  filesList: any,
  setFilesList: any,
  loanId: string,
  exceptionId?: any,
  callback?: any | null,
  secondaryIdentifier?: string,
  chatLineSequenceId?: string,
  primaryIdentifier?: string,
  loanStage?: LoanStage
) => {
  // console.log("document upload send file");
  const body = getFileUpladBody(filesList[count].name, loanId);
  try {
    const response: any = await getPreAssignedURL(body, filesList[count].type);
    const file = new FormData();
    if (response?.url && !response?.Error) {
      // console.log("data?.url", data?.url);
      const urlObj = response;
      file.append("file", filesList[count]);
      const config = {
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          updatePercentage(
            filesList[count].uniqueId,
            percentCompleted,
            filesList,
            setFilesList
          );
        }
      };
      const res: any = await uploadAWSFile(filesList[count], config, urlObj);
      if (res.status === 200) {
        // post API to insert in DB
        const insertBody = [
          {
            name: filesList[count].name,
            path: urlObj.url,
            box_document_path: "<box_document_path>",
            createdBy: "<userId>",
            updatedBy: "<userId>",
            createdOn: new Date(),
            updatedOn: new Date(),
            tags: filesList[count].tagCodeList
          }
        ];
        const type = chatId ? "" : "EXCEPTION";
        const typeId = chatId !== "" ? chatId : exceptionId;
        let insertDocsResp: any[] | any = [];
        if (secondaryIdentifier && primaryIdentifier) {
          insertDocsResp = await insertDocs(
            insertBody,
            chatId,
            secondaryIdentifier,
            chatLineSequenceId,
            primaryIdentifier
          );
        } else if (exceptionId && chatLineSequenceId) {
          insertDocsResp = await insertQueryDocs(
            insertBody,
            exceptionId,
            chatLineSequenceId
          );
        } else {
          insertDocsResp = await insertWaiverDocs(insertBody, type, typeId);
          const hasCondition: boolean = window.location.pathname.includes(
            "/Condition/"
          );

          if (hasCondition) {
            await insertDocs(
              insertBody,
              loanId,
              `${loanStage}-postClose`,
              `${loanStage}-postClose`,
              "LOAN",
              true
            );
          }
        }
        filePiles.push(...insertDocsResp.data);
        callback && callback(insertDocsResp.data);
      }
    }
  } catch (e) {
    console.error(e);
    const temp = [...filesList];
    temp.splice(count, 1);
    setFilesList([...temp]);
  }
};

export const sendDiscussionFile = async (
  chatId: string,
  count: number,
  filesList: any,
  setFilesList: any,
  loanId: string,
  callback?: any | null
): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const body = getFileUpladBody(filesList[count].name, loanId);
    getPreAssignedURL(body, filesList[count].type)
      .then(async (data: any) => {
        const file = new FormData();
        if (data.url && !data.Error) {
          const awsUrl = data;
          file.append("file", filesList[count]);
          const config = {
            onUploadProgress: (progressEvent: {
              loaded: number;
              total: number;
            }) => {
              const percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updatePercentage(
                filesList[count].uniqueId,
                percentCompleted,
                filesList,
                setFilesList
              );
            }
          };
          const res: any = await uploadAWSFile(
            filesList[count],
            config,
            awsUrl
          );
          if (res.status === 200) {
            // post API to insert in DB
            const insertBody = [
              {
                name: filesList[count].name,
                path: awsUrl.url,
                box_document_path: "<box_document_path>",
                createdBy: "<userId>",
                updatedBy: "<userId>",
                createdOn: new Date(),
                updatedOn: new Date(),
                tags: []
              }
            ];
            const callbackResult = await callback(chatId, insertBody);
            resolve(callbackResult);
          } else {
            const callbackResult = await callback(chatId, []);
            reject(callbackResult);
          }
        }
      })
      .catch(async e => {
        console.error(e);
        const temp = [...filesList];
        temp.splice(count, 1);
        setFilesList([...temp]);
        const callbackResult = await callback(chatId, []);
        reject(callbackResult);
      });
  });
};

export const handleDownload = (file: any, id: string, type?: string) => {
  const primaryIdentifier =
    type === "waiver"
      ? "WAIVER"
      : type === "chat" || type === "discussion" || type === "query"
      ? "CHAT"
      : type === "exception"
      ? "EXCEPTION"
      : type === "scenario"
      ? "SCENARIO"
      : "";

  downloadFileNew(file.name, file.id, primaryIdentifier, id)
    .then(data => console.log(data))
    .catch(error => console.error(error));
};

export const sendFilesObject = (
  count: number,
  name: string,
  type: any,
  fileObject: any,
  filesList: any,
  setFilesList: any,
  loanId: string,
  callback?: any | null,
  isDocSelfServe?: boolean,
  dispatch?: any
) => {
  // console.log("name", name);
  // console.log("type", type);
  const body = getFileUpladBody(name, loanId, "LOAN", "", "", isDocSelfServe);
  if (dispatch) {
    dispatch(showLoader());
  }
  // console.log("sendFilesObject doc upload apis");
  getPreAssignedURL(body, type)
    .then(async (data: any) => {
      if (data?.url && !data?.Error) {
        const awsUrl = data;
        if (dispatch) {
          dispatch(setDocumentUplaodUrl(awsUrl?.url));
        }
        const config = {};
        const res: any = await uploadAWSFile(fileObject, config, awsUrl);
        if (res.status === 200) {
          // post API to insert in DB
          const insertBody = [
            {
              name,
              path: awsUrl?.url,
              box_document_path: "<box_document_path>",
              createdBy: "<userId>",
              updatedBy: "<userId>",
              createdOn: new Date(),
              updatedOn: new Date(),
              tags: fileObject?.tagCodeList || []
            }
          ];

          callback(insertBody);
        }
      } else {
        callback([], data.Error);
      }
      if (dispatch) {
        dispatch(hideLoader());
      }
    })
    .catch(e => {
      if (dispatch) {
        dispatch(hideLoader());
      }
      callback([], e);

      console.error(e);
      const temp = [...filesList];
      temp.splice(count, 1);
      setFilesList([...temp]);
    });
};

export const uploadFileToDocuments = (
  body: any,
  primaryIdentifier: string,
  chatId: string,
  secondaryIdentifier: string,
  chatLineSequenceId: string,
  callBack?: any,
  exceptionId?: any,
  loanId?: string,
  loanStage?: LoanStage,
  fromCondition?: any,
  hasInquiry?: boolean
) => {
  if (secondaryIdentifier !== "" && primaryIdentifier) {
    insertDocs(
      body,
      chatId,
      secondaryIdentifier,
      chatLineSequenceId,
      primaryIdentifier,
      fromCondition,
      hasInquiry
    )
      .then((response: any) => {
        if (callBack instanceof Object) {
          callBack(response);
        }
      })
      .catch((err: any) => {
        if (callBack instanceof Object) {
          callBack([], err);
        }
      });
  } else {
    insertWaiverDocs(body, primaryIdentifier, exceptionId)
      .then(async (response: any) => {
        const hasCondition: boolean = window.location.pathname.includes(
          "/Condition/"
        );
        if (hasCondition && primaryIdentifier === "WAIVER" && loanId) {
          await insertDocs(
            body,
            loanId,
            `${loanStage}-postClose`,
            `${loanStage}-postClose`,
            "LOAN",
            fromCondition
          );
        }
        callBack(response);
      })
      .catch((err: any) => {
        console.error("err", err);
      });
  }
};
