/* eslint-disable @typescript-eslint/camelcase */
import {
  PropertyInformation,
  PropertyLocation,
  LocationSuggestion,
  LoanStage
} from "@toorak/tc-common-fe-sdk";
import {
  bridgePropertyFields,
  thirtyYearPropertyfields
} from "../constants/loanFieldType";
import {
  checkFieldValidity,
  compareRuleVersion,
  getRuleVersion,
  isEmptyValue
} from "../../utils/formatChecks";
import {
  rentDescriptionOld,
  rentDescription
} from "../constants/loanCreationDropDownValues";
import { getLoanType } from "../../config/config";
import { unitInfoFields } from "./PropertyDetailsForm";
import { ruleV02_22 } from "../../utils/constants";
import { hasDuplicateObjects } from "../../utils/utils";
import { UpdatedPropertyEconomics } from "../../masterView/common";

export function isPropertyAddressSame(
  address: LocationSuggestion,
  otherAddresses: LocationSuggestion[]
) {
  let result = false;
  for (let i = 0; i < otherAddresses.length; i += 1) {
    const otherAddress = otherAddresses[i];
    if (
      otherAddress &&
      otherAddress.street_line.replace(/\s/g, "").toLowerCase() ===
        address.street_line.replace(/\s/g, "").toLowerCase() &&
      otherAddress.city.replace(/\s/g, "").toLowerCase() ===
        address.city.replace(/\s/g, "").toLowerCase() &&
      otherAddress.state.replace(/\s/g, "").toLowerCase() ===
        address.state.replace(/\s/g, "").toLowerCase() &&
      otherAddress.zipcode.replace(/\s/g, "").toLowerCase() ===
        address.zipcode.replace(/\s/g, "").toLowerCase()
    ) {
      result = true;
      break;
    }
  }
  return result;
}

export function arePropertyAddressAmongstThemselvesSame(addresses: any) {
  let transformedPropList = addresses.map((property: any) => {
    return {
      addressLine1: property?.propertyLocation?.addressLine1,
      city: property?.propertyLocation?.city,
      state: property?.propertyLocation?.state,
      postalCode: property?.propertyLocation?.postalCode
    };
  });
  return hasDuplicateObjects(transformedPropList);
}

export const computePropertyEconomicsError = (
  propertyEconomics: Partial<UpdatedPropertyEconomics> | undefined,
  loanType: string
) => {
  const result: UpdatedPropertyEconomics = {
    originalAppraisalValue: "",
    originalRepairedAppraisalValue: "",
    purchasePrice: "",
    costBasis: "",
    grossPotentialRent: "",
    annualPropertyTaxes: "",
    insurance: "",
    rentalCashflowRatio: "",
    annualHazardInsurance: "",
    annualFloodInsurance: "",
    annualHoaFee: "",
    thirdPartyValuation: "",
    appraisalReported: "",
    decliningMarkets: ""
  };
  if (!propertyEconomics) {
    return result;
  }

  if (!propertyEconomics.appraisalReported) {
    result.appraisalReported = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyEconomics.appraisalReported,
      bridgePropertyFields.appraisalReported
    )
  ) {
    result.appraisalReported = "Enter valid number";
  } else {
    result.appraisalReported = "";
  }
  if (!propertyEconomics.originalAppraisalValue) {
    result.originalAppraisalValue = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyEconomics.originalAppraisalValue,
      bridgePropertyFields.originalAppraisalValue
    )
  ) {
    result.originalAppraisalValue =
      "Please enter value in proper currency format";
  } else {
    result.originalAppraisalValue = "";
  }

  if (!propertyEconomics.originalRepairedAppraisalValue) {
    result.originalRepairedAppraisalValue = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyEconomics.originalRepairedAppraisalValue,
      bridgePropertyFields.originalRepairedAppraisalValue
    )
  ) {
    result.originalRepairedAppraisalValue =
      "Please enter value in proper currency format";
  } else {
    result.originalRepairedAppraisalValue = "";
  }

  if (!propertyEconomics.purchasePrice) {
    result.purchasePrice = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyEconomics.purchasePrice,
      bridgePropertyFields.purchasePrice
    )
  ) {
    result.purchasePrice = "Please enter value in proper currency format";
  } else {
    result.purchasePrice = "";
  }

  if (!propertyEconomics.costBasis) {
    result.costBasis = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyEconomics.costBasis,
      bridgePropertyFields.costBasis
    )
  ) {
    result.costBasis = "Please enter value in proper currency format";
  } else {
    result.costBasis = "";
  }

  if (!propertyEconomics.grossPotentialRent) {
    result.grossPotentialRent = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyEconomics.grossPotentialRent,
      bridgePropertyFields.grossPotentialRent
    )
  ) {
    result.grossPotentialRent = "Please enter value in proper currency format";
  } else {
    result.grossPotentialRent = "";
  }

  if (!propertyEconomics.annualPropertyTaxes) {
    result.annualPropertyTaxes = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyEconomics.annualPropertyTaxes,
      bridgePropertyFields.annualPropertyTaxes
    )
  ) {
    result.annualPropertyTaxes = "Please enter value in proper currency format";
  } else {
    result.annualPropertyTaxes = "";
  }

  if (!propertyEconomics.insurance) {
    result.insurance = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyEconomics.insurance,
      bridgePropertyFields.insurance
    )
  ) {
    result.insurance = "Please enter value in proper currency format";
  } else {
    result.insurance = "";
  }

  return result;
};

export const computePropertyInformationError = (
  propertyInformation: Partial<PropertyInformation> | undefined,
  loanType: string
) => {
  const result = {
    propertyType: "",
    numberOfUnits: "",
    propertyValuationDate: "",
    propertyAcquisitionDate: "",
    changeInUseCase: "",
    preRehabSquareFootage: "",
    postRehabSquareFootage: "",
    percentageOccupied: ""
  };
  if (!propertyInformation) {
    return result;
  }
  if (!propertyInformation.propertyType) {
    result.propertyType = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyInformation.propertyType,
      bridgePropertyFields.propertyType
    )
  ) {
    result.propertyType = "This field is required";
  } else {
    result.propertyType = "";
  }
  if (!propertyInformation.changeInUseCase) {
    result.changeInUseCase = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyInformation.changeInUseCase,
      bridgePropertyFields.changeInUseCase
    )
  ) {
    result.changeInUseCase = "This field is required";
  } else {
    result.changeInUseCase = "";
  }
  if (!propertyInformation.propertyValuationDate) {
    result.propertyValuationDate = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyInformation.propertyValuationDate,
      bridgePropertyFields.propertyValuationDate
    )
  ) {
    result.propertyValuationDate = "This field is required";
  } else {
    result.propertyValuationDate = "";
  }
  if (!propertyInformation.propertyAcquisitionDate) {
    result.propertyAcquisitionDate = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyInformation.propertyAcquisitionDate,
      bridgePropertyFields.propertyAcquisitionDate
    )
  ) {
    result.propertyAcquisitionDate = "This field is required";
  } else {
    result.propertyAcquisitionDate = "";
  }
  if (!propertyInformation.numberOfUnits) {
    result.numberOfUnits = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyInformation.numberOfUnits,
      bridgePropertyFields.numberOfUnits
    )
  ) {
    result.numberOfUnits = "Enter valid number";
  } else {
    result.numberOfUnits = "";
  }
  if (!propertyInformation.preRehabSquareFootage) {
    result.preRehabSquareFootage = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyInformation.preRehabSquareFootage,
      bridgePropertyFields.preRehabSquareFootage
    )
  ) {
    result.preRehabSquareFootage = "Enter valid number";
  } else {
    result.preRehabSquareFootage = "";
  }
  if (!propertyInformation.postRehabSquareFootage) {
    result.postRehabSquareFootage = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyInformation.postRehabSquareFootage,
      bridgePropertyFields.postRehabSquareFootage
    )
  ) {
    result.postRehabSquareFootage = "Enter valid number";
  } else {
    result.postRehabSquareFootage = "";
  }
  if (!propertyInformation.percentageOccupied) {
    result.percentageOccupied = "This field is required";
  } else if (
    !checkFieldValidity(
      propertyInformation.percentageOccupied,
      bridgePropertyFields.percentageOccupied
    )
  ) {
    result.percentageOccupied = "Please enter value in decimal";
  } else {
    result.percentageOccupied = "";
  }
  return result;
};

export const computePropertyLocationError = (
  propertyLocation: Partial<PropertyLocation> | undefined,
  otherAddresses: LocationSuggestion[]
) => {
  if (!propertyLocation?.invalidAddress) {
    const result = {
      address: "",
      city: "",
      state: "",
      zipCode: ""
    };
    if (!propertyLocation) {
      return result;
    }

    let currentAddress = null;
    if (propertyLocation?.address) {
      if (typeof propertyLocation.address === "string") {
        currentAddress = {
          city: propertyLocation.city || "",
          state: propertyLocation.state || "",
          street_line: propertyLocation.address || "",
          zipcode: propertyLocation.zipCode || "",
          secondary: "",
          entries: 0
        };
      }
    }
    const isAddressSame = currentAddress
      ? isPropertyAddressSame(currentAddress, otherAddresses)
      : false;

    if (!propertyLocation.address) {
      result.address = "This field is required";
    } else if (isAddressSame) {
      result.address = "Duplicate address";
    } else {
      result.address = "";
    }

    if (!propertyLocation.city) {
      result.city = "This field is required";
    } else {
      result.city = "";
    }

    if (!propertyLocation.state) {
      result.state = "This field is required";
    } else {
      result.state = "";
    }

    if (!propertyLocation.zipCode) {
      result.zipCode = "This field is required";
    } else {
      result.zipCode = "";
    }
    return result;
  }
  return {
    address: "Invalid Address",
    city: "Invalid Address",
    state: "Invalid Address",
    zipCode: "Invalid Address"
  };
};

export const computePropertyError = (
  data: any,
  loanType: string,
  loanStage: LoanStage,
  bridgeIsGroundUpLoan: boolean,
  extraData: any = {} // can be used to run hide condition
) => {
  let propertyError = 0;
  const {
    propertyEconomics,
    propertyInformation,
    unitsInformation,
    loanRuleVersions
  } = data;
  let propertyEcoPayload = propertyEconomics?.payload || {};
  if (loanType === getLoanType[1].displayValue) {
    const ruleVersion = getRuleVersion(loanRuleVersions);
    if (!compareRuleVersion(ruleVersion, "<", ruleV02_22)) {
      propertyEcoPayload = { ...propertyEcoPayload, costBasis: "$0.00" };
    }
  }
  const propertyFormData: any[] = [
    propertyEcoPayload,
    propertyInformation?.payload ?? {}
  ];

  if (loanType === getLoanType[1].displayValue) {
    // eslint-disable-next-line no-unused-expressions
    unitsInformation &&
      unitsInformation.forEach((unitInformation: any) => {
        unitInfoFields
          .filter(
            (ele: any) =>
              !ele.requiredLeaseStatus ||
              (ele.requiredLeaseStatus &&
                ele.requiredLeaseStatus.includes(
                  unitInformation.payload?.leaseStatus
                ))
          )
          .forEach((item: any) => {
            let isValid = true;
            if (
              !item.optionalCondition ||
              !item.optionalCondition({
                unitInformation: unitInformation?.payload
              })
            ) {
              let dropDownValue =
                item.dropDownData &&
                item.dropDownData.map((ele: any) => ele.value);
              if (item.reduxFieldName === "leaseStatus") {
                dropDownValue = [...rentDescriptionOld, ...rentDescription];
              }
              isValid = checkFieldValidity(
                unitInformation.payload?.[item.reduxFieldName],
                {
                  fieldTpe: item?.fieldType,
                  extraData: dropDownValue
                }
              );
            } else if (
              !isEmptyValue(unitInformation.payload[item.reduxFieldName])
            ) {
              isValid = checkFieldValidity(
                unitInformation.payload[item.reduxFieldName],
                {
                  fieldTpe: item.fieldType,
                  extraData:
                    item.dropDownData &&
                    item.dropDownData.map((ele: any) => ele.value)
                }
              );
            }
            if (!isValid) {
              propertyError += 1;
            }
          });
      });
  }
  let templateKeys: {
    [index: string]: {
      fieldTpe: string;
      extraData?: any;
      optionalIn?: LoanStage[];
      isHidden?: (data: any) => boolean;
    };
  };
  if (loanType === getLoanType[0].displayValue) {
    Object.keys(bridgePropertyFields).forEach((key: string) => {
      if (key === "isPropertyPurchasedLast2Years") {
        templateKeys = {
          ...templateKeys,
          ...{
            [key]: {
              fieldTpe: bridgePropertyFields[key].fieldTpe,
              extraData: bridgePropertyFields[key].extraData,
              optionalIn: bridgeIsGroundUpLoan
                ? [LoanStage.fes]
                : [LoanStage.fes, LoanStage.pre, LoanStage.post],
              isHidden: bridgePropertyFields[key].isHidden
            }
          }
        };
      } else {
        templateKeys = {
          ...templateKeys,
          ...{
            [key]: {
              fieldTpe: bridgePropertyFields[key].fieldTpe,
              extraData: bridgePropertyFields[key].extraData,
              optionalIn: bridgePropertyFields[key].optionalIn,
              isHidden: bridgePropertyFields[key].isHidden
            }
          }
        };
      }
    });
  } else {
    Object.keys(thirtyYearPropertyfields).forEach((key: string) => {
      templateKeys = {
        ...templateKeys,
        ...{
          [key]: {
            fieldTpe: thirtyYearPropertyfields[key].fieldTpe,
            extraData: thirtyYearPropertyfields[key].extraData,
            optionalIn: thirtyYearPropertyfields[key].optionalIn
          }
        }
      };
    });
  }
  propertyFormData.map((element: any) => {
    propertyError += Object.keys(element).filter((x: string) => {
      if (templateKeys[x]) {
        const fieldType = templateKeys[x] || { fieldTpe: "default" };
        if (fieldType.isHidden && fieldType.isHidden(extraData)) {
          return false;
        }
        if (
          !checkFieldValidity(element[x], fieldType) &&
          !(
            isEmptyValue(element[x]) &&
            fieldType.optionalIn &&
            fieldType.optionalIn.includes(loanStage)
          )
        ) {
          return true;
        }
      }
      return false;
    }).length;
    return null;
  });
  return propertyError;
};
