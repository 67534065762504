import { Grid } from "@mui/material";
import React from "react";

import { useDrawsStyles } from "../Draws.styles";

import { useEffect } from "react";
import { ProvisionHistoryDetail } from "./ProvisionHistoryDetail";

export const ProvisionHistory = (props: any) => {
  const { historyData } = props;
  const classes = useDrawsStyles();

  useEffect(() => {}, []);

  const getTable = () => {
    return (
      <>
        <ProvisionHistoryDetail historyData={historyData} />
      </>
    );
  };

  return (
    <>
      {historyData && historyData?.length > 0 ? (
        getTable()
      ) : (
        <Grid
          className={classes.modalContainer}
          // style={{ minWidth: undefined, paddingLeft: "30%", paddingTop: "10%" }}
        >
          No Data Available
        </Grid>
      )}
    </>
  );
};
