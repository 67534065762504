import { Button, Menu, MenuItem } from "@mui/material";
import React from "react";
import arrowUpDark from "../../../images/arrow-up.svg";
import arrowDownDark from "../../../images/arrow-down.svg";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { usePricingAndGuidlinesStyles } from "../PricingAndGuidlinesSection.style";

export const ProgrammeSelectionModalDropdown = (props: any) => {
  const {
    DropDownList,
    handleDropdownSelection,
    label
    // statustodisable,
  } = props;
  const classes = usePricingAndGuidlinesStyles();
  const defaultStyle = {
    width: "100%",
    height: 50
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [loanIndex, setLoanIndex] = React.useState(0);

  const selectedLoanIndex = (index: any, option: any) => {
    setLoanIndex(index);
    handleDropdownSelection(option);
  };

  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  if (!DropDownList) {
    return <></>;
  }
  return (
    <>
      <div className="mt-3">
        <span className={`${classes.standardMargin}`}>{`Loan ID (${loanIndex +
          1}/${DropDownList.length})`}</span>
        <Button
          id="basic-button"
          aria-haspopup="true"
          onClick={handleClick}
          style={defaultStyle}
          className={`more-action-iconBtn ${classes.programmeSelectListDD} ${classes.exportBtn} ${classes.standardMargin}`}
        >
          <span className={classes.commonListDDText}>{label}</span>
          {anchorEl ? (
            <img src={arrowUpDark} alt="arrow-Up-Dark" />
          ) : (
            <img src={arrowDownDark} alt="arrow-Down-Dark" />
          )}
        </Button>
        <>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",

              style: {
                maxHeight: "200px",
                width: "430px",
                marginTop: "1px"
              }
            }}
            style={{ marginTop: 50 }}
          >
            <MenuItem
              className={classes.menuItemStyle}
              style={{ opacity: 1 }}
              value=" "
              disabled={true}
            >
              <span>Loan ID: Toorak/Originator</span>
              <span style={{ flex: 1 }}></span>
              <span>Ready for Submission?</span>
            </MenuItem>

            <div
              style={{
                marginTop: "0px"
              }}
            >
              {DropDownList.map((option: any, index: any) => {
                return (
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      selectedLoanIndex(index, option);
                    }}
                    data-testid="status-update-list"
                    role="button"
                    onKeyDown={() => {
                      handleClose();
                    }}
                    tabIndex={0}
                    key={option.value}
                    style={{ borderBottom: "1px solid lightgrey" }}
                  >
                    <div className={classes.labelStyle}>{option.label} </div>
                    {option.isReadyForSubmit && (
                      <div className={classes.iconStyle}>
                        <CheckCircleIcon className={classes.checkCircleStyle} />
                      </div>
                    )}
                  </MenuItem>
                );
              })}
            </div>
          </Menu>
        </>
      </div>
    </>
  );
};
