/* eslint-disable no-restricted-globals */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-sequences */
/* eslint-disable no-param-reassign */
/* eslint-disable no-return-assign */

/** ------------ Compare objects and get the difference with keys--------------------- */
// https://stackoverflow.com/questions/33232823/how-to-compare-two-objects-and-get-key-value-pairs-of-their-differences
export const isObject = (x: any) => Object(x) === x;

export const { isArray } = Array;

export const mut = (
  o: { [x: string]: any },
  [k, v]: any
): { [x: string]: any } => ((o[k] = v), o);

export const diff: any = (
  left: { [x: string]: any } = {},
  right: { [x: string]: any } = {},
  rel: string = "left"
) =>
  Object.entries(left)
    .map(([k, v]) =>
      isObject(v) && isObject(right[k])
        ? [k, diff(v, right[k], rel)]
        : right[k] !== v
        ? [k, { [rel]: v }]
        : [k, {}]
    )
    .filter(([k, v]) => Object.keys(v).length !== 0)
    .reduce(mut, isArray(left) && isArray(right) ? [] : {});

export const merge: any = (
  left: { [x: string]: any } = {},
  right: { [x: string]: any } = {}
) =>
  Object.entries(right)
    .map(([k, v]) =>
      isObject(v) && isObject(left[k]) ? [k, merge(left[k], v)] : [k, v]
    )
    .reduce(mut, left);

export const getObjectDifference = (x = {}, y = {}) =>
  merge(diff(x, y, "left"), diff(y, x, "right"));

/** ----- Object diff end ------------ */
/**
 *
 * @param keyPrefix key prefix to be added in addition to the keys we get from diff. ex: data.properties.
 * @param comment comment to be added to audit request body. TO-DO: this is a single comment now. Have to build functions to support field level comment.
 */
export const getAudit = (data: {
  oldObj: any;
  newObj: any;
  keyPrefix: string;
  comment: string;
  updatedById: string;
  ruleFieldMap?: { [index: string]: string };
  getChangedFieldIds?: boolean; // returns field keys matching loan, property and result service. Need this to check impacted results and reopen waiver.
}) => {
  const {
    oldObj,
    newObj,
    keyPrefix,
    comment,
    updatedById,
    getChangedFieldIds,
    ruleFieldMap = {}
  } = data;
  let changedFields: string[] = [];
  let changedRuleFields: string[] = [];
  const difference = getObjectDifference(oldObj, newObj);
  const auditObject = getAuditRequestBody(
    difference,
    keyPrefix,
    [],
    newObj,
    comment,
    updatedById
  );
  if (getChangedFieldIds) {
    const { fieldIds, ruleFieldIds } = getChangedFields(
      auditObject || [],
      ruleFieldMap
    );
    changedFields = fieldIds;
    changedRuleFields = ruleFieldIds;
  }
  return {
    auditObject,
    changedFields,
    changedRuleFields
  };
};
function getKey(k: string, key: string, updatedObj: any) {
  const lastKey = key.split(".").pop() || key;
  if (lastKey.includes("properties")) {
    return updatedObj?.[k]?.loanPropertyId;
  }
  if (lastKey.includes("propertyUnit")) {
    /**
     * Right now the property unit audit is getting saved with property unit index in the key. This is wrong since units can be added/deleted and index is not same everytime. 
     * Once back end migration is done, uncomment below code.
     */
    // const propertyId = key.charAt(16); // key will be something like data.properties[1]. propertyId will be at 16th position in this key when we are checking propertyUnit difference.
    // const currentProp = updatedObj.find(
    //   (ele: any) => ele.loanPropertyId === Number(propertyId)
    // );
    // return currentProp?.propertyUnit?.[k]?.propertyUnitId;
    return k;
  }
  return k;
}

function getChangedFields(
  auditObject: any[],
  ruleFieldMap: { [index: string]: string }
) {
  const fieldIds: string[] = [];
  const ruleFieldIds: string[] = [];
  if (auditObject.length) {
    auditObject.forEach((ele: any) => {
      const { field = "" } = ele;
      const key = field.split(".").pop();
      fieldIds.push(key);
      ruleFieldIds.push(ruleFieldMap[key] || key);
    });
  }
  console.log(auditObject, fieldIds, ruleFieldIds);
  return { fieldIds, ruleFieldIds };
}
function getAuditRequestBody(
  obj: any,
  key: string,
  arr: any[],
  updatedObj: any[],
  comment: string,
  updatedById: string
) {
  if (typeof obj !== "object" || obj.right !== undefined) {
    arr.push({ field: key, value: obj.right, comment, updatedBy: updatedById });
    return;
  }
  Object.keys(obj).map(k => {
    const cur = obj[k];
    const newKey = isNaN(Number(k))
      ? `${key}.${k}`
      : `${key}[${getKey(k, key, updatedObj)}]`;
    return getAuditRequestBody(
      cur,
      newKey,
      arr,
      updatedObj,
      comment,
      updatedById
    );
  });
  return arr;
}
