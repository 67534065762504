import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  budgetApproveStatusEnum,
  budgetReviewStatusEnum
} from "../../ilp/list-view/BudgetTaskManagement";
import {
  calculateBulkBudgetSum,
  //  UPDATE_BULK_BUDGET_SUM,
  // calculateBulkBudgetSum,
  getBulkBudgetReviewData,
  updateBudgetReviewBulk,
  updateStatusOnDocument,
  updateStatusOnTask
} from "../../stores/budgetReview/budgetReview.action";
import {
  BudgetReviewStore,
  BulkBudgetSumInterface
} from "../../stores/budgetReview/budgetReview.interface";
import { RootState } from "../../stores/rootReducer";
import { getCookie } from "../../utils/cookies";
import { isLoginPartyId } from "../BudgetReview.constant";
import { taskNames } from "../../stores/tasks/Tasks.action";
import { updateBulkBudgetSum } from "../../stores/budgetReview/budgetReview.reducer";

export function ReadyForReviewBtn({
  hasCallProceed,
  setHasCallProceed,
  showCommentPopup,
  setShowCommentPopup,
  setShowToast
}: any) {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isApprovalDisabled, setIsApprovalDisabled] = useState<boolean>(true);
  const [isRejectDisabled, setIsRejectDisabled] = useState<boolean>(true);
  const {
    budgetStatusHistory,
    budgetApprovalHistory,
    BulkBudgetSum,
    budgetErrors,
    budgetReviewHeader
  } = useSelector<RootState, BudgetReviewStore>(
    (state) => state.budgetReviewStore
  );

  const { tasks } = useSelector<RootState, any>((state) => state.TasksStore);

  const { loanId } = useParams<{
    loanId: string;
  }>() as any;
  const dispatch = useDispatch();

  const [showApproveBttn, setShowApproveBttn] = useState<boolean>(false);
  const {
    Approved,
    Rejected,
    InProgress,
    ReadyForBudgetApproval,
    ReadyForBudgetReview
  } = budgetReviewStatusEnum;

  useEffect(() => {
    if (
      budgetApprovalHistory.length &&
      isLoginPartyId(budgetApprovalHistory[0].assigneePartyId)
    ) {
      setShowApproveBttn(true);
    } else setShowApproveBttn(false);
  }, [budgetApprovalHistory, budgetReviewHeader.budgetReviewStatus]);

  useEffect(() => {
    const isFirstApprovalAssigneePartyId = budgetApprovalHistory.length
      ? isLoginPartyId(budgetApprovalHistory[0].assigneePartyId)
      : false;
    setIsRejectDisabled(
      isFirstApprovalAssigneePartyId
        ? ![ReadyForBudgetApproval].includes(
            budgetReviewHeader.budgetReviewStatus
          )
        : true
    );

    // eslint-disable-next-line
  }, [budgetReviewHeader.budgetReviewStatus, budgetApprovalHistory]);

  useEffect(
    () => {
      if (budgetErrors.hasErrors) {
        setIsDisabled(true);
        return;
      }
      const isFirstAssigneePartyId = budgetStatusHistory.length
        ? isLoginPartyId(budgetStatusHistory[0].assigneePartyId) &&
          [ReadyForBudgetReview, InProgress].includes(
            budgetReviewHeader.budgetReviewStatus
          )
        : false;
      setIsDisabled(!isFirstAssigneePartyId);

      const isFirstApprovalAssigneePartyId = budgetApprovalHistory.length
        ? isLoginPartyId(budgetApprovalHistory[0].assigneePartyId)
        : false;
      setIsApprovalDisabled(
        isFirstApprovalAssigneePartyId
          ? ![ReadyForBudgetApproval].includes(
              budgetReviewHeader.budgetReviewStatus
            )
          : true
      );
    },
    // eslint-disable-next-line
    [
      BulkBudgetSum,
      budgetErrors,
      budgetReviewHeader.budgetReviewStatus,
      showApproveBttn
    ]
  );

  useEffect(() => {
    if (!loanId) return;
    const calGrandTotal = (docsList: any[]) => {
      if (docsList.length < 1) return;
      const newDocsList = docsList.map((item: any) => {
        let grandbudget = 0;
        let newExtractedInfoProcessed = item.extractedInfoProcessed;
        item.extractedInfoProcessed.lineItems.forEach((line: any) => {
          grandbudget += line.budget;
        });
        newExtractedInfoProcessed = {
          ...newExtractedInfoProcessed,
          ...{ totalRehabBudget: grandbudget }
        };
        return {
          ...item,
          ...{ extractedInfoProcessed: newExtractedInfoProcessed }
        };
      });
      dispatch(updateBudgetReviewBulk(newDocsList));
      const totalVal: BulkBudgetSumInterface =
        calculateBulkBudgetSum(newDocsList);
      dispatch(updateBulkBudgetSum(totalVal));
    };
    dispatch(getBulkBudgetReviewData(loanId, calGrandTotal));
  }, [loanId, dispatch]);

  useEffect(() => {
    if (hasCallProceed.isProceed) {
      handleProceed(showCommentPopup.headerText);
    }
    // eslint-disable-next-line
  }, [hasCallProceed]);

  const handleProceed = async (header: string) => {
    let completionText =
      header === "Ready for Budget Approval"
        ? "Review is Completed Successfully"
        : "Budget Approved Successfully";
    setShowCommentPopup({
      comment: "",
      headerText: header,
      showComment: false
    });
    const userId = getCookie("person_id") || "";

    const updatedTasks: any = JSON.parse(JSON.stringify(tasks));
    let reviewAssignedBy = updatedTasks?.[0]?.assignedTasks.find((task: any) =>
      [taskNames.BUDGET_REVIEW].includes(task.taskName)
    )?.assignedBy;
    if (
      !reviewAssignedBy ||
      ["UnAssigned", "None"].includes(reviewAssignedBy)
    ) {
      reviewAssignedBy = userId;
    }

    let approveAssignedBy = updatedTasks?.[0]?.assignedTasks.find((task: any) =>
      [taskNames.BUDGET_APPROVE].includes(task.taskName)
    )?.assignedBy;
    if (
      !approveAssignedBy ||
      ["UnAssigned", "None"].includes(approveAssignedBy)
    ) {
      approveAssignedBy = userId;
    }

    let assignedReviewTasksObj = {
      op: "replace",
      taskName: "budgetReview",
      assignedBy: reviewAssignedBy,
      assigneePartyId: budgetStatusHistory[0].assigneePartyId,
      taskStatus:
        header === "Ready for Budget Approval"
          ? ReadyForBudgetApproval
          : Approved,
      taskId: budgetStatusHistory[0].taskId,
      comment: showCommentPopup.comment
    };
    let assignedApproveTasksObj = {
      op: "replace",
      taskName: "budgetApprove",
      assignedBy: approveAssignedBy,
      assigneePartyId: budgetApprovalHistory[0].assigneePartyId,
      taskStatus:
        header === "Ready for Budget Approval"
          ? budgetApproveStatusEnum.ReadyForBudgetApproval
          : budgetApproveStatusEnum.Approved,
      taskId: budgetApprovalHistory[0].taskId,
      comment: showCommentPopup.comment
    };
    if (header === "Rejection Comment") {
      completionText = "Budget Rejected Successfully";
      assignedReviewTasksObj = {
        ...assignedReviewTasksObj,
        ...{ taskStatus: Rejected }
      };
      assignedApproveTasksObj = {
        ...assignedApproveTasksObj,
        ...{ taskStatus: budgetApproveStatusEnum.Rejected }
      };
    }
    await updateStatusOnDocument(
      loanId,
      assignedReviewTasksObj.taskStatus,
      dispatch,
      tasks
    );

    dispatch(
      updateStatusOnTask(
        loanId,
        [assignedReviewTasksObj, assignedApproveTasksObj],
        budgetStatusHistory,
        budgetApprovalHistory
      )
    );

    setShowToast({ isShown: true, message: completionText });
    setHasCallProceed({
      isProceed: false,
      isRedo: false
    });
  };

  return (
    <>
      {showApproveBttn ? (
        <>
          <Button
            variant="contained"
            data-testid="reject-btn"
            style={{
              backgroundColor: "#f75676",
              color: "#fff"
            }}
            color="info"
            disabled={isRejectDisabled}
            onClick={() =>
              setShowCommentPopup({
                comment: "",
                headerText: "Rejection Comment",
                showComment: true
              })
            }
          >
            Reject
          </Button>
          <Button
            variant="contained"
            data-testid="approve-btn"
            disabled={isApprovalDisabled}
            color="info"
            onClick={() =>
              setShowCommentPopup({
                comment: "",
                headerText: "Approval Comment",
                showComment: true
              })
            }
            style={{
              backgroundColor: "#2ece89",
              color: "#fff",
              marginLeft: 20
            }}
          >
            Approve
          </Button>
        </>
      ) : (
        <Button
          variant="contained"
          data-testid="review-btn"
          disabled={isDisabled}
          color="info"
          style={{
            color: "#fff",
            backgroundColor: "#5f72e4",
            opacity: isDisabled ? 0.5 : undefined,
            pointerEvents: isDisabled ? "unset" : undefined
          }}
          onClick={() =>
            setShowCommentPopup({
              comment: "",
              headerText: "Ready for Budget Approval",
              showComment: true
            })
          }
        >
          Ready for Budget Approval
        </Button>
      )}
    </>
  );
}
