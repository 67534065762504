import { Grid } from "@mui/material";
import { Pagination } from "@mui/lab";
import { DropDown } from "@toorak/tc-common-fe-sdk";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getServicerData } from "../stores/servicerOnboarding/servicerOnboarding.action";
import { getCookie } from "../utils/cookies";
import { useDrawsStyles } from "./DrawRequest/Draws.styles";

export function getStoredFilter(userId: string, filterName: string) {
  const currentFilters = JSON.parse(localStorage.getItem("filters") || "{}");
  if (!currentFilters[userId]) {
    currentFilters[userId] = { [filterName]: {} };
  }
  const userIdFilterSetting = JSON.parse(
    JSON.stringify(currentFilters?.[userId])
  );
  const tempFilterSetting = userIdFilterSetting[filterName]
    ? userIdFilterSetting[filterName]
    : {};
  return tempFilterSetting;
}

export function saveFilters(
  updatedConfig: any | undefined,
  configType: string | undefined,
  userId: string,
  filterName: string,
  filterSetting?: any | undefined,
  filterUpdateCB?: any,
  page?: number,
  isDropdownChange?: boolean
) {
  const currentFilters = JSON.parse(localStorage.getItem("filters") || "{}");
  if (!currentFilters[userId]) {
    currentFilters[userId] = { [filterName]: {} };
  }
  const tempDrawFilterSetting = currentFilters?.[userId]?.[filterName] ?? {};
  let updatedDrawFilterSetting = filterSetting || {};
  if (!filterSetting && configType) {
    // eslint-disable-next-line
    let filterConfig = updatedConfig;
    if (!updatedConfig) {
      filterConfig = tempDrawFilterSetting?.tableFilterConfig
        ? tempDrawFilterSetting.tableFilterConfig
        : {};
    }
    updatedDrawFilterSetting = {
      ...tempDrawFilterSetting,
      [configType]: updatedConfig,
      page: isDropdownChange ? 1 : page
    };
  }

  currentFilters[userId][filterName] = updatedDrawFilterSetting;
  localStorage.setItem("filters", JSON.stringify(currentFilters));
  if (filterUpdateCB) {
    filterUpdateCB(currentFilters[userId][filterName]);
  }
}

export const ListViewPagination = ({
  filterName,
  tableData,
  getDefaultFilter,
  page,
  setPage,
  totalItemCount,
  pageValueChangeCB,
  filterUpdateCB
}: {
  filterName: string;
  tableData: any[];
  getDefaultFilter: any;
  page: number;
  setPage: any;
  totalItemCount: number;
  filterUpdateCB?: any;
  pageValueChangeCB?: any;
}) => {
  const userId = getCookie("person_id") || "userId";
  const drawClasses = useDrawsStyles();
  const dispatch = useDispatch();
  const [pageLimit, setPageLimit] = useState(25);

  useEffect(() => {
    const tempDrawFilterSetting = getStoredFilter(userId, filterName);
    const pageSize = tempDrawFilterSetting?.pageLimit || 25;
    setPageLimit(pageSize);
    // eslint-disable-next-line
  }, [dispatch, tableData]);

  const pageDropdownHandleChange = (e: any) => {
    const val = e.target.value;
    setPageLimit(val);
    setPage(1);

    saveFilters(
      val,
      "pageLimit",
      userId,
      filterName,
      undefined,
      filterUpdateCB,
      undefined,
      true
    );
  };

  const handlePageValueChange = (value: number) => {
    setPage(value);
    const cb = () => {
      const defaultFilter = getDefaultFilter();
      if (pageValueChangeCB) {
        pageValueChangeCB(defaultFilter);
        return;
      }

      dispatch(
        getServicerData(
          defaultFilter,
          defaultFilter.page,
          defaultFilter.pageLimit
        )
      );
    };
    saveFilters(value, "page", userId, filterName, undefined, cb, value, false);
  };

  const pageGetDropDownValue = () => {
    const list = [25, 50, 100, 500];
    return list.map((item: any) => ({
      value: item,
      label: item
    }));
  };
  return (
    <Grid item container xs={12} sm={12} md={12} xl={12}>
      {tableData.length > 0 && (
        <Grid item container xs={12} style={{ padding: "0 24px" }}>
          <Grid item container xs={4} sm={4} md={4} lg={4} xl={4}>
            <span className={drawClasses.pageText}>Rows per page</span>
            {tableData.length > 0 && (
              <span className={drawClasses.pageDropdown}>
                <DropDown
                  selectionOnChange={(e: any) => {
                    pageDropdownHandleChange(e);
                  }}
                  selectionData={pageGetDropDownValue()}
                  isViewMode={false}
                  style={{
                    width: "10px",
                    marginTop: "0 !important"
                  }}
                  formControlTestId="rows-per-page-dropdown"
                  inputLabelId="rows-per-page-dropdown-label"
                  inputLabelTestId="rows-per-page-dropdown-label-testId"
                  label=""
                  selectId="rows-per-page-dropdown-selectId"
                  selectTestId="rows-per-page-dropdown-select-testId"
                  selectionValue={JSON.stringify(pageLimit)}
                  helperText=""
                  valueTestId="rows-per-page"
                  data-testid="rows-per-page"
                  notRequired
                />
              </span>
            )}
          </Grid>
          <Grid
            item
            container
            xs={8}
            style={{
              justifyContent: "flex-end"
            }}
          >
            <span>
              <label
                htmlFor="hidden"
                className={drawClasses.listData}
                onClick={() => handlePageValueChange(1)}
                onKeyPress={(e) => handlePageValueChange(1)} //to solve lint error
              >
                <input type="hidden" />
                First Page
              </label>
            </span>
            <span className={drawClasses.pageText}>
              <Pagination
                count={Math.ceil(totalItemCount / pageLimit)}
                page={page}
                onChange={(e, val) => handlePageValueChange(val)}
                className={drawClasses.paginate}
              />
            </span>
            <span>
              <label
                htmlFor="hidden"
                className={drawClasses.listData}
                onClick={() =>
                  handlePageValueChange(Math.ceil(totalItemCount / pageLimit))
                }
                onKeyPress={(e) =>
                  handlePageValueChange(Math.ceil(totalItemCount / pageLimit))
                } //to solve lint error
              >
                <input type="hidden" />
                Last Page
              </label>
            </span>
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
