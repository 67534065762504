import {
  createReactRouterV6Options,
  ReactIntegration
} from "@grafana/faro-react";
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType
} from "react-router-dom";

import {
  ConsoleInstrumentation,
  ConsoleTransport,
  ErrorsInstrumentation,
  FetchTransport,
  initializeFaro,
  getWebInstrumentations,
  SessionInstrumentation,
  WebVitalsInstrumentation
} from "@grafana/faro-web-sdk";
import { TracingInstrumentation } from "@grafana/faro-web-tracing";
import { useEffect } from "react";
import eventEmitter from '../../eventEmitter';
import { getCookie } from "../../utils/cookies";
import { getConfig } from "../../config/config";

const appVersion = process.env.REACT_APP_VERSION || '1.0.0';  // Default if not set

const initialize = (url: string, apiKey: string, email: string, userId: string) => {
  if (!apiKey || !url || !email || !userId || (window as any).faroInstance) return;

  console.log('Initializing Faro with:', { apiKey, url, email, userId });

  const faro = initializeFaro({
    instrumentations: [
      ...getWebInstrumentations(),
      new TracingInstrumentation(),
      new ErrorsInstrumentation(),
      new WebVitalsInstrumentation(),
      new ConsoleInstrumentation({ disabledLevels: [] }), // Capture console.log
      new SessionInstrumentation(),
      new ReactIntegration({
        router: createReactRouterV6Options({
          createRoutesFromChildren,
          matchRoutes,
          Routes,
          useLocation,
          useNavigationType
        })
      })
    ],
    transports: [
      new FetchTransport({
        url,
        apiKey
      }),
      new ConsoleTransport()
    ],
    app: {
      name: 'tc-web-app-service',
      version: appVersion
    }
  });

  faro.api.setUser({
    email,
    id: userId
  });

  (window as any).faroInstance = faro;
};

const useFaroInitializer = () => {
  useEffect(() => {
    const email = getCookie("email") as string;
    const personId = getCookie("person_id") as string;
    const config = getConfig();

    // Initial Faro initialization
    initialize(config.faroUrl, config.faroAPIKey, email, personId);
    
    const handleUserLogin = () => {
      const newEmail = getCookie("email") as string;
      const newPersonId = getCookie("person_id") as string;
      const newConfig = getConfig();
      initialize(newConfig.faroUrl, newConfig.faroAPIKey, newEmail, newPersonId);
    };

    eventEmitter.addEventListener('userLogin', handleUserLogin);

    // Cleanup
    return () => {
      eventEmitter.removeEventListener('userLogin', handleUserLogin);
    };
  }, []); // Empty dependency array since the initialization relies on fresh values on each render
};
export default useFaroInitializer;