import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { WaiverWrapper } from "./WaiverWrapper";
import { GuidelinesAndPricingComparision } from "..";
import { ProgrammeSelectionModalDropdown } from "./ProgrammeSelectionModalDropdown";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../stores/rootReducer";
import { usePricingAndGuidlinesStyles } from "../PricingAndGuidlinesSection.style";

interface PricingAndGuidlinesSectionModalBodyProps {
  showWaiverPage: boolean;
  onRequestWaiverClick: (waiverDetails: any) => void;
  rateSheetTypeData: {
    pricingType: string;
  };
  waiverDetails: any;
  selectedProgramDetails: any;
  setGlobalSelected: (value: any) => void;
  setGlobalFilteredPartners: (value: any) => void;
  setLoanDataForWaiver: any;
  handleBack: () => void;
  dropDownLoanOptions: any[];
  setDropDownLoanOptions: any;
  selectedLoan: any;
  setSelectedLoan: any;
  loansWithoutAssignedPartner?: any[];
  criticalTests?: any;
}

function SelectLoan({
  selectedLoan,
  handleChange,
  dropDownLoanOptions,
  classes
}: any) {
  const totalReadyLoans = dropDownLoanOptions.filter(
    (item: any) => item.isReadyForSubmit
  ).length;
  return (
    <>
      <Grid item xs={5} data-testid="Programme-Selection-Modal-Dropdown">
        <ProgrammeSelectionModalDropdown
          DropDownList={dropDownLoanOptions}
          handleDropdownSelection={handleChange}
          label={selectedLoan.label}
        />
      </Grid>
      <Grid item xs={1}></Grid>
      <Grid item xs={3}>
        <div
          // label="Loan Ready For Submission <b>1/5</b>"
          className={classes.highlightText}
          color="secondary"
          data-testid="Loan-Ready-For-Submission"
        >
          {dropDownLoanOptions?.length > 1 ? `Loans ` : "Loan "} Ready For
          Submission{" "}
          <b style={{ fontSize: 14 }}>
            {totalReadyLoans}/{dropDownLoanOptions.length}
          </b>
        </div>
      </Grid>
    </>
  );
}

export function PricingAndGuidlinesSectionModalBody({
  showWaiverPage,
  onRequestWaiverClick,
  rateSheetTypeData,
  waiverDetails,
  selectedProgramDetails,
  setGlobalSelected,
  setGlobalFilteredPartners,
  handleBack,
  setLoanDataForWaiver,
  dropDownLoanOptions,
  setDropDownLoanOptions,
  selectedLoan,
  setSelectedLoan,
  loansWithoutAssignedPartner,
  criticalTests
}: PricingAndGuidlinesSectionModalBodyProps) {
  const classes = usePricingAndGuidlinesStyles();
  const [firstTimeOnly, setFirstTimeOnly] = useState<boolean>(true);
  const { loanId } = useParams<{
    loanId: string;
  }>() as any;
  const { waiversCreated } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );

  useEffect(() => {
    const selectedLoanNew = { ...selectedLoan };
    selectedLoanNew.waiverDetails = waiversCreated;
    selectedLoanNew.selectedProgramDetails = selectedProgramDetails;
    setSelectedLoan(selectedLoanNew);
    const dropDownLoanOptionsNew = [...dropDownLoanOptions];
    const index = dropDownLoanOptionsNew.findIndex(
      (obj) => obj.value === selectedLoan.value
    );
    if (index !== -1) {
      dropDownLoanOptionsNew[index].waiverDetails = waiversCreated;
      dropDownLoanOptionsNew[index] = selectedLoanNew;
    }
    setDropDownLoanOptions(dropDownLoanOptionsNew);
    if (firstTimeOnly) {
      setSelectedLoan(dropDownLoanOptionsNew[0]);
      setFirstTimeOnly(false);
    }
    // eslint-disable-next-line
  }, [waiversCreated, selectedProgramDetails]);

  const handleChange = (data: any) => {
    if (data.value === selectedLoan.value) return;
    setSelectedLoan(data);
    setGlobalSelected(data.selectedProgramDetails);
    setLoanDataForWaiver({ loanIdForWaiver: data.value });
  };

  return (
    <>
      {showWaiverPage ? (
        <WaiverWrapper
          selectedLoan={selectedLoan}
          waiverDetails={waiverDetails}
          selectedProgramDetails={selectedProgramDetails}
          callback={handleBack}
        />
      ) : (
        <>
          <Grid container justifyContent="center" alignItems="center">
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  xs={12}
                >
                  {loansWithoutAssignedPartner?.length ? (
                    <SelectLoan
                      classes={classes}
                      selectedLoan={selectedLoan}
                      handleChange={handleChange}
                      dropDownLoanOptions={dropDownLoanOptions}
                    />
                  ) : null}
                  <GuidelinesAndPricingComparision
                    loanId={
                      loansWithoutAssignedPartner?.length
                        ? selectedLoan?.value
                        : loanId
                    }
                    programSelectConfig={{
                      isProgramSelectView: true,
                      onRequestWaiverClick: onRequestWaiverClick,
                      setGlobalSelected: setGlobalSelected,
                      setGlobalFilteredPartners: setGlobalFilteredPartners,
                      selectedProgramDetails: selectedProgramDetails,
                      criticalTests
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
}
