import { getConfig } from "../../config/config";

export const countDrawErrors = (drawObj: any, selectedBankDetails: any) => {
  let loanerrorCount = 0;
  const { drawAmountDetails } = drawObj;
  const fieldsToIgnore = [
    "drawAmountDetails",
    "balanceToFinish",
    "originatorId",
    "propertyDetailsOptions",
    "drawType",
    "loanStatus",
    "finalApproval",
    "finalApprovalNotRequired",
    "originalMaturityDate", //todo:remove these below fields later
    "maturityDate",
    "currentMaturityDate",
    "nextPaymentDueDate",
    "gracePeriod",
    "payoffRequestDate",
    "availableFinancedBudgetAmount",
    "drawType",
    "loanExcelJson",
    "originatorName",
    "servicerId",
    "servicerName",
    "borrowerAddress",
    "requestedDate",
    "lastUpdatedDate",
    "documentTaggingStatus",
    "budgetReconcileStatus",
    "inspectionReviewStatus"
  ];
  // if (["development", "qa"].includes(getConfig().environment)) {
  //   fieldsArr.push("documentTaggingStatus");
  //   fieldsArr.push("budgetReconcileStatus");
  //   fieldsArr.push("inspectionReviewStatus");
  // }
  Object.keys(drawObj).forEach((field) => {
    if (fieldsToIgnore.includes(field)) return;
    if (!drawObj[field]) {
      if (field === "propertyDetails" && !drawObj[field]?.length) return;
      console.error("field Error:", field);

      loanerrorCount++;
    }
    if (field === "propertyDetails") {
      if (drawObj["propertyDetails"]?.length === 0) {
        console.error("field Error:", field);

        loanerrorCount++;
      } else {
        return;
      }
    }
  });

  Object.keys(drawAmountDetails).forEach((field) => {
    if (["edited", "stage"].includes(field)) return;
    let isZeroNetFunds: boolean = false;
    if (field === "netFundsToBorrower") {
      isZeroNetFunds = drawAmountDetails[field] === "0.00" ? true : false;
    }

    if (
      (!drawAmountDetails[field] && drawAmountDetails[field] !== 0) ||
      isZeroNetFunds
    ) {
      console.error("field Error:", field);
      loanerrorCount++;
    }
  });

  if (selectedBankDetails) {
    Object.keys(selectedBankDetails).forEach((field) => {
      if (["netFundsToBorrower", "edited", "bankDetailId"].includes(field))
        return;
      if (!selectedBankDetails[field]) {
        console.error("field Error:", field);
        loanerrorCount++;
      }
    });
  } else {
    loanerrorCount = loanerrorCount + 4;
  }
  return loanerrorCount;
};
