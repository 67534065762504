import { Button, ClickAwayListener } from "@mui/material";
import React from "react";
import { useDrawsStyles } from "../../assetManagement/DrawRequest/Draws.styles";
import arrowUpDark from "../../images/arrow-up.svg";
import arrowDownDark from "../../images/arrow-down.svg";
import { useBudgetStyles } from "../BudgetReview.style";
import { HtmlWhiteTooltip } from "../../utils/Tooltip";
import {
  DropdownWithTooltipInterface,
  getDisplayIcon
} from "./DropdownWithTooltip";
import { DocumentsAutoCompleteILP } from "../../create-loan/documents/DocumentsAutoCompleteILP";

export function DropdownWithSearch(props: DropdownWithTooltipInterface) {
  const {
    MenuItemOptions,
    tooltipText,
    rowData,
    BttnText,
    onMenuItemClick,
    styleMenuCard,
    displayIcon,
    isDisabled,
    customAutocompleteStyle,
    popperCustomStyle,
    renderOnlyCustomStyle
  } = props;
  const drawClasses = useDrawsStyles();
  const classes = useBudgetStyles();
  const [openDropDown, setOpenDropDown] = React.useState<boolean>(false);
  //eslint-disable-next-line
  const [inputRef, setInputRef] = React.useState<any>();

  const handleClose = () => {
    setOpenDropDown(false);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    // setAnchorEl(event.currentTarget);
    setOpenDropDown(true);
  };

  return (
    <>
      <Button
        id={`basic-button-${rowData.id}`}
        aria-controls={openDropDown ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={openDropDown ? "true" : undefined}
        onClick={handleClick}
        className={`more-action-iconBtn ${drawClasses.commonDrawsListDD}`}
        style={styleMenuCard ? styleMenuCard : null}
        disabled={isDisabled}
      >
        {displayIcon ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <span className={drawClasses.commonListDDText}>
              {getDisplayIcon(displayIcon)}
            </span>
            {rowData.commentId && (
              <HtmlWhiteTooltip arrow placement="left" title="Comment">
                <span className={classes.withComments}>
                  <sup>*</sup>
                </span>
              </HtmlWhiteTooltip>
            )}
          </div>
        ) : (
          <>
            <HtmlWhiteTooltip title={tooltipText} placement="right" arrow>
              <span
                style={{ fontWeight: 400, color: "#32325d" }}
                className={drawClasses.commonListDDText}
              >
                {BttnText}
              </span>
            </HtmlWhiteTooltip>
            {openDropDown ? (
              <img src={arrowUpDark} alt="arrow-Up-Dark" />
            ) : (
              <img src={arrowDownDark} alt="arrow-Down-Dark" />
            )}
          </>
        )}
      </Button>
      <div
        style={{
          position: "relative",
          zIndex: 1,
          width: "93%"
        }}
      >
        {openDropDown && (
          <>
            <div
              // className={classes.mainWrapper}
              style={{ height: "auto", width: "100%" }}
            >
              <div
                style={{
                  height: "auto",
                  width: "100%"
                }}
              >
                <ClickAwayListener onClickAway={() => handleClose()}>
                  <div>
                    <DocumentsAutoCompleteILP
                      docsTags={MenuItemOptions}
                      values={[]}
                      onTagSelection={(e: any, key?: any) => {
                        handleClose();
                        onMenuItemClick(key, rowData.id, rowData);
                      }}
                      customeStyle={{
                        position: "absolute",
                        width: "95%",
                        marginTop: "3px"
                      }}
                      closeDropdown={() => {
                        // inputRef.focus();
                        return null;
                      }}
                      setInputRef={setInputRef}
                      customAutocompleteStyle={customAutocompleteStyle}
                      popperCustomStyle={popperCustomStyle}
                      renderOnlyCustomStyle={renderOnlyCustomStyle}
                    />
                  </div>
                </ClickAwayListener>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
