export const usersFormFields = [
  {
    id: "form-first-name",
    label: "First Name",
    autoFocus: true,
    key: "firstName",
    isViewMode: false,
    inputType: "Text",
    isMandatory: true
  },
  {
    id: "form-middle-name",
    label: "Middle Name",
    autoFocus: false,
    key: "middleName",
    isViewMode: false,
    inputType: "Text",
    isMandatory: false
  },
  {
    id: "form-last-name",
    label: "Last Name",
    autoFocus: false,
    key: "lastName",
    isViewMode: false,
    inputType: "Text",
    isMandatory: true
  },
  {
    id: "form-email",
    label: "Primary e-mail",
    autoFocus: false,
    key: "primaryEmail",
    isViewMode: false,
    inputType: "Text",
    isMandatory: true
  },
  {
    id: "form-originator-name",
    label: "Originator Name",
    autoFocus: false,
    key: "selectedOrig",
    isViewMode: true,
    inputType: "Text",
    isMandatory: false
  },
  {
    id: "form-originator-roles",
    label: "Roles",
    autoFocus: false,
    key: "roles",
    isViewMode: true,
    inputType: "dropdown",
    isMandatory: false
  },
  {
    id: "form-originator-mapping",
    label: " Originator Mapping",
    autoFocus: false,
    key: "originatorMapping",
    isViewMode: true,
    inputType: "dropdown",
    isMandatory: false
  }
];

export const originatorUserFormFields = [
  {
    id: "form-first-name",
    label: "First Name",
    autoFocus: true,
    key: "firstName",
    isViewMode: false,
    inputType: "Text",
    isMandatory: true
  },
  {
    id: "form-middle-name",
    label: "Middle Name",
    autoFocus: false,
    key: "middleName",
    isViewMode: false,
    inputType: "Text",
    isMandatory: false
  },
  {
    id: "form-last-name",
    label: "Last Name",
    autoFocus: false,
    key: "lastName",
    isViewMode: false,
    inputType: "Text",
    isMandatory: true
  },
  {
    id: "form-email",
    label: "Primary e-mail",
    autoFocus: false,
    key: "primaryEmail",
    isViewMode: false,
    inputType: "Text",
    isMandatory: true
  }
];
