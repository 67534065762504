/* eslint-disable default-case */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, ChangeEvent, useEffect, useMemo } from "react";
import { Typography, Grid, Button, Dialog, Link } from "@mui/material";
import {
  ExpandCollapse,
  GridTextFieldForm,
  RadioButton,
  WarningModal
} from "@toorak/tc-common-fe-sdk";
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import { useParams } from "react-router-dom";
import { useStyles } from "./FrontendSizer.style";
import {
  sanitizeNumericInput,
  formatValueByType,
  checkFieldValidity,
  sanitizeValueByType,
  formatNumberToCommaSeparated,
  getErrorMessage,
  isEmptyValue
} from "../utils/formatChecks";
import { ToorakCondoQuest } from "./ToorakCondoQuest";
import {
  DropDownComponent,
  AlphaNumericComponent,
  DatePickerComponent
} from "./FrontendSizerComponents";
import moment from "moment";
import { Section, Fields, LoanSizerEnum } from "./FrontendSizerTemplate";
import { RootState } from "../stores/rootReducer";
import {
  // fesEditSection, //redux not getting updated anywhere
  // fesSectionPrevState,
  getFesRulesRequestObject,
  postFesLoan,
  postFesPropertyData
  // updateFesLoanDetails
  // updateFesPropertyDetails
} from "../stores/FrontEndSizer/fesLoanCreation.action";
import editImage from "../images/Edit.svg";
import LaunchIcon from "@mui/icons-material/Launch";
import {
  deleteOverriddenResult,
  evaluateLoan,
  getOverriddenOutputs,
  getRuleResult,
  // upadteRuleResults,
  updateOverriddenInredux
} from "../stores/EvaluationResults/EvaluationResults.action";
import {
  // CONDO_QUEST_OPENED,
  editCommentMissing,
  keepCreditEvalSync,
  updateEditComments,
  validateForm
} from "../create-loan/create-loan.action";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { isLATUser } from "../utils/AccessManagement";
import { getCookie } from "../utils/cookies";
import { getLoanType } from "../config/config";
import { MMMDDYYYYDateFormat } from "../utils/utils";
import {
  hideLoader,
  showLoader
} from "../stores/loaderAndException/loaderAndException.action";
import {
  calculateFesPropertyError,
  countFesErrors
} from "../create-loan/ErrorCounter";
import { setAutoSaveValuesCall } from "../stores/loanAndPropertyDetails/loanAndPropertyDetails.reducer";
import { getAffectedWaivers } from "../stores/WaiverRequest/WaiverRequest.action";
import { requestWaiverMessage } from "../utils/constants";
import { crossCollaterlizationData } from "../create-loan/constants/loanCreationDropDownValues";
import { CheckBox } from "../ui-components/CheckBox";
import {
  condoQuestOpened,
  setSectionPreviousState,
  updateFesLoanDetails,
  updateFesPropertyDetails
} from "../create-loan/create-loan.reducer";
import { updateRuleResults } from "../stores/EvaluationResults/EvaluationResults.reducer";
// export let toorakCondoQuest:any;
let commentObj: any = [];
export let fesEditFunctionSave: any;
export function prepareCommentObjectFes(
  field: string,
  value: any,
  comment: string,
  clearComments?: boolean,
  valueChange?: boolean
) {
  if (clearComments) {
    commentObj = [];
    return;
  }
  // if (!isLATUser()) {
  //   comment = "";
  // }
  //
  for (let i = 0; i < commentObj.length; i++) {
    if (commentObj[i].field === field) {
      if (valueChange) {
        commentObj[i].value = value;
        return;
      }
      if (comment.trim() === "") {
        commentObj.splice(i, 1);
        return;
      }

      commentObj[i].comment = comment;
      commentObj[i].value = value;
      return;
    }
  }
  if (comment.trim() === "" && !valueChange) {
    return;
  }
  const fieldCommentObj = {
    field,
    value,
    comment,
    // prevValue:"2",
    updatedBy: getCookie("person_id")
  };
  commentObj.push(fieldCommentObj);
}
export interface ErrorObject {
  [index: string]: string;
}
export function FrontendSizerSectionMapping(props: {
  sectionData: Section;
  isEvaluationPanelVisible: boolean;
  tabType: string;
  activePropertyId?: string;
}) {
  const { sectionData, isEvaluationPanelVisible, tabType, activePropertyId } =
    props;
  const { sectionId, sectionLabel, fields, sectionName } = sectionData;
  const colSpan = 4;

  const classes = useStyles();
  const dispatch = useDispatch();
  const [isMinimized, setIsMinimized] = useState<boolean>(false);

  const {
    loanState,
    loanSizerType
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = useSelector<RootState, any>((state) => state.createLoanStore.loanDetails);
  const { takeoutPartnerId } = useSelector<RootState, any>(
    (state) => state.createLoanStore?.loanDetails.fesLoanInformation
  );
  const dataInRedux = useSelector<RootState, any>((state) => {
    if (tabType === "loan") {
      return state.createLoanStore.loanDetails[sectionName];
    }
    const currentProperty: { [index: string]: any } | undefined =
      state.createLoanStore.loanDetails.propertyDetails.find(
        (element: { propertyId: string | undefined }) =>
          element.propertyId === activePropertyId
      );
    if (currentProperty) {
      return currentProperty[sectionName];
    }
  });
  const validate = useSelector<RootState, boolean>(
    (state) => state.createLoanStore.validateForm.validate
  );
  const overriddenValues = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore.overriddenValues
  );
  const { loanDetails, isViewMode, condoQuestOpen } = useSelector<
    RootState,
    any
  >((state) => state.createLoanStore);
  const { waiversCreated, loanEvaluationResult } = useSelector<RootState, any>(
    (state) => state.evaluationResultsStore
  );
  const [isEditable, setEditable] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [resetData, setResetData] = useState(false);
  const [condoValue, setCondoValue] = useState<any>("");

  const [originalSectionObject, setOriginalSectionObject] = useState<any>();
  const [fieldErrors, setFieldErrors] = useState<ErrorObject>({});
  const [showClearPopup, setShowClearPopup] = useState(false);
  const [reEvalCondo, setReEvalCondo] = useState(false);

  let [changedValueFields, setChangedValueFields] = useState<{
    [index: string]: boolean;
  }>({});
  const { loanId, loanType, loanStage } = useParams<any>() as any;
  const reasonForEdit = "Reason For Editing";
  const {
    propertyDetails

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } = loanDetails;

  const reEvaluationResult = async (
    isSubmitting: boolean,
    takeoutPartnerId?: string,
    changedField?: any,
    unitSection?: boolean,
    isFromGO?: any
  ) => {
    dispatch(showLoader());
    let changedFieldsArray: any = [];
    const loanDetailsErrorCount =
      countFesErrors(loanDetails, loanType, loanSizerType)?.loanerrorCount || 0;
    let propertyInfoAndEconomicsError = 0;
    if (propertyDetails?.length) {
      propertyDetails.forEach((ele: any) => {
        propertyInfoAndEconomicsError += calculateFesPropertyError(
          ele,
          loanType,
          loanSizerType
        );
      });
    }
    if (loanDetailsErrorCount > 0 || propertyInfoAndEconomicsError > 0) {
      dispatch(validateForm(true));
      handleClose();
      dispatch(hideLoader());
      return false;
    }
    if (changedField) {
      changedValueFields = changedField;
      // commentObj = comments;
    }
    if (!Array.isArray(changedValueFields)) {
      // eslint-disable-next-line
      Object.keys(changedValueFields).map(function (key, index) {
        let newKey = key;
        if (key === "squareFootage") newKey = "preRehabSquareFootage";
        if (key === "annualHoaFee") newKey = "annualHOAFee";
        changedFieldsArray.push(newKey);
        // dispatch(hideLoader());
      });
    }

    if (isLATUser() && !isFromGO) {
      let commentMissing = false;
      // eslint-disable-next-line
      commentObj.map((item: any) => {
        if (item.comment === "") {
          commentMissing = true;
        }
      });
      if (commentMissing || commentObj.length < changedFieldsArray.length) {
        dispatch(editCommentMissing(true));
        dispatch(hideLoader());
        handleClose();
        return false;
      }
    }
    let overriddenValuesToUse = overriddenValues;
    // skipping when no partner and loan is of dscr
    // https://toorakcapital.atlassian.net/browse/TA-4313
    if (
      changedFieldsArray.includes("originalLoanAmount") &&
      (loanType === getLoanType[0].displayValue ||
        (loanType === getLoanType[1].displayValue && takeoutPartnerId))
    ) {
      // delete  overridden results, LPP-9062
      const delBody = {
        resources: [`loanResult.${loanId}.loanPricing.totalSizerDepositAmount`]
      };
      await deleteOverriddenResult(
        loanId,
        loanStage,
        delBody,
        takeoutPartnerId
      );
      const resp = await getOverriddenOutputs(
        loanId,
        loanStage,
        takeoutPartnerId
      );
      overriddenValuesToUse = resp?.data;
      dispatch(updateOverriddenInredux(resp));
      const ruleResult: any = await getRuleResult(
        loanId,
        loanStage,
        takeoutPartnerId
      );
      dispatch(updateRuleResults(ruleResult));
    }

    // TA-3899 fix fox missing attributes
    if (Array.isArray(changedValueFields)) {
      changedFieldsArray = changedValueFields;
    }
    const { reRequestWaiverObject, openWaivers } = await getAffectedWaivers(
      changedFieldsArray,
      waiversCreated,
      loanId,
      loanStage,
      loanType,
      loanSizerType === LoanSizerEnum.groundUp
    );
    const refreshWaiver =
      (typeof reRequestWaiverObject === "object" &&
        Object.keys(reRequestWaiverObject)?.length) ||
      openWaivers.length;
    // skipping when no partner and loan is of dscr
    // https://toorakcapital.atlassian.net/browse/TA-4313
    if (
      refreshWaiver &&
      (loanType === getLoanType[0].displayValue ||
        (loanType === getLoanType[1].displayValue && takeoutPartnerId))
    ) {
      const resp = await getOverriddenOutputs(
        loanId,
        loanStage,
        takeoutPartnerId
      );
      overriddenValuesToUse = resp?.data;
      dispatch(updateOverriddenInredux(resp));
    }
    let optionalData = JSON.parse(JSON.stringify(loanDetails));
    if (!isFromGO) {
      postFesPropertyData(loanId, propertyDetails, loanSizerType).then(() => {
        setTimeout(() => {
          dispatch(keepCreditEvalSync(true));
        }, 1000);
      });
      // offsetting to keep main thread free
    } else {
      optionalData.fesLoanEconomics.interestRate = `${isFromGO.newInterestRate}`;
    }
    postFesLoan(optionalData, loanId, loanType, loanStage);
    if (!isFromGO) {
      const loanEvaluationRequest = getFesRulesRequestObject(
        optionalData,
        loanId,
        loanType,
        loanStage,
        true
      );

      loanEvaluationRequest.ruleAttributs = {
        ruleGroup: [],
        ruleField: [],
        ruleResult: []
      };
      loanEvaluationRequest.resultObject = overriddenValuesToUse;
      dispatch(
        evaluateLoan(
          loanEvaluationRequest,
          loanId,
          loanStage,
          true,
          false,
          propertyDetails,
          refreshWaiver
        )
      );
      // dispatch(
      //   evaluateLoan(
      //     getFesRulesRequestObject(
      //       loanDetails,
      //       loanId,
      //       "BRIDGE",
      //       loanStage,
      //       isSubmitting,
      //       true,
      //       loanEvaluationResult,
      //       changedFieldsArray
      //     ),
      //     loanId,
      //     loanStage,
      //     isSubmitting,
      //     isSubmitting
      //   )
      // );
      dispatch(updateEditComments(loanId + "_" + loanStage, commentObj));

      if (unitSection !== true) {
        setEditable(!isEditable);
      }
      handleClose();
    }
    commentObj = [];
    setChangedValueFields({});
    // dispatch(fesEditSection(true, sectionName));
    dispatch(hideLoader());
  };
  fesEditFunctionSave = reEvaluationResult;

  useEffect(() => {
    if (resetData) {
      const isLoanType = tabType !== "loan";
      dispatch(
        setSectionPreviousState({
          originalSectionObject,
          keyInfo: sectionName,
          isLoanType
        })
      );
      setResetData(!resetData);
    }
  }, [resetData]);

  useEffect(() => {
    if (reEvalCondo) {
      setShowClearPopup(false);
      dispatch(
        condoQuestOpened({
          condoQuestOpen: false,
          clearCondoValue: true
        })
      );
      //dispatch({
      //       type: CONDO_QUEST_OPENED,
      //     payload: { condoQuestOpen: false, //clearCondoValue: true }
      //    });
      updateRedux("calculatedCondoEligibility", "");
      setReEvalCondo(false);
    }
  }, [reEvalCondo]);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  let { infoContentLoan, infoContentProperty } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  if (infoContentLoan) {
    infoContentLoan = JSON.parse(JSON.stringify(infoContentLoan));
  }
  if (infoContentProperty) {
    infoContentProperty = JSON.parse(JSON.stringify(infoContentProperty));
  }
  const loanTypeMapping =
    loanType === getLoanType[0].displayValue ? "bridge" : loanType;

  let sectionNameComment = sectionName.replace("fes", "");
  sectionNameComment =
    sectionNameComment.charAt(0).toLowerCase() + sectionNameComment.slice(1);
  sectionNameComment =
    sectionName === "fesLoanInformation"
      ? "loanInfo"
      : sectionName === "fesBorrowerInformation"
      ? `loanUserMap[${loanDetails[sectionName].loanUserMapId}]`
      : sectionNameComment;
  if (sectionName === "fesPropertyInformation") {
    sectionNameComment = "propertyinfo";
  }

  // To set the info icon for section
  const sectionInfoData = useMemo(() => {
    let sectionInfoText = sectionName.replace("fes", "");
    sectionInfoText =
      sectionInfoText.charAt(0).toLowerCase() + sectionInfoText.slice(1);
    sectionInfoText =
      sectionName === "fesLoanInformation"
        ? "loanInfo"
        : sectionName === "fesBorrowerInformation"
        ? `loanUserMap`
        : sectionInfoText;
    if (sectionName === "fesPropertyInformation") {
      sectionInfoText = "propertyInfo";
    }
    return sectionInfoText;
  }, [sectionName]);

  useEffect(() => {
    if (validate) {
      let error: ErrorObject = {};
      sectionData.fields
        .filter(
          (ele) => !ele.hideCondition || !ele.hideCondition({ loanSizerType, dataInRedux })
        )
        .forEach((item) => {
          // if item.optionalCondition===false, then check field validity. if item.optionalCondition===true and checkFieldValidity===false, then also check field validity
          if (
            !item.optionalCondition ||
            !item.optionalCondition(dataInRedux) ||
            (!isEmptyValue(dataInRedux[item.fieldName]) &&
              !checkFieldValidity(dataInRedux[item.fieldName], {
                fieldTpe: item.fieldType,
                extraData:
                  item.extraData && item.extraData?.map((ele) => ele.value)
              }))
          ) {
            let errorText = "error...";
            switch (item.fieldType) {
              case "dropDown":
                errorText =
                  dataInRedux[item.fieldName] === ""
                    ? "This field is required"
                    : checkFieldValidity(dataInRedux[item.fieldName], {
                        fieldTpe: "dropDown",
                        extraData: item.extraData?.map((ele) => ele.value)
                      })
                    ? ""
                    : "This field is required";
                break;
              case "number":
                errorText =
                  dataInRedux[item.fieldName] === null ||
                  dataInRedux[item.fieldName] === ""
                    ? "This field is required"
                    : checkFieldValidity(dataInRedux[item.fieldName], {
                        fieldTpe: "number"
                      }) || dataInRedux[item.fieldName] === 0
                    ? ""
                    : "Enter a valid number";
                if (item.fieldName === "borrowerCreditScore") {
                  // if(Number(dataInRedux[item.fieldName]) && )
                  if (
                    dataInRedux[item.fieldName] &&
                    (Number(dataInRedux[item.fieldName]) < 300 ||
                      Number(dataInRedux[item.fieldName]) > 855)
                  ) {
                    errorText =
                      "Original Credit Score cannot be less than 300 or greater than 855";
                  }
                }
                break;
              case "numberNoFormat":
                errorText =
                  dataInRedux[item.fieldName] === null ||
                  dataInRedux[item.fieldName] === ""
                    ? "This field is required"
                    : checkFieldValidity(dataInRedux[item.fieldName], {
                        fieldTpe: "numberNoFormat"
                      }) || dataInRedux[item.fieldName] === 0
                    ? ""
                    : "Enter a valid number";
                if (item.fieldName === "borrowerCreditScore") {
                  // if(Number(dataInRedux[item.fieldName]) && )
                  if (
                    dataInRedux[item.fieldName] &&
                    (Number(dataInRedux[item.fieldName]) < 300 ||
                      Number(dataInRedux[item.fieldName]) > 855)
                  ) {
                    errorText =
                      "Original Credit Score cannot be less than 300 or greater than 855";
                  }
                }
                break;
              case "percentage":
                errorText =
                  dataInRedux[item.fieldName] === ""
                    ? "This field is required"
                    : checkFieldValidity(dataInRedux[item.fieldName], {
                        fieldTpe: "percentage"
                      })
                    ? ""
                    : "Enter a valid percentage value";
                break;
              case "currency":
                errorText =
                  dataInRedux[item.fieldName] === ""
                    ? "This field is required"
                    : checkFieldValidity(dataInRedux[item.fieldName], {
                        fieldTpe: "currency"
                      })
                    ? ""
                    : "Enter a valid currency value";
                break;
              case "string":
                errorText =
                  dataInRedux[item.fieldName] === ""
                    ? "This field is required"
                    : checkFieldValidity(dataInRedux[item.fieldName], {
                        fieldTpe: "string"
                      })
                    ? ""
                    : "Enter a valid string";
                break;
              case "date-time":
                errorText =
                  dataInRedux[item.fieldName] === null ||
                  dataInRedux[item.fieldName] === ""
                    ? "This field is required"
                    : checkFieldValidity(dataInRedux[item.fieldName], {
                        fieldTpe: "date"
                      })
                    ? ""
                    : getErrorMessage("date", dataInRedux[item.fieldName]);
                break;
              case "radio":
                errorText =
                  dataInRedux[item.fieldName] === null ||
                  dataInRedux[item.fieldName] === ""
                    ? "This field is required"
                    : checkFieldValidity(dataInRedux[item.fieldName], {
                        fieldTpe: "radio",
                        extraData: crossCollaterlizationData
                      })
                    ? ""
                    : getErrorMessage("radio", dataInRedux[item.fieldName]);
                break;
            }
            error = { ...error, ...{ [item.fieldName]: errorText } };
          }
        });
      setFieldErrors(error);
    }
  }, [validate, dataInRedux, loanSizerType]);
  const updateRedux = (fieldToUpdate: string, formattedValue: any) => {
    if (tabType === "loan") {
      dispatch(
        updateFesLoanDetails({
          sectionName,
          fieldName: fieldToUpdate,
          fieldValue: formattedValue
        })
      );
    } else if (activePropertyId) {
      dispatch(
        updateFesPropertyDetails({
          sectionName,
          fieldName: fieldToUpdate,
          fieldValue: formattedValue,
          propertyId: activePropertyId
        })
      );
    }
  };
  const onChange =
    (
      fieldType: string,
      fieldName: string,
      commentMapping?: string,
      ruleFieldName?: string
    ) =>
    (event: any) => {
      if (
        fieldName === "condoEligibility" &&
        loanDetails[sectionName]["calculatedCondoEligibility"] &&
        ruleFieldName !== "reEvaluateCondo"
      ) {
        setCondoValue(event.target.value);
        setShowClearPopup(true);
        return;
      }

      if (ruleFieldName === undefined || ruleFieldName === "") {
        ruleFieldName = fieldName;
      }
      if (!(ruleFieldName in changedValueFields)) {
        if (isEvaluationPanelVisible) {
          setChangedValueFields({
            ...changedValueFields,
            ...{ [ruleFieldName]: true }
          });
        }
      }

      let formattedValue = event.target.value;
      if (fieldName === "includeOutOfPocketBudgetInARLTV") {
        formattedValue = event.target.checked;
      }

      if (ruleFieldName === "reEvaluateCondo") {
        setShowClearPopup(false);
        formattedValue = condoValue;
        setReEvalCondo(true);
      }
      updateRedux(fieldName, formattedValue);
      if (!isEvaluationPanelVisible) {
        dispatch(
          setAutoSaveValuesCall({
            callAutoSave: true,
            tabType,
            changedFieldId: fieldName
          })
        );
      }
      if (fieldName === "foreignNationalString") {
        fieldName = "customer.foreignNationalString";
      }
      if (isEvaluationPanelVisible) {
        let sectionMappingName = `data.${tabType}.${sectionNameComment}.${commentMapping}`;
        if (activePropertyId !== undefined) {
          sectionMappingName = `data.${tabType}[${activePropertyId}].${sectionNameComment}.${commentMapping}`;
        }
        // let propertyId: any = activePropertyId === undefined ? 0 : activePropertyId;
        // let fieldValue = tabType === "loan" && activePropertyId === undefined ? loanDetails[sectionName][fieldName] : propertyDetails[propertyId - 1][sectionName][fieldName];
        const value =
          formattedValue === "SFR" ? "Single Family Detached" : formattedValue;
        prepareCommentObjectFes(sectionMappingName, value, "", false, true);
      }
    };

  const handleCrossChange =
    (
      fieldType: string,
      fieldName: string,
      commentMapping?: string,
      ruleFieldName?: string
    ) =>
    (event: ChangeEvent<{ value: unknown }> | any) => {
      let tempRuleFieldName = ruleFieldName;

      let sectionMappingName = `data.${tabType}.${sectionNameComment}.${commentMapping}`;
      if (
        event?.target.parentElement?.parentElement?.innerText.includes(
          reasonForEdit
        )
      ) {
        prepareCommentObjectFes(
          sectionMappingName,
          dataInRedux[fieldName],
          event.target.value
        );
        return;
      }
      if (tempRuleFieldName === undefined || tempRuleFieldName === "") {
        tempRuleFieldName = fieldName;
      }
      updateRedux(fieldName, event.target.value);
      if (isEvaluationPanelVisible) {
        if (activePropertyId !== undefined) {
          sectionMappingName = `data.${tabType}[${activePropertyId}].${sectionNameComment}.${commentMapping}`;
        }
        prepareCommentObjectFes(
          sectionMappingName,
          event.target.value,
          "",
          false,
          true
        );
        if (!(tempRuleFieldName in changedValueFields)) {
          setChangedValueFields({
            ...changedValueFields,
            ...{ [tempRuleFieldName]: true }
          });
        }
      } else {
        dispatch(
          setAutoSaveValuesCall({
            callAutoSave: true,
            tabType,
            changedFieldId: fieldName
          })
        );
      }
    };

  const handleBlur =
    (fieldType: string, fieldName: string, ruleFieldName?: any) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (ruleFieldName === undefined || ruleFieldName === "") {
        ruleFieldName = fieldName;
      }
      let formattedValue: string | boolean = event.target.value;
      if (fieldName === "includeOutOfPocketBudgetInARLTV") {
        formattedValue = event.target.checked;
      }
      switch (fieldType) {
        case "number":
          formattedValue = formatNumberToCommaSeparated(
            sanitizeNumericInput(event.target.value)
          );
          break;
        case "percentage":
          formattedValue = formatValueByType(
            event.target.value,
            "percentageWithout100x"
          );
          break;
        case "currency":
          formattedValue = formatValueByType(event.target.value, "currency");
          break;
        default: // return same value
      }
      if (
        event?.target.parentElement?.parentElement?.innerText.includes(
          reasonForEdit
        )
      ) {
        if (ruleFieldName === "interestRate") {
          ruleFieldName = "interestRateAsOfCutOffDate";
        }
        let sectionMappingName = `data.${tabType}.${sectionNameComment}.${ruleFieldName}`;
        if (activePropertyId !== undefined) {
          sectionMappingName = `data.${tabType}[${activePropertyId}].${sectionNameComment}.${ruleFieldName}`;
        }

        const propertyId: any =
          activePropertyId === undefined ? 0 : activePropertyId;
        let fieldValue =
          tabType === "loan" && activePropertyId === undefined
            ? loanDetails[sectionName][fieldName]
            : propertyDetails[propertyId - 1][sectionName][fieldName];
        if (fieldType === "date-time") {
          fieldValue = MMMDDYYYYDateFormat(fieldValue);
        } else if (typeof fieldValue !== "boolean") {
          fieldValue = fieldValue.replace("$", "");
          fieldValue = fieldValue.replace("%", "");
        }
        prepareCommentObjectFes(
          sectionMappingName,
          fieldValue,
          event.target.value
        );
        return;
      }
      updateRedux(fieldName, formattedValue);
      if (!isEvaluationPanelVisible) {
        dispatch(
          setAutoSaveValuesCall({
            callAutoSave: true,
            tabType,
            changedFieldId: fieldName
          })
        );
      }
    };

  const onDateChange = (
    date: any,
    fieldName: string,
    ruleFieldName?: string
  ) => {
    if (date) {
      if (!(fieldName in changedValueFields)) {
        if (isEvaluationPanelVisible) {
          setChangedValueFields({
            ...changedValueFields,
            ...{ [fieldName]: true }
          });
        }
      }
      updateRedux(fieldName, date);
      if (!isEvaluationPanelVisible) {
        dispatch(
          setAutoSaveValuesCall({
            callAutoSave: true,
            tabType,
            changedFieldId: fieldName
          })
        );
      }
    }
    let sectionMappingName = `data.${tabType}.${sectionNameComment}.${ruleFieldName}`;
    if (activePropertyId !== undefined) {
      sectionMappingName = `data.${tabType}[${activePropertyId}].${sectionNameComment}.${ruleFieldName}`;
    }
    // let propertyId: any = activePropertyId === undefined ? 0 : activePropertyId;
    // let fieldValue = tabType === "loan" && activePropertyId === undefined ? loanDetails[sectionName][fieldName] : propertyDetails[propertyId - 1][sectionName][fieldName];
    if (isEvaluationPanelVisible) {
      prepareCommentObjectFes(
        sectionMappingName,
        MMMDDYYYYDateFormat(date),
        "",
        false,
        true
      );
    }
  };
  const editsection = (secName: string) => {
    // dispatch(fesEditSection(true, secName));
    setOriginalSectionObject(dataInRedux);
    setEditable(!isEditable);
    // setResetData(!resetData);
  };

  const handleChange =
    (fieldType: string, fieldName: string, ruleFieldName?: any) =>
    (event: ChangeEvent<HTMLInputElement> | ChangeEvent<{ value: string }>) => {
      if (ruleFieldName === undefined || ruleFieldName === "") {
        ruleFieldName = fieldName;
      }
      if (!(fieldName in changedValueFields)) {
        if (isEvaluationPanelVisible) {
          setChangedValueFields({
            ...changedValueFields,
            ...{ [ruleFieldName]: true }
          });
        }
      }
      const formattedValue = sanitizeValueByType(event.target.value, fieldType);
      if (fieldName === "numberOfProperties") {
        if (formattedValue <= 1) {
          const fieldsToBeUpdated = [
            { fieldLabel: "numberOfProperties", value: formattedValue },
            { fieldLabel: "crossCollaterlization", value: "N" },
          ];
          fieldsToBeUpdated?.map((field) => {
            updateRedux(field.fieldLabel, field.value);
          });
        }
        updateRedux(fieldName, formattedValue);
      } else {
        updateRedux(fieldName, formattedValue);
      }
      if (isEvaluationPanelVisible) {
        let formattedValueForHistory: any = 0.0;
        if (fieldType === "currency") {
          formattedValueForHistory = formatValueByType(
            formattedValue,
            "currency"
          ).replace("$", "");
          //  formattedValueForHistory= formatValueByType(formattedValueForHistory, "currency");
        } else if (fieldType === "percentage") {
          formattedValueForHistory = formatValueByType(
            event.target.value,
            "percentageWithout100x"
          );
          formattedValueForHistory = formattedValueForHistory.replace("%", "");
        } else {
          formattedValueForHistory = formattedValue;
        }
        if (ruleFieldName === "interestRate") {
          ruleFieldName = "interestRateAsOfCutOffDate";
        }
        let sectionMappingName = `data.${tabType}.${sectionNameComment}.${ruleFieldName}`;
        if (activePropertyId !== undefined) {
          sectionMappingName = `data.${tabType}[${activePropertyId}].${sectionNameComment}.${ruleFieldName}`;
        }
        // let propertyId: any = activePropertyId === undefined ? 0 : activePropertyId;
        // let fieldValue = tabType === "loan" && activePropertyId === undefined ? loanDetails[sectionName][fieldName] : propertyDetails[propertyId - 1][sectionName][fieldName];
        prepareCommentObjectFes(
          sectionMappingName,
          formattedValueForHistory,
          "",
          false,
          true
        );
      }
    };
  const editHistory = useSelector<RootState, any>(
    (state) => state.createLoanStore.editHistory
  );
  let editHistory2: any;
  editHistory2 = editHistory;
  const ondropDownFocusOut =
    (
      fieldType: string,
      fieldName: string,
      commentMapping?: string,
      ruleFieldName?: any
    ) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      if (ruleFieldName === undefined || ruleFieldName === "") {
        ruleFieldName = fieldName;
      }
      if (commentMapping === undefined || commentMapping === "") {
        commentMapping = ruleFieldName;
      }
      if (
        event?.target.parentElement?.parentElement?.innerText.includes(
          reasonForEdit
        )
      ) {
        if (fieldName === "foreignNationalString") {
          ruleFieldName = "customer.foreignNationalString";
        }

        let sectionMappingName = `data.${tabType}.${sectionNameComment}.${commentMapping}`;
        if (activePropertyId !== undefined) {
          sectionMappingName = `data.${tabType}[${activePropertyId}].${sectionNameComment}.${commentMapping}`;
        }
        const propertyId: any =
          activePropertyId === undefined ? 0 : activePropertyId;
        const fieldValue =
          tabType === "loan" && activePropertyId === undefined
            ? loanDetails[sectionName][fieldName]
            : propertyDetails[propertyId - 1][sectionName][fieldName];

        const value =
          fieldValue === "SFR" ? "Single Family Detached" : fieldValue;
        prepareCommentObjectFes(sectionMappingName, value, event.target.value);
      }
    };
  const openCondoQuestionPopup = () => {
    console.log("function Triggered");
    //dispatch({
    //      type: CONDO_QUEST_OPENED,
    //      payload: {
    //        condoQuestOpen: true
    //      }
    //    });
    dispatch(
      condoQuestOpened({
        condoQuestOpen: true
      })
    );
    //   data-testId="open-in-new-modal"
  };

  const CondoEligibilityText = (text: string) => {
    return (
      <Link
        component="button"
        style={{
          color: "white",
          fontSize: "12px",
          height: "21px",
          padding: "3px 6px 2px",
          borderRadius: "2px",
          boxShadow: "0.5px 1px 2px 0 rgba(0, 0, 0, 0.2)",
          backgroundColor: "#11ceef",
          pointerEvents: "all",
          textDecoration: "none",
          cursor: "pointer"
        }}
        onClick={openCondoQuestionPopup}
      >
        <span style={{ verticalAlign: "text-top" }}>{text}</span>
        <LaunchIcon onClick={openCondoQuestionPopup} />
      </Link>
    );
  };

  const getEditedData = (
    sectionName: string,
    changeFieldName: any,
    fieldType: any
  ) => {
    let value =
      activePropertyId === undefined
        ? editHistory2[`data.${tabType}.${sectionName}.${changeFieldName}`]
        : editHistory2[
            `data.${tabType}[${activePropertyId}].${sectionName}.${changeFieldName}`
          ];
    let editedValue: any = [];
    let formatType =
      fieldType === "percentage" ? "percentageWithout100x" : fieldType;
    formatType =
      changeFieldName === "closingCost" ? "percentageWithout100x" : formatType;
    value?.forEach((val: any) => {
      const editObj: any = JSON.parse(JSON.stringify(val));
      let prevValue: any;
      let editedVal: any;
      prevValue = formatValueByType(editObj?.preValue, formatType || "string");
      editedVal = formatValueByType(editObj?.value, formatType || "string");
      editObj.preValue = prevValue;
      editObj.value = editedVal;
      editedValue.push(editObj);
    });
    return editedValue;
  };

  return (
    <div
      className={classes.container}
      key={activePropertyId ? `${activePropertyId}-${sectionId}` : sectionId}
      id={sectionId}
    >
      {showClearPopup && (
        <WarningModal
          isOpen={showClearPopup}
          handleClose={onChange(
            "string",
            "condoEligibility",
            "condoEligibility",
            "reEvaluateCondo"
          )}
          handleOptional={() => {
            setShowClearPopup(false);
          }}
          primaryBtnName="Proceed"
          header="Warning"
          body={
            "“Selecting a value in the drop down will override the results from the Condo-Eligibility form. Please confirm to over ride the output of the Condo Eligibility form”."
          }
        />
      )}
      {condoQuestOpen ? <ToorakCondoQuest openDialogProp={true} /> : ""}
      <Grid container spacing={3} className={classes.sectionWrapper}>
        <Grid item xs={12} container>
          <Grid item xs={6} sm={11}>
            <Typography className={classes.headingTypography}>
              {sectionLabel}
            </Typography>
          </Grid>
          <Grid item xs={6} sm={1} className={classes.headingImageContainer}>
            {[LoanStatusEnum.Approved, LoanStatusEnum.Rejected].filter(
              (state: LoanStatusEnum) => loanState === state
            ).length === 0 &&
              sectionName !== "loanCharacterisation" &&
              sectionName !== "loanFeatures" &&
              !isEditable &&
              !isViewMode && (
                <img
                  className={
                    !isEvaluationPanelVisible
                      ? classes.editImageDisplay
                      : classes.editImage
                  }
                  src={editImage}
                  alt="Edit Section"
                  onClick={() => editsection(sectionName)}
                  onKeyDown={() => editsection(sectionName)}
                />
              )}
            <ExpandCollapse
              isMinimize={isMinimized}
              clicked={() => {
                setIsMinimized(!isMinimized);
              }}
              keyDown={() => {
                setIsMinimized(!isMinimized);
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        sm={12}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        <div className={classes.divider} />
      </Grid>
      <Grid
        container
        spacing={6}
        className={classes.sectionWrapper}
        style={{
          display: isMinimized ? "none" : ""
        }}
      >
        {fields
          .filter(
            ele => {
              const loanInformation = dataInRedux;
              return !ele.hideCondition || !ele.hideCondition({ loanSizerType, loanInformation })
            }
          )
          .map((item: Fields) => {
            const {
              fieldId,
              fieldLabel,
              rules,
              fieldName,
              fieldType,
              extraData,
              ruleFieldName,
              optionalCondition
            } = item;
            let changeFieldName =
              ruleFieldName !== undefined
                ? ruleFieldName === "foreignNationalString"
                  ? "customer.foreignNationalString"
                  : ruleFieldName
                : fieldName;
            changeFieldName =
              changeFieldName === "cashOutFlag"
                ? "cashoutFlag"
                : changeFieldName;
            let dropdownMapping: any =
              ruleFieldName !== undefined
                ? ruleFieldName
                : changeFieldName || "";
            if (changeFieldName === "interestRate") {
              changeFieldName = "interestRateAsOfCutOffDate";
            }
            switch (fieldType) {
              case "dropDown":
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={colSpan}
                    xl={colSpan}
                    key={fieldId}
                  >
                    <DropDownComponent
                      formControlTestId={`${fieldId}-formControlTestId`}
                      inputLabelId={fieldId}
                      inputLabelTestId={`${fieldId}-inputLabelTestId`}
                      label={fieldLabel}
                      selectId={`${fieldId}-selectId`}
                      selectTestId={`${fieldId}-selectTestId`}
                      selectionValue={dataInRedux ? dataInRedux[fieldName] : ""}
                      selectionOnChange={onChange(
                        fieldType,
                        fieldName,
                        changeFieldName,
                        ruleFieldName
                      )}
                      selectionData={extraData || []}
                      modalLink={
                        item.fieldName === "condoEligibility"
                          ? CondoEligibilityText("CHECK CONDO ELIGIBILITY")
                          : ""
                      }
                      onBlur={ondropDownFocusOut(
                        fieldType,
                        fieldName,
                        changeFieldName
                      )}
                      helperText={fieldErrors[item.fieldName] || ""}
                      isViewMode={
                        isEvaluationPanelVisible
                          ? fieldName !== "borrowerType"
                            ? isEvaluationPanelVisible && !isEditable
                            : true
                          : false
                      }
                      isLATView={isLATUser()}
                      editedLabel={changedValueFields[dropdownMapping]}
                      editedData={getEditedData(
                        sectionNameComment,
                        changeFieldName,
                        fieldType
                      )}
                      infoIconData={
                        activePropertyId === undefined
                          ? infoContentLoan &&
                            infoContentLoan[
                              `fieldInfo.${loanTypeMapping}.fes.${tabType}.${sectionInfoData}.${changeFieldName}`
                            ]
                          : infoContentProperty &&
                            infoContentProperty[
                              `fieldInfo.${loanTypeMapping}.fes.${tabType}.${sectionInfoData}.${changeFieldName}`
                            ]
                      }
                      notRequired={
                        !optionalCondition || !optionalCondition(dataInRedux)
                      }
                    />
                  </Grid>
                );
              // break;
              case "number":
              case "string":
              case "percentage":
              case "currency":
              case "numberNoFormat":
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={colSpan}
                    xl={colSpan}
                    key={fieldId}
                  >
                    <AlphaNumericComponent
                      required={
                        !optionalCondition || !optionalCondition(dataInRedux)
                      }
                      value={
                        dataInRedux
                          ? fieldType === "numberNoFormat"
                            ? formatNumberToCommaSeparated(
                                dataInRedux[fieldName]
                              )
                            : dataInRedux[fieldName]
                          : ""
                      }
                      error={
                        !!(
                          fieldErrors[item.fieldName] &&
                          fieldErrors[item.fieldName] !== ""
                        )
                      }
                      label={fieldLabel}
                      helperText={fieldErrors[item.fieldName] || ""}
                      onChange={handleChange(
                        fieldType,
                        fieldName,
                        ruleFieldName
                      )}
                      onBlur={handleBlur(fieldType, fieldName, ruleFieldName)}
                      editedLabel={
                        changeFieldName === "interestRateAsOfCutOffDate"
                          ? changedValueFields["interestRate"]
                          : changedValueFields[changeFieldName]
                      }
                      isViewMode={isEvaluationPanelVisible && !isEditable}
                      editedData={getEditedData(
                        sectionNameComment,
                        changeFieldName,
                        fieldType
                      )}
                      infoIconData={
                        activePropertyId === undefined
                          ? infoContentLoan &&
                            infoContentLoan[
                              `fieldInfo.${loanTypeMapping}.fes.${tabType}.${sectionInfoData}.${changeFieldName}`
                            ]
                          : infoContentProperty &&
                            infoContentProperty[
                              `fieldInfo.${loanTypeMapping}.fes.${tabType}.${sectionInfoData}.${changeFieldName}`
                            ]
                      }
                    />
                  </Grid>
                );
              case "date-time":
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={colSpan}
                    xl={colSpan}
                    key={fieldId}
                  >
                    <DatePickerComponent
                      value={dataInRedux ? dataInRedux[fieldName] : null}
                      isViewMode={isEvaluationPanelVisible && !isEditable}
                      data-testid="data-test-id"
                      onChange={(e) => {
                        const temp = moment.utc(e).toDate();
                        // eslint-disable-next-line no-restricted-globals
                        if (e && isNaN(temp?.getTime())) return false;
                        onDateChange(e, fieldName, ruleFieldName);
                      }}
                      label={fieldLabel}
                      error={
                        !!(
                          fieldErrors[item.fieldName] &&
                          fieldErrors[item.fieldName] !== ""
                        )
                      }
                      onBlur={handleBlur(fieldType, fieldName, ruleFieldName)}
                      helperText={fieldErrors[item.fieldName] || ""}
                      editedLabel={changedValueFields[fieldName]}
                      editedData={
                        activePropertyId === undefined
                          ? editHistory2[
                              `data.${tabType}.${sectionNameComment}.${changeFieldName}`
                            ]
                          : editHistory2[
                              `data.${tabType}[${activePropertyId}].${sectionNameComment}.${changeFieldName}`
                            ]
                      }
                      infoIconData={
                        activePropertyId === undefined
                          ? infoContentLoan &&
                            infoContentLoan[
                              `fieldInfo.${loanTypeMapping}.fes.${tabType}.${sectionInfoData}.${changeFieldName}`
                            ]
                          : infoContentProperty &&
                            infoContentProperty[
                              `fieldInfo.${loanTypeMapping}.fes.${tabType}.${sectionInfoData}.${changeFieldName}`
                            ]
                      }
                      required
                    />
                  </Grid>
                );
              case "calculated-section":
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={colSpan}
                    xl={colSpan}
                    key={fieldId}
                  >
                    <GridTextFieldForm
                      required
                      value={dataInRedux ? dataInRedux[fieldName] : ""}
                      isViewMode
                      label={fieldLabel}
                    />
                  </Grid>
                );
              case "formula":
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={colSpan}
                    xl={colSpan}
                    key={fieldId}
                  >
                    <GridTextFieldForm
                      required
                      value={
                        rules
                          ? rules({
                              loanSizerType,
                              loanDetails,
                              activePropertyId,
                              loanEvaluationResult,
                              loanId
                            })
                          : ""
                      }
                      isViewMode
                      label={fieldLabel}
                    />
                  </Grid>
                );
              case "radio":
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={colSpan}
                    xl={colSpan}
                    key={fieldId}
                  >
                    <RadioButton
                      error={Boolean(fieldErrors[fieldName])}
                      label="Cross Collaterlization"
                      onChange={handleCrossChange(
                        fieldType,
                        fieldName,
                        changeFieldName,
                        ruleFieldName
                      )}
                      value={dataInRedux[fieldName] ?? "N"}
                      helperText={fieldErrors[fieldName]}
                      dataTestId="cross-collaterlization-radio-button"
                      isViewMode={isEvaluationPanelVisible && !isEditable}
                      required={!optionalCondition()}
                      data={crossCollaterlizationData}
                      editedLabel={changedValueFields[changeFieldName]}
                      editedData={getEditedData(
                        sectionNameComment,
                        changeFieldName,
                        fieldType
                      )}
                      enableReason={changedValueFields[changeFieldName]}
                      infoIconData={
                        activePropertyId === undefined
                          ? infoContentLoan &&
                            infoContentLoan[
                              `fieldInfo.${loanTypeMapping}.fes.${tabType}.${sectionInfoData}.${changeFieldName}`
                            ]
                          : infoContentProperty &&
                            infoContentProperty[
                              `fieldInfo.${loanTypeMapping}.fes.${tabType}.${sectionInfoData}.${changeFieldName}`
                            ]
                      }
                    />
                  </Grid>
                );
              case "checkbox":
                return (
                  <Grid
                    container
                    item
                    xs={12}
                    sm={6}
                    md={colSpan}
                    xl={colSpan}
                    key={fieldId}
                  >
                    <CheckBox
                      label={fieldLabel}
                      value={dataInRedux ? dataInRedux[fieldName] : ""}
                      onChange={onChange(
                        fieldType,
                        fieldName,
                        changeFieldName,
                        ruleFieldName
                      )}
                      isViewMode={isEvaluationPanelVisible && !isEditable}
                      editedLabel={
                        changeFieldName === "interestRateAsOfCutOffDate"
                          ? changedValueFields.interestRate
                          : changedValueFields[changeFieldName]
                      }
                      editedData={getEditedData(
                        sectionNameComment,
                        changeFieldName,
                        fieldType
                      )}
                      isLATView={isLATUser()}
                      enableReason={changedValueFields[changeFieldName]}
                      onBlur={handleBlur(fieldType, fieldName, ruleFieldName)}
                      gridClassName={classes.checkBoxContainer}
                    />
                  </Grid>
                );
              default:
                return <div> No items to render</div>;
            }
          })}

        {isEditable && (
          <Grid container item xs={12} sm={12} md={12} xl={12}>
            <Button
              variant="contained"
              className={
                isEditable ? classes.savebutton : classes.editImageDisplay
              }
              disabled={Object.keys(changedValueFields).length === 0}
              onClick={() => {
                handleOpen();
              }}
              color="info"
            >
              Save
            </Button>
            <Button
              variant="contained"
              color="info"
              className={
                isEditable ? classes.cancelButton : classes.editImageDisplay
              }
              onClick={() => {
                // dispatch(fesEditSection(false, ""));
                setEditable(!isEditable);
                setResetData(true);
                setChangedValueFields({});
                prepareCommentObjectFes("", "", "", true);
              }}
            >
              Cancel
            </Button>
          </Grid>
        )}
      </Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="duplicate-property-modal"
        aria-describedby="duplicate-property-modal-description"
      >
        <div style={{ padding: 20, width: 500, maxWidth: "100%" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexGrow: 1,
              alignItems: "center"
            }}
          >
            <div style={{ fontSize: 14, fontWeight: 600, color: "#32325d" }}>
              Warning
            </div>
            <CloseIcon
              htmlColor="#414169"
              style={{ width: 16, height: 16, cursor: "pointer" }}
              onClick={() => handleClose()}
            />
          </div>
          <div
            style={{
              fontSize: 12,
              lineHeight: 1.5,
              color: "#32325d",
              marginTop: 40
            }}
          >
            {requestWaiverMessage}
          </div>
          <div className={classes.modalDeleteButtonConatainer}>
            <Button
              variant="contained"
              color="primary"
              data-testid="modal-delete-button"
              className={classes.modalButton}
              onClick={() => {
                handleClose();
                reEvaluationResult(true, takeoutPartnerId);
              }}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              data-testid="modal-cancel-button"
              className={classes.modalCancelButton}
              onClick={() => {
                handleClose();
                // dispatch(fesEditSection(false, ""));
                setEditable(!isEditable);
                setResetData(true);
                setChangedValueFields({});
                prepareCommentObjectFes("", "", "", true);
              }}
            >
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
    </div>
  );
}
