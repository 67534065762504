import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  Button,
  Chip,
  Container,
  Grid,
  IconButton,
  Theme
} from "@mui/material";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import { useParams } from "react-router-dom";
import { ConfirmFormModal, LoanStage } from "@toorak/tc-common-fe-sdk";
import { useDispatch, useSelector } from "react-redux";
// import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import editImage from "../../images/Edit.svg";
import { PricingAndGuidlinesSection } from "./PricingAndGuidlinesSection";
import {
  evaluateLoanRuleResults,
  getCreditLinesByType,
  getOrgSpecificLoanTypes,
  updateTakeOutPartnerData
} from "../../network/apiService";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { BasicDropDown } from "../../ui-components/BasicDropDown";
import { ObjectType } from "../../masterView/common";
import { RootState } from "../../stores/rootReducer";
import { fetchAndDispatchLoan } from "../create-loan.action";
import { getLoanType } from "../../config/config";
import { CreditLineLoanViewContext } from "../CreateLoan";
import { evaluateLoanSuccess } from "../../stores/EvaluationResults/EvaluationResults.reducer";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      flexDirection: "column"
    },
    paper: { maxWidth: "60%" },
    sectionWrapper: {
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3),
      borderRadius: 4
    },
    buttonGroup: {
      padding: "2px 7px 2.4px 7.4px",
      borderRadius: "13px !important",
      fontSize: "10px !important",
      border: "0px",
      height: "auto",
      color: "#32325d",
      "&.Mui-selected": {
        backgroundColor: "#5e72e4 !important",
        color: "#fff !important",
        boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.21)"
      }
    },
    pendingChip: {
      backgroundColor: "rgb(251, 98, 63)",
      fontSize: 11,
      // backgroundColor: "#e9fafd",
      borderRadius: 12,
      color: "#fff",
      height: 22
    },
    selectedButton: {
      backgroundColor: "#5e72e4 !important",
      color: "#fff !important",
      boxShadow: " 0 2px 4px 0 rgba(0, 0, 0, 0.21)"
    },
    root: {
      padding: "5px",
      borderRadius: "13px !important",
      border: "solid 1px #dde1f3",
      backgroundColor: "#fff"
    }
  })
);

export const GuidelinesAndPricingComparision = (props: {
  programSelectConfig: any;
  loanId?: string;
}) => {
  const { programSelectConfig, loanId: propLoanId } = props;
  const { isProgramSelectView, isLockPopUp, takeOutPartnerLists } = programSelectConfig;
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const { loanStage, loanId: paramLoanId } = useParams<{
    loanStage: LoanStage;
    loanId: string;
  }>() as any;
  const loanId = propLoanId || paramLoanId;
  const { thirtyYearLoanInformation, fesLoanInformation } = useSelector<
    RootState,
    any
  >((state) => state.createLoanStore?.loanDetails);
  const { isViewMode } = useSelector<RootState, any>(
    (state) => state.createLoanStore
  );
  const { rateSheetType = "PRICE" } = useSelector<RootState, any>(
    state => state.evaluationResultsStore
  );
  const { takeoutPartnerId, takeoutPartnerStatus } = useMemo(() => {
    if (loanStage === LoanStage.fes) {
      const { takeoutPartnerId, takeoutPartnerStatus } = fesLoanInformation;
      return { takeoutPartnerId, takeoutPartnerStatus };
    }
    const { takeoutPartnerId, takeoutPartnerStatus } =
      thirtyYearLoanInformation?.payload;
    return { takeoutPartnerId, takeoutPartnerStatus };
  }, [loanStage, fesLoanInformation, thirtyYearLoanInformation]);

  const { originatorPartyId } = useSelector<RootState, any>(
    (state) => state.createLoanStore?.loanDetails?.loanDetailId
  );
  const classes = useStyles();
  const dispatch = useDispatch();
  const [takeOutPartnerList, setTakeOutPartnersList] = useState<ObjectType[]>(
    []
  );
  const [takeOutPartner, setTakeOutPartner] = useState<ObjectType | null>(null);
  const [tempPartnerSelected, setTempPartnerSelected] =
    useState<ObjectType | null>(null);
  const [allowTakeOutPartnerChange, setAllowTakeOutPartnerChange] =
    useState(false);
  const {creditLines} = useContext(CreditLineLoanViewContext);

  // const pendingTakeoutPartnerWaivers = useMemo(() => {
  //   if (!waiversCreated.length || !takeoutPartnerId) return [];
  //   const TakeoutPartnerWaivers = waiversCreated.filter((item: any) => {
  //     return item.waiverInfo.finalResponse.toLowerCase() !== "approved";
  //   });
  //   return TakeoutPartnerWaivers;
  // }, [waiversCreated, takeoutPartnerId]);

  const { loanState } = useSelector<RootState, any>(
    (state) => state.createLoanStore.loanDetails
  );

  const disableTpChange = useMemo(() => {
    return [
      "Purchase Initiated",
      "Purchased",
      "Rejected",
      "Withdrawn"
    ].includes(loanState);
  }, [loanState]);

  useEffect(() => {
    const orgToPartnerMap = getOrgSpecificLoanTypes(originatorPartyId);
    dispatch(showLoader());
    orgToPartnerMap.then((res: any) => {
      dispatch(hideLoader());
      const orgData = new Map();
      res.data.loanTypes.forEach((loanType: ObjectType) => {
        if(loanType.loanTypeName === getLoanType[1].loanCreationName){
          orgData.set(loanType.partnerId, loanType.partnerId);
        }
      });
      const tempPartnersData: ObjectType[] = [];
      creditLines.forEach((partnerData: ObjectType) => {
        if(orgData.has(partnerData.id)) {
          tempPartnersData.push({
            label: partnerData.fullName,
            value: partnerData.id,
            ...partnerData
          })
        }
    });
      setTakeOutPartnersList(tempPartnersData || takeOutPartnerLists);
    })
      .catch((err) => {
        dispatch(hideLoader());
        console.log(err);
      });
  }, [
    loanId,
    loanStage,
    dispatch,
    takeoutPartnerId,
    originatorPartyId,
    isProgramSelectView,
    takeOutPartnerLists
  ]);

  useEffect(() => {
    setTakeOutPartner(
      takeOutPartnerList?.find((it: any) => it?.id === takeoutPartnerId) ?? null
    );
  }, [takeOutPartnerList, takeoutPartnerId]);

  const handleTakeOutPartnerChange = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    const tp =
      takeOutPartnerList?.find((it: any) => it?.id === event?.target?.value) ??
      null;
    setTempPartnerSelected(tp);
  };
  const enableTakeOutChange = () => {
    setAllowTakeOutPartnerChange(true);
  };
  const handleChange = () => {
    setOpenConfirmBox(true);
    setAllowTakeOutPartnerChange(false);
  };

  const handleCancel = () => {
    setAllowTakeOutPartnerChange(false);
    setTakeOutPartner(
      takeOutPartnerList?.find((it: any) => it?.id === takeoutPartnerId) ?? null
    );
  };

  const handlePartnerChange = async () => {
    dispatch(showLoader());
    setTakeOutPartner(tempPartnerSelected);
    setOpenConfirmBox(false);
    const payload = [
      {
        loanId,
        loanDetails: {
          loanInfo: {
            takeoutPartner: tempPartnerSelected?.id,
            takeoutPartnerStatus: "OVERRIDDEN"
          }
        }
      }
    ];
    updateTakeOutPartnerData(payload).then(async() => {
      dispatch(fetchAndDispatchLoan(loanId, loanStage, true));
      const resp: any = await evaluateLoanRuleResults(loanId, loanStage, "30year");
      dispatch(evaluateLoanSuccess({
        loanEvaluationResult: resp?.data,
        reasonForFail: {},
        errorAddressList: {},
        isSubmitted: true
      }));
      dispatch(hideLoader());
    }).catch((err) => {
      dispatch(hideLoader());
      console.log(err);
    });
  }
  return (
    <Container style={{ maxWidth: 1280 }} key={`${loanId}-takeout-partner-form`}>
      <ConfirmFormModal
        isOpen={openConfirmBox}
        handleClose={() => {
          setOpenConfirmBox(false);
        }}
        confirmCondition={false}
        header="Confirmation"
        body={
          <p
            style={{
              lineHeight: 1.5,
              marginTop: 30,
              fontSize: 14,
              padding: 20,
              width: 500,
              paddingBlock: 0
            }}
          >
            The loan is locked with a different partner. Please confirm that you
            wish to transfer takeout partners and if so, update pricing to
            reflect correct pricing for new partner. Confirm to continue.
          </p>
        }
        size="xl"
        successHandler={handlePartnerChange}
        primaryBtnName="Yes"
        SecondaryBtnName="No"
        disableBackdropClick
        footerStyles={{
          marginTop: "30px",
          padding: "0px 20px 20px 20px"
        }}
      />
      <div className={classes.container}>
        {isProgramSelectView || isLockPopUp ? (
          <PricingAndGuidlinesSection
            takOutPartnerList={takeOutPartnerList}
            rateSheetType={rateSheetType}
            loanId={loanId}
            programSelectConfig={programSelectConfig}
            takeOutPartner={takeoutPartnerId}
          />
        ) : (
          <>
            <Grid
              container
              className={classes.sectionWrapper}
              alignItems="center"
              style={{ marginRight: "10px" }}
              data-testid="takeout-partner-edit"
            >
              <div>Take-Out Partner :</div>
              {!allowTakeOutPartnerChange && (
                <div style={{ marginLeft: "1rem" }}>
                  {takeOutPartner?.fullName || "-"}
                </div>
              )}
              {!allowTakeOutPartnerChange &&
                !disableTpChange &&
                !isViewMode && (
                  <>
                    <IconButton onClick={enableTakeOutChange}>
                      <img
                        src={editImage}
                        style={{ height: "16px" }}
                        alt="Edit Section"
                      />
                    </IconButton>
                  </>
                )}
              {takeoutPartnerStatus === "PENDING" && (
                <Chip
                  data-testid="pending-chip"
                  label="Pending"
                  className={classes.pendingChip}
                  color="secondary"
                />
              )}
              {allowTakeOutPartnerChange && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexGrow: 1,
                    marginLeft: "1rem"
                  }}
                >
                  <BasicDropDown
                    selected={tempPartnerSelected?.id || takeOutPartner?.id}
                    handleChange={handleTakeOutPartnerChange}
                    menuOptions={takeOutPartnerList}
                    width="15rem"
                    isDisabled={disableTpChange}
                  />
                  <div style={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      color="info"
                      style={{ marginRight: "1rem", marginBottom: "auto" }}
                      onClick={handleChange}
                    >
                      Save
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleCancel}
                      style={{ marginBottom: "auto" }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              )}
            </Grid>
            <PricingAndGuidlinesSection
              takOutPartnerList={takeOutPartnerList}
              rateSheetType={rateSheetType}
              loanId={loanId}
                programSelectConfig={{ isProgramSelectView: false, criticalTests: programSelectConfig.criticalTests }}
              takeOutPartner={takeOutPartner}
            />
          </>
        )}
      </div>
    </Container>
  );
};
