import { DrawStage } from "@toorak/tc-common-fe-sdk";
import {
  DrawObj,
  snackBarDetailsInterface
} from "../../stores/drawRequest/drawRequest.interface";
import { getConfig } from "../../config/config";

const {
  finalApproval,
  wireTransferInitiated,
  WireUnsuccessful,
  wireCompleted,
  drawSubmitted,
  DrawInQueue,
  inProgress,
  hold,
  draft,
  drawRequested,
  decline
} = DrawStage;
export interface additionalLoanEconomicsFieldsInterface {
  label: string;
  value: any;
  field: string;
  fieldType: string;
}
export const additionalLoanEconomicsFields = (
  drawObj: DrawObj
): additionalLoanEconomicsFieldsInterface[] => [
  // {
  //   label: "Original Maturity Date",
  //   value: drawObj?.originalMaturityDate,
  //   field: "originalMaturityDate",
  //   fieldType: "date"
  // },
  {
    label: "Next Payment Due Date",
    value: drawObj?.nextPaymentDueDate,
    field: "nextPaymentDueDate",
    fieldType: "date"
  },
  {
    label: "Maturity Date",
    value: drawObj?.maturityDate,
    field: "maturityDate",
    fieldType: "date"
  },
  {
    label: "Loan Status",
    value: drawObj.loanStatus,
    field: "loanStatus",
    fieldType: "string"
  },
  {
    label: "Grace Period",
    value: drawObj.gracePeriod,
    field: "gracePeriod",
    fieldType: "number"
  },
  {
    label: "Payoff request date",
    value: drawObj.payoffRequestDate,
    field: "payoffRequestDate",
    fieldType: "date"
  }
];

export const snackBarDetails: snackBarDetailsInterface = {
  verticalPlacement: "top",
  horizontalPlacement: "right",
  autoHideDuration: 2000,
  messageType: "success",
  message: "Draw is successfully deleted"
};
export const drawLeftTabNames = [
  {
    label: "Draw Details"
  },
  {
    label: "Documents"
  },
  {
    label: "Internal Discussions"
  }
];

export const drawRightTabNames = [
  // {
  //   label: "Evaluation Results "
  // },
  {
    label: "External Discussions"
  }
];

export const finalApprovalNotReqdDisableStages: DrawStage[] = [
  finalApproval,
  wireTransferInitiated,
  WireUnsuccessful,
  wireCompleted
];

export const OriginatorEditingStages: DrawStage[] = [
  drawSubmitted,
  DrawInQueue,
  inProgress,
  hold
];

export const AMNotEditingBankStages: DrawStage[] = [
  wireTransferInitiated,
  wireCompleted
];

export const DontShowHistoryStages: DrawStage[] = [
  drawSubmitted,
  DrawInQueue,
  draft,
  drawRequested
];
const configParams = getConfig();

export const newWireFeeDate = () => {
  return configParams.environment === "production"
    ? // 01-September-2023,
      new Date(2023, 8, 1)
    : new Date(2023, 7, 21);
};

export const newWireFeeOriginator = () => {
  return configParams.environment === "production"
    ? "RCN Capital"
    : configParams.environment === "qa"
    ? "Lending One"
    : configParams.environment === "uat"
    ? "XYZ Capital"
    : "Lending One";
};

export const getStageBgColor = (stage: string) => {
  switch (stage) {
    case draft:
    case drawSubmitted:
      return "#172b4d";
    case hold:
    case inProgress:
    case DrawInQueue:
      return "orange";
    case decline:
    case WireUnsuccessful:
      return "red";
    default:
      return "green";
  }
  // eslint-disable-next-line
};
