import { DrawStage } from "@toorak/tc-common-fe-sdk";
import { drawsActionBtnInterface } from "../../stores/drawRequest/drawRequest.interface";
import { isOnlyDrawMember } from "../AssetManagement.utils";
import { getConfig } from "../../config/config";

const {
  drawSubmitted,
  initialApproval,
  finalApproval,
  inProgress,
  decline,
  hold,
  wireTransferInitiated,
  wireCompleted,
  WireUnsuccessful,
  DrawInQueue,
  originatorPending
} = DrawStage;
export const drawsStageUpdate: drawsActionBtnInterface[] = [
  {
    title: drawSubmitted,
    allowedStatus: [DrawInQueue]
  },
  {
    title: inProgress,
    allowedStatus: [
      drawSubmitted,
      decline,
      hold,
      DrawInQueue,
      wireTransferInitiated,
      wireCompleted
    ]
  },
  {
    title: originatorPending,
    allowedStatus: [
      drawSubmitted,
      decline,
      hold,
      DrawInQueue,
      wireTransferInitiated,
      wireCompleted,
      inProgress
    ]
  },
  {
    title: hold,
    allowedStatus: [
      drawSubmitted,
      initialApproval,
      finalApproval,
      decline,
      inProgress,
      originatorPending,
      wireCompleted,
      DrawInQueue,
      wireTransferInitiated
    ]
  },
  {
    title: decline,
    allowedStatus: [
      drawSubmitted,
      initialApproval,
      finalApproval,
      wireTransferInitiated,
      hold,
      inProgress,
      originatorPending,
      wireCompleted,
      wireTransferInitiated,
      DrawInQueue
    ]
  },

  {
    title: initialApproval,
    allowedStatus: [inProgress, originatorPending]
  },
  {
    title: finalApproval,
    allowedStatus: [initialApproval]
  },
  {
    title: wireTransferInitiated,
    allowedStatus: [
      // drawSubmitted,
      finalApproval,
      WireUnsuccessful
    ]
  },
  {
    title: WireUnsuccessful,
    allowedStatus: [wireTransferInitiated]
  }
];

export const isBulkDrawViewEnabled = () =>
  sessionStorage.getItem("bulkDrawView") === "true";

export const progressList = [
  "Servicer Ready",
  "First Approval Ready",
  "Second Approval Ready",
  "Originator Pending",
  "Wire Sent",
  "Wire Ready",
  "Wire Verification Ready"
];

export const isStatusDisabled = (
  item: drawsActionBtnInterface,
  stage: DrawStage,
  finalApprovalNotRequired: string
): boolean => {
  const isAllowed = (): boolean => !item.allowedStatus.includes(stage);

  const statusesThatRequireAllowedCheck = [
    inProgress,
    hold,
    decline,
    initialApproval,
    WireUnsuccessful,
    DrawInQueue,
    drawSubmitted,
    wireCompleted,
    originatorPending
  ];

  const isFinalApprovalNotRequired =
    finalApprovalNotRequired?.toLowerCase() === "yes";
  if (item.title === DrawStage.originatorPending && !["development","qa"].includes(getConfig().environment)){
    return true;
  }

  if (statusesThatRequireAllowedCheck.includes(item.title as DrawStage)) {
    return isAllowed();
  }
  if (item.title === wireTransferInitiated) {
    return isFinalApprovalNotRequired && stage === initialApproval
      ? false
      : isAllowed();
  }
  if (item.title === finalApproval) {
    if (!isFinalApprovalNotRequired && !isOnlyDrawMember()) {
      return isAllowed();
    }
    return true;
  }
  return true;
};
