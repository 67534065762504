/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable prefer-const */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-var */
import { v1 as uuidv1 } from "uuid";
import {
  getPreAssignedURL,
  uploadAWSFile,
  downloadFileNew,
  insertDocs
} from "../create-loan.action";
import { insertTypeDocs as insertWaiverDocs } from "../../stores/WaiverRequest/WaiverRequest.action";
import { getFileUpladBody } from "../../utils/AccessManagement";
import { extensionList } from "../../utils/constants";
import { LoanStage } from "@toorak/tc-common-fe-sdk";

// const MAX_FILE_SIZE = 5242880;
const MAX_FILE_SIZE = 20971520; // 20mb in bytes

const MAX_FILE_COUNT = 50;

export const handleFileUpload = async (event: any, props: any) => {
  const { setOpenDialog, filesList, setDialogdetails, setFilesList, callBack } =
    props;
  // Docs number limit check
  var dialogData = {
    topic: "Validation Fail",
    head: "Validation",
    btnText: "Ok",
    contentText: "",
    isCancelDisable: true,
    btnFunction: () => setOpenDialog(false)
  };
  // eslint-disable-next-line vars-on-top
  var newFileList: any[] = [];
  const fileKeys = Object.keys(event.target.files);
  fileKeys.forEach((key, index) => {
    const file = event.target.files[index];
    if (
      filesList.length >= MAX_FILE_COUNT ||
      fileKeys.length + filesList.length > MAX_FILE_COUNT
    ) {
      // alert("Max file count reached!!!");
      dialogData.contentText =
        "Max file upload count reached \n (allowed upto only 50)";
      setDialogdetails(dialogData);
      setOpenDialog(true);
      return;
    }
    // files size check. max 5 MB allowed
    // remove the excedding file
    if (file.size > MAX_FILE_SIZE) {
      // alert("File size is more than 5MB")
      dialogData.contentText = "Max File upload size allowed is 20MB";
      setDialogdetails(dialogData);
      setOpenDialog(true);
      return;
    }
    // file format check
    if (extensionList.indexOf(file.name.split(".").pop().toLowerCase()) > -1) {
      file.uploading = true;
      file.uploaded = false;
      file.percentCompleted = 0;
    } else {
      dialogData.contentText = "Wrong file format";
      setDialogdetails(dialogData);
      setOpenDialog(true);
      return;
    }
    file.docsId = uuidv1();
    file.uniqueId = uuidv1();
    newFileList.push(file);
    setFilesList([...filesList, ...newFileList]);
    if (callBack) {
      callBack(filesList.length, [...filesList, ...newFileList], setFilesList);
    }
  });
  if (!callBack) {
    setFilesList([...filesList, ...newFileList]);
  }
};

export const updatePercentage = (
  uniqueId: any,
  percentage: number,
  filesList: any,
  setFilesList: any
) => {
  let temp = [...filesList];
  temp.map((item) => {
    if (item.uniqueId === uniqueId) {
      item.percentCompleted = percentage;
    }
    return item;
  });
  setFilesList(temp);
};

export const sendFileExtra = (
  count: number,
  waiverId: any,
  filesList: any,
  setFilesList: any,
  loanId: string,
  loanStage: LoanStage,
  isViewCallback?: any | null
) => {
  if (!filesList[count].id) {
    const body = getFileUpladBody(filesList[count].name, loanId);
    getPreAssignedURL(body, filesList[count].type)
      .then(async (data: any) => {
        const file = new FormData();
        if (data.url && !data.Error) {
          const awsUrl = data;
          // callBackUpload(awsUrl.url);
          file.append("file", filesList[count]);
          const config = {
            onUploadProgress: (progressEvent: {
              loaded: number;
              total: number;
            }) => {
              let percentCompleted = Math.round(
                (progressEvent.loaded * 100) / progressEvent.total
              );
              updatePercentage(
                filesList[count].uniqueId,
                percentCompleted,
                filesList,
                setFilesList
              );
            }
          };
          let res: any = await uploadAWSFile(filesList[count], config, awsUrl);
          if (res.status === 200) {
            // post API to insert in DB
            const insertBody = [
              {
                name: filesList[count].name,
                path: awsUrl.url,
                box_document_path: "<box_document_path>",
                createdBy: "<userId>",
                updatedBy: "<userId>",
                createdOn: new Date(),
                updatedOn: new Date(),
                tags: []
              }
            ];
            // callBackUpload(insertBody);
            const type = "WAIVER";
            const typeId = waiverId;
            const insertDocsResp: any = await insertWaiverDocs(
              insertBody,
              type,
              typeId
            );
            // callBackUpload(insertBody);
            // callBackUpload(filesList);
            // get the updated FilesList from localStorage
            if (insertDocsResp.status === 200) {
              const hasCondition: boolean =
                window.location.pathname.includes("/Condition/");

              if (hasCondition) {
                await insertDocs(
                  insertBody,
                  loanId,
                  `${loanStage}-postClose`,
                  `${loanStage}-postClose`,
                  "LOAN"
                );
              }
              if (isViewCallback) isViewCallback(count, insertBody);
            } else {
              let temp = [...filesList];
              temp.splice(count, 1);
              setFilesList([...temp]);
            }
          }
        }
      })
      .catch((e) => {
        // alert(filesList[count].ErrorMsg);
        console.log(e);
        let temp = [...filesList];
        temp.splice(count, 1);
        setFilesList([...temp]);
      });
  } else {
    const insertBody = [
      {
        name: filesList[count].name,
        path: filesList[count].path,
        box_document_path: "<box_document_path>",
        createdBy: "<userId>",
        updatedBy: "<userId>",
        createdOn: new Date(),
        updatedOn: new Date(),
        tags: []
      }
    ];
    // callBackUpload(insertBody);
    const type = "WAIVER";
    const typeId = waiverId;
    insertWaiverDocs(insertBody, type, typeId)
      .then(async (success: any) => {
        const hasCondition: boolean =
          window.location.pathname.includes("/Condition/");
        console.log(hasCondition, loanId);

        if (hasCondition && type === "WAIVER" && loanId) {
          await insertDocs(
            insertBody,
            loanId,
            `${loanStage}-postClose`,
            `${loanStage}-postClose`,
            "LOAN"
          );
        }
        if (isViewCallback) isViewCallback(count);
      })
      .catch((failure) => {
        let temp = [...filesList];
        temp.splice(count, 1);
        setFilesList([...temp]);
        console.log(failure);
      });
  }
};

export const handleDownload = (file: any, id: string, type?: string) => {
  // const { loanId } = useParams() as any;
  const primaryIdentifier =
    type === "waiver"
      ? "WAIVER"
      : type === "discussion" || type === "chat"
      ? "CHAT"
      : type === "exception"
      ? "EXCEPTION"
      : "";
  console.log(file);
  downloadFileNew(
    file.name,
    file.id,
    primaryIdentifier,
    id
    // secondaryIden,
    // secondaryIden
  )
    .then((data) => console.log(data))
    .catch((error) => console.log(error));
};

// export const handleDelete = (loanId: string) => {
//   const body = {
//     BucketName: `${bucketName}`,
//     Bucketkey: `mortgage/${file.name}`
//   };

//   deleteUploadedFile(body, file.name)
//     .then(data => console.log(data))
//     .catch(error => console.log(error));
// }
