/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { publicClient } from "@toorak/tc-common-fe-sdk";
import { getConfig, loanTypeInfoByName } from "../../config/config";
import { setCookie } from "../../utils/cookies";
import { formatValueForLoanAndPropertyApi } from "../../utils/formatChecks";
import {
  FieldInfoForValidationObjects,
  loanInformationFields,
  loanCreditFields,
  propertyInformationFields,
  unitInformationFields,
  propertyEconomicsFields
} from "./helpers/loanAndPropertyFields";
import {
  LoanDetails,
  PropertyDetails,
  // cancelAutoSavee,
  // deleteDocFromFileList,
  // overRideChecklist,
  // setAutoSaveValues,
  // showPopUpMessages,
  // updateAutoSaveFields,
  updateFilesInnCreditEval,
  updateLoanDetails,
  updateLoanFields,
  updatePropertyDetail,
  // updatePropertyFields,
  updateUnitInfoFields
} from "./loanAndPropertyDetails.reducer";
import { rentDescription } from "../../create-loan/constants/loanCreationDropDownValues";

// export const UPDATE_LOAN_FIELDS = "UPDATE_LOAN_FIELDS";
// export const UPDATE_LOAN_DETAILS = "UPDATE_LOAN_DETAILS";
// export const UPDATE_PROPERTY_FIELDS = "UPDATE_PROPERTY_FIELDS";
// export const UPDATE_UNIT_INFO_FIELDS = "UPDATE_UNIT_INFO_FIELDS";
// export const UPDATE_PROPERTY_DETAIL = "UPDATE_PROPERTY_DETAIL";
// export const UPDATE_PROPINFO_TOORVALID = "UPDATE_PROPINFO_TOORVALID";
// export const UPDATE_FILES_IN_CREDIT_EVAL = "UPDATE_FILES_IN_CREDIT_EVAL";
// export const SET_AUTO_SAVE_VALUES = "SET_AUTO_SAVE_VALUES";
// export const UPDATE_AUTO_SAVED_FIELDS = "UPDATE_AUTO_SAVED_FIELDS";
// export const CANCEL_AUTOSAVE = "CANCEL_AUTOSAVE";
// export const SHOW_MESSAGE = "SHOW_MESSAGE";
// export const HIDE_MESSAGE = "HIDE_MESSAGE";
// export const DELETE_DOCS_FROM_FILELIST = "DELETE_DOCS_FROM_FILELIST";
// export const CLEAR_ESCROW_MAP = "CLEAR_ESCROW_MAP";
// export const OVERRIDE_CHECKLIST = "OVERRIDE_CHECKLIST";

export const updateDataInRedux = (
  sectionName: string,
  fieldName: string,
  fieldValue: any
) => {
  return updateLoanFields({ sectionName, fieldName, fieldValue });
  // dispatch({
  //   type: UPDATE_LOAN_FIELDS,
  //   payload: { sectionName, fieldName, fieldValue }
  // });
};
// export const updatePropertyDataInRedux = (
//   sectionName: string,
//   fieldName: string,
//   fieldValue: any,
//   propertyId: any
// ) => {
//   return async (dispatch: any) => {
//     dispatch(
//       updatePropertyFields({ sectionName, fieldName, fieldValue, propertyId })
//     );
//     // dispatch({
//     //   type: UPDATE_PROPERTY_FIELDS,
//     //   payload: { sectionName, fieldName, fieldValue, propertyId }
//     // });
//   };
// };
// export const updateUnitInfoDataInRedux = (
//   fieldName: string,
//   fieldValue: any,
//   propertyId: any,
//   unitId: any
// ) => {
//   return async (dispatch: any) => {
//     dispatch(
//       updateUnitInfoFields({ fieldName, fieldValue, propertyId, unitId })
//     );
//     // dispatch({
//     //   type: UPDATE_UNIT_INFO_FIELDS,
//     //   payload: { fieldName, fieldValue, propertyId, unitId }
//     // });
//   };
// };
export const getSectionObject = (
  data: any,
  fieldsInfo: FieldInfoForValidationObjects
) => {
  try {
    let returnObj = {};
    Object.keys(data).forEach((key) => {
      const fieldType = fieldsInfo[key] ? fieldsInfo[key].fieldType : "other";
      const formattedValue = formatValueForLoanAndPropertyApi(
        data[key],
        fieldType
      );
      returnObj = { ...returnObj, [key]: formattedValue };
    });
    return returnObj;
  } catch (error) {
    console.log(error);
    return {};
  }
};
export const getSectionObjectForRedux = (
  data: any,
  fieldsInfo: FieldInfoForValidationObjects
) => {
  try {
    let returnObj = {};
    if (!data) return returnObj;
    Object.keys(data).forEach((key) => {
      // const fieldType = fieldsInfo[key] ? fieldsInfo[key].fieldType : "other";
      // const formattedValue = formatValueByType(data[key], fieldType);
      returnObj = { ...returnObj, [key]: data[key] };
    });
    return returnObj;
  } catch (error) {
    console.log(error);
    return data;
  }
};

export const getEmptyUnitInfo = () => {
  return [
    {
      errors: null,
      unitId: 1,
      payload: {
        leaseEndDate: null,
        leaseStartDate: null,
        leaseStatus: rentDescription[0],
        currentLeaseTerm: "",
        currentLeaseTermMonthly: "",
        marketRentMonthly: "",
        inPlaceLeaseRent: "",
        recentLeaseRent: "",
        monthlyLease: "",
        vacancyStatus: "",
        unitCategory: "",
        leaseDurationGreaterThan12M: "",
        leaseDurationLessThan12M: ""
      }
    }
  ];
};
export const updateLoanDetailsInRedux = (loanDetails: any, dispatch: any) => {
  const { loanInfo, loanCredit, loanUserMap, loanDetailId } = loanDetails;
  setCookie("sellerName", loanDetails?.sellerPartyInfo?.accountName);
  const tempLoanInfo = { ...loanInfo };
  if (loanDetails.loanType === "30 Year Loan") {
    tempLoanInfo.crossCollaterlization = loanInfo?.crossCollaterlization ?? "N";
  }

  const returnObj = {
    ...loanDetails,
    loanUserMap,
    loanInfo: getSectionObjectForRedux(tempLoanInfo, loanInformationFields),
    loanCredit: {
      ...getSectionObjectForRedux(loanCredit, loanCreditFields),
      truliaCrimeCheck: loanDetailId?.loanConfigId?.truliaCrime
    },
    loanConfig: loanDetailId?.loanConfigId
  };

  dispatch(updateLoanDetails({ loanDetails: returnObj }));
  // dispatch({
  //   type: UPDATE_LOAN_DETAILS,
  //   payload: { loanDetails: returnObj }
  // });
};
const getGuarantorBorrowerObj = (data: LoanDetails) => {
  const { loanUserMap, borrowerGuarantor } = data;
  console.log(data);
  const usersArr = JSON.parse(JSON.stringify(loanUserMap));
  try {
    // eslint-disable-next-line
    usersArr.map((item: any) => {
      let fieldName = "";
      if (item?.loanUserType !== "Guarantor") {
        fieldName =
          item.customer.partyType === "account"
            ? `${item.customer.accountName} (Entity)`
            : item?.isPrimary
            ? `${item.customer.firstName} ${item.customer.lastName} (Primary Borrower)`
            : `${item.customer.firstName} ${item.customer.lastName}`;
      } else {
        fieldName = item?.isPrimary
          ? `${item.customer.firstName} ${item.customer.lastName} (Primary Guarantor)`
          : `${item.customer.firstName} ${item.customer.lastName}`;
        item.customer.partyType = "person";
        item.customer.accountType = "person";
      }
      item.liquidityCurrency =
        borrowerGuarantor[`${fieldName}.liquidityCurrency`] &&
        borrowerGuarantor[`${fieldName}.liquidityCurrency`].replace("$", "");
      item.netWorthCurrency =
        borrowerGuarantor[`${fieldName}.netWorthCurrency`] &&
        borrowerGuarantor[`${fieldName}.netWorthCurrency`].replace("$", "");
      item.netWorthCurrency =
        item.netWorthCurrency === "" ? null : item.netWorthCurrency;
      item.liquidityCurrency =
        item.liquidityCurrency === "" ? null : item.liquidityCurrency;
    });
  } catch (error) {
    console.log(error);
    return usersArr;
  }

  return usersArr;
};
export const generatePostLoanRequestBody = (
  loanId: string,
  loanType: string,
  loanStage: string,
  data: LoanDetails
) => {
  const { loanInfo, loanCredit, loanConfig } = data;
  const requestObject = {
    loanId: loanId.toString(),
    loanTypeId: loanTypeInfoByName[loanType].loanTypeId,
    loanType,
    loanEconomics: {},
    loanUserMap: getGuarantorBorrowerObj(data),
    loanInfo: getSectionObject(loanInfo, loanInformationFields),
    loanCredit: getSectionObject(loanCredit, loanCreditFields),
    loanConfig: { ...loanConfig, truliaCrime: loanCredit?.truliaCrimeCheck },
    loanCondominium: {}
  };
  return requestObject;
};
export const saveLoanDetails = (
  loanId: string,
  loanType: string,
  loanStage: string,
  data: LoanDetails
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const requestBody = generatePostLoanRequestBody(
      loanId,
      loanType,
      loanStage,
      data
    );
    const url = `${getConfig().apiUrl}/aggregate/loans`;
    publicClient
      .post(url, requestBody)
      .then((res: { data: any }) => {
        const foo = JSON.parse(JSON.stringify(res.data));
        sessionStorage.setItem(
          "originatorPartyIdNew",
          foo?.loanDetailId?.originatorPartyId
        );
        resolve(res.data);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};

export const generatePostPropertyRequestBody = (
  loanId: string,
  loanType: string,
  loanStage: string,
  data: PropertyDetails[]
) => {
  const requestObject = data.map((ele, i) => {
    const { propertyInfo, loanPropertyId, propertyEconomics, propertyUnit } =
      ele;
    return {
      loanId,
      loanPropertyId,
      loanPropertyOrder: i + 1,
      propertyinfo: getSectionObject(propertyInfo, propertyInformationFields),
      propertyLocation: {},
      propertyEconomics: getSectionObject(
        propertyEconomics,
        propertyEconomicsFields
      ),
      propertyUnit: propertyUnit?.length
        ? propertyUnit.map((unit: any) =>
            getSectionObject(unit, unitInformationFields)
          )
        : propertyUnit,
      propertySummary: {},
      hasErrors: false
    };
  });
  return requestObject;
};
export const savePropertyDetails = (
  loanId: string,
  loanType: string,
  loanStage: string,
  data: PropertyDetails[]
): Promise<any> => {
  return new Promise((resolve, reject) => {
    const requestBody = generatePostPropertyRequestBody(
      loanId,
      loanType,
      loanStage,
      data
    );
    const url = `${
      getConfig().apiUrl
    }/aggregate/loan_property/${loanId}/properties?stage=${loanStage}`;
    publicClient
      .post(url, requestBody)
      .then((res: { data: any }) => {
        resolve(res);
      })
      .catch((e: any) => {
        reject(e);
      });
  });
};
export const updatePropertyDetailsInRedux = (data: any, dispatch: any) => {
  // return async () => {
    const propertyDetails = data.sort(function (a: any, b: any) {
      return a.loanPropertyOrder - b.loanPropertyOrder;
    });
    if (propertyDetails?.length) {
      const propertyObj = propertyDetails.map((element: any) => {
        const {
          loanPropertyId,
          propertyinfo,
          propertyLocation,
          propertyUnit = [],
          loanPropertyOrder
        } = element;
        return {
          ...element,
          loanPropertyId,
          loanPropertyOrder,
          propertyInfo: getSectionObjectForRedux(
            propertyinfo,
            propertyInformationFields
          ),
          propertyLocation,
          propertyUnit: propertyUnit?.length
            ? propertyUnit.map((item: any) => {
                return getSectionObjectForRedux(item, unitInformationFields);
              })
            : []
        };
      });
      dispatch(updatePropertyDetail({ propertyDetails: propertyObj || [] }));
      // dispatch({
      //   type: UPDATE_PROPERTY_DETAIL,
      //   payload: { propertyDetails: propertyObj || [] }
      // });
    }
  // };
};
const copyFileList = (file: any[]) => {
  const myFileArray: any[] = [];
  if (Array.isArray(file) && file.length) {
    file.map((item: any) => {
      const percentCompleted =
        item.uploaded === true ? 100 : item.percentCompleted;
      const fille = item.ErrorMsg
        ? { ...item, name: item.name }
        : {
            lastMod: item.lastModified,
            lastModDate: item.lastModifiedDate || item.createdOn,
            name: item.name,
            createdOn: item.createdOn,
            size: item.size,
            type: item.type,
            inQueue: item.inQueue,
            tag: item.tag,
            tagCategory: item.tagCategory,
            uploading: item.uploading,
            uploaded: item.uploaded,
            percentCompleted,
            path: item.path,
            docsId: item.docsId,
            uniqueId: item.uniqueId,
            categoryTag: item.categoryTag,
            url: item.url
          };
      // add the fille obj to your array
      myFileArray.push(fille);
      return null;
    });
  }
  return JSON.parse(JSON.stringify(myFileArray));
};
export function updateFilesInCreditEval(
  file: any,
  documentDetails: any,
  dispatch: any
) {
  return async () => {
    const formattedFile: any[] = copyFileList(file);
    documentDetails.length && formattedFile.push(...documentDetails);
    dispatch(updateFilesInnCreditEval({ file: formattedFile || [] }));
    // return {
    //   type: UPDATE_FILES_IN_CREDIT_EVAL,
    //   payload: { file: formattedFile || [] },
    //   currentSection: currentTab
    // };
  };
}

// export const setAutoSaveValuesCall = (data: {
//   callAutoSave: boolean;
//   tabType: string;
//   changedFieldId: string;
// }) => {
//   return async (dispatch: any) => {
//     dispatch(setAutoSaveValues(data));
//     //dispatch({ type: SET_AUTO_SAVE_VALUES, payload: data });
//   };
// };

// export const updateLastAutoSavedFields = (data: { savedFields: string[] }) => {
//   return async (dispatch: any) => {
//     dispatch(updateAutoSaveFields(data));
//   };
// };

// export const cancelAutoSave = () => {
//   return async (dispatch: any) => {
//     dispatch(cancelAutoSavee());
//     //dispatch({ type: CANCEL_AUTOSAVE });
//   };
// };

// export const showMessage = (payload: { message: any; header: any }) => {
//   return async (dispatch: any) => {
//     dispatch(showPopUpMessages(payload));
//     //dispatch({ type: SHOW_MESSAGE, payload });
//   };
// };

// export const hideMessage = () => {
//   return async (dispatch: any) => {
//     dispatch(hideMessages());
//     // dispatch({ type: HIDE_MESSAGE });
//   };
// };

// export function deleteUploadedDocument(file: any, actionId: string) {
//   const { docsId } = file;
//   return async (dispatch: any) => {
//     dispatch(deleteDocFromFileList({ docsId, actionId }));
//     // dispatch({
//     //   type: DELETE_DOCS_FROM_FILELIST,
//     //   payload: { docsId, actionId }
//     // });
//   };
// }

// export const overrideChecklist = (overridenValue: any) => {
//   return async (dispatch: any) => {
//     dispatch(overRideChecklist(overridenValue));
//     // dispatch({
//     //   type: OVERRIDE_CHECKLIST,
//     //   payload: overridenValue
//     // });
//   };
// };
