import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Grid, Menu, MenuItem, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  AddRowsOption,
  ExtractionInProgressState,
  isLoginPartyId
} from "../BudgetReview.constant";
import { useStylesServicer } from "../../assetManagement/ServicerOnboarding/LoanDetails/ServicerOnboarding.styles";
import { useBudgetStyles } from "../BudgetReview.style";
import { RootState } from "../../stores/rootReducer";
import { sanitizeCurrency } from "../../utils/formatChecks";
import {
  BudgetReviewStore,
  LineItem
} from "../../stores/budgetReview/budgetReview.interface";
import {
  budgetApproveStatusEnum,
  budgetReviewStatusEnum
} from "../../ilp/list-view/BudgetTaskManagement";
import {
  updateStatusOnDocument,
  updateStatusOnTask
} from "../../stores/budgetReview/budgetReview.action";
import { useParams } from "react-router-dom";
import { getCookie } from "../../utils/cookies";
import { taskNames } from "../../stores/tasks/Tasks.action";

interface TotalBudgetAreaProps {
  fieldsData: LineItem[];
  setFieldsData: (data: any[]) => void;
  isDisabled: boolean;
  setShowCommentPopup: any;
  showCommentPopup: any;
  hasCallProceed: any;
  setShowToast: any;
  setHasCallProceed: any;
}

export const addEmptyLineItems = (
  row: any,
  fieldsData: any[],
  withNullAsId?: any,
  unitVal?: string | number
) => {
  const newFields = [];

  for (let i = 0; i < row.val; i++) {
    const newField = {
      id: withNullAsId ? null : `tempId_${Math.random()}`,
      unit: unitVal ? unitVal : 1,
      item: "",
      description: "",
      financedBudget: withNullAsId ? 0 : "$0.00",
      budget: withNullAsId ? 0 : "$0.00",
      StandardizedItem: "",
      standardizedItemId: null
    };

    newFields.push(newField);
  }

  const updatedData = [...fieldsData, ...newFields];
  return updatedData;
};

export function TotalBudgetArea(props: TotalBudgetAreaProps) {
  const {
    fieldsData,
    setFieldsData,
    hasCallProceed,
    showCommentPopup,
    isDisabled,
    setShowCommentPopup,
    setShowToast,
    setHasCallProceed
  } = props;
  const classes = useStylesServicer();
  const budgetClasses = useBudgetStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const {
    totalBudget,
    totalfinancedBudget,
    budgetApprovalHistory,
    budgetStatusHistory,
    budgetReviewHeader,
    budgetReviewResponse,
    lineItemsList
  } = useSelector<RootState, BudgetReviewStore>(
    (state) => state.budgetReviewStore
  );
  const { tasks } = useSelector<RootState, any>((state) => state.TasksStore);
  const [isRedoAllowed, setIsRedoAllowed] = useState<boolean>(true);
  const [isFirstAssigneePartyId, setIsFirstAssigneePartyId] =
    useState<boolean>(true);
  const [isFirstApprovalAssigneePartyId, setIsFirstApprovalAssigneePartyId] =
    useState<boolean>(true);

  const { loanId } = useParams<{
    loanId: string;
  }>() as any;
  const dispatch = useDispatch();

  useEffect(() => {
    if (!(budgetStatusHistory.length && budgetApprovalHistory.length)) return;

    const isFirstAssigneePartyIdTemp = budgetStatusHistory.length
      ? isLoginPartyId(budgetStatusHistory[0].assigneePartyId)
      : false;

    const isFirstApprovalAssigneePartyIdTmep = isLoginPartyId(
      budgetApprovalHistory[0].assigneePartyId
    );
    setIsFirstApprovalAssigneePartyId(isFirstApprovalAssigneePartyIdTmep);
    setIsFirstAssigneePartyId(isFirstAssigneePartyIdTemp);
  }, [budgetStatusHistory, budgetApprovalHistory]);

  useEffect(
    () => {
      setIsRedoAllowed(() =>
        isFirstApprovalAssigneePartyId ? false : !isFirstAssigneePartyId
      );
    },
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (hasCallProceed.isRedo) {
      handleRedoBudget();
    }
    // eslint-disable-next-line
  }, [hasCallProceed]);

  const { Approved, Rejected, InProgress, ReadyForBudgetApproval } =
    budgetReviewStatusEnum;

  const handleAddRowOption = (row: any) => {
    const unitVal = lineItemsList.length
      ? lineItemsList[lineItemsList.length - 1].unit
      : 1;
    const updatedData = addEmptyLineItems(row, fieldsData, false, unitVal);
    setFieldsData(updatedData);
  };

  const handleAddRow = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedoBudget = async () => {
    setShowCommentPopup({
      comment: "",
      headerText: "Redo Budget Review",
      showComment: false
    });
    const userId = getCookie("person_id") || "";
    const updatedTasks: any = JSON.parse(JSON.stringify(tasks));
    let reviewAssignedBy = updatedTasks?.[0]?.assignedTasks.find((task: any) =>
      [taskNames.BUDGET_REVIEW].includes(task.taskName)
    )?.assignedBy;
    if (
      !reviewAssignedBy ||
      ["UnAssigned", "None"].includes(reviewAssignedBy)
    ) {
      reviewAssignedBy = userId;
    }

    let approveAssignedBy = updatedTasks?.[0]?.assignedTasks.find((task: any) =>
      [taskNames.BUDGET_APPROVE].includes(task.taskName)
    )?.assignedBy;
    if (
      !approveAssignedBy ||
      ["UnAssigned", "None"].includes(approveAssignedBy)
    ) {
      approveAssignedBy = userId;
    }
    let assignedReviewTasksObj = {
      op: "replace",
      taskName: "budgetReview",
      assignedBy: reviewAssignedBy,
      assigneePartyId: budgetStatusHistory[0].assigneePartyId,
      taskStatus: isFirstApprovalAssigneePartyId
        ? ReadyForBudgetApproval
        : InProgress,
      taskId: budgetStatusHistory[0].taskId,
      comment: showCommentPopup.comment
    };
    let assignedApproveTasksObj = {
      op: "replace",
      taskName: "budgetApprove",
      assignedBy: approveAssignedBy,
      assigneePartyId: budgetApprovalHistory[0].assigneePartyId,
      taskStatus: isFirstApprovalAssigneePartyId
        ? budgetApproveStatusEnum.ReadyForBudgetApproval
        : budgetApproveStatusEnum.Pending,
      taskId: budgetApprovalHistory[0].taskId,
      comment: showCommentPopup.comment
    };
    await updateStatusOnDocument(
      loanId,
      assignedReviewTasksObj.taskStatus,
      dispatch,
      tasks
    );
    dispatch(
      updateStatusOnTask(
        loanId,
        [assignedReviewTasksObj, assignedApproveTasksObj],
        budgetStatusHistory,
        budgetApprovalHistory
      )
    );
    // dispatch(
    //   updateStatusOnTask(
    //     loanId,
    //     [assignedApproveTasksObj],
    //     budgetApprovalHistory
    //   )
    // );

    setShowToast({ isShown: true, message: "Status Updated Successfully" });
    setHasCallProceed({
      isProceed: false,
      isRedo: false
    });
  };

  return (
    <Grid container style={{ marginTop: 15 }}>
      {[Approved, Rejected].includes(budgetReviewHeader.budgetReviewStatus) ? (
        <Grid item xs={12} sm={6} style={{ paddingLeft: 15 }}>
          <Button
            aria-controls="dropdown-menu"
            aria-haspopup="true"
            onClick={() =>
              setShowCommentPopup({
                comment: "",
                headerText: "Redo Budget Review",
                showComment: true
              })
            }
            className={budgetClasses.dropdownBttn}
            disabled={isRedoAllowed}
          >
            <Typography variant="h5" className={classes.dropDownText}>
              Redo Budget Review
            </Typography>
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12} sm={6} style={{ paddingLeft: 15 }}>
          {!isDisabled && (
            <Button
              aria-controls="dropdown-menu"
              aria-haspopup="true"
              onClick={handleAddRow}
              className={budgetClasses.dropdownBttn}
              disabled={
                isDisabled ||
                ExtractionInProgressState.includes(
                  budgetReviewResponse?.processState
                )
              }
            >
              <Typography variant="h5" className={classes.dropDownText}>
                Add Rows
              </Typography>
              <KeyboardArrowLeftIcon
                fontSize="large"
                htmlColor="#32325d"
                style={{
                  transform: `rotate(${anchorEl ? "270" : "90"}deg)`,
                  objectFit: "contain"
                }}
                className={classes.arrowMobileViewServicer}
              />
            </Button>
          )}
          <Menu
            id="dropdown-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            // getContentAnchorEl={null}
          >
            {AddRowsOption.map((item) => (
              <MenuItem
                onClick={() => {
                  handleClose();
                  handleAddRowOption(item);
                }}
                key={item.title}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </Grid>
      )}
      <Grid
        item
        container
        justifyContent="flex-end"
        spacing={1}
        style={{ paddingRight: "6%" }}
      >
        <Grid item style={{ fontWeight: 600, paddingRight: "20px" }}>
          Calculated Total:
        </Grid>
        <Grid item style={{ fontWeight: 600, paddingRight: "20px" }}>
          {sanitizeCurrency(`${totalBudget}`)}
        </Grid>
        <Grid item style={{ fontWeight: 600 }}>
          {sanitizeCurrency(`${totalfinancedBudget}`)}
        </Grid>
      </Grid>
    </Grid>
  );
}
