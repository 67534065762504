/* eslint-disable no-unused-expressions */
/* eslint-disable react-hooks/exhaustive-deps */
import { AppBar, Grid, IconButton, Tab, Tabs } from "@mui/material";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useDispatch, useSelector } from "react-redux";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { modalHeaderStyles } from "./styles";

import { GuidelinesComparision } from "../create-loan/guidelines-and-pricing-comparision/GuidlinesComparision";
import { PricingComparision } from "../create-loan/guidelines-and-pricing-comparision/PricingComparision";
import {
  showLoader,
  hideLoader
} from "../stores/loaderAndException/loaderAndException.action";
import { ObjectType } from "../masterView/common";
import {
  // getEvaluationResultForPartner,
  getRateLockOptions
} from "../network/apiService";
import {
  formatWaiverData,
  getPricingAndResults
} from "../create-loan/guidelines-and-pricing-comparision/PricingAndGuidlinesSection";
import { RootState } from "../stores/rootReducer";
import { RateLockcontext } from "./LoanCard";

export const GuidlinesComparisionContextForDashboard = createContext<any>(
  undefined
);

const subViews = [
  { label: "Tests", value: "guidelineTests" },
  { label: "Pricing", value: "pricing" }
];

export const TKOPModalConatiner = (props: {
  onClose: Function;
  partners: any[];
  loanId: string;
  loanStage: LoanStage;
  loanRuleVersions: any;
  takeoutPartnerId: string;
  originatorId: string;
}) => {
  const {
    onClose,
    // partners,
    loanId,
    loanStage,
    loanRuleVersions,
    takeoutPartnerId,
    originatorId
  } = props;
  let { partners } = props;
  partners = partners.filter(
    (partner: ObjectType) =>
      !(
        partner.partyIdCriteria === "EXCLUDE" &&
        partner.orgPartyId.includes(originatorId)
      )
  );
  const {
    thirtyYearLoanInformation,
    fesLoanInformation,
    thirtyYearLoanEconomics,
    bridgeLoanBorrowerInformation,
    bridgeLoanGuarantorInformation,
    // loanRuleVersions,
    loanSummary,
    fesBorrowerInformation,
    fesLoanEconomics,
    propertyDetails
  } = useSelector<RootState, any>(state => state.createLoanStore?.loanDetails);
  const { waiversCreated, customerInfo } = useSelector<RootState, any>(
    state => state.evaluationResultsStore
  );
  const { rateLockPeriodAndExpiry } = useContext(RateLockcontext);
  const classes = modalHeaderStyles();
  const dispatch = useDispatch();
  const [selectedView, setSelectedView] = useState(subViews[0].value);
  const [partnersData, setPartnersData] = useState<any>({
    guidelineTests: [],
    pricing: []
  });
  const [rateSheetTypeData, setRateSheetTypeData] = useState<ObjectType | null>(
    null
  );

  const partnerWiseWaivers = useMemo(() => {
    if (!waiversCreated.length) return {};
    const partnerWiseWaivers: any = {};
    waiversCreated?.forEach((waiver: any) => {
      const waiverData = formatWaiverData(waiver, customerInfo);
      if (!partnerWiseWaivers[waiver.takeoutPartnerId]) {
        partnerWiseWaivers[waiver.takeoutPartnerId] = new Map();
      }
      partnerWiseWaivers[waiver.takeoutPartnerId].set(
        waiver.waiverKeys[0],
        waiverData
      );
    });
    return partnerWiseWaivers;
  }, [waiversCreated, customerInfo]);

  const loanData: ObjectType = useMemo(() => {
    const fesLoanData = {
      borrowerInformation: [fesBorrowerInformation],
      loanInformation: fesLoanInformation,
      loanEconomics: fesLoanEconomics,
      loanRuleVersions
    };
    const prePostLoanData = {
      borrowerInformation: bridgeLoanBorrowerInformation,
      guarantorInformation: bridgeLoanGuarantorInformation,
      loanInformation: thirtyYearLoanInformation.payload,
      loanEconomics: thirtyYearLoanEconomics.payload,
      loanRuleVersions,
      loanSummary
    };

    if (loanStage === LoanStage.fes) {
      return fesLoanData;
    }
    return prePostLoanData;
  }, [
    loanStage,
    bridgeLoanBorrowerInformation,
    fesLoanInformation,
    bridgeLoanGuarantorInformation,
    fesBorrowerInformation,
    fesLoanEconomics,
    loanRuleVersions,
    loanSummary,
    thirtyYearLoanInformation,
    thirtyYearLoanEconomics
  ]);

  useEffect(() => {
    dispatch(showLoader());
    const resultsPromiseList: any[] = [];
    partners?.forEach((item: any) => {
      resultsPromiseList.push(
        getPricingAndResults(
          item,
          loanId,
          loanStage,
          item.id,
          partnerWiseWaivers?.[item.id] ?? new Map(),
          propertyDetails,
          loanData,
          rateLockPeriodAndExpiry
        )
      );
    });
    Promise.all(resultsPromiseList)
      .then((res: any) => {
        setPartnersData({
          ...partnersData,
          [selectedView]: res
        });
        dispatch(hideLoader());
      })
      .catch(err => {
        dispatch(hideLoader());
        console.log(err);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loanId, loanStage, dispatch, selectedView, partners.length]);

  useEffect(() => {
    // if (!loanId || loanType !== getLoanType[1].displayValue || !loanDetails)
    //   return;
    const orgPartyId = originatorId ?? "";
    const rateAdjustmentsRuleVersion =
      loanRuleVersions?.filter(
        (item: ObjectType) => item.name === "RATE_SHEET_TYPE"
      ) ?? [];
    if (rateAdjustmentsRuleVersion.length === 0) return;
    const rateSheetData = getRateLockOptions(
      orgPartyId,
      rateAdjustmentsRuleVersion,
      takeoutPartnerId
    );
    rateSheetData.then((res: any) => {
      const respMap: any = new Map(
        res?.data?.data.map((it: ObjectType) => [it.name, it])
      );
      const orgSpecificRateSheetData = respMap
        .get("RATE_SHEET_TYPE")
        ?.lookupData?.filter((it: ObjectType) => {
          return it?.originatorId && it?.originatorId !== "ALL";
        });
      const tempRateSheetTypeData = orgSpecificRateSheetData?.length
        ? orgSpecificRateSheetData
        : respMap.get("RATE_SHEET_TYPE")?.lookupData?.[0];
      setRateSheetTypeData(
        Array.isArray(tempRateSheetTypeData)
          ? tempRateSheetTypeData[0]
          : tempRateSheetTypeData
      );
    });
  }, [loanId, loanStage, loanRuleVersions]);

  const handleChange = (e: any, val: string) => {
    if (!val) return;
    setSelectedView(val);
  };

  return <>
    <Grid
      container
      justifyContent="space-between"
      alignItems="center"
      style={{
        padding: "20px 5px 20px 15px"
      }}
    >
      <Grid item>
        <span className={classes.header}>
          {selectedView === "guidelineTests"
            ? "Loan Test Failure for Take-Out Partners"
            : "Pricing for Take-Out Partners"}
        </span>
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item alignItems="center" className={classes.testsPricingChip}>
            <AppBar position="static" color="default">
              <Tabs
                value={selectedView}
                onChange={handleChange}
                indicatorColor="primary"
                textColor="primary"
                aria-label="partner classification"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "transparent"
                  }
                }}
                centered
              >
                {subViews.map((item: any) => (
                  <Tab
                    key={item.value}
                    label={item.label}
                    value={item.value}
                    style={{ textTransform: "none" }}
                  />
                ))}
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item>
            <IconButton
              aria-label="close"
              onClick={(e: any) => onClose()}
              component="button"
              size="large">
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>

    <Grid
      container
      className={classes.sectionWrapper}
      style={{ flexDirection: "column" }}
    >
      {partnersData && partnersData?.[selectedView].length > 0 && (
        <GuidlinesComparisionContextForDashboard.Provider
          value={{
            partnersData: partnersData?.[selectedView],
            // getPricingAndResults,  commented as it is not getting used from context
            setPartnersData,
            rateSheetType: rateSheetTypeData?.pricingType ?? "PRICE",
            takeOutPartner: takeoutPartnerId
          }}
        >
          {selectedView === "pricing" && (
            <PricingComparision
              programSelectConfig={{
                isLockPopUp: false,
                currentSelectedPartner: {
                  assignedPartner: "",
                  partnerId: ""
                },
                setCurrentSelectedPartner: () => null
              }}
              partnersData={partnersData?.["pricing"] ?? []}
            />
          )}
          {selectedView === "guidelineTests" && (
            <GuidelinesComparision
              programSelectConfig={{ isProgramSelectView: false }}
            />
          )}
        </GuidlinesComparisionContextForDashboard.Provider>
      )}
    </Grid>
  </>;
};
