import { Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import createStyles from "@mui/styles/createStyles";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    requestedContainer: {
      border: "1px solid red",
      borderRadius: "4px"
    },
    approvedContainer: {
      border: "1px solid rgb(46, 206, 137)",
      borderRadius: "4px"
    },
    allProperties: {
      fontSize: 12,
      fontWeight: 600,
      color: "#32325d"
    },
    editImage: {
      width: 16,
      height: 16,
      marginRight: 16,
      objectFit: "contain",
      cursor: "pointer",
      minWidth: "auto"
    },
    container: {
      borderRadius: 4,
      display: "flex",
      flexDirection: "column",
      boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
      backgroundColor: "#ffffff",
      marginTop: theme.spacing(3),
      width: "100%"
    },
    paper: { maxWidth: "60%" },
    sectionWrapper: {
      padding: `${theme.spacing(3)} ${theme.spacing(3)}`,
      display: "flex"
    },
    dueDiligenceSection: {
      display: "flex",
      padding: "20px",
      sectionWrapper: {
        MuiGridItem: {
          padding: 16
        }
      }
    },
    headingTypography: {
      textAlign: "left",
      fontSize: "14px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    heading2Typography: {
      textAlign: "left",
      fontSize: "12px !important",
      fontWeight: 600,
      color: "#32325d",
      lineHeight: 1.57
    },
    gridOne: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      width: "100%",
      flexGrow: 1,
      flexBasis: "72.5%"
    },
    headingImageContainer: {
      justifyContent: "flex-end",
      display: "flex",
      alignItem: "center"
    },
    tagTitle: {
      width: "38px",
      height: "14px",
      fontFamily: "OpenSans",
      fontSize: "10px",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      paddingLeft: "5px",
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff"
    },
    editedLabel: {
      width: "46px",
      height: "16px",
      borderRadius: "9px",
      backgroundColor: "#f75676",
      marginTop: "3px"
    },
    editImageDisplay: {
      display: "none"
    },
    headingImage: {
      width: 16,
      height: 16,
      objectFit: "contain",
      cursor: "pointer",
      minWidth: "auto"
    },
    divider: {
      height: "1px",
      border: "solid 1px #f2f2f2",
      padding: "0px !important"
    },
    formSelectContainer: {
      justifyContent: "space-around",
      display: "flex",
      flexDirection: "row",
      width: "95%",
      padding: "8px 0px 0px 0px"
    },
    addTagText: {
      fontSize: "12px",
      marginTop: "9px",
      marginBottom: "15px",
      lineHeight: 1.5,
      color: "#8897aa"
    },
    savebutton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgb(0, 0, 0 / 0.24)",
      backgroundColor: "#5e72e4"
    },
    addExtensionButton: {
      width: 120,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgb(0, 0, 0 / 0.24)",
      backgroundColor: "#5e72e4",
      color: "#ffffff",
      "&:hover": {
        backgroundColor: "#3d55df"
      }
    },
    cancelButton: {
      width: 69,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#fff",
      color: "#32325d",
      marginLeft: 10,
      "&:hover": {
        background: "none"
      }
    },
    dialogActionArea: {
      display: "block",
      paddingLeft: "20px",
      paddingBottom: "15px"
    },
    dialogDeleteButton: {
      fontSize: "12px",
      fontWeight: 600,
      lineHeight: 1.83,
      letterSpacing: "normal",
      textAlign: "center",
      color: "#ffffff",
      // width: "89px",
      textTransform: "none",
      height: "36px",
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5566d6",
      "&:hover": {
        backgroundColor: "#5566d6 !important"
      }
    },
    dialogCancelBtn: {
      boxShadow: "0 1px 3px 0 rgba(0, 0, 0, 0.22)",
      backgroundColor: "#ffffff",
      border: "solid 1px #ececec",
      color: "#32325d",
      "&:hover": {
        backgroundColor: "#ffffff !important"
      }
    },

    dialogText: {
      height: "18px",
      marginTop: "16px",
      marginBottom: "40px",
      fontSize: "12px",
      lineHeight: 1.5,
      letterSpacing: "normal"
    },
    userDetail: {
      padding: "10px 0px 10px 4px;"
    },
    viewDetailsBtnContainer: {
      padding: "5px 0 5px 5px"
    },
    viewDetailsbutton: {
      borderRadius: "4px",
      boxShadow: "1px 2px 2px 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4"
    },
    requestedUser: {
      fontSize: "11px",
      color: "#8897aa",
      fontWeight: 500,
      marginRight: "135px"
    },
    hide: {
      display: "none"
    },
    mrl: {
      "& img": {
        margin: "0 5px"
      }
    },
    downloadBtn: {
      display: "flex",
      alignItems: "center",
      fontWeight: 600,
      color: "#32325d",
      fontSize: 12,
      "&:hover": {
        textDecoration: "none"
      },
      "&:disabled": {
        opacity: "0.5"
      },
      "& svg": {
        fontSize: "1.333em",
        margin: "0 5px"
      }
    },
    labelText: {
      fontWeight: "bold"
    },
    helperText: {
      padding: "12px 24px 0",
      fontSize: 12,
      color: "#fb633f"
    },
    viewDetailsConatiner: {
      padding: "0px 14px 14px 14px"
    },
    paddingRight0: {
      padding: "0px 0px 14px 14px"
    },
    guidelineMobileView: {
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        flexBasis: "100%",
        marginTop: "15px"
      }
    },
    hideGuidelineMobileView: {
      [theme.breakpoints.down("sm")]: {
        display: "none"
      }
    },
    reset: {
      width: "auto",
      height: 28,
      borderRadius: 4,
      marginLeft: 10,
      boxShadow: "0 1px 3px 0 rgb(0 0 0 / 20%)",
      color: "#32325d",
      border: "none"
    },
    button: {
      marginRight: 10,
      marginTop: 18,
      marginBottom: 18,
      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: "10px"
      }
    },
    loanTypeLabel: {
      fontWeight: 600
    },
    itemTypography: {
      padding: "4px 7px 3px 7px",
      color: "#ffffff",
      fontSize: 10,
      fontWeight: "bold",
      backgroundColor: "#172b4d",
      borderRadius: 6,
      textTransform: "uppercase",
      marginRight: "7px",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("sm")]: {
        marginBottom: "5px"
      },
      [theme.breakpoints.down("md")]: {
        margin: "0 7px 5px 0"
      }
    },
    tooltip: {
      backgroundColor: "#fff",
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: "0 2px 12px 0 rgb(50 50 93 / 21%)",
      margin: "0px 10px",
      padding: "0.5rem",
      fontSize: "12px"
    },
    arrow: {
      color: "#fff"
    }
  })
);

export const useFormLabelStyles = makeStyles((theme: Theme) => ({
  root: {
    "&.MuiFormControlLabel-root": {
      padding: 12,
      margin: 5,
      border: "1px solid lightgrey",
      borderRadius: 6,
      alignItems: "flex-start",
      width: 180,
      justifyContent: "space-between"
    }
  },
  label: {
    fontSize: 12,
    // color: "#8897aa",
    fontWeight: 600
  }
}));
