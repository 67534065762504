import React, { useEffect, useState } from "react";
import { LineItem } from "../../stores/budgetReview/budgetReview.interface";
import { DropdownWithSearch } from "./DropdownWithSearch";
import { availableOptionsInterface } from "../../utils/DropDownCommon";
// import { DropdownWithTooltip } from "./DropdownWithTooltip";

interface StandardizedItemFieldInterface {
  standardizedItems: any[];
  showUnitField: boolean;
  row: LineItem;
  fieldsData: LineItem[];
  setFieldsData: any;
  handleItemOnBlur: any;
  isDisabled: boolean;
  proppertyMapping: any;
}
// const customAutocompleteStyle = {
//   marginTop: "45px"
// };

export function StandardizedItemField(props: StandardizedItemFieldInterface) {
  const {
    standardizedItems,
    row,
    fieldsData,
    setFieldsData,
    handleItemOnBlur,
    showUnitField,
    isDisabled,
    proppertyMapping
  } = props;
  const [budgetStandardizedItemOptions, setBudgetStandardizedItemOptions] =
    useState<availableOptionsInterface[]>([]);

  useEffect(() => {
    if (!standardizedItems?.length) return;
    let tempOptions: any[] = [];
    standardizedItems.forEach((item: any) => {
      tempOptions.push({
        ...item,
        ...{
          title: item.itemName,
          name: item.itemName
        }
      });
    }, []);
    setBudgetStandardizedItemOptions(tempOptions);
  }, [standardizedItems]);

  const handleStandardItemChange = (value: any, id: string, rowData: any) => {
    if (!proppertyMapping.isPropertyMapped) {
      proppertyMapping.setPopUpOpen(true);
      return;
    }
    if (rowData.standardizedItemId === value.standardizedItemId) return;
    const updatedData = fieldsData.map((item: LineItem) => {
      if (item.id === id) {
        return {
          ...item,
          standardizedItem: value.title,
          name: value.title,
          standardizedItemId: value.standardizedItemId
        };
      }

      return item;
    });
    setFieldsData(updatedData);
    handleItemOnBlur(updatedData);
  };

  function getValue(row: LineItem, forToolTip?: boolean): string {
    if (!row.standardizedItemId) {
      return "Select";
    }

    const standardizedItem = budgetStandardizedItemOptions.find(
      (item: any) => item.standardizedItemId === row.standardizedItemId
    )?.title;

    if (!standardizedItem) {
      return "Select";
    }
    const maxLen = showUnitField ? 12 : 16;

    return forToolTip
      ? String(standardizedItem)
      : String(standardizedItem).length > maxLen
      ? `${String(standardizedItem).slice(0, maxLen)}...`
      : String(standardizedItem);
  }

  return (
    <>
      <DropdownWithSearch
        MenuItemOptions={budgetStandardizedItemOptions}
        tooltipText={getValue(row, true)}
        BttnText={getValue(row)}
        rowData={row}
        onMenuItemClick={handleStandardItemChange}
        styleMenuCard={{
          width: showUnitField ? 125 : 150,
          background: !row.standardizedItemId ? "#fff0da" : "#fff"
        }}
        isDisabled={isDisabled}
        renderOnlyCustomStyle={{ margin: "6px 0px 6px 0px" }}
        // customAutocompleteStyle={customAutocompleteStyle}
        popperCustomStyle={{ height: 0, marginTop: 40 }}
      />
    </>
  );
}
