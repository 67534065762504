import React, { useContext, useMemo } from "react";
import {
  Grid,
  Table as MuiTable,
  TableContainer,
  TableHead,
  TableCell,
  TableRow,
  Radio,
  Typography
} from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import moment from "moment";
import Table from "../../masterView/CustomTable";
import { ObjectType } from "../../masterView/common";
import { labelFieldName30year } from "../guideline-outputs/ToorakProceedsFields";
import { getLoanPricingFields } from "../guideline-outputs/LoanPricingFields";
import { GuidlinesComparisionContext } from "./PricingAndGuidlinesSection";
import { GuidlinesComparisionContextForDashboard } from "../../take-out-partners/TKOPModalContainer";
import { ZeroStateLoan } from "../loan-lists/DetailsView/ZeroStateLoan";
import { MasterViewStyles } from "../../masterView/styles";
import { getOriginatorLabel } from "./ResultsSummaryCard";
import { isLATUser } from "../../utils/AccessManagement";

const largestDataPoint = '300px';

export const rateLockFields = [
  {
    label: "Pre/Post Close Rate Lock Expiry",
    field: "rateLockExpiryDate,rateLockPeriod"
  },
  {
    label: "Rate Adj. - Lock Period",
    field: "rateLockAdjustment"
  }
];

export const getEligiblePartnersCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType[] = [
    {
      id: "empty",
      title: "",
      width: `${largestDataPoint}`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600",
        background: "#fff",
        borderRight: "1px solid #dedede"
      }
    }
  ];
  // eslint-disable-next-line no-unused-expressions
  partnerList?.forEach((partnerData: ObjectType, index: number) => {
    const partnerId = partnerData.key || partnerData.id;
    cols.push({
      id: partnerId,
      title: partnerData.label,
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      "data-testid": `partner-pricing-data-${partnerData.label}`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600",
        textAlign: "center",
        background: "#fff",
        borderRight: "1px solid #dedede"
      }
    });
  }
  );
  return cols;
};

export const getfinalPricingCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType = [
    {
      id: "label",
      title: "Final Toorak Pricing",
      "data-testid": "final-toorak-pricing",
      width: `${largestDataPoint}`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede"
      }
    }
  ];

  partnerList.forEach((partnerData: ObjectType) => {
    const partnerId = partnerData.key || partnerData.id;
    cols.push({
      id: partnerId,
      "data-testid": `final-toorak-pricing-${partnerId}`,
      title: "",
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede",
        textAlign: "center"
      }
    })
  }
  );
  return cols;
};

export const getPricingAdjustmentsCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType = [
    {
      id: "label",
      title: "Pricing Adjustments",
      "data-testid": "pricing-adjustment",
      width: `${largestDataPoint}`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede"
      }
    }
  ];

  partnerList.forEach((partnerData: ObjectType) => {
    const partnerId = partnerData.key || partnerData.id;
    cols.push({
      id: partnerId,
      title: "",
      "data-testid": `pricing-adjustment-${partnerId}`,
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede",
        textAlign: "center"
      }
    })
  }

  );
  return cols;
};

export const getRateLockCols = (partnerList: ObjectType[]) => {
  const cols: ObjectType = [
    {
      id: "label",
      title: "Initial lock term cost",
      "data-testid": "rate-lock-adjustment",
      width: `${largestDataPoint}`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede"
      }
    }
  ];
  partnerList.forEach((partnerData: ObjectType) => {
    const partnerId = partnerData.key || partnerData.id;
    cols.push({
      id: partnerId,
      title: "",
      "data-testid": `rate-lock-adjustment-${partnerId}`,
      width: `calc((100% - ${largestDataPoint}) / ${partnerList.length})`,
      headStyle: {
        padding: "1rem",
        fontWeight: "600"
      },
      style: {
        padding: "1rem",
        borderRight: "1px solid #dedede",
        textAlign: "center"
      }
    })
  }

  );
  return cols;
};

const getRateLockExpiryDate = (
  rateLockExpiryDate: number,
  lockInPeriod: number,
  extendByDays: number = 0
) => {
  const expiry = moment
    .utc(rateLockExpiryDate)
    .add(parseInt(`${extendByDays}`, 10), "days");
  const expiryDate = `${expiry.format("ddd, MMM DD, YYYY")}`;

  return `${expiryDate} | ${lockInPeriod} Days`;
};

export const getRowsData = (fields: ObjectType[], partnersList: ObjectType[]) => {
  return fields
    .filter(
      (fieldVal: ObjectType) =>
        (fieldVal?.hideCondition && fieldVal?.hideCondition()) ||
        !fieldVal?.hideCondition
    )
    .map((fieldData: ObjectType) => {
      let rowData: ObjectType = {
        label: fieldData.label
      };
      partnersList?.forEach((partnerData: ObjectType) => {
        const partnerId = partnerData.key || partnerData.id;
        rowData = {
          ...rowData,
          [partnerId]:
            fieldData.label !== "Pre/Post Close Rate Lock Expiry"
              ? partnerData.data?.[fieldData.field]
              : partnerData.data?.[fieldData.field.split(",")[0]] &&
              getRateLockExpiryDate(
                partnerData.data?.[fieldData.field.split(",")[0]],
                Number.parseInt(
                  partnerData.data?.[fieldData.field.split(",")[1]] || 0,
                  10
                )
              )
        };
      });
      return rowData;
    });
};

export const PricingComparision = (props: any) => {
  const { programSelectConfig, partnersData } = props;
  const {
    isLockPopUp,
    currentSelectedPartner,
    setCurrentSelectedPartner,
    eligibleTakeOutPartnerResult,
    takeOutPartnerLists
  } = programSelectConfig;
  const { dailyCreditLimit, eligiblePartners } = eligibleTakeOutPartnerResult ?? {};

  const { rateSheetType } =
    useContext(GuidlinesComparisionContext) ??
    useContext(GuidlinesComparisionContextForDashboard);
  const eligiblePartnersColumns = getEligiblePartnersCols(partnersData);
  const finalPricingCols = getfinalPricingCols(partnersData);
  const pricingAdjustmentsCols = getPricingAdjustmentsCols(partnersData);
  const rateLockCols = getRateLockCols(partnersData);

  const classes = MasterViewStyles();

  const gridTemplate = useMemo(() => {
    return eligiblePartnersColumns?.reduce((prev: string, next: any) => {
      return `${prev}${next?.width ? `${next.width}px ` : "1fr "}`;
    }, "");
  }, [eligiblePartnersColumns]);

  const EligiblePartnersTableRow = (props: any) => {
    let cumulativeWidth = 0;
    return (
      <TableRow
        component="div"
        style={{
          gridTemplateColumns: `${gridTemplate}`,
          display: "flex"
        }}
      >
        {eligiblePartnersColumns?.map((col: any, index: number) => {
          if (col?.frozen) {
            if (index !== 0) {
              cumulativeWidth += eligiblePartnersColumns[index - 1].width;
            }
          }

          return (
            <TableCell
              component="div"
              key={col.id}
              className={`${col?.headClass ?? ""} ${col?.frozen ? classes.stickyHeader : ""
                }`}
              style={{
                ...col?.headStyle,
                ...(col?.frozen && {
                  left: cumulativeWidth,
                  zIndex: 1,
                }),
                width: col.width || "auto"
              }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                <span>
                  {col.title ? (
                    <>
                      <Radio
                        checked={
                          [
                            currentSelectedPartner?.partnerId,
                            currentSelectedPartner?.assignedPartner
                          ].includes(col?.id) &&
                          dailyCreditLimit[col?.id]?.isWithinLimit
                        }
                        disabled={!dailyCreditLimit[col?.id]?.isWithinLimit}
                        onChange={(e) => {
                          setCurrentSelectedPartner({
                            partnerId: col?.id,
                            assignedPartner: isLATUser()
                              ? col.title
                              : getOriginatorLabel(col?.title, takeOutPartnerLists)
                          });
                        }}
                      />
                      {isLATUser() ? col.title : getOriginatorLabel(col?.title, takeOutPartnerLists)}
                      {!dailyCreditLimit[col?.id]?.isWithinLimit && (
                        <Grid style={{ color: "red" }}>
                          {"  "}
                          *Daily credit limit reached
                        </Grid>
                      )}
                    </>
                  ) : (
                    <>
                      <span>{col?.title}</span>
                      <span>{col?.subTitle}</span>
                    </>
                  )}
                </span>
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    );
  };

  return (
    <>
      {partnersData?.length !== 0 && (
        <>
          {isLockPopUp && eligiblePartners && eligiblePartners.length > 1 && (
            <Grid>
              <Grid
                style={{
                  display: "flex"
                }}
              >
                <InfoOutlinedIcon
                  style={{ width: 15, height: 15, marginTop: "12px" }}
                  htmlColor="#32325d"
                />
                <Typography
                  variant="h4"
                  style={{
                    fontSize: 12,
                    color: "#32325d",
                    paddingLeft: 5,
                    lineHeight: 1.6,
                    paddingTop:"10px"
                  }}
                >
                  By default, we have selected the Best Pricing for the
                  loan, but you can click on the Program Name to switch
                  between them.
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid
            data-testid="pricing-comparision"
            style={{
              marginTop: "1rem",
              borderTop: "1px solid #dedede",
              borderLeft: "1px solid #dedede"
            }}
          >
            <TableContainer>
              {isLockPopUp ? (
                <MuiTable component="div">
                  <TableHead component="div">
                    <EligiblePartnersTableRow />
                  </TableHead>
                </MuiTable>
              ) : (
                <Table rowsData={[]} columnsData={eligiblePartnersColumns} />
              )}
              <Table
                rowsData={getRowsData(
                  getLoanPricingFields(rateSheetType),
                  partnersData
                )}
                columnsData={finalPricingCols}
                customTableRowStyle={{ display: "flex" }}
              />
              <Table
                rowsData={getRowsData(
                  labelFieldName30year(rateSheetType),
                  partnersData
                )}
                columnsData={pricingAdjustmentsCols}
                customTableRowStyle={{ display: "flex" }}
              />
              {!isLockPopUp && (
                <Table
                  rowsData={getRowsData(rateLockFields, partnersData)}
                  columnsData={rateLockCols}
                />
              )}
            </TableContainer>
          </Grid>
        </>
      )}
      {!partnersData?.length && (
        <ZeroStateLoan
          textToShow="There are no results to show for the this loan."
          secondTextToShow=""
        />
      )}
    </>
  );
};