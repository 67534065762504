import {
  CircularProgress,
  Grid,
  Paper,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "../../create-loan/ttf-review/document-review/document-splitting/DocumentSplitStyle";
import {
  getBudgetReviewData,
  getCommentsList,
  getStandardizedItems,
  updateBudgetReview,
  updateStatusOnDocument,
  updateStatusOnTask,
  updateLineReduxData,
  updatePredictionStatus
} from "../../stores/budgetReview/budgetReview.action";
import {
  BudgetReviewStore,
  BudgetTotalInfo,
  LineItem
} from "../../stores/budgetReview/budgetReview.interface";
import { RootState } from "../../stores/rootReducer";
import { convertUSDToNumber, sanitizeCurrency } from "../../utils/formatChecks";
import { useBudgetStyles } from "../BudgetReview.style";
// import { BudgetFieldsAction } from "./BudgetFieldsAction";
import { StandardizedItemField } from "./StandardizedItemField";
import { Alert } from "@mui/material";
import { useParams } from "react-router-dom";
import { URLParamsType } from "../../utils/constants";
import { DropdownWithTooltip } from "./DropdownWithTooltip";
import { budgetReviewStatusEnum } from "../../ilp/list-view/BudgetTaskManagement";
import {
  BudgetApproverStatus,
  ExtractionInProgressState,
  ExtractionProcessState,
  isLoginPartyId
} from "../BudgetReview.constant";
import { LoanTypesValues } from "../../assetManagement/ServicerOnboarding/LoanDetails/constants";
import { getCookie } from "../../utils/cookies";
import { BudgetTotalAndActionBtnArea } from "./BudgetTotalAndActionBtnArea";
import { WarningModal } from "@toorak/tc-common-fe-sdk";
import { usePolling } from "../../custom-hooks/usePolling";
import { apiUrlHost } from "../../stores/servicerOnboarding/servicerOnboarding.action";
import {
  hideLoader,
  showLoader
} from "../../stores/loaderAndException/loaderAndException.action";
import { checkPropertyMapped } from "../BudgetReview.utils";
import { HtmlWhiteTooltip } from "../../utils/Tooltip";
import { BudgetDescription } from "./BudgetDescription";
import { LineItemsManualEntry } from "./LineItemsManualEntry";
import { availableOptionsInterface } from "../../utils/DropDownCommon";
import { taskNames } from "../../stores/tasks/Tasks.action";
import {
  setBudgetGetResponse,
  updateBudgetPrimaryLoanId,
  updateLineItemDisable,
  updateTotalBudget,
  updateTotalFinancedBudget
} from "../../stores/budgetReview/budgetReview.reducer";
import { addEmptyLineItems } from "./TotalBudgetArea";

const BudgetFieldsAction = React.lazy(() =>
  import("./BudgetFieldsAction").then((module) => ({
    default: module.BudgetFieldsAction
  }))
);

export function BudgetFieldsArea() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const budgetClasses = useBudgetStyles();
  const [fieldsData, setFieldsData] = useState<LineItem[]>([]);
  const [showUnitField, setShowUnitFIeld] = useState<boolean>(false);
  const [budgetUnitOptions, setBudgetUnitOptions] = useState<
    availableOptionsInterface[]
  >([]);
  const { loanType, loanId } = useParams<URLParamsType>() as any;
  const textareaRefs = useRef<any[]>([]);

  const [showToast, setShowToast] = useState({ isShown: false, message: "" });
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const [isManualEntry, setIsManualEntry] = useState<boolean>(false);
  const [isPropertyMapped, setIsPropertyMapped] = useState<boolean>(false);
  const [popUpOpen, setPopUpOpen] = useState<boolean>(false);
  const [commentsList, setCommentsList] = useState<any[]>([]);

  const hideSnackBar = () => {
    setShowToast({ isShown: false, message: "" });
  };

  const {
    standardizedItems,
    selectedBudgetDoc,
    lineItemsList,
    budgetButtonInfo,
    budgetReviewResponse,
    unitVal,
    budgetReviewHeader,
    totalBudget,
    totalfinancedBudget,
    budgetStatusHistory,
    budgetApprovalHistory
  } = useSelector<RootState, BudgetReviewStore>(
    (state) => state.budgetReviewStore
  );

  const propertyDetails = useSelector<RootState, any[]>(
    (state) => state.createLoanStore.loanDetails.propertyDetails
  );

  const { startPolling, stopPolling } = usePolling();
  const [isApprovalView, setIsApprovalView] = useState<boolean>(false);

  useEffect(() => {
    if (!budgetReviewResponse) return;
    const propertyMappedVal = checkPropertyMapped(budgetReviewResponse);
    setIsPropertyMapped(() => propertyMappedVal);
    // eslint-disable-next-line
  }, [budgetReviewResponse?.staticData]);

  useEffect(() => {
    if (!budgetStatusHistory.length) return;
    const isApproval =
      budgetApprovalHistory.length > 0 &&
      isLoginPartyId(budgetApprovalHistory[0].assigneePartyId);
    setIsApprovalView(isApproval);
    const isDisabledTemp = isApproval
      ? budgetReviewStatusEnum.ReadyForBudgetApproval !==
        budgetReviewHeader.budgetReviewStatus
      : isLoginPartyId(budgetStatusHistory[0].assigneePartyId)
      ? BudgetApproverStatus.includes(budgetReviewHeader.budgetReviewStatus)
      : true;
    setIsDisabled(isDisabledTemp);
    dispatch(updateLineItemDisable(isDisabledTemp));
  }, [
    dispatch,
    budgetReviewResponse,
    budgetApprovalHistory,
    budgetStatusHistory,
    budgetReviewHeader.budgetReviewStatus
  ]);
  const { thirtyYearLoanEconomics, bridgeLoanInformation } = useSelector<
    RootState,
    any
  >((state) => state.createLoanStore.loanDetails);

  const { tasks } = useSelector<RootState, any>((state) => state.TasksStore);

  const [firstSelectedPropIdSet, setFirstSelectedPropIdSet] = useState(false);

  useEffect(() => {
    let primaryLoanId = "";
    if (loanType === LoanTypesValues.BridgeLoan) {
      primaryLoanId = bridgeLoanInformation.payload?.primaryLoanID;
    } else {
      primaryLoanId = thirtyYearLoanEconomics.payload?.primaryLoanID;
    }
    dispatch(updateBudgetPrimaryLoanId(primaryLoanId));
  }, [thirtyYearLoanEconomics, dispatch, bridgeLoanInformation, loanType]);

  useEffect(() => {
    let UnitNumb = Number(unitVal);
    if (isNaN(UnitNumb)) {
      return setBudgetUnitOptions([{ title: "1" }]);
    }
    const result = [];

    for (let i = UnitNumb; i >= 1; i--) {
      result.unshift({ title: i.toString() });
    }
    setBudgetUnitOptions(result);
  }, [unitVal]);

  // function updateWithEmptyLineItems() {
  //   function cb(resp: any) {
  //     let formattedLineItems = JSON.parse(
  //       JSON.stringify([...resp.extractedInfoProcessed.lineItems])
  //     );
  //     for (const item of formattedLineItems) {
  //       item.budget = `$${item.budget}.00`;
  //     }
  //     setFieldsData(formattedLineItems);
  //     setIsManualEntry(true);
  //   }
  //   const newLineItems = addEmptyLineItems({ val: 10 }, [], true);
  //   console.log(budgetReviewResponse);
  //   dispatch(
  //     updatePredictionStatus(
  //       budgetReviewResponse,
  //       budgetReviewResponse.processState,
  //       newLineItems,
  //       cb
  //     )
  //   );
  // }

  const updateWithEmptyLineItems = useCallback(
    (response?: any) => {
      function cb(resp: any) {
        let formattedLineItems = JSON.parse(
          JSON.stringify([...resp.extractedInfoProcessed.lineItems])
        );
        for (const item of formattedLineItems) {
          item.budget = `$${item.budget}.00`;
        }
        setFieldsData(formattedLineItems);
        setIsManualEntry(true);
      }
      const newLineItems = addEmptyLineItems({ val: 10 }, [], true);
      updatePredictionStatus(
        response ? response : budgetReviewResponse,
        response ? response.processState : budgetReviewResponse.processState,
        newLineItems,
        cb,
        dispatch
      );
    },
    [budgetReviewResponse, dispatch]
  );

  const onPollSuccess = (response: any) => {
    if (
      ExtractionInProgressState.includes(budgetReviewResponse?.processState) &&
      isManualEntry
    ) {
      stopPolling();
    }
    if (ExtractionInProgressState?.includes(response?.processState)) {
      return true; // Continue polling
    }
    //when status moved to prediction completed
    stopPolling();
    if (
      response.extractedInfoProcessed &&
      response.extractedInfoProcessed?.lineItems?.length
    ) {
      updateLineReduxData(
        response,
        dispatch,
        budgetButtonInfo.financedBudgetPercentage,
        commentsList,
        true
      );
      dispatch(setBudgetGetResponse(response));
    } else {
      updateWithEmptyLineItems();
    }
  };

  const onPollTimeExceed = () => {
    console.error("time exceed");
  };

  useEffect(() => {
    if (!selectedBudgetDoc?.id) return;
    if (
      ExtractionInProgressState?.includes(budgetReviewResponse?.processState)
    ) {
      const url = `${apiUrlHost}/ocr/extraction/document/${selectedBudgetDoc.id}`;
      startPolling({
        url,
        interval: 5000,
        onSuccess: (res: any) => {
          return onPollSuccess(res.data);
        },
        method: "GET",
        onExceedingPollingWaitTime: () => {
          onPollTimeExceed();
        },
        pollingWaitTime: 300000 // allow 5 minutes polling
      });
    }

    // eslint-disable-next-line
  }, [ExtractionInProgressState, budgetReviewResponse?.processState]);

  async function getCommentsDataAndBudgetData(docsId: number) {
    try {
      dispatch(showLoader());
      let resp = await getCommentsList(`${docsId}`);
      setCommentsList(resp);
      dispatch(hideLoader());
      dispatch(
        getBudgetReviewData(
          docsId,
          resp,
          budgetButtonInfo.financedBudgetPercentage,
          propertyDetails,
          updateWithEmptyLineItems
        )
      );
    } catch (e) {
      console.error(e);
      dispatch(hideLoader());
    }
  }

  useEffect(() => {
    if (!selectedBudgetDoc?.id || budgetButtonInfo.financedBudgetPercentage < 0)
      return;
    getCommentsDataAndBudgetData(selectedBudgetDoc.id);
    // eslint-disable-next-line
  }, [selectedBudgetDoc, budgetButtonInfo.financedBudgetPercentage]);

  useEffect(() => {
    dispatch(getStandardizedItems());
  }, [dispatch]);

  useEffect(() => {
    // if (!lineItemsList.length) return;
    setFieldsData(lineItemsList);
  }, [lineItemsList]);

  useEffect(() => {
    if (
      ExtractionProcessState.predictionSkippedManualEntry ===
      budgetReviewResponse?.processState
    ) {
      setIsManualEntry(true);
    } else {
      setIsManualEntry(false);
    }
  }, [budgetReviewResponse]);

  useEffect(() => {
    if (
      firstSelectedPropIdSet ||
      !(budgetReviewHeader?.selectedPropId && budgetReviewResponse?.status)
    )
      return;
    if (!fieldsData.length) return; //if there is no lineitems, dont update
    let tempBudgetInfo = {
      rehabBudget: sanitizeCurrency(`${totalBudget}`),
      financedBudgetAmount: sanitizeCurrency(`${totalfinancedBudget}`)
    };
    dispatch(
      updateBudgetReview(
        fieldsData,
        budgetReviewResponse,
        tempBudgetInfo,
        budgetReviewResponse.status,
        budgetReviewHeader
      )
    );
    if (!firstSelectedPropIdSet) {
      setFirstSelectedPropIdSet(true);
    }
    // eslint-disable-next-line
  }, [dispatch, budgetReviewResponse, budgetReviewHeader.selectedPropId]);

  const handleFieldChange = (
    event: any,
    id: string | null,
    field: string,
    dropdownField?: string
  ) => {
    if (popUpOpen) return;
    if (!isPropertyMapped) {
      setPopUpOpen(true);
      return;
    }
    let value = event.target.value;
    if (field === "budget" && value?.trim()) {
      // Allow only numeric with 2 decimals values for the budget field
      value = value.trim().replace(/[^0-9.]/g, "");
      if (!/^\d+(\.\d{0,2})?$/.test(value)) {
        // If it's NOT a valid number,
        return;
      }
      // if (isNaN(value)) return;
    }
    const updatedData = fieldsData.map((item: LineItem) => {
      if (item.id === id) {
        return dropdownField
          ? {
              ...item,
              [field]: value,
              [dropdownField]: false
            }
          : {
              ...item,
              [field]: value
            };
      }

      return item;
    });
    setFieldsData(updatedData);
  };

  async function handleItemOnBlur(
    updatedData?: LineItem[],
    budgetTotalInfo?: BudgetTotalInfo
  ) {
    if (!isPropertyMapped) {
      console.error("Property not mapped properly");
      setPopUpOpen(true);
      return;
    }
    let lineItems = fieldsData;
    let tempBudgetInfo = budgetTotalInfo
      ? budgetTotalInfo
      : {
          rehabBudget: sanitizeCurrency(`${totalBudget}`),
          financedBudgetAmount: sanitizeCurrency(`${totalfinancedBudget}`)
        };
    if (updatedData) {
      lineItems = updatedData;
    }
    const userId = getCookie("person_id") || "";

    if (
      [
        budgetReviewStatusEnum.LoanSubmitted,
        budgetReviewStatusEnum.ReadyForBudgetReview
      ].includes(budgetReviewHeader.budgetReviewStatus)
    ) {
      //update status to in progress
      const updatedTasks: any = JSON.parse(JSON.stringify(tasks));
      let assignedBy = updatedTasks?.[0]?.assignedTasks.find((task: any) =>
        [taskNames.BUDGET_REVIEW].includes(task.taskName)
      )?.assignedBy;
      if (!assignedBy || ["UnAssigned", "None"].includes(assignedBy)) {
        assignedBy = userId;
      }
      let assignedTasksObj = [
        {
          op: "replace",
          taskName: "budgetReview",
          assignedBy,
          assigneePartyId: budgetStatusHistory[0].assigneePartyId,
          taskStatus: budgetReviewStatusEnum.InProgress,
          taskId: budgetStatusHistory[0].taskId,
          comment: "Status Updated by System"
        }
      ];

      await updateStatusOnDocument(
        loanId,
        budgetReviewStatusEnum.InProgress,
        dispatch
      );
      dispatch(
        updateStatusOnTask(loanId, assignedTasksObj, budgetStatusHistory)
      );
    }
    const cb = () =>
      setShowToast({ isShown: true, message: "Saved Successfully" });

    dispatch(
      updateBudgetReview(
        lineItems,
        budgetReviewResponse,
        tempBudgetInfo,
        budgetReviewResponse.status,
        budgetReviewHeader,
        budgetButtonInfo.financedBudgetPercentage,
        cb
      )
    );
    // }
  }

  function handleBudgetOnBlur(event: any, id: string | null) {
    let value = event.target.value;
    let newFinanacedBudget = 0;
    let newTotalFinancedBudget = 0;
    if (value?.trim() === "") {
      const updatedData = lineItemsList.map((item: LineItem) => {
        return {
          ...item,
          budget: item.budget
        };
      });
      setFieldsData(updatedData);
      return;
    }

    if (value?.trim()) {
      newFinanacedBudget =
        (budgetButtonInfo.financedBudgetPercentage *
          convertUSDToNumber(value)) /
        100;
      value = sanitizeCurrency(`${value}`);
    }
    let newTotalBudget = 0;
    const updatedData = fieldsData.map((item: LineItem) => {
      if (item.id === id) {
        newTotalBudget += convertUSDToNumber(`${value}`);
        newTotalFinancedBudget += newFinanacedBudget;
        return {
          ...item,
          budget: value,
          financedBudget: sanitizeCurrency(`${newFinanacedBudget}`)
        };
      } else {
        newTotalBudget += convertUSDToNumber(`${item.budget}`);
        newTotalFinancedBudget += convertUSDToNumber(`${item.financedBudget}`);
      }
      return item;
    });
    const budgetTotalInfo: BudgetTotalInfo = {
      rehabBudget: sanitizeCurrency(`${newTotalBudget}`),
      financedBudgetAmount: sanitizeCurrency(`${newTotalFinancedBudget}`)
    };
    setFieldsData(updatedData);

    dispatch(updateTotalBudget(newTotalBudget));
    dispatch(updateTotalFinancedBudget(newTotalFinancedBudget));
    handleItemOnBlur(updatedData, budgetTotalInfo);
  }

  useEffect(() => {
    setShowUnitFIeld(budgetReviewHeader.isUnitShown);
  }, [budgetReviewHeader.isUnitShown]);

  const onUnitMenuItemClick = (val: any, id: string, rowData: any) => {
    if (!isPropertyMapped) {
      setPopUpOpen(true);
      return;
    }
    if (rowData.unit === val.title) return;
    const updatedData = fieldsData.map((item: LineItem) => {
      if (item.id === id) {
        return {
          ...item,
          unit: val.title
        };
      }

      return item;
    });
    setFieldsData(updatedData);
    handleItemOnBlur(updatedData);
  };

  const getValue = (val: string, forToolTip?: boolean) => {
    const maxLen = showUnitField ? (window.innerWidth > 1250 ? 12 : 10) : 12;
    return forToolTip
      ? String(val)
      : String(val).length > maxLen
      ? `${String(val).slice(0, maxLen)}...`
      : String(val);
  };

  return (
    <>
      <Snackbar
        open={showToast.isShown}
        autoHideDuration={2000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right"
        }}
        onClose={hideSnackBar}
      >
        <Alert severity="success">{showToast.message}</Alert>
      </Snackbar>
      <WarningModal
        isOpen={popUpOpen}
        handleClose={() => setPopUpOpen(false)}
        primaryBtnName="OK"
        header="Warning"
        body="Please select the property to be mapped with this document."
      />
      <Grid className={budgetClasses.budgetFieldsAreaContainer}>
        <Grid
          container
          item
          xs={12}
          className={classes.header}
          style={{ padding: "8px 5px" }}
        >
          <Grid item container style={{ width: "5%" }} />
          <Grid
            item
            container
            style={{
              width: "9%",
              alignItems: "center",
              display: showUnitField ? "flex" : "none"
            }}
          >
            <Typography className={classes.headerText}>Unit</Typography>
          </Grid>
          <Grid
            item
            container
            style={{
              width: showUnitField ? "15%" : "16%",
              alignItems: "center"
            }}
          >
            <Typography className={classes.headerText}>Item </Typography>
          </Grid>
          <Grid item container style={{ width: "30%", alignItems: "center" }}>
            <Typography className={classes.headerText}>Description</Typography>
          </Grid>
          <Grid
            item
            container
            style={{
              width: showUnitField ? "17%" : "21%",
              alignItems: "center"
            }}
          >
            <Typography className={classes.headerText}>
              Standardized Item
            </Typography>
          </Grid>
          <Grid
            item
            container
            style={{
              width: showUnitField ? "10%" : "11%",
              alignItems: "center"
            }}
          >
            <Typography className={classes.headerText}>Budget ($)</Typography>
          </Grid>
          <Grid
            item
            container
            style={{
              width: showUnitField ? "10%" : "11%",
              alignItems: "center"
            }}
          >
            <Typography className={classes.headerText}>
              Financed Budget ($)
            </Typography>
          </Grid>
          <Grid item container style={{ width: "3%" }} />{" "}
        </Grid>
        <Grid
          container
          item
          xs={12}
          className={budgetClasses.lineItemsContainer}
        >
          <LineItemsManualEntry
            budgetReviewResponse={budgetReviewResponse}
            stopPolling={stopPolling}
            setFieldsData={setFieldsData}
            isApprovalView={isApprovalView}
            isManualEntry={isManualEntry}
            setIsManualEntry={setIsManualEntry}
          />
          {(budgetReviewResponse?.processState ===
            ExtractionProcessState.completed ||
            isManualEntry) && (
            <>
              {fieldsData.map((row: LineItem, index: number) => (
                <Grid
                  container
                  item
                  xs={12}
                  className={classes.tableContent}
                  style={{
                    height: row.isDescEditMode ? "90px" : "60px"
                  }}
                >
                  <Grid item container className={budgetClasses.budgetTextView}>
                    {index + 1}
                  </Grid>

                  <Grid
                    item
                    container
                    style={{
                      width: "9%",
                      display: showUnitField ? "block" : "none"
                    }}
                    className={budgetClasses.budgetTextView}
                  >
                    <DropdownWithTooltip
                      MenuItemOptions={budgetUnitOptions}
                      tooltipText={""}
                      BttnText={row.unit ? `${row.unit}` : "Select"}
                      rowData={row}
                      onMenuItemClick={onUnitMenuItemClick}
                      styleMenuCard={{ width: 45 }}
                      MenuStyle={{ width: 55 }}
                      isDisabled={isDisabled}
                    />
                  </Grid>

                  <Grid
                    item
                    container
                    style={{ width: showUnitField ? "15%" : "16%" }}
                  >
                    <TextField
                      variant="outlined"
                      data-testid={`item-input`}
                      value={row.item}
                      className={`${
                        isDisabled
                          ? budgetClasses.lineItemTextFieldDisabled
                          : budgetClasses.lineItemTextField
                      }`}
                      style={{
                        width: showUnitField ? "98%" : "95%",
                        borderColor: row.item === "" ? "red" : "#cad1d7"
                      }}
                      onChange={(e) => handleFieldChange(e, row.id, "item")}
                      onBlur={() => {
                        handleItemOnBlur();
                      }}
                      disabled={isDisabled}
                    />
                  </Grid>
                  <Grid item container style={{ width: "30%" }}>
                    <BudgetDescription
                      row={row}
                      isDisabled={isDisabled}
                      setPopUpOpen={setPopUpOpen}
                      showUnitField={showUnitField}
                      isPropertyMapped={isPropertyMapped}
                      textareaRefs={textareaRefs}
                      fieldsData={fieldsData}
                      setFieldsData={setFieldsData}
                      handleItemOnBlur={handleItemOnBlur}
                      handleFieldChange={handleFieldChange}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    className={budgetClasses.budgetTextView}
                    style={{
                      width: showUnitField ? "17%" : "21%"
                    }}
                  >
                    <StandardizedItemField
                      proppertyMapping={{ isPropertyMapped, setPopUpOpen }}
                      standardizedItems={standardizedItems}
                      row={row}
                      fieldsData={fieldsData}
                      setFieldsData={setFieldsData}
                      handleItemOnBlur={handleItemOnBlur}
                      showUnitField={showUnitField}
                      isDisabled={isDisabled}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    style={{ width: showUnitField ? "10%" : "11%" }}
                  >
                    <HtmlWhiteTooltip
                      title={row.budget}
                      placement="right"
                      arrow
                    >
                      <TextField
                        variant="outlined"
                        data-testid={`last-page-input`}
                        value={row.budget}
                        className={`${
                          isDisabled
                            ? budgetClasses.lineItemTextFieldDisabled
                            : budgetClasses.lineItemTextField
                        }`}
                        onChange={(e) => handleFieldChange(e, row.id, "budget")}
                        onBlur={(e) => handleBudgetOnBlur(e, row.id)}
                        disabled={isDisabled}
                      />
                    </HtmlWhiteTooltip>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{ width: showUnitField ? "10%" : "11%" }}
                    className={budgetClasses.budgetTextView}
                  >
                    {/* {formatValueByType(`${row.financedBudget ?? ""}`, "currency")} */}
                    <HtmlWhiteTooltip
                      title={row.financedBudget}
                      placement="right"
                      arrow
                    >
                      <span>{getValue(row.financedBudget)}</span>
                    </HtmlWhiteTooltip>
                  </Grid>
                  <Grid
                    item
                    container
                    style={{ width: "3%", paddingLeft: 15 }}
                    className={budgetClasses.budgetTextView}
                  >
                    <BudgetFieldsAction
                      row={row}
                      proppertyMapping={{ isPropertyMapped, setPopUpOpen }}
                      handleFieldChange={handleFieldChange}
                      fieldsData={fieldsData}
                      setFieldsData={setFieldsData}
                      docsId={selectedBudgetDoc?.id}
                      setShowToast={setShowToast}
                      handleItemOnBlur={handleItemOnBlur}
                      isDisabled={isDisabled}
                    />
                  </Grid>
                </Grid>
              ))}
            </>
          )}
          {!ExtractionInProgressState.includes(
            budgetReviewResponse?.processState
          ) ||
            (ExtractionInProgressState.includes(
              budgetReviewResponse?.processState
            ) &&
              !isManualEntry && (
                <Paper
                  elevation={3}
                  className={budgetClasses.noLineItemContainer}
                >
                  <CircularProgress size={35} style={{ marginLeft: 15 }} />
                  <br />
                  <Typography>Loading...!</Typography>
                </Paper>
              ))}
          {budgetReviewResponse?.processState ===
            ExtractionProcessState.failed && (
            <Paper elevation={3} className={budgetClasses.noLineItemContainer}>
              <span className={budgetClasses.noLineItemMmessage}>
                No Data To Show
              </span>
            </Paper>
          )}
        </Grid>
      </Grid>
      <BudgetTotalAndActionBtnArea
        fieldsData={fieldsData}
        setFieldsData={setFieldsData}
        isDisabled={isDisabled}
      />{" "}
    </>
  );
}
