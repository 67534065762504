import { isValidNumber } from "../../../create-loan/30-year-loan-details/ValidationRule";
import {
  ToorakProductEnum,
  multiFamMixedUsePropertyTypeDropDown,
  multiFamMixedUsevaluationSource,
  oneFourFamilyPropertyTypeDropDown,
  propertyTypeDropDown,
  rentalPropertyTypeDropDown,
  thirtyYearPropertyTypeDropDown,
  valuationSource,
  yesNoDropdown
} from "../../../create-loan/constants/loanCreationDropDownValues";
import { LoanSizerEnum } from "../../../frontend-sizer/FrontendSizerTemplate";
import { sanitizeNumericInput } from "../../../utils/formatChecks";
import { numberType, yOrNDropDown } from "../../constants";
import { getBooleanDropDownValue } from "../../views/common/util";

const propertyTypeValues = thirtyYearPropertyTypeDropDown.map((item) => ({
  value: item,
  label: item === "SFR" ? "Single Family Detached" : item
}));

const bridgePropertyTypeValues = propertyTypeDropDown.map((item) => ({
  value: item,
  label: item === "SFR" ? "Single Family Detached" : item
}));

const rentalPropertyTypeValues = rentalPropertyTypeDropDown.map((item) => ({
  value: item,
  label: item === "SFR" ? "Single Family Detached" : item
}));

const dscrFESPropertyType = {
  fieldName: "propertyType",
  backendKey: "propertyType",
  fieldLabel: "Property Type",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: propertyTypeValues || [],
  colSpan: 4,
  isVisible: (data: any, loanType: any) => loanType === "InvestorDSCR",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyType"
};

const dscrPropertyType = {
  fieldName: "propertyType",
  fieldLabel: "Property Type",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: propertyTypeValues || [],
  colSpan: 4,
  isVisible: (data: any, loanType: any) => loanType === "InvestorDSCR",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyType"
};

const bridgePropertyType = {
  fieldName: "propertyType",
  fieldLabel: "Property Type",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: bridgePropertyTypeValues || [],
  colSpan: 4,
  isVisible: (data: any, loanType: any, isSubmitted: boolean, otherData: any) =>
    otherData?.selectedToorakProduct !== ToorakProductEnum.Rental &&
    loanType !== "InvestorDSCR",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyType"
};

const bridgeFESPropertyType = {
  fieldName: "propertyType",
  fieldLabel: "Property Type",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    oneFourFamilyPropertyTypeDropDown.map((item: any) => ({
      value: item,
      label: item === "SFR" ? "Single Family Detached" : item
    })) || [],
  colSpan: 4,
  isVisible: (data: any, loanType: any, isSubmitted: boolean, otherData: any) =>
    true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyType"
};

const bridgeFESMultiFamilyPropertyType = {
  fieldName: "propertyType",
  fieldLabel: "Property Type",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    multiFamMixedUsePropertyTypeDropDown.map((item: any) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: () => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyType"
};

const rentalPropertyType = {
  fieldName: "propertyType",
  fieldLabel: "Property Type",
  required: () => true,
  fieldType: (loanType: string) => "dropDown",
  dropDownData: rentalPropertyTypeValues || [],
  colSpan: 4,
  isVisible: (data: any, loanType: any, isSubmitted: boolean, otherData: any) =>
    otherData?.selectedToorakProduct === ToorakProductEnum.Rental &&
    loanType !== "InvestorDSCR",
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyType"
};

const noOfUnits = {
  backendKey: "propertyUnits",
  fieldName: "numberOfUnits",
  fieldLabel: "Number Of Units",
  required: () => true,
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyUnits"
};

const dscrFESNoOfUnits = {
  fieldName: "numberOfUnits",
  backendKey: "propertyUnits",
  fieldLabel: "Number Of Units",
  required: () => true,
  fieldType: (loanType: string) => "number",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyUnits",
};

const mostRecentPropertyValuationDate = {
  fieldName: "propertyValuationDate",
  backendKey: "recentPropertyValuationDate",
  fieldLabel: "Most Recent Property Valuation Date",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "recentPropertyValuationDate",
};

const dscrFESMostRecentPropertyValuationDate = {
  fieldName: "propertyValuationDate",
  backendKey: "recentPropertyValuationDate",
  fieldLabel: "Most Recent Property Valuation Date",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "recentPropertyValuationDate"
};
const originalDateOfAcquisition = {
  fieldName: "propertyAcquisitionDate",
  backendKey: "propertyAcquisitionDate",
  fieldLabel: "Original Date Of Acquisition",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyAcquisitionDate"
};

const dscrFESOriginalDateOfAcquisition = {
  fieldName: "propertyOriginallyAcquired",
  backendKey: "propertyAcquisitionDate",
  fieldLabel: "Original Date Of Acquisition",
  required: () => true,
  fieldType: (loanType: string) => "date",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "propertyAcquisitionDate"
};

const dscrFesSquareFootage = {
  fieldName: "squareFootage",
  backendKey: "squareFootage",
  fieldLabel: "Property Square Footage",
  required: () => true,
  ChangeValueMethod: sanitizeNumericInput,
  errorChecks: isValidNumber,
  fieldType: () => "number",
  type: numberType,
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const isPropertyOnDebt = {
  fieldName: "isPropertyOnDebt",
  backendKey: "isPurchasedWithDebt",
  fieldLabel: "Property with Debt",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    yesNoDropdown.map((item) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const squareFootage = {
  fieldName: "squareFootage",
  fieldLabel: "Square Footage",
  required: () => true,
  ChangeValueMethod: sanitizeNumericInput,
  errorChecks: isValidNumber,
  fieldType: () => "number",
  type: numberType,
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const dscrPrePostPercentageOccupied = {
  fieldName: "percentageOccupied",
  backendKey: "occupiedPercentage",
  fieldLabel: "% Occupied",
  required: () => false,
  fieldType: (loanType: string) => "percentageNoWithout100x",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true,
  commentField: "occupiedPercentage"
};

const dscrFesPercentageOccupied = {
  fieldName: "occupiedPercentage",
  backendKey: "occupiedPercentage",
  fieldLabel: "% Occupied",
  required: () => false,
  fieldType: (loanType: string) => "percentageNoWithout100x",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) => true,
  commentField: "occupiedPercentage"
};

const bridgePercentageOccupied = {
  fieldName: "percentageOccupied",
  backendKey: "occupiedPercentage",
  fieldLabel: "% Occupied",
  required: () => true,
  fieldType: (loanType: string) => "percentageNoWithout100x",
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "occupiedPercentage"
};

const changeInUseCase = {
  fieldName: "changeInUseCase",
  backendKey: "changeInUseCase",
  fieldLabel: "Expansion (>20%/+750 sq.ft) or Change Of Use Case",
  required: () => false,
  fieldType: (loanType: string) => "booleanDropDown",
  dropDownData:
    yOrNDropDown.map((item) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "changeInUseCase",
  getValue: (propertyInformation: any) => {
    return getBooleanDropDownValue(propertyInformation.changeInUseCase, false);
  }
};

const bridgeFES14ChangeInUseCase = {
  fieldName: "changeInUseCase",
  backendKey: "changeInUseCase",
  fieldLabel: "Expansion (>20%/+750 sq.ft) or Change Of Use Case",
  required: () => false,
  fieldType: (loanType: string) => "booleanDropDown",
  dropDownData:
    yesNoDropdown.map((item) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "changeInUseCase",
  getValue: (propertyInformation: any) => {
    return getBooleanDropDownValue(propertyInformation.changeInUseCase, true);
  }
};

const preRehabSquareFootage = {
  fieldName: "preRehabSquareFootage",
  backendKey: "preRehabSquareFootage",
  fieldLabel: "Pre-Rehab Square Footage",
  required: () => true,
  ChangeValueMethod: sanitizeNumericInput,
  errorChecks: isValidNumber,
  fieldType: () => "number",
  type: numberType,
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "preRehabSquareFootage"
};

const postRehabSquareFootage = {
  fieldName: "postRehabSquareFootage",
  backendKey: "postRehabSquareFootage",
  fieldLabel: "Post-Rehab Square Footage",
  required: () => true,
  ChangeValueMethod: sanitizeNumericInput,
  errorChecks: isValidNumber,
  fieldType: () => "number",
  type: numberType,
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable,
  commentField: "postRehabSquareFootage"
};

const valuationSourceFES = {
  fieldName: "valuationSource",
  fieldLabel: "Valuation Source",
  fieldType: (loanType: string) => "dropDown",
  colSpan: 4,
  required: () => false,
  isVisible: (data: any) => true,
  dropDownData:
    valuationSource.map((item) => ({
      value: item,
      label: item
    })) || [],
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const multiFamilyValuationSourceFES = {
  fieldName: "valuationSource",
  fieldLabel: "Valuation Source",
  fieldType: (loanType: string) => "dropDown",
  colSpan: 4,
  required: () => false,
  isVisible: (data: any) => true,
  dropDownData:
    multiFamMixedUsevaluationSource.map((item) => ({
      value: item,
      label: item
    })) || [],
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const isEntitledLand = {
  fieldName: "isEntitledLand",
  fieldLabel: "Entitled Land / As of Right Development",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    yOrNDropDown.map((item) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const bridgeFesGrUpIsEntitledLand = {
  fieldName: "isEntitledLand",
  fieldLabel: "Entitled Land / As of Right Development",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    yesNoDropdown.map((item) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const isPropertyPurchasedLast2YearsFes = {
  fieldName: "isPropertyPurchasedLast2Years",
  fieldLabel: "Property purchased within last 2 years",
  required: () => false,
  fieldType: (loanType: string) => "dropDown",
  dropDownData:
    yesNoDropdown.map((item) => ({
      value: item,
      label: item
    })) || [],
  colSpan: 4,
  isVisible: (data: any) => true,
  isViewMode: (isEvaluationPanelVisible: any, isEditable: any) =>
    isEvaluationPanelVisible && !isEditable
};

const isPropertyPurchasedLast2Years = {
  ...isPropertyPurchasedLast2YearsFes,
  isVisible: (data: any) => {
    return true;
  },
  required: () => true
}

const dscrFESFields = [
  dscrFesSquareFootage,
  dscrFESPropertyType,
  dscrFESOriginalDateOfAcquisition,
  dscrFESMostRecentPropertyValuationDate,
  isPropertyOnDebt,
  dscrFESNoOfUnits,
  dscrFesPercentageOccupied
];

const bridgeFesGroundFields = [
  bridgeFESPropertyType,
  noOfUnits,
  // changeInUseCase,
  valuationSourceFES,
  isPropertyPurchasedLast2YearsFes,
  bridgeFesGrUpIsEntitledLand
];

const bridgeFesOneToFourFields = [
  bridgeFESPropertyType,
  noOfUnits,
  bridgeFES14ChangeInUseCase,
  valuationSourceFES,
  bridgePercentageOccupied,
];

const bridgeFesMultiFamilyFields: any = [
  bridgeFESMultiFamilyPropertyType,
  noOfUnits,
  bridgeFES14ChangeInUseCase,
  multiFamilyValuationSourceFES,
  bridgePercentageOccupied,
];

const dscrPrePostFields = [
  dscrPropertyType,
  bridgePropertyType,
  rentalPropertyType,
  noOfUnits,
  mostRecentPropertyValuationDate,
  originalDateOfAcquisition,
  squareFootage,
  dscrPrePostPercentageOccupied
];

const bridgePrePostFields: any = (productType: string) => {
  const fields = [
    bridgePropertyType,
    noOfUnits,
    mostRecentPropertyValuationDate,
    originalDateOfAcquisition,
    changeInUseCase,
    preRehabSquareFootage,
    postRehabSquareFootage,
    bridgePercentageOccupied
  ];
  return productType === LoanSizerEnum.groundUp ? [...fields, isPropertyPurchasedLast2Years] : fields;
};

const bridgePostFields: any = (productType: string) => {
  const fields = [
    bridgePropertyType,
    noOfUnits,
    mostRecentPropertyValuationDate,
    originalDateOfAcquisition,
    { ...changeInUseCase, required: () => true },
    preRehabSquareFootage,
    postRehabSquareFootage,
    bridgePercentageOccupied
  ];
  return productType === LoanSizerEnum.groundUp ? [...fields, isPropertyPurchasedLast2Years] : fields;
};

function getFeildsBasedOnLoanSizerType(loanSizerType: any) {
  switch (loanSizerType) {
    case LoanSizerEnum.multifamilyMixedUse:
      return bridgeFesMultiFamilyFields;
    case LoanSizerEnum.groundUp:
      return bridgeFesGroundFields;
    case LoanSizerEnum.oneFourFamily:
      return bridgeFesOneToFourFields;
    default:
      return [];
  }
}

export const getPropertyInformationFields = (
  loanStage: string,
  loanType: any,
  loanSizerType: any
) => {
  switch (loanStage) {
    case "fes":
      return loanType === "InvestorDSCR"
        ? dscrFESFields
        : getFeildsBasedOnLoanSizerType(loanSizerType);
    case "pre":
      return loanType === "InvestorDSCR"
        ? dscrPrePostFields
        : bridgePrePostFields(loanSizerType);
    case "post":
      return loanType === "InvestorDSCR" ? dscrPrePostFields : bridgePostFields(loanSizerType);
    default:
      return [];
  }
};
