/* eslint-disable @typescript-eslint/camelcase */
import { ObjectType } from "../../../masterView/common";
import { isLATUser } from "../../../utils/AccessManagement";
import { TABLE_FUNDING, WAREHOUSE_FUNDING, ADVANCE_FUNDING } from "../../../utils/constants";

export const loanStages: any = {
  Loans: ["PRE", "POST"],
  "Guideline Evaluation": ["FES"],
  "See both": ["PRE", "POST", "FES", "TOORVAL"],
  "On-Hold Loans": ["ON-HOLD"],
  "Toorval Appraisal": ["TOORVAL"]
};

export const loanTags = [
  { label: "Express Loan", key: "Express", isDisabled: false },
  {
    label: "Premier Loans",
    key: "Premier",
    isDisabled: false
  }
];

export const loanListSourceFields = [
  "currentPendingAllExceptionCount",
  "currentPendingExternalExceptionCount",
  "currentPendingAllEnquiryCount",
  "currentPendingExternalEnquiryCount",
  "currentPendingAllWaiverCount",
  "currentPendingExternalWaiverCount",
  "loan.workflowState",
  "enquiries",
  "loan.qcRequired",
  "loan.assignedTo",
  "loan.loanDetailId.loanId",
  "loan.loanDetailId.tradeId",
  "loan.roleOfAssignee",
  "loan.loanProcessType",
  "loan.tapeToFileStatus",
  "loan.dueDilligenceDate",
  "loan.loanTypeId.loanTypeName",
  "loan.loanInfo.toorakProduct",
  "loan.loanTypeId.loanTypeDescription",
  "loan.loanType",
  "loan.loanInfo.loanPurpose",
  "borrowers",
  "loan.loanEconomics.originalLoanAmount",
  "loan.loanEconomics.interestRateAsOfCutOffDate",
  "loan.loanStage",
  "loan.loanCreationDate",
  "updatedOn",
  "loan.loanState",
  "loan.loanInfo.noOfProperties",
  "loan.loanSource",
  "loan.loanInfo.primaryLoanId",
  "loan.loanEconomics.totalBudgetAmount",
  "loan.loanEconomics.cashOutAmount",
  "loan.loanEconomics.totalOriginationAndDiscountPoints",
  "loan.loanEconomics.accrualType",
  "loan.loanEconomics.cutOffDateMaximumLoanAmount",
  "loan.loanEconomics.cutOffDateLoanAmount",
  "loan.propertyEconomics.loanEconomics",
  "loan.loanEconomics.rateAdjustmentFrequency",
  "loan.loanEconomics.paymentAdjustmentFrequency",
  "loan.loanEconomics.originalMonthlyPiPayment",
  "loan.loanEconomics.originalMaximumLoanAmount",
  "loan.loanEconomics.financedInterestReserve",
  "loan.loanEconomics.subsequentPeriodicCap",
  "loan.loanInfo.pledgeOfEquity",
  "loan.loanInfo.toorakProduct",
  "loan.loanInfo.extensionOptions",
  "loan.loanInfo.crossCollaterlization",
  "loan.loanInfo",
  "loan.loanType",
  "loan.loanInfo.originalMaturityDate",
  "loan.loanInfo.firstPaymentDateOfLoan",
  "loan.loanInfo.originationDate",
  "loan.loanSubmissionDate",
  "loan.initialLoanSubmissionDate",
  "loan.loanInfo.cutOffDate",
  "loan.loanInfo.loanStructure",
  "guarantors",
  "loan.loanUserMap",
  "loan.loanEconomics.interestOnlyPeriod",
  "loan.loanEconomics.initialRateAdjustmentPeriod",
  "loan.loanEconomics.initialPeriodicCap",
  "loan.loanEconomics.initialPayAdjustmentPeriod",
  "loan.loanEconomics.grossArmMargin",
  "result.loanResult.loanEconomics.dscr",
  "result.loanResult.loanFeatures.originalLtc",
  "result.loanResult.loanFeatures.originalAsIsLtv",
  "result.loanResult.loanFeatures.originalAsRepairedLtv",
  "ruleVersions.rateSheet.effectiveStartDate",
  "loan.loanEconomics.currentLoanBalance",
  "loan.loanEconomics.costToLoanRatio",
  "loan.loanEconomics.armProductType",
  "loan.loanEconomics.armIndex",
  "loan.loanEconomics.financedBudgetAmount",
  "loan.loanDetailId.loanConfigId.datePurchased",
  "loan.loanDetailId.loanConfigId.dateUploaded",
  "properties",
  "originator",
  "loan.loanEconomics.lifetimeMaxRate",
  "loan.loanDetailId.loanSummaryId.monthlyTaxAmount",
  "loan.loanDetailId.loanSummaryId.monthlyInsuranceAmount",
  "loan.loanDetailId.loanSummaryId.taxes",
  "loan.loanDetailId.loanSummaryId.insurance",
  "loan.loanDetailId.loanSummaryId.taxesandInsuranceDue",
  "loan.loanDetailId.loanSummaryId.escrowFund",
  "loan.loanDetailId.loanSummaryId.lastPaymentDate",
  "loan.loanDetailId.loanSummaryId.nextPaymentDue",
  "loan.loanDetailId.loanSummaryId.updatedEscrowBalance",
  "loan.loanDetailId.loanSummaryId.leaseStatus",
  "loan.loanDetailId.loanSummaryId.seasoned",
  "loan.loanDetailId.loanSummaryId.paymentHistoryandUPBChecked",
  "result.loanResult.finalCalculation.overallLoanEligibility",
  "result.loanResult.firstLoss.firstLossPercentage",
  "result.loanResult.firstLoss.totalCreditReserveHoldback",
  "loan.loanDetailId.loanConfigId.appraisal",
  "loan.loanDetailId.loanConfigId.streetView",
  "loan.loanDetailId.loanConfigId.tapeToFile",
  "loan.loanDetailId.loanConfigId.truliaCrime",
  "loan.loanDetailId.loanConfigId.zillow",
  "loan.loanDetailId.loanConfigId.emailSentToOriginator",
  "loan.loanDetailId.loanConfigId.dueDiligencePartyId",
  "loan.loanEconomics.rateType",
  "result.loanResult.loanEconomics.prePaymentPenaltyMonths",
  "loan.loanEconomics.prepayPenaltyType",
  "loan.loanEconomics.paymentAdjustmentFrequency",
  "loan.loanEconomics.originalMonthlyPiPayment",
  "loan.loanEconomics.lifeRateCap",
  "loan.loanEconomics.preRehabSquareFootage",
  "loan.loanEconomics.postRehabSquareFootage",
  "loan.loanDetailId.originatorPartyId",
  "result.loanResult.loanCharacterisations.loanCharacterisation",
  "result.loanResult.loanCharacterisations.toorakLoanType",
  "result.loanResult.loanCharacterization.toorakLoanType",
  "result.loanResult.loanCharacterisations.needEnhancedReview",
  "result.loanResult.loanCharacterization.needEnhancedReview",
  "loan.ageing",
  "loan.errorsPending",
  "loan.fieldsPending",
  "loan.loanCreatedBy",
  "grades",
  "firstOpenConditionDate",
  "loan.loanTypeId.loanTypeName",
  "lastUpdatedToorak",
  "lastUpdatedOriginator",
  "enquiries",
  "exceptions",
  "waivers",
  "bundle.created_on",
  "loan.onHoldBy",
  "onHoldDetails",
  "loan.loanDetailId.loanLandmarks",
  "result.loanResult.loanPricing.finalToorakPrice",
  "result",
  "result.loanResult.riskScores.totalAggregateRisk",
  "result.loanResult.riskScores.inStateLocRisk",
  "result.loanResult.riskScores.riskBucket",
  "loan.loanInfo.takeoutPartner",
  "loan.rateLockExpiryDate",
  "borrowerDetails.customer.foreignNationalString",
  "loan.ddReportStatus",
  "loan.loanDetailId.loanSummaryId.teamIndiaTapetoFile",
  "loan.rateLockSyncStatus"
];
export const getLoanTypeFilterMasterViewOptions = (options: ObjectType[]) => {
  return [
    {
      id: "loan.loanDetailId.fundingType.raw",
      options: [{ label: "Table Fund Loan", key: TABLE_FUNDING }],
      style: { padding: "0.5rem 0 0 0" }
    },
    {
      id: "loan.loanDetailId.isWarehouseFunded",
      options: [{ label: "Warehouse Funded Loan", key: WAREHOUSE_FUNDING }],
      style: { padding: "0.5rem 0 0 0" }
    },
    {
      id: "loan.loanDetailId.fundingType.raw",
      options: [{ label: "Advance Funded Loan", key: ADVANCE_FUNDING }],
      style: { padding: "0.5rem 0 0 0" }
    },
    {
      id: "loan.loanProcessType.raw",
      options: loanTags,
      style: { padding: "1rem 0" }
    }
  ];
};

export const getLoanTypeFilterOptions = (options: ObjectType[]) => {
  return [
    {
      id: "loan.loanType.raw",
      options,
      style: { padding: "1rem 0" }
    },
    {
      id: "loan.loanDetailId.fundingType.raw",
      options: [{ label: "Table Fund Loan", key: TABLE_FUNDING }]
    },
    {
      id: "loan.loanDetailId.isWarehouseFunded",
      options: [{ label: "Warehouse Funded Loan", key: WAREHOUSE_FUNDING }]
    },
    {
      id: "loan.loanDetailId.fundingType.raw",
      options: [{ label: "Advance Funded Loan", key: ADVANCE_FUNDING }]
    },
    {
      id: "loan.loanProcessType.raw",
      options: loanTags,
      style: { padding: "1rem 0" }
    }
  ];
};
export const DetailViewStates = ["Detailed", ""];

const exceptionsPending = isLATUser()
  ? {
      range: {
        currentPendingAllExceptionCount: {
          gt: 0
        }
      }
    }
  : {
      range: {
        currentPendingExternalExceptionCount: {
          gt: 0
        }
      }
    };
const enquiriesPending = isLATUser()
  ? {
      range: {
        currentPendingAllEnquiryCount: {
          gt: 0
        }
      }
    }
  : {
      range: {
        currentPendingExternalEnquiryCount: {
          gt: 0
        }
      }
    };
const waiversPending = isLATUser()
  ? {
      range: {
        currentPendingAllWaiverCount: {
          gt: 0
        }
      }
    }
  : {
      range: {
        currentPendingExternalWaiverCount: {
          gt: 0
        }
      }
    };
export const tabFilters: any = {
  allTab: null,
  errorsPending: {
    range: {
      "loan.errorsPending": {
        gt: 0
      }
    }
  },
  // docsPending: {
  //   range: {
  //     "loan.docsPending": {
  //       gt: 0
  //     }
  //   }
  // },
  fieldsPending: {
    range: {
      "loan.fieldsPending": {
        gt: 0
      }
    }
  },
  waiversPending,
  exceptionsPending,
  enquiriesPending,
  agedLoans: {
    range: {
      "loan.lastLoanStatusUpdateDate": {
        lt: "now-11d"
      }
    }
  }
};

export const LoanHistoryHeader = [
  { value: "Loan Stage" },
  { value: "Loan Status" },
  { value: "Date Time Stamp", sortable: true },
  { value: "Action By" }
];

export const LoanActions: any = {
  loan_creation: "Loan Created",
  update_status: "Loan Status Updated",
  update_hold_status: "Updated Hold Status",
  loan_conversion: "Loan Converted",
  update_bundle_status: "Bundle Status Updated",
  first_condition_creation: "First Condition Posted",
  update_loan_scenarios: "Scenario Updated",
  loan_delete: "Loan Deleted",
  rate_lock_extended: "Rate Lock Extended",
  rate_lock_applied: "Rate Lock Applied",
  rate_lock_apply_notification: "Rate Lock Notification sent",
  // "assign_tape_to_file": "Assigned Tape to File"
  // "update_sub_status": "Loan Status Updated",
  // "update_tape_to_file": "Update Tape to File",
  // "submit_tape_to_file": "Submitted Tape to File",
  // "waiver_carry_forward": "Waiver Carry Forwarded",
  // "assign_to": "Loan Assigned",
};

export const AppraisalActions: any = {
  "data entry": "Loan Created",
  "under review": "Loan Status Updated",
  "approved conditionally": "Approved Conditionally",
  approved: "Approved",
  rejected: "Rejected"
};
