import { isEmptyValue } from "@toorak/tc-common-util-sdk/dist/errorCountService/fieldMissingCount";
import { getCookie } from "../../../utils/cookies";
import { toISOLocalDateString, isNullOrUndefined, formatStringDate } from "../../../utils/formatChecks";
import { ObjectType } from "../../../masterView/common";
import { updateBorrowerInfo, updateBridgeLoanDetails, updateBridgeLoanGurantor, updateFESBorrowerInfo, updateThirtyYearLoanDetails } from "../../../create-loan/create-loan.action";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { LeaseStatusEnum } from "../../../create-loan/constants/loanCreationDropDownValues";
import { updateFESLoanDetailsInBulk } from "../../../create-loan/create-loan.reducer";
import { MMMDDYYYYDateFormat } from "../../../utils/utils";

export function getBorrowerObjectForLoanUpdate(
  borrowerInformation: any,
  bridgeLoanBorrowerInformation: any,
  loanDetails?: any,
  isSubmitted?: boolean,
  loanStage?: string,
  loanType?: string,
  borrowerData?: any,
  borrowerUserMap?: any,
  isFormEditing?: boolean,
) {
  const borrowerObj: any = [];
  const loanUserSequenceMap = new Map();
  const removedBorrowers: any = [];
  const bDataToBeChecked = isFormEditing ? borrowerData : bridgeLoanBorrowerInformation
  // Add and delete option of borrower
  if (borrowerInformation?.length) {
    let latestBorrowerSequence: any = getCookie("latestBorrowerSequence");
    latestBorrowerSequence = latestBorrowerSequence
      ? Number(latestBorrowerSequence) ?? -1
      : -1;
    borrowerInformation.forEach(
      (element: any, index: number) => {
        if (element) {
          let borrowerType = element?.loanUserType || "";
          const hasBorrowerChanged = element?.hasBorrowerChanged ?? false;

          let { partyId } = loanDetails.bridgeLoanBorrowerInformation[index]?.payload ?? "";
          if (loanStage === LoanStage.fes) {
            partyId = loanDetails?.fesBorrowerInformation?.partyId;
          }
          if (!borrowerType) {
            borrowerType = borrowerData[index]?.payload?.borrowerType || bridgeLoanBorrowerInformation[index]?.payload?.borrowerType
          }
          const creditReportDate = element?.originalCreditScoreReportDate || element?.originalCreditReportDate
          const entityNameStr = element?.borrowingEntityName || element?.accountName || "";
          const partyType = borrowerType === "Entity" ? "account" : borrowerType === "Individual" ? "person" : (borrowerType === "" ? (loanType === "BridgeLoan" ? "account" : "person") : "person");
          const accountNameStr =
            borrowerType === "Entity" ? entityNameStr || "" : "";
          const accountType = "BORROWER";
          const firstNameToSave =
            borrowerType === "Individual" ? element?.firstName : entityNameStr;
          let creditDateValue: string | null = null;
          if (
            creditReportDate &&
            !isNaN(new Date(creditReportDate).getTime())
          ) {
            if (typeof creditReportDate === "string") {
              creditDateValue = MMMDDYYYYDateFormat(creditReportDate, "MMM DD, YYYY", "YYYY-MM-DD");
            } else
              creditDateValue = toISOLocalDateString(creditReportDate);
          }
          if (creditDateValue) creditDateValue = creditDateValue.split("T")[0];
          let loanUserSequence = "";
          if (isSubmitted) {
            if (!bDataToBeChecked[index]?.payload?.borrowerLoanUserSequence) latestBorrowerSequence++;
            loanUserSequence =
              bDataToBeChecked[index]?.payload?.borrowerLoanUserSequence || latestBorrowerSequence.toString();
          } else {
            loanUserSequence = JSON.stringify(index);
          }
          let currVal = Number(loanUserSequence);

          if (loanUserSequenceMap.get(loanUserSequence) != null) {
            while (loanUserSequenceMap.get(loanUserSequence) != null) {
              currVal++;
              loanUserSequence = currVal.toString();
            }
          }

          loanUserSequenceMap.set(loanUserSequence, loanUserSequence);
          let operation = "replace";
          const newData = bDataToBeChecked[index]?.payload?.op === "add";
          const loanUserMapId = bDataToBeChecked[index]?.payload?.loanUserMapId || loanDetails?.fesBorrowerInformation?.loanUserMapId;
          if (newData && !hasBorrowerChanged) {
            operation = "add"
          }
          const borrowerInfo: any = {
            "op": operation,
            loanUserType: "Borrower",
            ...(loanUserMapId && {
              loanUserMapId
            }),
            loanUserSequence: borrowerData[index]?.payload?.borrowerLoanUserSequence || loanUserSequence,
            ...(partyId && {
              partyId
            }),
            customer: {
              accountType,
              hasBorrowerChanged,
              partyType,
              ...(partyId && {
                partyId
              }),
              // contactList: [],
              // addressList: [],
            },
          };
          if (bDataToBeChecked[index]?.payload?.loanUserMapId) {
            borrowerInfo["loanUserMapId"] = bDataToBeChecked[index]?.payload?.loanUserMapId;
          }
          if (element?.accountName) {
            borrowerInfo.customer = {
              ...borrowerInfo.customer,
              accountName: accountNameStr
            }
          }
          if (typeof element?.isPrimaryBorrower === "boolean") {
            borrowerInfo["isPrimary"] = element?.isPrimaryBorrower;
          }

          if (bDataToBeChecked?.length === 1 && !element?.isPrimaryBorrower && bDataToBeChecked[0]?.payload?.borrowerType === "Individual") {
            borrowerInfo["isPrimary"] = true;
          }

          if (element?.email || element?.borrowerEmail || element?.billingPhoneNumber || element?.contactNumber) {
            loanDetails.bridgeLoanBorrowerInformation[index]?.payload.borrowerEmail
            const emailToBeSent = element?.email || element?.borrowerEmail || loanDetails.bridgeLoanBorrowerInformation[index]?.payload?.borrowerEmail || loanDetails.bridgeLoanBorrowerInformation[index]?.payload?.email;
            const contactNumberToBeSent = element?.billingPhoneNumber || element?.contactNumber || loanDetails.bridgeLoanBorrowerInformation[index]?.payload?.billingPhoneNumber || loanDetails.bridgeLoanBorrowerInformation[index]?.payload?.contactNumber;
            borrowerInfo["customer"] = {
              ...borrowerInfo["customer"],
              contactList: [{
                contactNumber: contactNumberToBeSent || null,
                email: emailToBeSent || null,
                partyContactId: 1 // Hardcoded value as of now as we are not saving multiple contacts in UI
              }
              ]
            };
          }
          if (element?.billingAddress) {
            const addressObj: any = {};
            if (element?.billingAddress !== "") {
              addressObj.addressLine1 = element?.billingAddress?.billingAddress;
              addressObj.city = element?.billingAddress?.city;
              addressObj.locationValidationStatus = element?.billingAddress?.locationValidationStatus;
              addressObj.pincode = element?.billingAddress?.pincode;
              addressObj.state = element?.billingAddress?.state;
            } else {
              addressObj.addressLine1 = element?.billingAddress?.billingAddress;
            }

            borrowerInfo["customer"] = {
              ...borrowerInfo["customer"],
              addressList: [{
                ...borrowerInfo["customer"].addressList,
                ...addressObj,
                partyAddressId: 1 // Hardcoded value as of now as we are not saving multiple address in UI
              }
              ]
            };
          }
          if (firstNameToSave) {
            borrowerInfo["customer"] = {
              ...borrowerInfo["customer"],
              firstName: firstNameToSave || ""
            };
          }
          if (element?.lastName) {
            borrowerInfo["customer"] = {
              ...borrowerInfo["customer"],
              lastName: element?.lastName
            };
          }

          if (element?.originalCreditScoreMedian) {
            borrowerInfo["originalCreditScoreMedian"] = isEmptyValue(element?.originalCreditScoreMedian)
              ? null
              : element?.originalCreditScoreMedian.toString()
          }
          if (element?.inState) {
            borrowerInfo["inState"] = element?.inState ?? ""
          }
          if (element?.borrowerCreditScore) {
            borrowerInfo["borrowerCreditScore"] = isEmptyValue(element?.borrowerCreditScore) ? null : element?.borrowerCreditScore.toString();
          }
          if (creditDateValue) {
            borrowerInfo["originalCreditScoreReportDate"] = creditDateValue;
          }
          if (element?.borrowerExperience) {
            borrowerInfo["borrowerExperience"] = isEmptyValue(element?.borrowerExperience) ? null : Number(element?.borrowerExperience);
          }
          if (element?.experience) {
            const bExperience = element?.experience;
            borrowerInfo["experience"] = isEmptyValue(bExperience)
              ? null
              : Number(bExperience)
          }
          if (element?.heavyRehabExperience) {
            borrowerInfo["heavyRehabExperience"] = isEmptyValue(element?.heavyRehabExperience) ? null : Number(element?.heavyRehabExperience);
          }
          if (element?.borrowerGUCExperience) {
            borrowerInfo["borrowerGUCExperience"] = isEmptyValue(element?.borrowerGUCExperience) ? null : Number(element?.borrowerGUCExperience);
          }
          if (element?.foreignNationalString) {
            borrowerInfo["customer"]["foreignNationalString"] = element?.foreignNationalString
          }
          borrowerObj.push(borrowerInfo);
        }
      }
    );
  } else if (!isFormEditing && bridgeLoanBorrowerInformation?.length) {
    let latestBorrowerSequence: any = getCookie("latestBorrowerSequence");
    latestBorrowerSequence = latestBorrowerSequence
      ? Number(latestBorrowerSequence) ?? -1
      : -1;
    bridgeLoanBorrowerInformation.forEach(
      (element: any, index: number) => {
        let {
          borrowerType,
          borrowingEntityName,
          originalCreditReportDate,
          originalCreditScoreReportDate
        } = element;

        if (!borrowerType) {
          borrowerType = bridgeLoanBorrowerInformation[index]?.payload?.borrowerType
        }
        const creditReportDate = originalCreditScoreReportDate || originalCreditReportDate;

        const partyType = borrowerType === "Entity" ? "account" : "person";
        const accountName =
          borrowerType === "Entity" ? borrowingEntityName || "" : "";
        const accountType = "BORROWER";
        let creditDateValue: string | null = null;
        if (
          creditReportDate &&
          !isNaN(new Date(creditReportDate).getTime())
        ) {
          if (typeof creditReportDate === "string") {
            creditDateValue = MMMDDYYYYDateFormat(creditReportDate, "MMM DD, YYYY", "YYYY-MM-DD");
          } else
            creditDateValue = toISOLocalDateString(creditReportDate);
        }
        if (creditDateValue) creditDateValue = creditDateValue.split("T")[0];
        let loanUserSequence = "";
        if (isSubmitted) {
          if (!bridgeLoanBorrowerInformation[index]?.payload?.borrowerLoanUserSequence) latestBorrowerSequence++;
          loanUserSequence =
            bridgeLoanBorrowerInformation[index]?.payload?.borrowerLoanUserSequence || latestBorrowerSequence.toString();
        } else {
          loanUserSequence = JSON.stringify(index);
        }
        let currVal = Number(loanUserSequence);

        if (loanUserSequenceMap.get(loanUserSequence) != null) {
          while (loanUserSequenceMap.get(loanUserSequence) != null) {
            currVal++;
            loanUserSequence = currVal.toString();
          }
        }
        loanUserSequenceMap.set(loanUserSequence, loanUserSequence);
        const borrowerInfo: any = {
          "op": "add",
          loanUserType: "Borrower",
          loanUserSequence,
          customer: {
            accountType,
            partyType,
            contactList: [],
            addressList: []
          },
          accountName
        };
        borrowerObj.push(borrowerInfo);
      }
    );

  }

  if (borrowerData?.length !== bridgeLoanBorrowerInformation?.length) {
    const borrowerIds = borrowerData?.map((borrower: any) => borrower?.borrowerId);
    const missingBorrowers = bridgeLoanBorrowerInformation?.filter((borrower: any) => !borrowerIds.includes(borrower.borrowerId));
    missingBorrowers?.map((mBorrower: any) => {
      if (borrowerUserMap?.find((b: any) => [mBorrower?.payload?.borrowerLoanUserSequence, mBorrower.loanUserSequence].includes(b.loanUserSequence))) {
        borrowerObj.push({
          op: "remove",
          loanUserType: "Borrower",
          loanUserSequence: mBorrower?.payload?.borrowerLoanUserSequence || mBorrower.loanUserSequence,
          partyId: mBorrower?.payload?.partyId || "",
        });
        removedBorrowers.push(mBorrower?.payload?.borrowerLoanUserSequence || mBorrower.loanUserSequence)
      }
    })
  }
  return borrowerObj;
}

export const getfundingChannelKey = (key: string) => {
  if (key === "Normal" || key === null) return "normal";
  else if (key === "Advance Funding") return "advance_funding";
  else return "table_funding";
};

export function getGuarantorObjectForLoanUpdate(
  guarantorInformation: any,
  bridgeLoanGuarantorInformation: any,
  loanDetails?: any,
  isSubmitted?: boolean,
  guarantorsData?: any,
  guarantorUserMap?: any,
  isFormEditing?: boolean,
) {
  // const { partyId } = loanDetails?.bridgeLoanGuarantorInformation[0]?.payload;
  let newGuarantorLoan: any = [];
  const removedGuarantors: any = [];

  let latestGuarantorSequence: any = getCookie("latestGuarantorSequence");
  latestGuarantorSequence = latestGuarantorSequence
    ? Number(latestGuarantorSequence) ?? -1
    : -1;
  const loanUserSequenceMap = new Map();
  if (guarantorInformation?.length) {
    guarantorInformation.map(
      (element: any, index: number) => {
        let originalCreditReportDate: any =
          element?.originalCreditReportDate;

        const ownershipOfEntity = element?.pOEntity
          ? Number(element?.pOEntity?.replace("%", ""))
          : null;
        let foreignNational = element?.foreignNationalString || null;
        const contactList = element?.guarantorEmail
          ? [
            {
              contactNumber: "",
              email: element?.guarantorEmail,
              partyContactId: 1 // Hardcoded value as of now as we are not saving multiple contacts in UI
            }
          ]
          : [];
        let creditDateValue = null;
        if (
          originalCreditReportDate &&
          !isNaN(new Date(originalCreditReportDate).getTime())
        ) {
          try {
            creditDateValue = formatStringDate(originalCreditReportDate);
          } catch (error) {
            creditDateValue = formatStringDate(originalCreditReportDate);
          }
        }
        let primaryGuarantor = element?.isPrimaryGuarantor || bridgeLoanGuarantorInformation[index]?.payload?.isPrimaryGuarantor || null;
        let loanUserSequence = "";
        if (isSubmitted) {
          if (!bridgeLoanGuarantorInformation[index]?.payload?.guarantorLoanUserSequence) latestGuarantorSequence++;
          loanUserSequence =
            bridgeLoanGuarantorInformation[index]?.payload?.guarantorLoanUserSequence || latestGuarantorSequence.toString();
        } else {
          loanUserSequence = JSON.stringify(index);
        }

        let currVal = Number(loanUserSequence);
        if (loanUserSequenceMap.get(loanUserSequence) != null) {
          while (loanUserSequenceMap.get(loanUserSequence) != null) {
            currVal++;
            loanUserSequence = currVal.toString();
          }
        }
        loanUserSequenceMap.set(loanUserSequence, loanUserSequence);
        let operation = "replace";
        const partyId = bridgeLoanGuarantorInformation[index]?.payload?.partyId
        const newData = bridgeLoanGuarantorInformation?.find((borrower: any) => borrower?.payload?.op === "add");
        if (!partyId || newData) {
          operation = "add"
        }
        const loanUserMapId = bridgeLoanGuarantorInformation[index]?.payload?.loanUserMapId;

        const obj = {
          "op": operation,
          ...(bridgeLoanGuarantorInformation[index]?.payload?.partyId && {
            partyId: bridgeLoanGuarantorInformation[index]?.payload?.partyId
          }),
          ...(loanUserMapId && {
            loanUserMapId
          }),
          loanUserType: "Guarantor",
          loanUserSequence: bridgeLoanGuarantorInformation[index]?.payload?.guarantorLoanUserSequence || loanUserSequence,
          isPrimary: primaryGuarantor,
          ...(bridgeLoanGuarantorInformation[index]?.payload?.partyId && {
            partyId: bridgeLoanGuarantorInformation[index]?.payload?.partyId
          }),
          customer: {
            addressList: [
              {
                addressNumber: "",
                addressType: "",
                addressLine1: "",
                addressLine2: "",
                addressLine3: "",
                city: "",
                state: "",
                country: "",
                pincode: ""
              }
            ],
            contactList,
            partyType: "person",
            accountType: "person",

            ...(bridgeLoanGuarantorInformation[index]?.payload?.eiNumber && {
              eiNumber: bridgeLoanGuarantorInformation[index]?.payload?.eiNumber
            }),
            ...(bridgeLoanGuarantorInformation[index]?.payload?.ssNumber && {
              ssNumber: bridgeLoanGuarantorInformation[index]?.payload?.ssNumber
            }),
            ...(bridgeLoanGuarantorInformation[index]?.payload?.passportExpirationDate && {
              passportExpirationDate: bridgeLoanGuarantorInformation[index]?.payload?.passportExpirationDate
            })
          }
        };
        if (bridgeLoanGuarantorInformation[index]?.payload?.loanUserMapId) {
          obj["loanUserMapId"] = bridgeLoanGuarantorInformation[index]?.payload?.loanUserMapId;
        }
        if (element?.creditScore) {
          obj["originalCreditScoreMedian"] = element?.creditScore;
        }
        if (element?.originalCreditScoreMedian) {
          obj["originalCreditScoreMedian"] = element?.originalCreditScoreMedian;
        }
        if (creditDateValue) {
          obj["originalCreditScoreReportDate"] = creditDateValue;
        }
        if (element?.guarantorExperience) {
          obj["experience"] = isNullOrUndefined(element?.guarantorExperience)
            ? null
            : Number(element?.guarantorExperience);
        }
        if (ownershipOfEntity) {
          obj["ownershipOfEntity"] = ownershipOfEntity;
        }
        if (element?.guarantorFirstName) {
          obj["customer"]["firstName"] = element?.guarantorFirstName;
        }
        if (element?.guarantorLastName) {
          obj["customer"]["lastName"] = element?.guarantorLastName;
        }
        if (foreignNational) {
          obj["customer"]["foreignNationalString"] = foreignNational;
        }
        if (element) {
          newGuarantorLoan.push(obj);
        }
      }
    );
  } else if (!isFormEditing && bridgeLoanGuarantorInformation) {
    bridgeLoanGuarantorInformation.map((bInfo: any, index: number) => {
      let loanUserSequence = "";
      if (isSubmitted) {
        if (!bridgeLoanGuarantorInformation[index]?.payload?.guarantorLoanUserSequence) latestGuarantorSequence++;
        loanUserSequence =
          bridgeLoanGuarantorInformation[index]?.payload?.guarantorLoanUserSequence || latestGuarantorSequence.toString();
      } else {
        loanUserSequence = JSON.stringify(index);
      }

      let currVal = Number(loanUserSequence);
      if (loanUserSequenceMap.get(loanUserSequence) != null) {
        while (loanUserSequenceMap.get(loanUserSequence) != null) {
          currVal++;
          loanUserSequence = currVal.toString();
        }
      }
      loanUserSequenceMap.set(loanUserSequence, loanUserSequence);
      const borrowerInfo: any = {
        "op": "add",
        ...(bridgeLoanGuarantorInformation[index]?.payload?.partyId && {
          partyId: bridgeLoanGuarantorInformation[index]?.payload?.partyId
        }),
        loanUserType: "Guarantor",
        loanUserSequence: bridgeLoanGuarantorInformation[index]?.payload?.guarantorLoanUserSequence || loanUserSequence,
        isPrimary: null,
        ...(bridgeLoanGuarantorInformation[index]?.payload?.partyId && {
          partyId: bridgeLoanGuarantorInformation[index]?.payload?.partyId
        }),
        customer: {
          addressList: [
            {
              addressNumber: "",
              addressType: "",
              addressLine1: "",
              addressLine2: "",
              addressLine3: "",
              city: "",
              state: "",
              country: "",
              pincode: ""
            }
          ],
          contactList: [],
          partyType: "person",
          accountType: "person",
        }
      };
      newGuarantorLoan.push(borrowerInfo);
    })
  }
  if (guarantorsData?.length !== bridgeLoanGuarantorInformation?.length) {
    const guarantorIds = guarantorsData?.map((guarantor: any) => guarantor?.guarantorId);
    const missingGuarantorss = bridgeLoanGuarantorInformation?.filter((guarantor: any) => !guarantorIds.includes(guarantor.guarantorId));
    missingGuarantorss?.map((mGuarantor: any) => {
      if (guarantorUserMap?.find((b: any) => [mGuarantor?.payload?.guarantorLoanUserSequence, mGuarantor.loanUserSequence].includes(b.loanUserSequence))) {
        newGuarantorLoan.push({
          op: "remove",
          loanUserType: "Guarantor",
          loanUserSequence: mGuarantor?.payload?.guarantorLoanUserSequence || mGuarantor.loanUserSequence,
          partyId: mGuarantor?.payload?.partyId || "",
        });
        removedGuarantors.push(mGuarantor?.payload?.guarantorLoanUserSequence || mGuarantor?.loanUserSequence)
      } else {
        if (newGuarantorLoan.find((newGData: any, idx: number) => [mGuarantor?.payload?.guarantorLoanUserSequence, mGuarantor?.loanUserSequence].includes(newGData?.loanUserSequence))) {
          newGuarantorLoan = newGuarantorLoan?.filter((newGData: any, idx: number) => ![mGuarantor?.payload?.guarantorLoanUserSequence, mGuarantor?.loanUserSequence].includes(newGData?.loanUserSequence));
        }
      }
    })
  }
  return newGuarantorLoan;
}

export const createInitialErrorFields = (fields: ObjectType[], data?: ObjectType) => {
  return new Map(fields?.map((field) => [field.fieldName, data?.[field.fieldName] ? "" : "This field is required"]));
};

export const setErrorField = (fieldConfig?: ObjectType, value?: any, setFieldErrors?: any) => {
  if (!fieldConfig || !setFieldErrors) return;
  const { fieldName, required, errorChecks, errorMsg } = fieldConfig;
  let errorMessage = "";
  if (!required?.()) return;
  const validErrorCheck = errorChecks?.(value) ?? isEmptyValue(value);
  errorMessage = validErrorCheck ? errorMsg : "";
  setFieldErrors((fieldsErrors: any) => {
    const updatedFieldsErrors = new Map(fieldsErrors);
    return updatedFieldsErrors.set(fieldName, errorMessage);
  });
};

export const updateLoanInredux = (data: any, dispatch: any, sectionName: string, isDSCR: boolean) => {
  if (isDSCR) {
    dispatch(
      updateThirtyYearLoanDetails({
        [`thirtyYear${sectionName}`]: data
      })
    );
  } else {
    dispatch(
      updateBridgeLoanDetails({
        [`bridge${sectionName}`]: data
      })
    );
  }
};

export function getUpdatedBorrowerInfoToDelete(borrowerId: string, borrowerInfo: any, deleteBorrower?: boolean, partyId?: string, currentUserSequence?: any) {
  const updatedLoanUserMap: any = [];
  borrowerInfo.map((borrower: any) => {
    if (borrower.borrowerId === borrowerId) {
      const partyType = borrower.payload?.borrowerType === "Entity" ? "account" : "person";
      let loanUserSequence = currentUserSequence || borrower.payload?.borrowerLoanUserSequence || borrower.loanUserSequence;
      if (borrower.payload?.borrowerLoanUserSequence === "" && borrowerInfo.length === 1) {
        loanUserSequence = "0";
      }
      if (deleteBorrower) {
        updatedLoanUserMap.push({
          op: "remove",
          loanUserType: "Borrower",
          loanUserSequence,
          // partyId: borrower.payload?.partyId || partyId, // removed as per new changes
        });
      } else {
        const returnObj: any = {};
        Object.keys(borrower.payload).forEach((key) => {
          if (["borrowerType", "isPrimaryBorrower", "locationValidationStatus", "partyId", "loanUserMapId", "borrowerLoanUserSequence"].includes(key)) {
            returnObj[key] = borrower.payload[key]
          }
          returnObj[key] = ""
        });
        updatedLoanUserMap.push({
          op: "replace",
          ...returnObj
        })
      }
    }
  });
  return updatedLoanUserMap;
}

export function getUpdatedBorrowerInfoToAdd(borrowerInfoCount: any, newBorrowerData: any, userSequence?: any, isReset?: boolean) {
  const updatedLoanUserMap: any = [];
  updatedLoanUserMap.push({
    "op": "add",
    loanUserType: "Borrower",
    loanUserSequence: userSequence || borrowerInfoCount - 1,
    isPrimary: Boolean(isReset),
    customer: {
      accountType: "BORROWER",
      partyType: isReset ? "person" : newBorrowerData?.borrowerType === "Individual" ? "person" : "account",
      contactList: [],
      addressList: []
    },
  });
  return updatedLoanUserMap;
}

export function getUpdatedGuarantorInfoToAdd(guarantorInfoCount: any) {
  const updatedLoanUserMap: any = [];
  updatedLoanUserMap.push({
    "op": "add",
    loanUserType: "Guarantor",
    loanUserSequence: guarantorInfoCount,
    customer: {
      accountType: "person",
      partyType: "person",
      contactList: [],
      addressList: []
    },
  });
  return updatedLoanUserMap;
}

export function getDeleteGuarantorInfo(guarantorData: any, userSequence: any) {
  return [{
    "op": "remove",
    loanUserType: "Guarantor",
    loanUserSequence: guarantorData.payload?.guarantorLoanUserSequence || userSequence,
    partyId: guarantorData.payload?.partyId,
  }]
}

export function getFieldErrosCount(fieldErrors: any) {
  let errorCount = 0;
  fieldErrors.forEach((value: any) => {
    if (value) {
      errorCount++;
    }
  });
  return errorCount;
}

export function getUpdatedUnitInfoToAdd(loanId: any, rentDescription: any, propertyId: any, unitsLength: number) {
  const newUnit: any = {
    leaseStatus: rentDescription[0],
    currentLeaseTerm: "",
    marketRentMonthly: null,
    inPlaceLeaseRent: null,
    recentLeaseRent: null,
    leaseStartDate: null,
    leaseEndDate: null,
    percentageOccupied: rentDescription?.[0] === LeaseStatusEnum.occupiedLongTerm || rentDescription?.[0] === LeaseStatusEnum.occupiedShortTerm ? "100.0000" : "0.0000"
  };

  const returnPayload = [{
    loanId,
    "loanPropertyId": propertyId,
    "loanPropertyOrder": propertyId,
    propertyUnit: [{
      "op": "add",
      unitParamId: unitsLength + 1,
      ...newUnit
    }]
  }];
  return returnPayload;
};

export const getDifferentialData = (object1: any, object2: any, keysToBeChecked?: any) => {
  const diffObj: any = { ...object1 };

  for (let key in object2) {
    const commentKey = keysToBeChecked?.[key] || key
    if (object1?.hasOwnProperty(commentKey) && (object2.hasOwnProperty(key))) {
      if (object1[commentKey] !== object2[key]) {
        diffObj[commentKey] = object2[key];
      }
    }
  }

  return diffObj;
};

export function getUnitInfoToBeDeleted(loanId: any, propertyId: any, unitId: any) {
  return [{
    loanId,
    loanPropertyId: propertyId,
    loanPropertyOrder: propertyId,
    propertyUnit: [{
      op: "remove",
      unitParamId: unitId,
      propertyUnitId: unitId
    }]
  }];
}

export function getUIKeys(fields: any) {
  const result: ObjectType = {};
  fields.map((field: any) => {
    result[field.backendKey || field.fieldName] = field.fieldName;
  });
  return result;
}

export function throttle(fn: Function, delay: number) {
  let run = false
  return function (...args: any) {
    if (!run) {
      fn(...args)
      run = true
      setTimeout(() => run = false, delay)
    }
  }
}

export function debouncedFunction(fn: Function, delay: number) {
  let timer: any;
  return function (...args: any) {
    clearTimeout(timer);
    timer = setTimeout(() => {
      fn(...args);
    }, delay);
  }
}

export const updateBorrowerData = debouncedFunction(
  (borrowerId: any, data: any, dispatch: any, loanStage: string) => {
    if (loanStage === LoanStage.fes) {
      dispatch(updateFESBorrowerInfo(borrowerId, data));
    } else {
      dispatch(updateBorrowerInfo(borrowerId, data));
    }
  }, 500);

export const updateGuarantorData = debouncedFunction(
  (guarantorId: any, data: any, dispatch: any) => {
    dispatch(updateBridgeLoanGurantor(guarantorId, data));
  }, 500);

export const updateLoanData = debouncedFunction(
  (data: any, key: string, loanStage: string, isDSCR: boolean, dispatch: any) => {
    if (loanStage === LoanStage.fes) {
      dispatch(updateFESLoanDetailsInBulk({
        sectionName: key,
        payload: {
          ...data
        }
      }));
    } else {
      updateLoanInredux({
        payload: {
          ...data
        }
      },
        dispatch,
        key,
        isDSCR
      );
    }
  }, 500);

export const getfundingChannelValue = (key: string) => {
  let fundingChannelValue = "";
  switch (key) {
    case "advance_funding":
      fundingChannelValue = "Advance Funding";
      break;
    case "table_funding":
      fundingChannelValue = "Table Funding";
      break;
    default:
      fundingChannelValue = "Normal";
  }
  return fundingChannelValue;
};

export const getBooleanDropDownValue = (value: string | boolean, isYesOrNoDropdown: boolean) => {
  switch (value) {
    case "Y":
    case true:
    case "Yes":
      return isYesOrNoDropdown ? "Yes" : "Y";
    case "N":
    case false:
    case "No":
      return isYesOrNoDropdown ? "No" : "N";
    default:
      return "";
  }
}