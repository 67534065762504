/* eslint-disable no-unneeded-ternary */
import React, { useState } from "react";
import { Dialog, Button, Tooltip } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  LoanStage,
  WarningModal,
  WarningModalProps
} from "@toorak/tc-common-fe-sdk";
import { usePricingAndGuidlinesStyles } from "../PricingAndGuidlinesSection.style";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useParams } from "react-router-dom";

interface ProgrammeSelectionModalWrapper extends WarningModalProps {
  subheader?: any;
  isPrimaryBtnDisabled?: boolean;
}

export const ProgrammeSelectionModalWrapper = (
  props: ProgrammeSelectionModalWrapper
) => {
  const classes = usePricingAndGuidlinesStyles();
  const {
    isOpen,
    handleClose,
    header,
    subheader,
    body,
    primaryBtnName,
    closeDisable,
    handleOptional,
    maxWidth,
    hideButtons,
    isPrimaryBtnDisabled
  } = props;
  const [openWarning, setOpenWarning] = useState<boolean>(false);
  const { loanStage } = useParams<{
    loanStage: LoanStage;
  }>() as any;
  const focusProceedButton = (input: any) => {
    if (input) {
      setTimeout(() => {
        input.focus();
      }, 100);
    }
  };

  function handleSubmit() {
    if (isPrimaryBtnDisabled) return;
    if (loanStage === LoanStage.fes) {
      handleClose();
    } else {
      setOpenWarning(true);
    }
  }
  return (
    <>
      {openWarning && (
        <WarningModal
          closeDisable
          isOpen={openWarning}
          handleClose={() => {
            handleClose();
            setOpenWarning(false);
          }}
          primaryBtnName="OK"
          header="Information"
          body={"Loan is not currently locked."}
        />
      )}
      <Dialog
        open={isOpen}
        aria-labelledby="take-out-modal"
        aria-describedby="take-out-modal-description"
        data-testid="take-out-component"
        maxWidth={maxWidth}
        style={{ padding: 0 }}
      >
        <div
          style={{
            width: 1000
          }}
        >
          <div className={classes.modalHeader}>
            <div className={classes.modalHeaderText} data-testid="header-text">
              {header}
            </div>
            {closeDisable ? null : (
              <CloseIcon
                htmlColor="#414169"
                className={classes.closeIcon}
                onClick={handleOptional ? handleOptional : handleClose}
              />
            )}
          </div>
          <div
            className={classes.modalSubHeaderText}
            data-testid="subheader-text"
          >
            {subheader}
          </div>
          {!subheader && (
            <div
              className={classes.horizontalLine}
              data-testid="horizontal-line"
            ></div>
          )}
          <div className={classes.modalBody} data-testid="body-text">
            {body}
          </div>
          {hideButtons && (
            <div className={classes.modalPrimaryButton}>
              <Button
                variant="contained"
                color="primary"
                className={
                  isPrimaryBtnDisabled
                    ? classes.modalButtonDisabled
                    : classes.modalButton
                }
                onClick={handleSubmit}
                data-testid="primaryBtn"
                ref={focusProceedButton}
              >
                {primaryBtnName}
                {isPrimaryBtnDisabled && (
                  <span
                    style={{ color: "white", marginLeft: 5, paddingTop: 5 }}
                  >
                    <Tooltip
                      arrow
                      placement="right"
                      title={
                        isPrimaryBtnDisabled
                          ? "Select the Program, review the failed tests and resolve to enable submit."
                          : ""
                      }
                      classes={{
                        tooltip: classes.customTooltip,
                        arrow: classes.customArrow
                      }}
                    >
                      <InfoOutlinedIcon />
                    </Tooltip>
                  </span>
                )}
              </Button>
              <Button
                autoFocus
                onClick={handleOptional}
                color="primary"
                className={`${classes.dialogCancelBtn} ${classes.modalButton}`}
              >
                Cancel
              </Button>
            </div>
          )}
        </div>
      </Dialog>
    </>
  );
};
