import { Box, Button, Grid } from "@mui/material";
import { DrawStage, SnackBarMessage } from "@toorak/tc-common-fe-sdk";
import React, { Suspense, useEffect, useState } from "react";
import HistoryIcon from "@mui/icons-material/HistoryOutlined";
import { useDispatch, useSelector } from "react-redux";

import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import {
  hideLoader,
  showLoader
} from "../../../stores/loaderAndException/loaderAndException.action";
import { countDrawErrors } from "../DrawErrorCheck";
import {
  updateDrawDetails,
  postDrawStageHistory
} from "../../../stores/drawRequest/drawRequest.action";
import { StageAuditTrail } from "./StageAuditTrail";
import { DrawRolesEnum } from "../../../utils/constants";
import { RootState } from "../../../stores/rootReducer";
import { getRoles, isAMTView } from "../../AssetManagement.utils";
import {
  DrawRequestStore,
  drawsActionBtnInterface
} from "../../../stores/drawRequest/drawRequest.interface";
import { useDrawsStyles } from "../Draws.styles";
import {
  DontShowHistoryStages,
  OriginatorEditingStages,
  getStageBgColor,
  snackBarDetails
} from "../Constant";
import { DrawStageUpdatePopup } from "./DrawStageUpdatePopup";
import { updateDrawStage } from "../../../stores/drawRequest/drawRequest.reducer";
import { DrawDocsButtonsWrapper } from "../DrawDocTagging/DrawDocsButtonsWrapper";
// import { getTasks, taskNames } from "../../../stores/tasks/Tasks.action";
import { getConfig } from "../../../config/config";
import { getTasks, taskNames } from "../../../stores/tasks/Tasks.action";
import { budgetReviewStatusEnum } from "../../../ilp/list-view/BudgetTaskManagement";

// const ConfirmFormModal = React.lazy(() =>
//   import("@toorak/tc-common-fe-sdk").then(module => ({
//     default: module.ConfirmFormModal
//   }))
// );

const DrawMoreAction = React.lazy(() =>
  import("./DrawMoreAction").then((module) => ({
    default: module.DrawMoreAction
  }))
);

interface DrawDetailsHeaderInterface {
  drawId: string;
  isOrig: boolean;
  showDeleteDrawWarning: () => void;
  updateIsSubmitClicked: (val: boolean) => void;
  goBackToLoanListPage: () => void;
  updateIsEditing: (val: boolean) => void;
  isEditing: boolean;
  toorakLoanId: string;
}

export const DrawDetailsHeader = ({
  drawId,
  isOrig,
  showDeleteDrawWarning,
  updateIsSubmitClicked,
  goBackToLoanListPage,
  updateIsEditing,
  isEditing,
  toorakLoanId
}: DrawDetailsHeaderInterface) => {
  const { drawDetails, drawDocs, selectedBankDetails } = useSelector<
    RootState,
    DrawRequestStore
  >((state) => state.drawRequestStore);
  const classes = useDrawsStyles();
  const dispatch = useDispatch();
  const [snackBarMsg, setSnackBarMsg] = useState<{
    messageType: "success" | "info" | "warning" | "error" | undefined;
    message: string;
  }>({
    messageType: undefined,
    message: ""
  });
  const [budgetReconDisable, setBudgetReconDisable] = useState<boolean>(true);
  const { tasks } = useSelector<RootState, any>((state) => state.TasksStore);

  useEffect(() => {
    if (!toorakLoanId) return;
    if (!tasks?.length) {
      getTasks(dispatch, {
        resourceTypes: ["LOAN"],
        resourceIds: [toorakLoanId],
        taskNames: [
          taskNames.TAG,
          taskNames.SPLIT,
          taskNames.REVIEW,
          taskNames.RECONCILE,
          taskNames.BUDGET_REVIEW,
          taskNames.BUDGET_APPROVE
        ]
      });
    }
  }, [toorakLoanId, tasks]);

  useEffect(() => {
    if (!drawDocs.length) return;
    if (!tasks?.length) return;
    let budgetReviewStatus = tasks?.[0]?.assignedTasks.find((task: any) =>
      [taskNames.BUDGET_REVIEW].includes(task.taskName)
    )?.taskStatus;
    const filDocsList = drawDocs.filter(
      (item: any) =>
        item.isPurchasePrimary &&
        item.tags.some((tag: any) => tag.tag.code === "DDDC")
    );
    const isDisable = !(
      filDocsList.length &&
      budgetReviewStatus === budgetReviewStatusEnum.Approved
    );
    setBudgetReconDisable(isDisable);
  }, [drawDocs, tasks]);

  // useEffect(() => {
  //   let reviewAssignedBy = tasks?.[0]?.assignedTasks.find((task: any) =>
  //     [taskNames.BUDGET_REVIEW].includes(task.taskName)
  //   )?.assignedBy;
  // }, [tasks]);

  useEffect(() => {
    setShowStageHistoryIcon(
      !DontShowHistoryStages.includes(drawDetails?.stage)
    );
    // eslint-disable-next-line
  }, [drawDetails.stage]);

  const role: DrawRolesEnum = getRoles();

  const [showStatusUpdate, setShowStatusUpdate] = useState<boolean>(false);
  const [stageToUpdate, setStageToUpdate] = useState<DrawStage | null>(null);
  // const [updateComments, setUpdateComments] = useState<string>("");
  const [showStageHistory, setStageHistory] = useState<boolean>(false);
  const [showStageHistoryIcon, setShowStageHistoryIcon] =
    useState<boolean>(false);

  const { stage } = drawDetails;

  const [openSnackBar, setOpenSnackBar] = useState<boolean>(false);
  const {
    draft,
    drawSubmitted,
    decline,
    wireTransferInitiated,
    wireCompleted,
    DrawInQueue
  } = DrawStage;

  const handleSaveClick = async () => {
    if (stage !== draft) {
      const errors = countDrawErrors(drawDetails, selectedBankDetails);
      if (errors) {
        updateIsSubmitClicked(true);
        return;
      }
    }
    const isEditingStage =
      isOrig && [...OriginatorEditingStages, draft].includes(drawDetails.stage);

    if (!(isEditingStage || !isOrig)) {
      return;
    }

    const updateStage: DrawStage = drawDetails.stage;
    try {
      await dispatch(
        updateDrawDetails(
          drawDetails,
          selectedBankDetails,
          drawId,
          isOrig,
          updateStage,
          drawDetails.originatorId
        )
      );
      updateIsEditing(false);
      setSnackBarMsg({
        messageType: "success",
        message: "Draw is successfully saved."
      });
      setOpenSnackBar(true);
    } catch (e) {
      setSnackBarMsg({
        messageType: "error",
        message: "Draw is not saved."
      });
      setOpenSnackBar(true);
    }
  };

  // const checkDrawInQueueStatus = (): boolean => {
  //   //EST
  //   const offset = -5.0;

  //   const localDate = new Date();
  //   const utc = localDate.getTime() + localDate.getTimezoneOffset() * 60000;

  //   const estDate = new Date(utc + 3600000 * offset);
  //   var cutoffTime = new Date();
  //   cutoffTime.setHours(11); //11AM is the cutoff time
  //   cutoffTime.setMinutes(0);
  //   cutoffTime.setSeconds(0);
  //   console.log(cutoffTime < estDate);

  //   return cutoffTime < estDate;
  // };

  const handleSubmitClick = async () => {
    const errors = countDrawErrors(drawDetails, selectedBankDetails);
    if (errors) {
      console.error("field Errors count:", errors);
      updateIsSubmitClicked(true);
      return;
    }
    updateIsSubmitClicked(false);

    dispatch(showLoader());

    // const statusToBeUpdated = drawSubmitted;

    await dispatch(
      updateDrawDetails(
        drawDetails,
        selectedBankDetails,
        drawId,
        isOrig,
        drawSubmitted,
        drawDetails.originatorId
      )
    );
    // dispatch(updateDrawStageRedux(drawSubmitted));
    dispatch(hideLoader());
    setSnackBarMsg({
      messageType: "success",
      message: "Draw is successfully submitted."
    });
    setOpenSnackBar(true);
  };

  const showSubmitButton = () => {
    return isOrig
      ? stage !== undefined && stage.toUpperCase() === "DRAFT"
      : false;
  };
  const showEditDrawButton = () => {
    return isOrig
      ? OriginatorEditingStages.includes(stage)
      : ![wireTransferInitiated, wireCompleted].includes(stage) && isAMTView();
  };
  const showDeleteDrawButton = () => {
    return (
      stage !== undefined && [drawSubmitted, DrawInQueue, draft].includes(stage)
    );
  };

  const handleStageUpdateSuccess = (drawstage: DrawStage) => {
    dispatch(updateDrawStage(drawstage));
    setSnackBarMsg({
      messageType: "success",
      message: "Draw Status is successfully updated."
    });
    setOpenSnackBar(true);
  };

  const handleActionBtnClick = async (draw: drawsActionBtnInterface) => {
    if (
      [decline, wireTransferInitiated].includes(draw.title as DrawStage) ||
      [wireTransferInitiated, wireCompleted].includes(stage)
    ) {
      // changing the status from wireTransferInitiated, wireCompleted to any other status OR
      // changing the status to decline, wireTransferInitiated
      // show updateComments pop up
      setStageToUpdate(draw.title as DrawStage);
      setShowStatusUpdate(true);
    } else {
      try {
        await dispatch(
          postDrawStageHistory(
            drawId,
            draw.title as DrawStage,
            role,
            drawDetails.originatorId
          )
        );
        handleStageUpdateSuccess(draw.title as DrawStage);
      } catch (e) {
        setSnackBarMsg({
          messageType: "error",
          message: "Draw Status is not updated."
        });
        setOpenSnackBar(true);
      }
    }
  };

  const handleConfirmStageUpdate = async (updateComments: string) => {
    if (!stageToUpdate) return;
    try {
      await dispatch(
        postDrawStageHistory(
          drawId,
          stageToUpdate,
          role,
          drawDetails.originatorId,
          updateComments
        )
      );
      handleStageUpdateSuccess(stageToUpdate);
    } catch (e) {
      setSnackBarMsg({
        messageType: "error",
        message: "Draw Status is not updated."
      });
      setOpenSnackBar(true);
    }
    setShowStatusUpdate(false);
  };

  return (
    <Grid item container xs={12} className={classes.headerContainer}>
      {isAMTView() && (
        <DrawStageUpdatePopup
          showStatusUpdate={showStatusUpdate}
          setShowStatusUpdate={setShowStatusUpdate}
          handleConfirmStageUpdate={handleConfirmStageUpdate}
        />
      )}
      <Grid item container xs={6} sm={6} md={8} xl={8}>
        <Box
          className="button-typography-wrapper-left"
          style={{ padding: "4px 0px" }}
        >
          <Button
            variant="contained"
            data-testid="back-button"
            color="primary"
            startIcon={<KeyboardArrowLeftIcon />}
            onClick={goBackToLoanListPage}
          >
            Back
          </Button>
        </Box>
        <span className={classes.drawHeaderDetails}>
          <span className={classes.itemHeaderText}>
            Draw Request ID: {drawId}
          </span>
          {/* change stage color here */}
          <span
            className={classes.stageDisplay}
            data-testid="draw-view-stageDisplay"
            style={{ backgroundColor: getStageBgColor(stage) }}
          >
            {stage?.toUpperCase()}
          </span>

          <span>
            {showStageHistoryIcon && (
              <Button
                data-testid="draw-view-loanHistoryBtn"
                startIcon={<HistoryIcon fontSize="large" />}
                onMouseEnter={() => {
                  setStageHistory(!showStageHistory);
                }}
                onMouseLeave={() => {
                  setStageHistory(!showStageHistory);
                }}
              />
            )}
          </span>
          {showStageHistory && <StageAuditTrail />}
        </span>
      </Grid>
      <Grid
        item
        container
        xs={6}
        sm={6}
        md={4}
        style={{ justifyContent: "flex-end" }}
      >
        {showEditDrawButton() && (
          <>
            {!isEditing ? (
              <>
                <Button
                  onClick={() => {
                    updateIsEditing(true);
                  }}
                  data-testid="edit-button"
                  className={classes.editButton}
                >
                  Edit
                </Button>
              </>
            ) : (
              <Button
                variant="outlined"
                onClick={handleSaveClick}
                data-testid="save-button"
                className={classes.saveButton}
              >
                Save
              </Button>
            )}
          </>
        )}
        {isOrig && showDeleteDrawButton() ? (
          <Button
            variant="text"
            data-testid="draw-delete-btn"
            className={`${classes.cancelButton} ${
              stage !== undefined && stage.toUpperCase() === "DRAFT"
                ? classes.cancelButton
                : classes.deleteDrawButton
            }`}
            onClick={() => showDeleteDrawWarning()}
          >
            Delete Draw
          </Button>
        ) : null}
        {showSubmitButton() && (
          <span>
            <Button
              variant="outlined"
              onClick={handleSaveClick}
              data-testid="save-button"
              className={classes.saveButton}
            >
              {" "}
              Save{" "}
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmitClick}
              data-testid="submit-button"
              className={classes.submitButton}
              color="info"
            >
              Submit
            </Button>
          </span>
        )}
        {isAMTView() &&
          ["development", "qa"].includes(getConfig().environment) && (
            <DrawDocsButtonsWrapper
              drawId={drawId}
              loanId={toorakLoanId}
              disable={!drawDocs?.length}
              budgetReconDisable={budgetReconDisable}
            />
          )}
        {isAMTView() &&
          ((showEditDrawButton() && !isEditing) || !showEditDrawButton()) && (
            <Suspense fallback={<span>Loading...</span>}>
              <DrawMoreAction
                handleActionBtnClick={handleActionBtnClick}
                drawDetails={drawDetails}
              />
            </Suspense>
          )}
      </Grid>
      <SnackBarMessage
        open={openSnackBar}
        verticalPlacement={snackBarDetails.verticalPlacement}
        horizontalPlacement={snackBarDetails.horizontalPlacement}
        autoHideDuration={snackBarDetails.autoHideDuration}
        handleClose={() => setOpenSnackBar(false)}
        messageType={snackBarMsg.messageType}
        message={snackBarMsg.message}
        data-testid="draw-update-message"
      />
    </Grid>
  );
};
