import React from "react";

export const SearchIcon = () => {
  return (
    <svg
      className="svg-icon"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
    >
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          transform="translate(3.000000, 3.000000)"
          fill="#32325D"
          fillRule="nonzero"
        >
          <path d="M12.5,11 L11.71,11 L11.43,10.73 C12.4438721,9.55402182 13.0011023,8.05269443 13,6.5 C13,2.91014913 10.0898509,0 6.5,0 C2.91014913,0 0,2.91014913 0,6.5 C0,10.0898509 2.91014913,13 6.5,13 C8.11,13 9.59,12.41 10.73,11.43 L11,11.71 L11,12.5 L16,17.49 L17.49,16 L12.5,11 Z M6.5,11 C4.01,11 2,8.99 2,6.5 C2,4.01 4.01,2 6.5,2 C8.99,2 11,4.01 11,6.5 C11,8.99 8.99,11 6.5,11 Z" />
        </g>
      </g>
    </svg>
  );
};
