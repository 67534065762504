import React from "react";
import { Autocomplete } from "@mui/material";
import { useAutoCorrectStyles } from "../waiver-form/DocumentsForm.style";
import {
  Grid,
  InputAdornment,
  Paper,
  Popper,
  TextField
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useStyles } from "./DocumentsForm.style";

export const DocumentsAutoCompleteILP = (props: any) => {
  const classes = useStyles();
  const autoClasses = useAutoCorrectStyles();
  const {
    docsTags,
    docTagRef,
    onTagSelection,
    closeDropdown,
    // setInputRef,
    customeStyle,
    customAutocompleteStyle,
    popperCustomStyle,
    renderOnlyCustomStyle
  } = props;
  const onSelectingTag = (e: any, key: any) => {
    if (key !== null) {
      onTagSelection(e, key);
    }
  };
  const onCloseDropdown = (event: any) => {
    if (event.clientX === undefined) {
      closeDropdown(event);
    }
  };
  // const setRef = (input: any) => {
  //   setInputRef(input);
  // };
  return (
    <Autocomplete
      open
      openOnFocus
      id="checkboxes-tags-demo"
      data-testid="auto-complete-input"
      options={docsTags}
      classes={autoClasses}
      forcePopupIcon={false}
      onChange={(e: any, key: any) => {
        onSelectingTag(e, key);
      }}
      onClose={event => onCloseDropdown(event)}
      disableCloseOnSelect
      getOptionLabel={(option: any) => option?.name ?? ""}
      disablePortal
      renderOption={(props, option, selected) => {
        return (
          <li {...props}>
            <Grid
              container
              style={
                renderOnlyCustomStyle
                  ? renderOnlyCustomStyle
                  : { margin: "6px 0px 6px 6px" }
              }
              ref={docTagRef}
            >
              <Grid item xs={11}>
                <div
                  className={classes.optionMenu}
                  title={option.name}
                  data-code={option.code}
                >
                  {option.name}
                </div>
              </Grid>
            </Grid>
          </li>
        );
      }}
      style={customeStyle ? customeStyle : { maxWidth: 356 }}
      PaperComponent={({ style, ...otherProps }) => (
        <Paper
          style={
            customAutocompleteStyle
              ? { ...style, ...customAutocompleteStyle }
              : { ...style }
          }
          {...otherProps}
        />
      )}
      PopperComponent={({ style, ...popperProps }) => (
        <Popper
          // eslint-disable-next-line  react/jsx-props-no-spreading
          {...popperProps}
          placement="bottom-start"
          style={
            popperCustomStyle
              ? { ...style, ...popperCustomStyle }
              : { ...style, height: 0 }
          }
        />
      )}
      renderInput={params => {
        // eslint-disable-next-line
        params.InputProps.startAdornment = (
          <InputAdornment position="start">
            <SearchIcon className={classes.searchIcon} />
          </InputAdornment>
        );
        return (
          <TextField
            autoFocus
            // inputRef={input => {
            //   setRef(input);
            // }}
            size={"small"}
            className={`${classes.textFieldSearchContainerILP} autoCompleteCustomInput`}
            // eslint-disable-next-line  react/jsx-props-no-spreading
            {...params as any}
            variant="outlined"
            placeholder="Search here"
          />
        );
      }}
    />
  );
};
