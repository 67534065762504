import React, { useEffect } from 'react';
import { LogLevel } from '@grafana/faro-web-sdk';

const debounce = (func: any, delay: number) => {
  let timeoutId: any;
  return (...args: any) => {
    if (timeoutId) clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func(...args);
    }, delay);
  };
};

const findText = (el: any) => (
  el?.innerText || el?.textContent || el?.label || el?.ariaLabel || el?.getAttribute("data-testid") || ''
);

const pushToFaro = (logMessage: string) => {
  const faro = (window as any).faro;
  if (faro) {
    console.log('Faro instance found, sending log...');  // Debugging log
    faro.api.pushLog([logMessage], {
      level: LogLevel.INFO,
    });
  } else {
    console.log('Faro instance not found.');  // Debugging log
  }
}

const handleClick = (event: any) => {
  let element = event?.target;

  // Traverse up the DOM if the clicked element is not an interactive element
  while (element) {
    const tagName = element.tagName.toLowerCase();

    // Check if the element is a relevant element
    if (tagName.match(/^(button|a|input|select|textarea|span|li|h1|h2|h3|h4|h5)$/i)) {
      break;
    }

    // If the element is an SVG, check if its parent is a button
    if (tagName === 'svg') {
      let parent = element.parentElement;
      if (parent.tagName.toLowerCase() !== 'button') {
        break;
      }
    }

    // Move to the parent element
    element = element.parentElement;
  }

  if (element) {
    const elementType = element.tagName.toLowerCase();
    const elementId = element.id ? `#${element.id}` : '';

    // Check inside the current element and up to two levels of children and parents for non-empty text
    let elementText = findText(element);

    if (!elementText) {
      // Check up to two levels inside the current element
      for (let i = 0; i < Math.min(2, element.children.length) && !elementText; i++) {
        elementText = findText(element.children[i]);
      }

      // If still no text, traverse up the DOM to find non-empty text from up to two parent elements
      let parentElement = element.parentElement;
      for (let i = 0; i < 2 && !elementText && parentElement; i++) {
        elementText = findText(parentElement);
        parentElement = parentElement.parentElement;
      }
    }

    const logMessage = `User clicked on ${elementType}${elementId ? `: ${elementId}` : ''}${elementText ? `: ${elementText}` : ''}`;
    console.log(logMessage);
    pushToFaro(logMessage);
  } else {
    console.log('No valid element found for logging.');
  }
};

const handleInput = debounce((event: any) => {
  const input = event.target;
  const logMessage = `Input changed: ${input.name || input.id} to ${input.value}`;
  console.log(logMessage);
  pushToFaro(logMessage);
}, 300);


const withUserInteractionTracking = (WrappedComponent: any) => {
  return (props: any) => {
    useEffect(() => {
      document.addEventListener('click', handleClick);
      document.addEventListener('input', handleInput);
      return () => {
        document.removeEventListener('click', handleClick);
        document.removeEventListener('input', handleInput);
      };
    }, []);

    return <WrappedComponent {...props} />;
  };
};

export default withUserInteractionTracking;