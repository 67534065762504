/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-shadow */
import React from "react";
import { TableContainer, TableBody, TableCell, TableHead, TableRow, Table } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import { FieldInfo } from "./configs/PropertyAggregateFields";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(3)
  },
  head: {
    fontWeight: 600,
    fontSize: "12px",
    padding: "20px 15px"
  },
  tableContainer: {
    maxHeight: "calc(100vh - 290px)",
    // boxShadow: "0 2px 12px 0 rgba(50, 50, 93, 0.21)",
    borderRadius: "4px"
  },
  cell: {
    fontWeight: 600,
    fontSize: "12px",
    padding: "20px 15px"
  },
  stickyCell: {
    borderBottom: "none !important"
  }
}));

const StickyTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#f6f9fc",
    color: "#32325d",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar - 1,
    padding: "0px !important",
    border: "none"
  },
  body: {
    backgroundColor: "#ffffff",
    left: 0,
    position: "sticky",
    zIndex: theme.zIndex.appBar - 2,
    padding: "0px !important",
    border: "none"
  }
}))(TableCell);

const StyledTableCell = withStyles(theme => ({
  head: {
    backgroundColor: "#f6f9fc",
    color: "#32325d",
    borderBottom: "1px solid #e9ecef"
  },
  body: {
    backgroundColor: "#ffffff",
    color: "#32325d",
    borderBottom: "1px solid #e9ecef"
  }
}))(TableCell);

const StyledTableRow = withStyles(theme => ({
  root: {
    borderBottom: "1px solid #e9ecef"
  }
}))(TableRow);
export interface RowObjType {
  [index: string]: any;
  rowStyle: any;
  fields: any[];
  columnGroups?: {
    // This is right now supported only at the end indexes of table. Not supported in middle columns
    hasGroup: boolean;
    style: any;
    isExpanded: (data: any) => boolean;
    groupRange: {
      startIndex: number;
      // endIndex: number;
    };
  };
}
export interface TableProps {
  headers: RowObjType[];
  columnHeaders: RowObjType; // this can be same as headers. But is specific cases like property aggregator tab, there is more than one header. So has to be sent explicitly as props.
  tableData: {
    [index: string]: any;
    columnValues: any[]; // This is used to render number of rows for values.
  };
  stickyColumnRange: number;
  headerRowHeight?: number; // default 60px
}
export const AggregatePropertyTable = (props: TableProps) => {
  const {
    headers,
    stickyColumnRange,
    tableData,
    columnHeaders,
    headerRowHeight = 60
  } = props;
  const { columnValues } = tableData;
  const classes = useStyles();
  const CustomTableCell = (cellProps: {
    fieldConfig: FieldInfo;
    rowIndex: number;
    tableData: any;
    rowData?: any;
    value?: any;
    header?: boolean;
    cellClasses: any;
    cellStyle?: any;
  }) => {
    const { fieldConfig, cellClasses, cellStyle = {}, value } = cellProps;
    const { fieldCellStyle, customComponent, fieldLabel } = fieldConfig;

    if (customComponent) {
      return (
        <StyledTableCell
          className={cellClasses}
          style={{ ...fieldCellStyle?.style, ...cellStyle }}
        >
          {customComponent(cellProps)}
        </StyledTableCell>
      );
    }

    return (
      <StyledTableCell
        className={cellClasses}
        style={{ ...fieldCellStyle?.style, ...cellStyle }}
      >
        {value || fieldLabel}
      </StyledTableCell>
    );
  };
  const getColGroupStartIndex = (colHead: RowObjType) => {
    if (colHead.columnGroups?.hasGroup) {
      return colHead.columnGroups.groupRange.startIndex;
    }
  };
  return (
    <div>
      <TableContainer className={classes.tableContainer}>
        <Table stickyHeader aria-label="aggregate property table">
          <TableHead>
            {headers.map((headerObj: RowObjType, index: number) => {
              const { fields, rowStyle = {}, ...remainingProps } = headerObj;
              return (
                <TableRow>
                  {fields?.length && (
                    <>
                      {stickyColumnRange && (
                        <StickyTableCell
                          style={{
                            ...rowStyle,
                            top: index * headerRowHeight
                          }}
                        >
                          {fields
                            .slice(0, stickyColumnRange)
                            .map((item: any) => {
                              return (
                                <CustomTableCell
                                  {...remainingProps}
                                  header
                                  fieldConfig={item}
                                  tableData={tableData}
                                  cellStyle={rowStyle}
                                  value={item.value}
                                  cellClasses={`${classes.head} ${classes.stickyCell}`}
                                  rowIndex={index}
                                />
                              );
                            })}
                        </StickyTableCell>
                      )}
                      {fields.slice(stickyColumnRange).map((item: any) => {
                        return (
                          <CustomTableCell
                            {...remainingProps}
                            header
                            tableData={tableData}
                            fieldConfig={item}
                            cellStyle={{
                              ...rowStyle,
                              borderRadius: 0, // for grouped columns in unit level we have to set it 0 here for headers. If in future there comes a requirement in which border radius is needed for header, we can use columnGroups props to add this conditionally
                              top: index * headerRowHeight
                            }}
                            value={item.value}
                            cellClasses={`${classes.head}`}
                            rowIndex={index}
                          />
                        );
                      })}
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableHead>
          <TableBody>
            {columnValues.map((data: any, index: number) => {
              return (
                <StyledTableRow
                  style={
                    columnHeaders.columnGroups?.hasGroup &&
                    columnHeaders.columnGroups.isExpanded({
                      tableData,
                      rowData: data
                    })
                      ? { verticalAlign: "top" }
                      : {}
                  }
                >
                  {stickyColumnRange && (
                    <>
                      {columnHeaders.fields
                        .slice(0, stickyColumnRange)
                        .map((item: any) => {
                          const {
                            rowStyle,
                            fields,
                            ...remainingProps
                          } = columnHeaders;
                          return (
                            <StickyTableCell
                              style={{ ...rowStyle, border: "none" }}
                            >
                              <CustomTableCell
                                {...remainingProps}
                                tableData={tableData}
                                fieldConfig={item}
                                cellStyle={rowStyle}
                                cellClasses={`${classes.cell} ${classes.stickyCell}`}
                                value={data.value}
                                rowData={data}
                                rowIndex={index}
                              />
                            </StickyTableCell>
                          );
                        })}
                    </>
                  )}
                  {columnHeaders.fields
                    .slice(
                      stickyColumnRange,
                      getColGroupStartIndex(columnHeaders)
                    )
                    .map((item: any) => {
                      const {
                        rowStyle,
                        fields,
                        ...remainingProps
                      } = columnHeaders;
                      return (
                        <CustomTableCell
                          {...remainingProps}
                          tableData={tableData}
                          fieldConfig={item}
                          cellStyle={rowStyle}
                          cellClasses={`${classes.cell}`}
                          value={data.value}
                          rowData={data}
                          rowIndex={index}
                        />
                      );
                    })}
                  {columnHeaders.columnGroups?.hasGroup && (
                    <>
                      {columnHeaders.fields
                        .slice(getColGroupStartIndex(columnHeaders))
                        .map((item: any) => {
                          const {
                            rowStyle,
                            fields,
                            ...remainingProps
                          } = columnHeaders;
                          const color =
                            columnHeaders.columnGroups?.style.backgroundColor;
                          return (
                            <CustomTableCell
                              {...remainingProps}
                              tableData={tableData}
                              fieldConfig={item}
                              cellStyle={
                                color
                                  ? {
                                      backgroundColor: color,
                                      ...rowStyle
                                    }
                                  : rowStyle
                              }
                              cellClasses={`${classes.cell}`}
                              value={data.value}
                              rowData={data}
                              rowIndex={index}
                            />
                          );
                        })}
                    </>
                  )}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};
