import { publicClient, LoanStage } from "@toorak/tc-common-fe-sdk";
import {
  LoanStatusEnum,
  appraisalToLoanStatus
} from "@toorak/tc-common-util-sdk";
import {
  getAppraisalServiceUrl,
  AppraisalStatus,
  removeNullish
} from "../../create-loan/appraisal/util";
import {
  AppraisalForm,
  HouseCanaryForm,
  ToorakReconciliationForm,
  ComparableFields,
  HouseCanaryListingHistory,
  houseCanaryFormFields,
  appraisalFormFields,
  toorakReconciliationFormFields,
  comparableFields,
  houseCanarySalesHistory,
  houseCanaryListingHistory,
  subjectHistoryForm,
  updateSubjectHistoryFormField,
  setAppraisalFormField,
  updateHouseCanaryFormFieldRedix,
  setToorakReconciliationFormField,
  setAppraisalComparable,
  setAppraisalInfoSuccess,
  setHouseCanaryListingHistorySuccess,
  setHouseCanaryListingHistoryField,
  setHouseCanarySalesHistorySuccess,
  setHouseCanarySalesHistoryField,
  setFalseAppraisalInfoSuccess,
  setAppraisalInfoFailure,
  setAppraisalLandmarkSuccess
} from "./appraisal.reducer";
import { FieldValue } from "../../create-loan/appraisal/shared/Field";
import { formatValueByType } from "../../utils/formatChecks";
import { getErrorConfigObject } from "../../ErrorHandler/ErrorConfigData";
import { updateLoanStatusbyLoanId } from "../../create-loan/create-loan.action";
import {
  showLoader,
  hideLoader
} from "../loaderAndException/loaderAndException.action";
import { getCookie } from "../../utils/cookies";
import {
  addToorvalFormUpdate,
  getToorvalFormUpdates
} from "../../create-loan/appraisal/shared/persistUpdate";

export const UPDATE_SUBJECT_HISTORY_FORM_FIELD =
  "UPDATE_SUBJECT_HISTORY_FORM_FIELD";
export const UPDATE_HOUSE_CANARY_LISTING_HISTORY_FIELD =
  "UPDATE_HOUSE_CANARY_LISTING_HISTORY_FIELD";
export const GET_HOUSE_CANARY_LISTING_HISTORY_SUCCESS =
  "GET_HOUSE_CANARY_LISTING_HISTORY_SUCCESS";
export const UPDATE_HOUSE_CANARY_LISTING_HISTORY =
  "UPDATE_HOUSE_CANARY_LISTING_HISTORY";
export const UPDATE_HOUSE_CANARY_SALES_HISTORY_FIELD =
  "UPDATE_HOUSE_CANARY_SALES_HISTORY_FIELD";
export const GET_HOUSE_CANARY_SALES_HISTORY_SUCCESS =
  "GET_HOUSE_CANARY_SALES_HISTORY_SUCCESS";
export const UPDATE_HOUSE_CANARY_SALES_HISTORY =
  "UPDATE_HOUSE_CANARY_SALES_HISTORY";
export const DELETE_APPRAISAL_INFO_FAILURE = "DELETE_APPRAISAL_INFO_FAILURE";
export const DELETE_APPRAISAL_INFO_SUCCESS = "DELETE_APPRAISAL_INFO_SUCCESS";
export const UPDATE_APPRAISAL_FORM_FIELD = "UPDATE_APPRAISAL_FORM_FIELD";
export const UPDATE_APPRAISAL_COMPARABLE = "UPDATE_APPRAISAL_COMPARABLE";
export const INSERT_APPRAISAL_INFO = "INSERT_APPRAISAL_INFO";
export const INSERT_APPRAISAL_INFO_SUCCESS = "INSERT_APPRAISAL_INFO_SUCCESS";
export const INSERT_APPRAISAL_INFO_FAILURE = "INSERT_APPRAISAL_INFO_FAILURE";
export const UPDATE_APPRAISAL_INFO = "UPDATE_APPRAISAL_INFO";
export const UPDATE_APPRAISAL_INFO_SUCCESS = "UPDATE_APPRAISAL_INFO_SUCCESS";
export const UPDATE_APPRAISAL_INFO_FAILURE = "UPDATE_APPRAISAL_INFO_FAILURE";
export const GET_APPRAISAL_INFO = "GET_APPRAISAL_INFO";
export const GET_APPRAISAL_INFO_SUCCESS = "GET_APPRAISAL_INFO_SUCCESS";
export const GET_APPRAISAL_INFO_FAILURE = "GET_APPRAISAL_INFO_FAILURE";
export const RESET_APPRAISAL_STORE = "RESET_APPRAISAL_STORE";
export const SAVE_APPRAISAL_ID = "SAVE_APPRAISAL_ID";
export const UPDATE_HOUSE_CANARY_FORM_FIELD = "UPDATE_HOUSE_CANARY_FORM_FIELD";
export const UPDATE_TOORAK_RECONCILIATION_FORM =
  "UPDATE_TOORAK_RECONCILIATION_FORM";
export const CHECK_APPRAISAL_STATUS_APPROVE_CONDITIONALLY =
  "CHECK_APPRAISAL_STATUS_APPROVE_CONDITIONALLY";
export const UPDATE_APPRAISAL_LANDMARK_SUCCESS =
  "UPDATE_APPRAISAL_LANDMARK_SUCCESS";
export const UPDATE_APPRAISAL_LANDMARK_FAILURE =
  "UPDATE_APPRAISAL_LANDMARK_FAILURE";

export const onlyUpdateAppraisalForm = async (options: {
  toorakLoanID: string;
  appraisalID: string;
  form?: Partial<AppraisalForm>;
}): Promise<any> => {
  const { toorakLoanID, form = {}, appraisalID } = options;
  // let appraisalID = getAppraisalID();
  if (!appraisalID) return;
  const url = `${getAppraisalServiceUrl()}/${appraisalID}`;
  const mappedForm: Partial<AppraisalForm> = {
    ...form,
    status: form.status,
    toorakLoanID
  };
  try {
    await publicClient.post(url, mapAppraisalData(mappedForm, "service"), {
      errorConfig: getErrorConfigObject({
        skipForStatus: [400]
      })
    } as any);
    return Promise.resolve();
  } catch (e) {
    console.error(e);
    return Promise.reject();
  }
};

export const submitAppraisalForm = (options: {
  toorakLoanID: string;
  form?: Partial<AppraisalForm>;
  save: boolean;
}): any => {
  return async (dispatch: any) => {
    const { toorakLoanID, form = {}, save = true } = options;
    const appraisalID = getAppraisalID();
    if (!appraisalID) {
      // return dispatch({
      //   type: UPDATE_APPRAISAL_INFO_FAILURE,
      //   payload: {}
      // });
      return dispatch(setAppraisalInfoFailure());
    }
    const url = `${getAppraisalServiceUrl()}/${appraisalID}`;
    const mappedForm: Partial<AppraisalForm> = {
      ...form,
      status: save ? form.status : AppraisalStatus.underReview,
      toorakLoanID
    };
    try {
      dispatch(showLoader());
      await publicClient.post(url, mapAppraisalData(mappedForm, "service"), {
        errorConfig: getErrorConfigObject({
          skipForStatus: [400]
        })
      } as any);
      dispatch(hideLoader());
      // dispatch({ type: UPDATE_APPRAISAL_INFO_SUCCESS, payload: {} });
      dispatch(setFalseAppraisalInfoSuccess());
      if (save === false) {
        await updateLoanStatusbyLoanId(toorakLoanID, {
          loanStage: LoanStage.tv,
          loanState: LoanStatusEnum.UnderReview,
          comments: ""
        });
        window.location.reload();
      }
    } catch (_e) {
      // dispatch({ type: UPDATE_APPRAISAL_INFO_FAILURE, payload: {} });
      dispatch(setAppraisalInfoFailure());

      dispatch(hideLoader());
    }
  };
};

export const deleteAppraisalForm = (): any => {
  return async (dispatch: any) => {
    const appraisalID = getAppraisalID();
    if (!appraisalID) {
      return dispatch({ type: DELETE_APPRAISAL_INFO_FAILURE, payload: {} });
    }
    const url = `${getAppraisalServiceUrl()}/${appraisalID}`;
    try {
      dispatch(showLoader());
      await publicClient.delete(url);
      dispatch({ type: DELETE_APPRAISAL_INFO_SUCCESS, payload: {} });
      dispatch(hideLoader());
    } catch (_e) {
      dispatch({ type: DELETE_APPRAISAL_INFO_FAILURE, payload: {} });
      dispatch(hideLoader());
    }
  };
};

export const reviewAppraisalForm = (options: {
  toorakLoanID: string;
  status: AppraisalStatus;
  reload?: boolean;
  comment?: string;
}): any => {
  return async (dispatch: any) => {
    const { toorakLoanID, status, reload = false, comment = "" } = options;
    const appraisalID = getAppraisalID();
    if (!appraisalID) {
      if (reload) window.location.reload();
      // return dispatch({ type: UPDATE_APPRAISAL_INFO_FAILURE, payload: {} });
      dispatch(setAppraisalInfoFailure());
    }
    const url = `${getAppraisalServiceUrl()}/${appraisalID}`;
    const mappedForm: Partial<AppraisalForm> = {
      status,
      toorakLoanID
    };
    try {
      dispatch(showLoader());
      await publicClient.post(url, mapAppraisalData(mappedForm, "service"), {
        errorConfig: getErrorConfigObject({
          skipForStatus: [400]
        })
      } as any);
      const newStatus = appraisalToLoanStatus(status);
      await updateLoanStatusbyLoanId(toorakLoanID, {
        loanStage: LoanStage.tv,
        loanState: newStatus,
        comments: comment
      });
      window.location.reload();
      dispatch(hideLoader());
      // dispatch({ type: UPDATE_APPRAISAL_INFO_SUCCESS, payload: {} });

      dispatch(setFalseAppraisalInfoSuccess());
      if (reload) window.location.reload();
    } catch (_e) {
      // dispatch({ type: UPDATE_APPRAISAL_INFO_FAILURE, payload: {} });
      dispatch(setAppraisalInfoFailure());

      dispatch(hideLoader());
      if (reload) window.location.reload();
    }
  };
};

export const updateAppraisalFormField = (options: {
  toorakLoanID?: string;
  fieldName: string;
  fieldValue: FieldValue;
  fieldType?: string;
  form?: Partial<AppraisalForm>;
  reload?: boolean;
  status?: AppraisalStatus;
}) => {
  const {
    toorakLoanID,
    fieldName,
    fieldValue,
    form = {},
    reload = false,
    status
  } = options;
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_APPRAISAL_FORM_FIELD,
    //   payload: { fieldName, fieldValue }
    // });
    dispatch(setAppraisalFormField({ fieldName, fieldValue }));

    if (fieldValue === "") return;
    if (toorakLoanID) {
      form[fieldName] = fieldValue;
      dispatch(
        insertLoanAppraisalInformation({
          form: mapAppraisalData(form, "service"),
          toorakLoanID,
          reload,
          status,
          autoSave: true
        })
      );
    }
  };
};

export const updateAppraisalFormStatus = (options: {
  toorakLoanID?: string;
  status: AppraisalStatus;
}) => {
  const { toorakLoanID, status } = options;
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_APPRAISAL_FORM_FIELD,
    //   payload: { fieldName: "status", fieldValue: status }
    // });
    dispatch(
      setAppraisalFormField({ fieldName: "status", fieldValue: status })
    );

    if (toorakLoanID) {
      dispatch(
        insertLoanAppraisalInformation({ form: { status }, toorakLoanID })
      );
    }
  };
};

// export const checkAppraisalStatusApproveConditionally = (
//   openPopup: boolean
// ): any => {
//   return async (dispatch: any) => {
//     dispatch({
//       type: CHECK_APPRAISAL_STATUS_APPROVE_CONDITIONALLY,
//       payload: openPopup
//     });
//   };
// };

export const updateHouseCanaryFormField = (options: {
  toorakLoanID: string;
  fieldName: string;
  fieldValue: FieldValue;
  form: Partial<HouseCanaryForm>;
}) => {
  const { fieldName, fieldValue, toorakLoanID, form = {} } = options;
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_HOUSE_CANARY_FORM_FIELD,
    //   payload: { fieldName, fieldValue }
    // });
    dispatch(updateHouseCanaryFormFieldRedix({ fieldName, fieldValue }));

    if (fieldValue === "") return;
    if (toorakLoanID) {
      form[fieldName] = fieldValue;
      dispatch(
        insertLoanAppraisalInformation({ form, toorakLoanID, autoSave: true })
      );
    }
  };
};

export const getHouseCanaryValue = (options: Partial<AppraisalForm>): any => {
  const { address, postalCode } = options;
  return async (dispatch: any) => {
    const url = `${getAppraisalServiceUrl()}/house-canary/value?address=${(
      address as string
    )
      ?.split(" ")
      .join("+")}&zipcode=${postalCode}`;
    dispatch(showLoader());
    try {
      const { data } = await publicClient.get(url, {
        errorConfig: getErrorConfigObject({
          skipForStatus: [500, 404]
        })
      } as any);
      dispatch(hideLoader());
      const forceMap = {
        hcAvmMin: data.priceLwr,
        houseCanaryValue: data.priceMean,
        confidenceSummary: data.fsd,
        hcAvmMedian: data.priceMean,
        hcAvmMax: data.priceUpr,
        ...data
      };
      // dispatch({
      //   type: GET_APPRAISAL_INFO_SUCCESS,
      //   payload: mapAppraisalData(forceMap, "reducer")
      // });
      dispatch(setAppraisalInfoSuccess(mapAppraisalData(forceMap, "reducer")));

      dispatch(hideLoader());
    } catch (_e) {
      dispatch(hideLoader());
    }
  };
};

export const getHouseCanaryListingHistory = (
  options: Partial<AppraisalForm>
): any => {
  const { address, postalCode } = options;
  return async (dispatch: any) => {
    const url = `${getAppraisalServiceUrl()}/house-canary/listing/history?address=${(
      address as string
    )
      ?.split(" ")
      .join("+")}&zipcode=${postalCode}`;
    dispatch(showLoader());
    try {
      const { data } = await publicClient.get(url, {
        errorConfig: getErrorConfigObject({
          skipForStatus: [500, 404]
        })
      } as any);
      dispatch(hideLoader());
      // dispatch({
      //   type: GET_HOUSE_CANARY_LISTING_HISTORY_SUCCESS,
      //   payload: transformFields(
      //     data,
      //     "reducer",
      //     Object.keys(houseCanaryListingHistory())
      //   )
      // });
      dispatch(
        setHouseCanaryListingHistorySuccess(
          transformFields(
            data,
            "reducer",
            Object.keys(houseCanaryListingHistory())
          )
        )
      );
    } catch (_e) {
      dispatch(hideLoader());
    }
  };
};

export const postHouseCanaryListingHistory = (
  options: Partial<AppraisalForm>,
  data: HouseCanaryListingHistory[]
): any => {
  const { address, postalCode } = options;
  return async (dispatch: any) => {
    const url = `${getAppraisalServiceUrl()}/house-canary/listing/history?address=${(
      address as string
    )
      ?.split(" ")
      .join("+")}&zipcode=${postalCode}`;
    dispatch(showLoader());
    try {
      await publicClient.post(url, data);
      dispatch(hideLoader());
    } catch (_e) {
      dispatch(hideLoader());
    }
  };
};

export const getHouseCanarySalesHistory = (
  options: Partial<AppraisalForm>
): any => {
  const { address, postalCode } = options;
  return async (dispatch: any) => {
    const url = `${getAppraisalServiceUrl()}/house-canary/sales/history?address=${(
      address as string
    )
      ?.split(" ")
      .join("+")}&zipcode=${postalCode}`;
    dispatch(showLoader());
    try {
      const { data } = await publicClient.get(url, {
        errorConfig: getErrorConfigObject({
          skipForStatus: [500, 404]
        })
      } as any);
      const mapped = data.map((d: any) =>
        transformFields(d, "reducer", Object.keys(houseCanarySalesHistory()))
      );
      dispatch(hideLoader());
      // dispatch({
      //   type: GET_HOUSE_CANARY_SALES_HISTORY_SUCCESS,
      //   payload: mapped
      // });
      dispatch(setHouseCanarySalesHistorySuccess(mapped));
    } catch (_e) {
      dispatch(hideLoader());
    }
  };
};

export const postHouseCanarySalesHistory = (
  options: Partial<AppraisalForm>,
  data: HouseCanaryListingHistory[]
): any => {
  const { address, postalCode } = options;
  return async (dispatch: any) => {
    const url = `${getAppraisalServiceUrl()}/house-canary/sales/history?address=${(
      address as string
    )
      ?.split(" ")
      .join("+")}&zipcode=${postalCode}`;
    dispatch(showLoader());
    try {
      await publicClient.post(url, data);
      dispatch(hideLoader());
    } catch (_e) {
      dispatch(hideLoader());
    }
  };
};

export const getHouseCanaryBlockGroupValueDistribution = (
  options: Partial<AppraisalForm>
): any => {
  const { address, postalCode } = options;
  return async (dispatch: any) => {
    const url = `${getAppraisalServiceUrl()}/house-canary/blockgroupValueDistribution?address=${(
      address as string
    )
      ?.split(" ")
      .join("+")}&zipcode=${postalCode}`;
    dispatch(showLoader());
    try {
      const { data } = await publicClient.get(url, {
        errorConfig: getErrorConfigObject({
          skipForStatus: [500, 404]
        })
      } as any);
      // dispatch({
      //   type: GET_APPRAISAL_INFO_SUCCESS,
      //   payload: mapAppraisalData(data, "reducer")
      // });
      dispatch(setAppraisalInfoSuccess(mapAppraisalData(data, "reducer")));
      dispatch(hideLoader());
    } catch (_e) {
      dispatch(hideLoader());
    }
  };
};

export const updateToorakReconciliationForm = (options: {
  toorakLoanID: string;
  fieldName: string;
  fieldValue: FieldValue;
  form: Partial<ToorakReconciliationForm>;
}) => {
  const { fieldName, fieldValue, toorakLoanID, form = {} } = options;
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_TOORAK_RECONCILIATION_FORM,
    //   payload: { fieldName, fieldValue }
    // });
    dispatch(setToorakReconciliationFormField({ fieldName, fieldValue }));

    if (fieldValue === "") return;
    if (toorakLoanID) {
      form[fieldName] = fieldValue;
      dispatch(
        insertLoanAppraisalInformation({ form, toorakLoanID, autoSave: true })
      );
    }
  };
};

// export const resetAppraisalStore = () => {
//   return async (dispatch: any) => {
//     sessionStorage.removeItem("appraisalID");
//     dispatch({
//       type: RESET_APPRAISAL_STORE
//     });
//   };
// };

export const getAppraisalID = () => {
  return sessionStorage.getItem("appraisalID");
};

export const saveAppraisalID = (appraisalID: string) => {
  if (!appraisalID) return;
  sessionStorage.setItem("appraisalID", appraisalID);
};

export const updateAppraisalComparable = (options: {
  toorakLoanID?: string;
  fieldName: string;
  fieldValue: FieldValue;
  comparableIndex: number;
  form?: Partial<ToorakReconciliationForm>;
  originatorId?: string;
  status?: AppraisalStatus;
}) => {
  const { toorakLoanID, fieldName, fieldValue, comparableIndex, form, status } =
    options;
  return async (dispatch: any) => {
    // Empty string will occur when deleting field value and should update appraisal with null value
    const fieldVal = fieldValue !== "" ? fieldValue : null;
    // dispatch({
    //   type: UPDATE_APPRAISAL_COMPARABLE,
    //   payload: {
    //     fieldName,
    //     fieldValue: fieldVal,
    //     comparableIndex
    //   }
    // });
    dispatch(
      setAppraisalComparable({
        fieldName,
        fieldValue: fieldVal,
        comparableIndex
      })
    );

    if (form?.lenderProvidedSalesComparables) {
      const updatedComparables = [...form?.lenderProvidedSalesComparables];
      const byIndex = updatedComparables[comparableIndex];
      const updatedByIndex = { ...byIndex };
      updatedByIndex[fieldName] = fieldValue;
      updatedComparables[comparableIndex] = updatedByIndex;

      if (toorakLoanID)
        dispatch(
          insertLoanAppraisalInformation({
            toorakLoanID,
            form: { lenderProvidedSalesComparables: updatedComparables },
            status,
            autoSave: true
          })
        );
    }
  };
};

export const updateHouseCanaryListingHistoryField = (options: {
  fieldName: string;
  fieldValue: FieldValue;
  index: number;
}) => {
  const { fieldName, fieldValue, index } = options;
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_HOUSE_CANARY_LISTING_HISTORY_FIELD,
    //   payload: {
    //     fieldName,
    //     fieldValue,
    //     index
    //   }
    // });
    dispatch(
      setHouseCanaryListingHistoryField({ fieldName, fieldValue, index })
    );
  };
};

export const updateSubjecthistoryFormField = (options: {
  fieldName: string;
  fieldValue: FieldValue;
  toorakLoanID?: string;
  status?: AppraisalStatus;
}) => {
  const { fieldName, fieldValue, toorakLoanID, status } = options;
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_SUBJECT_HISTORY_FORM_FIELD,
    //   payload: {
    //     fieldName,
    //     fieldValue
    //   }
    // });

    dispatch(updateSubjectHistoryFormField({ fieldName, fieldValue }));

    if (toorakLoanID) {
      const form: any = {};
      form[fieldName] = fieldValue;
      dispatch(
        insertLoanAppraisalInformation({
          toorakLoanID,
          form,
          status,
          autoSave: true
        })
      );
    }
  };
};

export const updateHouseCanarySalesHistoryField = (options: {
  fieldName: string;
  fieldValue: FieldValue;
  index: number;
}) => {
  const { fieldName, fieldValue, index } = options;
  return async (dispatch: any) => {
    // dispatch({
    //   type: UPDATE_HOUSE_CANARY_SALES_HISTORY_FIELD,
    //   payload: {
    //     fieldName,
    //     fieldValue,
    //     index
    //   }
    // });
    dispatch(setHouseCanarySalesHistoryField({ fieldName, fieldValue, index }));
  };
};

export function getLoanAppraisalInformation(loanID: string): any {
  return async (dispatch: any) => {
    // const url = `${getAppraisalServiceUrl()}/loan/${loanID}`;
    // try {
    //   dispatch({ type: GET_APPRAISAL_INFO, payload: {} });
    //   dispatch(showLoader());
    //   const { data } = await publicClient.get(url, {
    //     errorConfig: getErrorConfigObject({
    //       skipForStatus: [404]
    //     })
    //   } as any);
    //   if (data.appraisalID) saveAppraisalID(data.appraisalID);
    //   let dataToRender = {};
    //   if (data.excelDoc && data.status === "data entry") {
    //     const excelData = data.excelDoc;
    //     dataToRender = {
    //       ...data,
    //       assignmentFee: data.assignmentFee || excelData.assignmentFee,
    //       bathroomsAsIs: data.bathroomsAsIs || excelData.bathroomsAsIs,
    //       bathroomsProposed:
    //         data.bathroomsProposed || excelData.bathroomsProposed,
    //       bedroomsAsIs: data.bedroomsAsIs || excelData.bedroomsAsIs,
    //       bedroomsProposed: data.bedroomsProposed || excelData.bedroomsProposed,
    //       comment: data.comment || excelData.comment,
    //       lenderProvidedSalesComparables:
    //         data.lenderProvidedSalesComparables ||
    //         excelData.lenderProvidedSalesComparables,
    //       lenderCalculatedAsRenovatedValue:
    //         data.lenderCalculatedAsRenovatedValue ||
    //         excelData.lenderCalculatedAsRenovatedValue,
    //       loanID: data.loanID || excelData.loanID,
    //       propertySquareFootageAsIs:
    //         data.propertySquareFootageAsIs ||
    //         excelData.propertySquareFootageAsIs,
    //       propertySquareFootageProposed:
    //         data.propertySquareFootageProposed ||
    //         excelData.propertySquareFootageProposed,
    //       proposedRenovationBudget:
    //         data.proposedRenovationBudget || excelData.proposedRenovationBudget,
    //       purchasePrice: data.purchasePrice || excelData.purchasePrice,
    //       zillowLink: data.zillowLink || excelData.zillowLink,
    //       city: data.city || excelData.city,
    //       postalCode: data.postalCode || excelData.postalCode,
    //       state: data.state || excelData.state,
    //       address: data.address || excelData.address,
    //       country: data.country || data.country,
    //       toorakApprovedARV:
    //         data.toorakApprovedARV || excelData.toorakApprovedARV
    //     };
    //     // dispatch({
    //     //   type: GET_APPRAISAL_INFO_SUCCESS,
    //     //   payload: mapAppraisalData(dataToRender, "reducer")
    //     // });
    //     dispatch(
    //       setAppraisalInfoSuccess(mapAppraisalData(dataToRender, "reducer"))
    //     );
    //     dispatch(hideLoader());
    //   } else {
    //     // dispatch({
    //     //   type: GET_APPRAISAL_INFO_SUCCESS,
    //     //   payload: mapAppraisalData(data, "reducer")
    //     // });
    //     dispatch(setAppraisalInfoSuccess(mapAppraisalData(data, "reducer")));
    //     dispatch(hideLoader());
    //   }
    // } catch (_e) {
    //   dispatch({ type: GET_APPRAISAL_INFO_FAILURE, payload: {} });
    //   dispatch(hideLoader());
    //   // attempt to update UI with what is persisted
    //   const updates = getToorvalFormUpdates(loanID);
    //   const data: any = {};
    //   if (updates) {
    //     updates.forEach((update: any) => {
    //       const [key, value] = update;
    //       data[key] = value;
    //     });
    //     // dispatch({
    //     //   type: GET_APPRAISAL_INFO_SUCCESS,
    //     //   payload: mapAppraisalData(data, "reducer")
    //     // });
    //     dispatch(setAppraisalInfoSuccess(mapAppraisalData(data, "reducer")));
    //   }
    // }
  };
}

export function getAppraisalLandmarks(appraisalId: string): any {
  return async (dispatch: any) => {
    const url = `${getAppraisalServiceUrl()}/${appraisalId}/landmarks`;
    try {
      dispatch(showLoader());
      const { data } = await publicClient.get(url);
      // dispatch({ type: UPDATE_APPRAISAL_LANDMARK_SUCCESS, payload: data });
      dispatch(setAppraisalLandmarkSuccess(data));

      dispatch(hideLoader());
    } catch (_e) {
      dispatch({ type: UPDATE_APPRAISAL_LANDMARK_FAILURE, payload: [] });
      dispatch(hideLoader());
    }
  };
}

function transformFields(
  data: ToorvalData,
  type: ToValueI,
  acceptableKeys: string[]
) {
  const newData: Partial<AppraisalForm> = {};
  // only capture relevant fields
  acceptableKeys.forEach((key) => {
    if (data[key]) newData[key] = data[key];
  });
  fieldTransforms.forEach((value, key) => {
    const toType = value[type];
    if (!data[key]) return;
    if (toType === "decimal" || toType === "number")
      newData[key] = Number(formatValueByType(data[key], toType));
    if (toType === "currency")
      newData[key] = String(formatValueByType(String(data[key]), toType));
    if (toType === "date")
      newData[key] = String(formatValueByType(String(data[key]), toType));
  });
  return newData;
}

type ToValueI = "service" | "reducer";
type ToValueS = "date" | "string" | "currency" | "decimal" | "number";
type ToValue = {
  service: ToValueS;
  reducer: ToValueS;
};
type ToorvalData = Partial<
  AppraisalForm | HouseCanaryForm | ToorakReconciliationForm | ComparableFields
>;
const currencyToNumber: ToValue = { reducer: "currency", service: "decimal" };
const numberToNumber: ToValue = { reducer: "number", service: "number" };
const dateToString: ToValue = { reducer: "date", service: "string" };

const fieldTransforms = new Map([
  ["toorakApprovedARV", currencyToNumber],
  ["borrowowerARV", currencyToNumber],
  ["lenderCalculatedAsRenovatedValue", currencyToNumber],
  ["purchasePrice", currencyToNumber],
  ["proposedRenovationBudget", currencyToNumber],
  ["assignmentFee", currencyToNumber],
  ["squareFee", currencyToNumber],
  ["renovationBudget", currencyToNumber],
  ["salesPrice", currencyToNumber],
  ["salesDate", dateToString],
  ["hcAvmMin", currencyToNumber],
  ["hcAvmMax", currencyToNumber],
  ["hcAvmMedian", currencyToNumber],
  ["value5", currencyToNumber],
  ["value25", currencyToNumber],
  ["value50", currencyToNumber],
  ["value75", currencyToNumber],
  ["value95", currencyToNumber],
  ["value95", currencyToNumber],
  ["valueMax", currencyToNumber],
  ["houseCanaryValue", currencyToNumber],
  ["houseCanaryRange", currencyToNumber],
  ["propertyExplorer", currencyToNumber],
  ["bpoValue", currencyToNumber],
  ["amount", currencyToNumber],
  ["propertySquareFootageAsIs", numberToNumber],
  ["propertySquareFootageProposed", numberToNumber],
  ["bedroomsAsIs", numberToNumber],
  ["bedroomsProposed", numberToNumber],
  ["bathroomsAsIs", numberToNumber],
  ["bathroomsProposed", numberToNumber],
  ["distanceToSubject", numberToNumber],
  ["bedrooms", numberToNumber],
  ["convertYZ", numberToNumber]
]);

function mapAppraisalData(data: ToorvalData, type: ToValueI): ToorvalData {
  const newData = transformFields(data, type, [
    ...Object.keys(toorakReconciliationFormFields()),
    ...Object.keys(appraisalFormFields()),
    ...Object.keys(houseCanaryFormFields()),
    ...Object.keys(subjectHistoryForm())
  ]);
  if (
    data.lenderProvidedSalesComparables &&
    data.lenderProvidedSalesComparables.length > 0
  ) {
    newData.lenderProvidedSalesComparables =
      data.lenderProvidedSalesComparables.map((comp: ComparableFields) => {
        return transformFields(comp, type, Object.keys(comparableFields()));
      });
  }

  if (data["toorakLoanID"]) {
    newData["toorakLoanID"] = data["toorakLoanID"];
  }
  if (data["originatorPartyID"]) {
    newData["originatorPartyID"] = data["originatorPartyID"];
  }
  return removeNullish(newData);
}

const alertOnDuplicateAppraisal = (response: any) => {
  if (response?.status === 409) {
    switch (response?.data?.errorId) {
      case "duplicate_originator_and_address":
        alert(
          "There is already a Toorval Loan in the system with same property Address! Please try again with new Address."
        );
        break;
      case "duplicate_originator_loan_id":
        alert(
          "There is already a Toorval Loan in the system with same Primary Loan ID! Please try again with new Primary Loan ID."
        );
        break;
      default:
        alert(
          "There is already a ToorVal Loan in the system with same property Address and or Primary Loan ID! Please try again with a different property address or loan ID."
        );
    }
  }
};

export const insertLoanAppraisalInformation = (options: {
  toorakLoanID: string;
  form?: Partial<AppraisalForm>;
  reload?: boolean;
  status?: AppraisalStatus;
  autoSave?: boolean;
}) => {
  const {
    form = {},
    toorakLoanID,
    reload = false,
    status,
    autoSave = false
  } = options;
  return async (dispatch: any) => {
    const url = `${getAppraisalServiceUrl()}`;
    const appraisalID = getAppraisalID();
    if (appraisalID)
      return dispatch(
        updateLoanAppraisalInformation({
          form,
          toorakLoanID,
          reload,
          status,
          autoSave
        })
      );
    const mappedForm: Partial<AppraisalForm> = {
      ...form,
      status,
      originatorPartyID: getCookie("org_id"),
      toorakLoanID: toorakLoanID
    };
    if (!mappedForm?.address) {
      // attempt to persist data
      for (const key in form) {
        addToorvalFormUpdate(toorakLoanID, key, form[key]);
      }
      return;
    }
    // do we have data persisted?
    const updates = getToorvalFormUpdates(toorakLoanID);
    if (updates) {
      updates.forEach((update: any) => {
        const [key, value] = update;
        mappedForm[key] = value;
      });
    }

    try {
      dispatch({ type: INSERT_APPRAISAL_INFO, payload: {} });
      if (autoSave === false) dispatch(showLoader());
      const { data } = await publicClient.post(
        url,
        mapAppraisalData(mappedForm, "service")
      );
      if (autoSave === false) dispatch(hideLoader());
      dispatch(saveAppraisalID(data));
      dispatch({ type: INSERT_APPRAISAL_INFO_SUCCESS, payload: {} });
      if (reload) window.location.reload();
    } catch (_e: any) {
      alertOnDuplicateAppraisal(_e.response);
      dispatch({ type: INSERT_APPRAISAL_INFO_FAILURE, payload: {} });
      if (reload) window.location.reload();
      if (autoSave === false) dispatch(hideLoader());
    }
  };
};

export const updateLoanAppraisalInformation = (options: {
  toorakLoanID: string;
  form?: Partial<AppraisalForm>;
  reload?: boolean;
  status?: AppraisalStatus;
  autoSave?: boolean;
}) => {
  return async (dispatch: any) => {
    const {
      toorakLoanID,
      form = {},
      reload = false,
      status,
      autoSave = false
    } = options;
    const appraisalID = getAppraisalID();
    if (!appraisalID) return;
    const url = `${getAppraisalServiceUrl()}/${appraisalID}`;
    const mappedForm: Partial<AppraisalForm> = {
      ...form,
      status,
      originatorPartyID: getCookie("org_id"),
      toorakLoanID
    };
    try {
      if (autoSave === false) dispatch(showLoader());
      const body = mapAppraisalData(mappedForm, "service");
      await publicClient.post(url, body);
      // dispatch({ type: UPDATE_APPRAISAL_INFO_SUCCESS, payload: {} });
      dispatch(setFalseAppraisalInfoSuccess());
      if (autoSave === false) dispatch(hideLoader());
      if (reload) window.location.reload();
    } catch (e: any) {
      alertOnDuplicateAppraisal(e.response);
      // dispatch({ type: UPDATE_APPRAISAL_INFO_FAILURE, payload: {} });
      dispatch(setAppraisalInfoFailure());

      if (reload) window.location.reload();
      if (autoSave === false) dispatch(hideLoader());
    }
  };
};

export async function convertAppraisal(options: {
  toorakLoanID: string;
  newStage: LoanStage;
}): Promise<void> {
  const { toorakLoanID, newStage } = options;
  const appraisalID = getAppraisalID();
  if (!appraisalID) return;
  const url = `${getAppraisalServiceUrl()}/${appraisalID}`;
  const mappedForm: Partial<AppraisalForm> = {
    convertedTo: newStage,
    originatorPartyID: getCookie("org_id"),
    toorakLoanID
  };
  try {
    const body = mapAppraisalData(mappedForm, "service");
    await publicClient.post(url, body);
  } catch (e) {
    console.error(e);
  }
}
