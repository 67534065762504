/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
import {
  CircularProgress,
  DialogContent,
  Grid,
  Table,
  TableContainer,
  Typography
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import React, { useCallback, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { LoanStatusEnum } from "@toorak/tc-common-util-sdk";
import { LoanHistoryStyle } from "./LoanHistoryStyles";
import { TableHeader } from "../../purchase-and-settlements/components/TableHeader";
import { LoanHistoryHeader, LoanActions } from "./constants";
import { fetchLoanLandmarks } from "../../create-loan.action";
import { TableBodyComponent } from "../../purchase-and-settlements/components/TableBodyComponent";
import {
  getUserFullAndOrgName,
  genFormattedUserDetails
} from "../../../stores/EvaluationResults/EvaluationResults.action";
import { getTimeFromDate } from "../../../utils/utils"; // checkIfToorvalLoan
// import {
//   getAppraisalData,
//   getAppraisalLandmarks
// } from "../../../network/apiService";
import { genMaskedOrgBundleStatus } from "../../purchase-and-settlements/constants";
import { onHoldEnumValue } from "../../on-hold-loan/AddOnHoldDialog";

interface LoanHistoryProps {
  loanId: string;
  originatorId: string;
  onClose: any;
  isLoanTableFunding?: boolean;
  isLoanAdvanceFunding?: boolean;
}

export const LoanHistory = (props: LoanHistoryProps) => {
  const { loanId, originatorId, onClose, isLoanTableFunding = false, isLoanAdvanceFunding = false } = props;
  const classes = LoanHistoryStyle();
  const [landMarks, setLandMarks] = useState<any>(null);
  const [history, setHistory] = useState<any>([]);
  const [hasError, setHasError] = useState(false);

  const createLoanActions = ["loan_creation", "loan_conversion"];
  const statusUpdateActions = ["update_status", "update_bundle_status"];
  const dataEntryStatus = [
    LoanStatusEnum.Submit,
    LoanStatusEnum.ExcelError,
    LoanStatusEnum.DataEntry
  ];

  // const { count, showCount } = useSelector<RootState, any>(
  //   (state) => state.testStore
  // );

  useEffect(() => {
    if (landMarks) {
      let loanStage = "";
      let loanStatus = "";
      let LoanAndBundleLandmarks = [];
      if (
        landMarks.bundleLandmarks.some(
          (data: any) => data.value === LoanStatusEnum.Purchased
        )
      ) {
        LoanAndBundleLandmarks = [
          ...landMarks.loanLandmarks.filter(
            (update: any) => update.value !== LoanStatusEnum.Purchased
          ),
          ...landMarks.bundleLandmarks
        ];
      } else {
        LoanAndBundleLandmarks = [
          ...landMarks.loanLandmarks,
          ...landMarks.bundleLandmarks
        ];
      }

      const historyData = LoanAndBundleLandmarks.filter(
        (value: any) => LoanActions[value.name] && value.stage !== LoanStage.tv
      )
        .map((data: any) => {
          // if(LoanActions[data.name]) {
          if (createLoanActions.includes(data.name)) {
            if (data.value === LoanStage.pre) loanStage = "Pre Close";
            else if (data.value === LoanStage.post) loanStage = "Post Close";
            else loanStage = "GuideLine Evaluation";
            loanStatus = ((isLoanTableFunding || isLoanAdvanceFunding) && data.value === LoanStage.post) ? LoanStatusEnum.Purchased: LoanStatusEnum.DataEntry;
          }
          if (statusUpdateActions.includes(data.name)) loanStatus = data.value;
          if (data.name === "update_status") {
            if (loanStage === "GuideLine Evaluation" && data.value === "submit")
              loanStatus = "Preliminary Sizing";
            if (
              loanStage !== "GuideLine Evaluation" &&
              dataEntryStatus.includes(data.value)
            )
              loanStatus = LoanStatusEnum.DataEntry;
            if (data.value === LoanStatusEnum.DueDiligenceReview)
              loanStatus = "Review In Progress";
          }
          const customerDetails = {
            customers: landMarks?.customers,
            accountMapping: landMarks?.accountMapping,
            accounts: landMarks?.accounts
          };
          const actionBy: any = getUserFullAndOrgName(
            customerDetails,
            data.createdBy,
            data.date
          );
          if (data.createdBy?.trim() === "" && actionBy) {
            actionBy.NameInitials = undefined;
            actionBy.userFullName = "Toorak Connect";
            actionBy.accountTypeFirstLetter = "";
          }
          if (data?.isSystemUpdate) {
            actionBy.userFullName = onHoldEnumValue.systemValueToBeShown;
          }
          let action = LoanActions[data.name];
          if (data.name === "update_hold_status") {
            action = data.value === "NONE" ? "Revoked on Hold" : "Put on Hold";
          }
          if (data.name === "update_loan_scenarios") {
            if (data.value === "add scenario") action = "Scenario Added";
            else if (data.value === "delete scenario")
              action = "Scenario Deleted";
            else action = LoanActions[data.name];
          }
          const userDetails = { ...actionBy, action };
          const date = getDate(data.date || data.createdOn);
          const rawDate = data.date || data.createdOn;
          const valueArr = [];
          // const status = (data.name === "loan_creation" || data.name === "loan_conversion") ? "Data Entry" : data.value;
          valueArr.push(
            { value: loanStage },
            { value: loanStatus },
            { value: date, rawDate },
            { userDetails }
          );
          if (data.name === "loan_delete") {
            if (data.stage === LoanStage.pre) loanStage = "Pre Close";
            else if (data.stage === LoanStage.post) loanStage = "Post Close";
            else loanStage = "GuideLine Evaluation";
          }
          return valueArr;
          // }
        })
        .sort(
          (a: any, b: any) => Date.parse(a[2].rawDate) - Date.parse(b[2].rawDate)
        );
      setHistory([historyData]);
    }
    // eslint-disable-next-line
  }, [landMarks]);

  useEffect(() => {
    fetchLoanLandmarks(loanId)
      .then((resp: any) => {
        setLandMarks(resp.data);
      })
      .catch((err: any) => {
        setHasError(true);
        console.log(err);
      });
  }, [loanId]);

  useEffect(() => {
    if (!landMarks) return;
    if (Object.keys(landMarks).length) landmarksPrep(landMarks);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [landMarks]);

  const getUserDetails = useCallback(
    (data: any) => {
      const customerDetails = {
        customers: landMarks?.customers,
        accountMapping: landMarks?.accountMapping,
        accounts: landMarks?.accounts
      };
      let actionByProps: any = {};
      if (data.createdBy?.trim() === "") {
        actionByProps = {
          ...actionByProps,
          NameInitials: undefined,
          userFullName: "Toorak Connect",
          accountTypeFirstLetter: ""
        };
      } else {
        actionByProps =
          genFormattedUserDetails(
            customerDetails,
            data.createdBy,
            data.date || data.createdOn,
            false
          ) || {};
      }
      let actionLabel = LoanActions[data.name];
      if (data.name === "update_hold_status") {
        actionLabel = data.value === "NONE" ? "Revoked on Hold" : "Put on Hold";
      }
      if (data.name === "update_loan_scenarios") {
        if (data.value === "add scenario") actionLabel = "Scenario Added";
        else if (data.value === "delete scenario")
          actionLabel = "Scenario Deleted";
      }
      return { ...actionByProps, action: actionLabel };
    },
    [landMarks]
  );

  const landmarksPrep = (landMarksData: any) => {
    let loanStage = "";
    let loanStatus = "";
    let LoanAndBundleLandmarks: any[] = [];
    if (
      landMarksData.bundleLandmarks.some(
        (data: any) => data.value === LoanStatusEnum.Purchased
      )
    ) {
      LoanAndBundleLandmarks = [
        ...landMarksData.loanLandmarks.filter(
          (update: any) => update.value !== LoanStatusEnum.Purchased
        ),
        ...landMarksData.bundleLandmarks
      ];
    } else
      LoanAndBundleLandmarks = [
        ...landMarksData.loanLandmarks,
        ...landMarksData.bundleLandmarks
      ];
    const historyData: any[] = LoanAndBundleLandmarks.reduce(
      (acc: any[], data: any) => {
        if (!(data.name in LoanActions)) {
          return acc;
        }
        if (createLoanActions.includes(data.name)) {
          if (data.value === LoanStage.pre) loanStage = "Pre Close";
          else if (data.value === LoanStage.post) loanStage = "Post Close";
          else if (data.value === LoanStage.tv) loanStage = "ToorVal";
          else loanStage = "GuideLine Evaluation";
          loanStatus = ((isLoanTableFunding || isLoanAdvanceFunding) && data.value === LoanStage.post) ? LoanStatusEnum.Purchased: LoanStatusEnum.DataEntry;
        }
        if (
          data.name === "loan_conversion" && // loan conversion post purchased for table fund loans
          LoanAndBundleLandmarks[0].value === LoanStage.pre &&
          isLoanTableFunding
        ) {
          loanStage = "Post Close";
          loanStatus = LoanStatusEnum.Purchased;
        }
        if (statusUpdateActions.includes(data.name)) loanStatus = data.value;
        if (data.name === "update_status") {
          if (loanStage === "GuideLine Evaluation" && data.value === "submit")
            loanStatus = "Preliminary Sizing";
          if (loanStage === "ToorVal" && data.value === "submit")
            loanStatus = "Under Review";
          if (loanStage === "Toorval") loanStatus = data.value;
          if (
            loanStage !== "GuideLine Evaluation" &&
            loanStage !== "ToorVal" &&
            dataEntryStatus.includes(data.value)
          )
            loanStatus = LoanStatusEnum.DataEntry;
          if (data.value === LoanStatusEnum.DueDiligenceReview)
            loanStatus = "Review In Progress";
        }
        if (data.name === "update_bundle_status") {
          loanStatus = genMaskedOrgBundleStatus(data.value);
          if (data.stage === LoanStage.pre) loanStage = "Pre Close";
          else if (data.stage === LoanStage.post) loanStage = "Post Close";
        }
        if (data.name === "loan_delete") {
          if (data.stage === LoanStage.pre) loanStage = "Pre Close";
          else if (data.stage === LoanStage.post) loanStage = "Post Close";
          else if (data.value === LoanStage.tv) loanStage = "ToorVal";
          else loanStage = "GuideLine Evaluation";
        }
        const userDetails = getUserDetails(data);
        if (data?.isSystemUpdate) {
          userDetails.userFullName = onHoldEnumValue.systemValueToBeShown;
        }
        const date = getDate(data.date || data.createdOn);
        const rawDate = data.date || data.createdOn;
        return [
          [
            { value: loanStage },
            { value: loanStatus },
            { value: date, rawDate },
            { userDetails }
          ],
          ...acc
        ];
      },
      []
    ).sort((a: any, b: any) => {
      return Date.parse(a[2]?.rawDate) - Date.parse(b[2]?.rawDate);
    });
    setHistory([historyData]);
  };

  const sortCallBack = useCallback(
    (sortOrder: string) => {
      if (history?.length) {
        const loanLandMarks = JSON.parse(JSON.stringify(history[0]));
        loanLandMarks.sort((a: any, b: any) =>
          sortOrder === "ASC"
            ? Date.parse(a[2].value) - Date.parse(b[2].value)
            : Date.parse(b[2].value) - Date.parse(a[2].value)
        );
        setHistory([loanLandMarks]);
      }
    },
    [history]
  );

  const filterCallBack = useCallback(() => {
    console.log("filter callback called");
  }, []);

  const getDate = (date: string) => {
    const formattedDate = getTimeFromDate(date, "MM/DD/YYYY, HH:mm");
    return formattedDate;
  };

  const loader = () => {
    return (
      <>
        <Grid className={classes.loaderModal}>
          <Grid
            xs={12}
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
          >
            <Grid>
              {hasError ? (
                <Typography>Unable to fetch Loan History</Typography>
              ) : (
                <>
                  <CircularProgress size={35} style={{ marginLeft: 15 }} />
                  <Typography>Loading...!</Typography>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <>
      <Dialog
        fullWidth
        maxWidth="md"
        open
        className={classes.dialog}
        style={{ height: "90%" }}
        onClose={() => onClose(false)}
        aria-labelledby="loan-history-dialog"
        aria-describedby="loan-status-history"
      >
        <DialogContent>
          <Grid
            container
            style={{
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100% - 75px)"
            }}
          >
            {/* main modal body */}
            <Grid item xs={12} sm={12}>
              {/* modal header */}
              <Grid container style={{ padding: "20px 28px" }}>
                <Grid item xs={11}>
                  <b>Loan History</b>
                  <Grid style={{ width: "100%" }}>
                    Toorak/Originator Loan ID:{" "}
                    <b>
                      {loanId}/{originatorId}
                    </b>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={1}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end"
                  }}
                >
                  <CloseIcon
                    htmlColor="#414169"
                    className={classes.closeIcon}
                    onClick={() => onClose(false)}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* {!landMarks && !isAppraisalHistoryLoading ? ( */}
            {history.length === 0 ? (
              loader()
            ) : (
              <Grid container style={{ height: "330px", overflowY: "auto" }}>
                {/* modal body */}
                <TableContainer className={classes.tableWrapper}>
                  <Table stickyHeader id="pricingTable1">
                    <TableHeader
                      data={LoanHistoryHeader.map((value: any) => {
                        return value;
                      })}
                      sortCallBack={sortCallBack}
                      filterCallBack={filterCallBack}
                      isHistoryTable
                      style={{
                        th: {
                          padding: "10px 0px 7px 30px",
                          borderRight: "1px solid rgb(190 199 208 / 51%)",
                          fontWeight: 600
                        }
                      }}
                    />
                    {history.length > 0 &&
                      history.map((rowData: any) => {
                        return (
                          <TableBodyComponent
                            style={{
                              td: {
                                height: "64px",
                                width: "25%",
                                paddingLeft: "30px",
                                borderRight: "1px solid rgb(190 199 208 / 51%)"
                              }
                            }}
                            data={rowData}
                          />
                        );
                      })}
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
