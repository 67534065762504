import React from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import createStyles from "@mui/styles/createStyles";
import makeStyles from "@mui/styles/makeStyles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import { AppBar, Button, Grid, Typography } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { convertLoan, deleteLoan } from "../create-loan.action";
import { push } from "connected-react-router";
// import { setTabView } from "../../stores/SettlementAndPurchase/SettlementAndPurchase.action";
import { LoanStage } from "@toorak/tc-common-fe-sdk";
import { setLoanToConvert, setloanToDelete } from "../create-loan.reducer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loanCreationTypography: {
      alignSelf: "center",
      color: "#FFF"
    },
    headerText: {
      fontSize: "1em",
      [theme.breakpoints.down("sm")]: {
        fontSize: "0.8em"
      }
    },
    paper: {
      width: "50%",
      marginTop: "10%",
      marginLeft: "20%"
    },
    table: {
      minWidth: 650
    },
    fixedHeader: {
      position: "fixed",
      height: "60px",
      top: "0"
    },
    cards: {
      height: "246px",
      margin: "50px 33px 0 44px",
      padding: "54px 68px 35px 67px",
      boxShadow: "0 20px 20px 0 #e2e4f1",
      backgroundColor: "#fbfbfd"
    },
    saveButton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      marginTop: "20px",
      position: "absolute",
      right: "30%"
    },
    convertButton: {
      width: 150,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      marginTop: "20px",
      position: "absolute",
      right: "30%"
    },
    dialogButton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      margin: "20px 10px",
      color: "white",
      border: "0px",
      cursor: "pointer"
    },
    disableDialogButton: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#bdc1da",
      margin: "20px 10px",
      color: "white",
      border: "0px"
    },
    actionButtons: {
      width: 70,
      height: 28,
      borderRadius: 4,
      boxShadow: "1 2 2 0 rgba(0, 0, 0, 0.24)",
      backgroundColor: "#5e72e4",
      marginTop: "50px",
      marginLeft: "60px"
    }
  })
);

export const DeleteLoan = (props: any) => {
  const { loanId, context } = useParams() as any;
  console.log("aaaa " + loanId + " " + context);
  const classes = useStyles();
  let loanData: any = sessionStorage.getItem("loanToDeleteData");
  let loanDataCopy = JSON.parse(loanData);
  console.log(JSON.parse(loanData));
  function createData(key: string, value: any) {
    return { key, value };
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [first, setFirst] = React.useState("");
  const [second, setSecond] = React.useState("");
  const [third, setThird] = React.useState("");
  const [fourth, setFourth] = React.useState("");
  const [fifth, setFifth] = React.useState("");
  const [sixth, setSixth] = React.useState("");
  const [loanStage, setloanStage] = React.useState("");
  const [isActionDone, setIsActionDone] = React.useState(false);

  if (!loanStage) {
    setloanStage(loanDataCopy.loanStage);
  }
  const deletePopUp = () => {
    setOpen(true);
  };
  const deleteConfirm = () => {
    if (first + second + third + fourth + fifth + sixth === "DELETE") {
      setOpen(false);
      deleteLoan(loanId, loanStage).then((res: any) => {
        console.log(res.status);
        if (res.status === 204) {
          dispatch(setloanToDelete({ loanNumber: "" }));
          sessionStorage.removeItem("loanToDeleteData");
          setIsActionDone(true);
          setOpen(true);
        }
      });
    } else console.log("type correctly");
  };

  async function convertionConfirm() {
    if (loanDataCopy?.loanStage === "FES") {
      convertLoan(loanId, loanDataCopy?.loanStage, LoanStage.pre, false).then(
        (res: any) => {
          setloanStage(res.data.loanData.loanStage);
          if (res.status === 200) {
            dispatch(
              setLoanToConvert({
                loanNumber: ""
              })
            );
            sessionStorage.removeItem("loanToDeleteData");
            setIsActionDone(true);
            setOpen(true);
          }
        }
      );
    } else if (loanDataCopy?.loanStage === "PRE") {
      convertLoan(loanId, loanDataCopy?.loanStage, LoanStage.post, false).then(
        (res: any) => {
          setloanStage(res.data.loanData.loanStage);
          if (res.status === 200) {
            // dispatch({
            //   type: LOAN_TO_CONVERT,
            //   payload: {
            //     loanNumber: ""
            //   }
            // });
            dispatch(
              setLoanToConvert({
                loanNumber: ""
              })
            );
            sessionStorage.removeItem("loanToDeleteData");
            setIsActionDone(true);
            setOpen(true);
          }
        }
      );
    }
  }

  const redirectToAdmin = () => {
    setIsActionDone(false);

    // dispatch(setTabView(1));
    navigate("/support/home/page");
  };

  const deleteCancel = () => {
    setFirst("");
    setSecond("");
    setThird("");
    setFourth("");
    setFifth("");
    setSixth("");
    setOpen(false);
  };

  const rows = [
    createData("Loan Type", loanDataCopy?.loanType),
    createData("Status", loanDataCopy?.loanState),
    createData("Originator Loan Id", loanDataCopy?.loanInfo?.primaryLoanId),
    createData("Loan Stage", loanDataCopy?.loanStage),
    createData("Originator", loanDataCopy?.originatorInfo?.accountName)
  ];
  return (
    <>
      <AppBar color="default" position="static" className={classes.fixedHeader}>
        <Grid container spacing={1}>
          <Grid item>
            <Button
              data-testid="nav-back"
              variant="contained"
              color="primary"
              startIcon={
                <KeyboardArrowLeftIcon className={classes.headerText} />
              }
              onClick={() => {
                // dispatch({
                //   type: LOAN_TO_DELETE,
                //   payload: {
                //     loanNumber: ""
                //   }
                // });
                dispatch(
                  setloanToDelete({
                    loanNumber: ""
                  })
                );
                // dispatch(setTabView(5));
                // dispatch(push("/loans/list?tab=5"));
                navigate("/support/loans/page");
              }}
              // onClick={() =>
              //     condole.log("hi");
              // //   dispatch(push(waiverDetails.details?.backLocation))
              // }
            >
              <Typography className={classes.headerText}>Back</Typography>
            </Button>
          </Grid>
          <Grid item className={classes.loanCreationTypography}>
            <Typography className={classes.headerText}>
              Loan Deletion Request • Loan ID: {loanId}
            </Typography>
          </Grid>
        </Grid>
      </AppBar>
      <TableContainer className={classes.paper} component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>
                <b>Loan Number</b>{" "}
              </TableCell>
              <TableCell align="center">{loanId}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.key}>
                <TableCell component="th" scope="row">
                  <b> {row.key}</b>
                </TableCell>
                <TableCell align="center">{row.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {context === "delete" ? (
        <Button
          variant="contained"
          data-testid="save-button"
          className={classes.saveButton}
          onClick={() => {
            deletePopUp();
          }}
          color="info"
        >
          Delete
        </Button>
      ) : (
        <Button
          variant="contained"
          data-testid="save-button"
          className={classes.convertButton}
          disabled={loanStage === LoanStage.post}
          onClick={() => {
            convertionConfirm();
          }}
          color="info"
        >
          {loanStage === LoanStage.fes
            ? "Convert to PRE"
            : loanStage === LoanStage.pre
            ? "Convert to POST"
            : "Convert"}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={deleteCancel}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Please Confirm</DialogTitle>
        <DialogContent>
          {!isActionDone ? (
            <div>
              <DialogContentText>
                Are you sure you want to delete the Loan number..? Please Type{" "}
                <b>DELETE( Caps On)</b> In below Boxes.
              </DialogContentText>
              <form>
                <TextField
                  autoFocus
                  margin="none"
                  id="first"
                  variant="outlined"
                  type="text"
                  style={{ width: 40, marginRight: 10 }}
                  inputProps={{ maxLength: 1 }}
                  onChange={(e) => setFirst(e.target.value)}
                />
                <TextField
                  inputProps={{ maxLength: 1 }}
                  margin="none"
                  id="second"
                  variant="outlined"
                  type="text"
                  style={{ width: 40, marginRight: 10 }}
                  onChange={(e) => setSecond(e.target.value)}
                />
                <TextField
                  inputProps={{ maxLength: 1 }}
                  margin="none"
                  id="third"
                  variant="outlined"
                  type="text"
                  style={{ width: 40, marginRight: 10 }}
                  onChange={(e) => setThird(e.target.value)}
                />
                <TextField
                  inputProps={{ maxLength: 1 }}
                  margin="none"
                  id="fourth"
                  variant="outlined"
                  type="text"
                  style={{ width: 40, marginRight: 10 }}
                  onChange={(e) => setFourth(e.target.value)}
                />
                <TextField
                  inputProps={{ maxLength: 1 }}
                  margin="none"
                  id="fifth"
                  variant="outlined"
                  type="text"
                  style={{ width: 40, marginRight: 10 }}
                  onChange={(e) => setFifth(e.target.value)}
                />
                <TextField
                  inputProps={{ maxLength: 1 }}
                  margin="none"
                  id="sixth"
                  variant="outlined"
                  type="text"
                  style={{ width: 40 }}
                  onChange={(e) => setSixth(e.target.value)}
                />
              </form>
            </div>
          ) : context === "delete" ? (
            <DialogContentText>Loan ID: {loanId} is deleted.</DialogContentText>
          ) : (
            <DialogContentText>
              Loan ID: {loanId} is converted to {loanStage} loan stage.
            </DialogContentText>
          )}
        </DialogContent>
        <DialogActions>
          {!isActionDone ? (
            <div>
              <Button
                variant="contained"
                onClick={deleteConfirm}
                disabled={
                  !(
                    first + second + third + fourth + fifth + sixth ===
                    "DELETE"
                  )
                }
                className={classes.dialogButton}
                color="primary"
              >
                Delete
              </Button>
              <Button
                onClick={deleteCancel}
                className={classes.dialogButton}
                color="primary"
              >
                Cancel
              </Button>
            </div>
          ) : (
            <Button
              onClick={redirectToAdmin}
              className={classes.dialogButton}
              color="primary"
            >
              OK
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};
